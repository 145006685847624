import { useEffect, useRef, useState } from "react";
import util from '../utilities/util';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { SlideMenu } from "primereact/slidemenu";
import { SCDialog, SCInputText } from "../components";
import AjudaFormTela from "./AjudaFormTela";
import AlunosEstagiosForm from "./AlunosEstagiosForm";
import { useForm } from "react-hook-form";
import { useToast } from "../context/ToastContext";
import api from "../services/api";
import { ALERTAS } from "../utilities/constantes";
import IAlunosEstagios from "../interfaces/IAlunosEstagios";
import { useAuth } from "../providers/auth";
import { IAlunoForm } from "../interfaces/IAlunos";


const AlunosEstagios = () => {
    const toast = useToast();
    const tarefa = 'AlunosEstagios';

    const { periodoSelecionado } = useAuth();

    let emptyEstagio: IAlunosEstagios = {
        AlunosEstagioId: undefined,
        EmpresaId: 0,
        Matricula: 0,
        TurmaId: '',
        DisciplinaId: '',

        AlunosEstagioItemId: undefined,
        ConvenioId: 0,
        FuncionarioId: 0,
        Funcionario: '',
        Detalhamento: '',
        DataInicial: new Date(''),
        DataFinal: new Date(''),
        CargaHoraria: 0,
        EmpresaOrgao: '',
        AgenteIntegrador: '',
        Remuneracao: 0,
        ValeTransporte: 0,
        ValeRefeicao: 0,
        Observacao: '',
        Concluido: false,

        AlunosEstagioItem: [],
    };

    let alunoValue: IAlunoForm = {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Nome: '',
        AlunoId: undefined,
        RGOrgaoExpeditorUF: '',
        PaiRGOrgaoExpeditorUF: '',
        MaeRGOrgaoExpeditorUF: '',
    };

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [estagiarios, setEstagiarios] = useState<any>(null);
    const [estagiario, setEstagiario] = useState<IAlunosEstagios>(emptyEstagio);
    const [estagiosDialog, setEstagiosDialog] = useState<boolean>(false);
    const [deleteEstagiarios, setDeleteEstagiarios] = useState<boolean>(false);
    const [aluno, setAluno] = useState<IAlunoForm>(alunoValue);

    const dt = useRef<any>(null);
    const menu = useRef<any>(null);

    const methods = useForm({ defaultValues: emptyEstagio });
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

    const load = async () => {
        const result = await api.get('/alunosEstagio/list', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });

        if (result.data)
            setEstagiarios(result.data);
        else
            setEstagiarios([])
    }

    useEffect(() => {
        load();
    }, []);

    const hideDialog = () => {
        setEstagiosDialog(false)
    };

    const onSubmit = async (data: any) => {
        load();
    }

    const openNew = () => {
        setEstagiario(emptyEstagio)
        setAluno(alunoValue)
        setEstagiosDialog(true)
    };

    const editar = async (item: any) => {
        const alunos = await api.get('/alunosEstagio/alunosList', { params: { Matricula: item.Matricula, EmpresaId: periodoSelecionado?.EmpresaId } })

        const alunoperiodo = await api.get('/alunosPeriodo/matricula', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Matricula: alunos.data[0].Matricula
            }
        });

        alunos.data[0].Turma = {
            Codigo: alunoperiodo.data.TurmaCodigo,
        };

        alunos.data[0].AlunosPeriodo = alunoperiodo.data;
        alunos.data[0].DataNascimento = alunos.data.DataNascimento ? new Date(alunos.data.DataNascimento) : new Date();

        setAluno(alunos.data[0]);
        setEstagiario(item)
        setEstagiosDialog(true)
    };

    const opcoes = [
        {
            label: 'Imprimir',
            icon: 'pi pi-print',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Exp. para Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Ajuda',
            icon: 'pi pi-question-circle',
            command: () => {
                dt.current.exportCSV();
            }
        },
    ];

    const openMenu = (event: any) => {
        menu?.current?.toggle(event)
    };

    const confirmDelete = (item: any) => {
        setEstagiario(item)
        setDeleteEstagiarios(true)
        setEstagiosDialog(true)
    };

    const deletar = async () => {
        try {
            const respEstagio = await api.delete('/alunosEstagio', { params: { AlunosEstagioId: estagiario.AlunosEstagioId } });
            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
            setEstagiosDialog(false)
            setDeleteEstagiarios(false)
            setEstagiario(emptyEstagio)
            load();

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }

    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Alunos Estágio</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
                        <div className='grid'>
                            <div className="col-12 md:col-8">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="Descricao"
                                    label='Descrição'
                                    className='w-full'
                                />
                            </div>
                            <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
                            </div>
                        </div>
                    </form>
                    <Divider />

                    <DataTable
                        size='small'
                        stripedRows
                        ref={dt}
                        value={estagiarios}
                        //onSelectionChange={(e) => setSelectedCartao(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhum registro encontrado."
                        header={null}
                        responsiveLayout="scroll">

                        <Column
                            field="Nome"
                            header="Aluno"
                        />
                        <Column
                            field="Matricula"
                            header="Matricula"
                        />
                        <Column
                            field="Codigo"
                            header="Curso"
                        />
                        <Column body={(rowData: any) => {
                            return (
                                <div className="actions flex align-items-center justify-content-between">
                                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                                </div>
                            );
                        }} style={{ width: 90 }}
                        />
                    </DataTable>

                    <SCDialog maximized={!util.isDesktop()} visible={estagiosDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
                        header={`${estagiario.AlunosEstagioId === undefined ? 'Cadastrando ' : (deleteEstagiarios ? 'Excluíndo' : 'Alterando')} Estágios`}
                        modal className="p-fluid" onHide={hideDialog}
                    >
                        <AlunosEstagiosForm
                            setEstagioDialog={setEstagiosDialog}
                            aluno={aluno}
                            estagiario={estagiario}
                            reload={load}
                            deleteEstagiarios={deleteEstagiarios}
                            setDeleteEstagiarios={setDeleteEstagiarios}
                            deletar={deletar}
                        />
                    </SCDialog>
                </div>
            </div>
        </div>
    );
};

export default AlunosEstagios