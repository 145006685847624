import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCalendar, SCDropdown, SCFieldset, SCInputMask, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IAbastecimento from '../interfaces/IAbastecimento';
import { useAuth } from '../providers/auth';
import { format, parse } from 'date-fns';


interface Props {
  abastecimento?: IAbastecimento;
  setAbastecimentoDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteAbastecimento: boolean;
  setDeleteAbastecimento(param: boolean): void;
}

const AbastecimentoCaixaForm: React.FC<Props> = ({ setAbastecimentoDialog, abastecimento, reload, deletar, deleteAbastecimento, setDeleteAbastecimento }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'AbastecimentoCaixaForm';

  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const [usuarios, setUsuarios] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues: IAbastecimento = abastecimento || {
    AbastecimentoId: undefined,
    AbastecimentoData: new Date(''),
    AbastecimentoHora: '',
    UsuarioId: 0,
    Valor: 0,
    CadastradoData: new Date(''),
    CadastradoHora: '',
    CadastradoOperador: ''
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const isMaster = usuario?.Master;
  const usuarioIdLogado = usuario?.UsuarioId;

  const load = async () => {
    let resp;
    try {
      resp = await api.get('/usuario/listaUsuarioEmpresa', {
        params: { EmpresaId: periodoSelecionado?.EmpresaId },
      });

      if (resp.data) {
        if (!isMaster) {
          let usuarioMaster = resp.data.find((user: any) => user.UsuarioId == usuarioIdLogado)
          setUsuarios(usuarioMaster ? [usuarioMaster] : []);

        } else {
          setUsuarios(resp.data);
        }

      } else {
        setUsuarios([]);
      }

    } catch (error) {
      console.error('Erro ao carregar usuários:', error);
    }

    if (!abastecimento?.AbastecimentoId) {
      setValue('UsuarioId', usuario?.UsuarioId)
    }

    if (!deleteAbastecimento) {
      setValue('AbastecimentoData', new Date())
      setValue('AbastecimentoHora', format(new Date(), 'HH:mm'))
      setValue('CadastradoData', new Date())
      setValue('CadastradoHora', format(new Date(), 'HH:mm'))
      setValue('CadastradoOperador', usuario?.Apelido)
    }
  }

  const onSubmit = async (data: any) => {
    setLoading(true)

    if (data.AbastecimentoData) {
      const dateFromCode = new Date(data.AbastecimentoData);

      // Converte a data para o formato YYYY-MM-DD
      console.log('dateFromCode', dateFromCode)
      const formattedDate = format(dateFromCode, 'yyyy-MM-dd');
      data.AbastecimentoData = formattedDate
      console.log('formattedDate', formattedDate)

      if (new Date(data.AbastecimentoData) > new Date()) {
        toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Data Inválida!' });
        return false
      }
    }

    if (!data.Valor || data.Valor == 0 || data.Valor < 1) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Valor Inválido!' });
      return false
    }

    let resp: any = undefined;

    try {
      if (data.AbastecimentoId === undefined) {

        delete data.DataFinal
        delete data.DataInicio

        console.log(data)

        resp = await api.post('/abastecimentocaixa', data);

        if (resp.data) {
          let dadosEncerramentoCaixa = {
            EmpresaId: periodoSelecionado?.EmpresaId,
            CaixaData: data.CadastradoData,
            CaixaHora: data.CadastradoHora,
            CaixaOperador: data.CadastradoOperador
          }

          await api.post('/encerramentocaixa', dadosEncerramentoCaixa)
        }
      }

      setLoading(false)
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setAbastecimentoDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const validaDataAbastecimento = () => {
    if (!getValues()?.AbastecimentoData) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Preencha da Data de Abastecimento!' });
      return false
    } else {
      return true
    }
  }

  const validaUsuario = () => {
    if (!getValues()?.UsuarioId) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Preencha o campo Usuário!' });
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    load()
  }, [])

  const cancelar = (e: any) => {
    setDeleteAbastecimento(false);
    setAbastecimentoDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCCalendar
            label='Data'
            control={control}
            name='AbastecimentoData'
            dateFormat='dd/mm/yy'
            mask='99/99/9999'
            showIcon
            disabled={deleteAbastecimento}
            validate={validaDataAbastecimento}
          />
        </div>

        <div className="col-12 md:col-1">
          <SCInputMask
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="AbastecimentoHora"
            label='Hora'
            required={!deleteAbastecimento}
            disabled={deleteAbastecimento}
            mask='99:99'
          />
        </div>
      </div>

      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCDropdown
            control={control}
            errors={errors}
            name="UsuarioId"
            label='Usuário'
            options={usuarios}
            optionLabel='Apelido'
            optionValue='UsuarioId'
            disabled={deleteAbastecimento}
            validate={validaUsuario}
          />
        </div>
      </div>

      <div className='grid'>
        <div className="col-6 md:col-2">
          <SCInputText
            control={control}
            errors={errors}
            name="Valor"
            label='Valor (R$)'
            required={!deleteAbastecimento}
            disabled={deleteAbastecimento}
          />
        </div>
      </div>

      <SCFieldset legend='Log de Cadastrado' className='col-12 mt-3'>
        <div className='grid'>

          <div className="col-12 md:col-2">
            <SCCalendar
              label='Data'
              control={control}
              name='CadastradoData'
              dateFormat='dd/mm/yy'
              mask='99/99/9999'
              showIcon
              disabled
            />
          </div>


          <div className="col-12 md:col-1">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="CadastradoHora"
              label='Hora'
              disabled
            />
          </div>

          <div className="col-12 md:col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="CadastradoOperador"
              label='Usuário'
              disabled
            />
          </div>
        </div>

        <div className='grid'>

        </div>
      </SCFieldset>

      <div className='grid mt-3'>
        <Divider />
        {deleteAbastecimento ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default AbastecimentoCaixaForm;
