import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, SITUACAO_ALUNO, TURNOS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import IAluno, { IAlunoForm, IAlunoPost } from '../interfaces/IAlunos';
import { format } from 'date-fns';
import AlunosForm from './AlunosForm';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { loadAlunosPeriodo, loadDadosAlunos } from '../utilities/alunosUtil';
import AlunosEstagiosForm from './AlunosEstagiosForm';
import IAlunosEstagios from '../interfaces/IAlunosEstagios';
import TrocaTurmaAlunoForm from './TrocaTurmaAlunoForm';


interface IFormAlunoConsulta {
    Nome?: string;
}

const Alunos = () => {
    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const tarefa = 'AlunoForm';
    // Access the client
    const queryClient = useQueryClient()

    let emptyRegistro: IAlunoForm = {
        Loading: false,
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        AlunosPeriodo: {
            AlunosPeriodoId: null,
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
        },
        CursoId: '',
        Turno: '',
        NivelSequenciaId: '',
    };

    const [filter, setFilter] = useState<IFormAlunoConsulta>({
        Nome: ''
    });

    const { data, isLoading } = useQuery('litar-alunos', () => {
        return api.get('/aluno/list', {
            params: {
                Nome: filter.Nome,
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        });
    }, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        cacheTime: 0
    })

    const dt = useRef<any>(null);
    const menu = useRef<any>(null);

    const methods = useForm({ defaultValues: emptyRegistro });
    const { handleSubmit, control, formState: { errors }, getValues, reset, trigger } = methods;

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [estagiario, setEstagiario] = useState<any>();
    const [trocaTurmaDialog, setTrocaTurmaDialog] = useState<boolean>(false);
    const [registroSelecionado, setRegistroSelecionado] = useState<any>(null);
    const [aluno, setAluno] = useState<IAlunoForm>(emptyRegistro);

    const load = async () => {

    }

    useEffect(() => {
        load();
    }, []);

    const hideDialog = () => {
        setTrocaTurmaDialog(false)
    };

    const onSubmit = async (data: any) => {
        load();
    }

    const openNew = () => {
        setAluno(emptyRegistro)
        setTrocaTurmaDialog(true)
    };

    const editar = async (item: any) => {
        setAluno(item)
        setTrocaTurmaDialog(true)
    };

    const openMenu = (event: any) => {
        menu?.current?.toggle(event)
    };

    const opcoes = [
        {
            label: 'Imprimir',
            icon: 'pi pi-print',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Exp. para Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Ajuda',
            icon: 'pi pi-question-circle',
            command: () => {
                dt.current.exportCSV();
            }
        },
    ];

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Troca de Turma do Aluno</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <div className='grid pt-2'>
                        <div className="col-12 md:col-8">
                            <SCInputText
                                value={filter.Nome}
                                onChange={(e) => setFilter({ ...filter, Nome: e.target.value })}
                                name="Nome"
                                label='Nome do Aluno'
                                className='w-full'
                            />
                        </div>
                        <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                            <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={() => queryClient.fetchQuery('litar-alunos')} />
                            <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                            <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
                        </div>
                    </div>
                    <Divider />
                    {isLoading ? <Loading full={false} /> : (
                        <DataTable
                            size='small'
                            stripedRows
                            ref={dt}
                            value={data?.data}
                            onSelectionChange={(e) => setRegistroSelecionado(e.value)}
                            dataKey="AlunoId"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 20, 40]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="{first} a {last} de {totalRecords}"
                            emptyMessage="Nenhum registro encontrado."
                            header={null}
                            responsiveLayout="scroll">
                            <Column field="TurmaCodigo" header="Turma" sortable ></Column>
                            <Column field="Nome" header="Nome" sortable ></Column>
                            <Column field="Matricula" header="Matrícula" sortable></Column>
                            <Column field="SituacaoAcademica" header="Situação" sortable body={(rowData) => {
                                return SITUACAO_ALUNO.find(x => x.value === rowData.SituacaoAcademica)?.descricao;
                            }}></Column>
                            <Column body={(rowData: any) => {
                                return (
                                    <div className="actions flex align-items-center justify-content-between">
                                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                                    </div>
                                );
                            }} style={{ width: 90 }}
                            />
                        </DataTable>
                    )}
                    <FormProvider {...methods}>
                        <form className='pt-3'>

                            <SCDialog maximized={!util.isDesktop()} visible={trocaTurmaDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
                                header={`Alterando Turma`}
                                modal className="p-fluid" onHide={hideDialog}
                            >
                                <TrocaTurmaAlunoForm
                                    setTrocaTurmaDialog={setTrocaTurmaDialog}
                                    aluno={aluno}
                                    reload={load}
                                />
                            </SCDialog>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    );
};

export default Alunos;
