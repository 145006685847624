import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

import { SCButton, SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputText, SCInputTextarea, SCRadioButton, SCTabView } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, AREAS_CONHECIMENTO, GRAU_NIVEL_ESCOLARIDADE, TIPO_CURSO } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import ICurso from '../interfaces/ICurso';
import { useAuth } from '../providers/auth';
import { TabPanel, TabView } from 'primereact/tabview';
import Loading from '../components/Loading';


interface Props {
  curso?: ICurso;
  setCursoDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteCurso: boolean;
  setDeleteCurso(param: boolean): void;
}

const CursoForm: React.FC<Props> = ({ setCursoDialog, curso = null, reload, deletar, deleteCurso, setDeleteCurso }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'CursoForm';

  const toast = useToast();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const { usuario, empresaSelecionada } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [desabilitaCheck, setDesabilitaCheck] = useState<boolean>(false);

  const defaultValues: ICurso = curso || {
    EmpresaId: empresaSelecionada?.EmpresaId,
    DescricaoHistorico: '',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    setDesabilitaCheck(false)
    if (curso?.CursoId) {
      const resp = await api.get('/curso/consultaMatriz', {
        params: {
          EmpresaId: empresaSelecionada?.EmpresaId,
          CursoId: curso?.CursoId,
        }
      }
      );

      if (resp.data.length > 0) {
        setDesabilitaCheck(true)
      }
    }
  }



  const onSubmit = async (data: ICurso) => {

    data.EmpresaId = empresaSelecionada?.EmpresaId;
    console.log('curso', data);
    setLoading(true)
    // return;
    let resp: any = undefined;
    try {
      if (data.CursoId === undefined)
        resp = await api.post('/curso', data);
      else
        resp = await api.put('/curso', data);

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setLoading(false);
      reload();
      setCursoDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
      setLoading(false);
    }

  }

  const cancelar = (e: any) => {
    setDeleteCurso(false);
    setCursoDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

      {loading && (<Loading />)}

      <TabView >
        <TabPanel header="Principal">
          <div className='grid'>
            <div className="col-2">
              <SCInputText
                autoFocus={true}
                control={control}
                errors={errors}
                style={{ textTransform: 'uppercase' }}
                name="Codigo"
                label='Código'
                maxLength={8}
                required={!deleteCurso}
                disabled={curso?.CursoId !== undefined}
                tarefa={tarefa}
                tooltip='Máximo de 8 caracteres'
                tooltipOptions={{ event: 'focus' }}
              />
            </div>
          </div>
          <div className='grid'>
            <div className="col-9">
              <SCInputText
                control={control}
                errors={errors}
                name="Descricao"
                label='Descrição'
                minLength={3}
                required={!deleteCurso}
                disabled={deleteCurso}
                onBlur={(e: any) => {
                  const dados = getValues();
                  if (dados.DescricaoHistorico?.trim() === undefined || dados.DescricaoHistorico?.trim() === '')
                    setValue('DescricaoHistorico', e?.target?.value);
                }}
                tarefa={tarefa}
                tooltip='Máximo de 160 caracteres'
                tooltipOptions={{ event: 'focus' }}
                style={{ textTransform: 'uppercase' }}
              />

            </div>
          </div>
          <div className='grid'>
            <div className="col-9 ">
              <SCInputText
                control={control}
                errors={errors}
                name="DescricaoHistorico"
                label='Descrição para o histórico'
                required={!deleteCurso}
                disabled={deleteCurso}
                tarefa={tarefa}
                tooltip='Máximo de 60 caracteres'
                tooltipOptions={{ event: 'focus' }}
                style={{ textTransform: 'uppercase' }}
              />
            </div>
          </div>
          <div className='grid'>
            <div className="col-3 pt-2">
              <SCCheckbox
                control={control}
                name="ExigeMatrizCurricular"
                label='Exige Matriz Curricular'
                disabled={deleteCurso || desabilitaCheck}
              />
            </div>
          </div>

          <div className='grid'>
            <div className="col-9">
              <SCInputText
                control={control}
                errors={errors}
                name="AutorizacaoResolucao"
                label='Autorização / Resolução'
                disabled={deleteCurso}
                tarefa={tarefa}
                tooltip='Máximo de 100 caracteres'
                tooltipOptions={{ event: 'focus', position: 'bottom' }}
              />
            </div>
          </div>

          <div className='grid'>
            <div className="col-9 md:col-3">
              <SCDropdown
                control={control}
                errors={errors}
                name="TipoCurso"
                label='Nível do Curso'
                options={TIPO_CURSO}
                optionLabel='descricao'
                disabled={deleteCurso}
                tarefa={tarefa}
              />

            </div>
            {/* <div className="col-9 md:col-3">
              <SCDropdown
                control={control}
                errors={errors}
                name="NivelEscolaridade"
                label='Nível de Escolaridade'
                options={GRAU_NIVEL_ESCOLARIDADE}
                optionLabel='descricao'
                disabled={deleteCurso}
                tarefa={tarefa}
              />

            </div> */}

            <div className="col-3">
              <SCCalendar
                label='Início do Funcionamento'
                control={control}
                name='InicioFuncionamento'
                dateFormat='dd/mm/yy'
                mask='99/99/9999'
                showIcon
              />
            </div>

          </div>

          <div className='grid'>
            <div className="col-3">
              <SCInputText
                control={control}
                errors={errors}
                name="IdCursoInep"
                label='ID do Curso no INEP'
                disabled={deleteCurso}
                tarefa={tarefa}
                tooltip='Máximo de 20 caracteres'
                tooltipOptions={{ event: 'focus' }}
              />
            </div>

            <div className="col-4">
              <div className='pb-2'>Tipo (Controle da Catraca) <AjudaForm tarefa={tarefa} campo='TipoControleCatraca' /></div>
              <div className="flex align-items-center">
                <SCRadioButton
                  control={control}
                  name="TipoControleCatraca"
                  label='Colégio'
                  value='C'
                  disabled={deleteCurso}
                />
                <SCRadioButton
                  control={control}
                  name="TipoControleCatraca"
                  label='Curso Livre'
                  value='L'
                  className='ml-3'
                  disabled={deleteCurso}
                />
                <SCRadioButton
                  control={control}
                  name="TipoControleCatraca"
                  label='Faculdade'
                  value='F'
                  className='ml-3'
                  disabled={deleteCurso}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Habilitação / Competência">

          <SCFieldset legend='Habilitação' className='col-12 mt-2 mb-3' >
            <div className='grid'>
              <div className="col-3">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="HabilitacaoCodigo"
                  label='Código'
                  disabled={deleteCurso}
                  tarefa={tarefa}
                  tooltip='Máximo de 20 caracteres'
                  tooltipOptions={{ event: 'focus' }}
                />
              </div>

              <div className="col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="HabilitacaoDescricao"
                  label='Habilitação'
                  disabled={deleteCurso}
                  tarefa={tarefa}
                  tooltip='Máximo de 120 caracteres'
                  tooltipOptions={{ event: 'focus' }}
                />
              </div>
            </div>
          </SCFieldset>
          <div className='grid'>
            <div className="col-9">
              <SCInputText
                control={control}
                errors={errors}
                name="ConfereGrau"
                label='Confere Grau de'
                disabled={deleteCurso}
                tarefa={tarefa}
                tooltip='Máximo de 160 caracteres'
                tooltipOptions={{ event: 'focus' }}
              />
            </div>
          </div>
          <div className='grid'>
            <div className="col-4">
              <SCInputText
                control={control}
                errors={errors}
                name="CodigoEMec"
                label='Código E-MEC'
                disabled={deleteCurso}
                tarefa={tarefa}
                tooltip='Máximo de 40 caracteres'
                tooltipOptions={{ event: 'focus' }}
              />
            </div>
          </div>

          <div className='grid'>
            <div className="col-12">
              <SCInputTextarea
                style={{ minHeight: 100 }}
                control={control}
                errors={errors}
                name="Competencia"
                label='Competência'
                disabled={deleteCurso}
                rows={5}
              />
            </div>
          </div>
        </TabPanel>
      </TabView>

      <div className='grid'>
        <Divider />
        {deleteCurso ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}
      </div>
    </form>

  );
};

export default CursoForm;
