import React, { useEffect, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';

import { SCButton, SCCheckbox, SCDropdown, SCInputMask, SCInputNumber, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import { HiCurrencyDollar, HiOutlineDocumentText } from 'react-icons/hi';
import { Divider } from 'primereact/divider';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { LOCAL_PAGAMENTO } from '../utilities/constantes';
import SelectConvenio from '../components/SelectConvenio';
import SelectPlanoPagamento from '../components/SelectPlanoPagamento';
import { useQuery } from 'react-query';
import api from '../services/api';
import { Dialog } from 'primereact/dialog';
import AlunosFormResponsavel from './AlunosFormResponsavel';
import { classNames } from 'primereact/utils';
import { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';
import InputCPFCNPJ from '../components/form/inputCPFCNPJ/inputCPFCNPJ';
import { FaUserPlus } from 'react-icons/fa';
import SelectTurma from '../components/SelectTurma';
import SelectCurso from '../components/SelectCurso';
import SelectNivelSequencia from '../components/SelectNivelSequencia';
import SelectItinerarioFormativo from '../components/SelectItinerarioFormativo';
import SelectOrigem from '../components/SelectOrigem';

type Props = any;

const AlunosFormMatricula: React.FC<Props> = ({showDelete, ...props }) => {

  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const [parcelaContratoLiquida, setParcelaContratoLiquida] = React.useState<number>(0);
  const [primeiraParcelaContratoLiquida, setPrimeiraParcelaContratoLiquida] = React.useState<number>(0);
  const { control, formState: { errors }, getValues, setValue, watch } = useFormContext();
  const [isCPF, setIsCPF] = useState(true);

  const watchRecalcularContrato = watch('RecalcularContrato');
  const watchCPF =  watch('ResponsavelFIN.CPF');
  const watchMatricula = watch('Matricula');
  const watchExigeItinerario = watch('ExigeItinerario');  
  const watchAlunosPeriodo = watch('AlunosPeriodo');

  useEffect(() => {
    if (watchCPF){
      setIsCPF(watchCPF.length === 14);
    }
  }, [watchCPF])
  
  useEffect(() => {
    if (watchAlunosPeriodo)
    {
      calculaValorLiquidoParcela();
      calculaValorLiquidoPrimeiraParcela();
    }
  }, [watchAlunosPeriodo]);

  const calculaValorLiquidoPrimeiraParcela = () => {    
    const valor = getValues('AlunosPeriodo.PrimeiraParcelaContrato');
    const desconto = getValues('AlunosPeriodo.DescontoPrimeiraContrato');
    const liquido = valor - desconto;
    setPrimeiraParcelaContratoLiquida(liquido);
  }

  const calculaValorLiquidoParcela = () => {
    const valor = getValues('AlunosPeriodo.ValorParcelaContrato');
    const desconto = getValues('AlunosPeriodo.ValorDescontoContrato');
    const liquido = valor - desconto;
    setParcelaContratoLiquida(liquido);
  }

  useEffect(() => {
    calculaValorLiquidoParcela();
    calculaValorLiquidoPrimeiraParcela();
  }, [watchRecalcularContrato]);



  return (
    <div className='col-12' > 

        <Divider align="left">
          <div className="inline-flex align-items-center">
            <i className="pi pi-id-card mr-2"></i>
            <b>Informações de Matrícula</b>
          </div>
        </Divider>
        
        <div className='grid'>
          <div className="col-6">
            <SelectTurma
              control={control}
              errors={errors}
              carregaDetalhe={true}
              name="TurmaCodigo"
              label='Turma'
              required
              disabled={watchMatricula}
            />
          </div>          
          <div className="col-2">
          <SCInputText
            control={control}
            errors={errors}
            name="Turno"
            label='Turno'
            disabled
          />
        </div>  
        </div>

        <div className='grid'>
          <div className="col-4">
            <SelectCurso
              control={control}
              errors={errors}
              name="CursoId"
              label='Curso'
              disabled
            />
          </div>
          <div className="col-4">
            <SelectNivelSequencia
              control={control}
              errors={errors}
              name="AlunosPeriodo.NivelSequenciaId"
              label='Nível/Sequência'
              disabled
            />
          </div>
          {watchExigeItinerario && (
          <div className="col-4">
            <SelectItinerarioFormativo
              control={control}
              errors={errors}
              name="AlunosPeriodo.ItinerarioFormativoId"
              label='Itinerário Formativo'
              required={watchExigeItinerario}
            />
          </div>
          )}
        </div>

      <div className='grid'>
        <div className="col-4">
          <SelectPlanoPagamento
            control={control}
            errors={errors}
            name="AlunosPeriodo.PlanoPagamentoId"
            required={!showDelete}
            label='Plano de Pagamento'
          />
        </div>
        <div className="col-3">
          <SelectConvenio
            control={control}
            errors={errors}
            name="AlunosPeriodo.ConvenioId"
            required={!showDelete}
            label='Convênio'
          />
        </div>
        <div className="col-2">
          <SCInputText
            control={control}
            errors={errors}
            name="DiaPadraoVencimento"
            label='Dia p/ Vencimento'
            required={!showDelete}
            disabled={showDelete}
            maxLength={2}
            numberOnly
            max={31}
          />
        </div>        
        <div className="col-2">
          <SCDropdown
            control={control}
            errors={errors}
            name="AlunosPeriodo.LocalPagamento"
            label='Loc. Pagamento'
            options={LOCAL_PAGAMENTO}
            optionLabel='descricao'
            optionValue='value'
            disabled={showDelete}
          />
        </div>
      </div>

      <div className='grid'>
        
          <div className="col-3">
            <SelectOrigem
              control={control}
              errors={errors}
              name="FormaOrigem"
              label='Origem'
              disabled={showDelete}
              required
            />
          </div>
      </div>

      <Divider align="left">
        <div className="inline-flex align-items-center">
          <HiOutlineDocumentText size={20} color="#1c678d" fontWeight="light" />
          <b>Contrato</b>
        </div>
      </Divider>
      <div className='grid'>
        <div className="col-2">
          <SCInputNumber
            control={control}
            errors={errors}
            name="AlunosPeriodo.ValorContrato"
            label='Valor Total (R$)'
            disabled={showDelete}
            maxFractionDigits={2}
            minFractionDigits={2}
          />
        </div>
        <div className="col-2">
          <SCInputNumber
            control={control}
            errors={errors}
            name="AlunosPeriodo.PrimeiraParcelaContrato"
            label='1ª Parcela de (R$)'
            disabled={showDelete}
            maxFractionDigits={2}
            minFractionDigits={2}
            onBlur={calculaValorLiquidoPrimeiraParcela}
          />
        </div>
        <div className="col-3">
          <SCInputNumber
            control={control}
            errors={errors}
            name="AlunosPeriodo.DescontoPrimeiraContrato"
            label='Desconto 1ª parcela (R$)'
            disabled={showDelete}
            maxFractionDigits={2}
            minFractionDigits={2}
            onBlur={calculaValorLiquidoPrimeiraParcela}
          />
        </div>        
        <div className="col-2">
          <SCInputNumber
            value={primeiraParcelaContratoLiquida}
            label='Líquido (R$)'
            disabled
            inputStyle={{ color: 'green' }}
            maxFractionDigits={2}
            minFractionDigits={2}
          />
        </div>
      </div>

      <div className='grid'>
        <div className="col-2">
          <SCInputNumber
            control={control}
            errors={errors}
            name="AlunosPeriodo.ParcelaContrato"
            label='demais parcerlas '
            disabled={showDelete}
            maxFractionDigits={0}
            minFractionDigits={0}

          />
        </div>
        <div className="col-2">
          <SCInputNumber
            control={control}
            errors={errors}
            name="AlunosPeriodo.ValorParcelaContrato"
            label='de (R$)'
            disabled={showDelete}
            maxFractionDigits={2}
            minFractionDigits={2}
            onBlur={calculaValorLiquidoParcela}
          />
        </div>
        
        <div className="col-3">
          <SCInputNumber
            control={control}
            errors={errors}
            name="AlunosPeriodo.ValorDescontoContrato"
            label='Desconto nas demais parcelas (R$)'
            disabled={showDelete}
            maxFractionDigits={2}
            minFractionDigits={2}
            onBlur={calculaValorLiquidoParcela}
          />
        </div>
        <div className="col-2">
          <SCInputNumber
            value={parcelaContratoLiquida}
            label='Líquido (R$)'
            disabled
            inputStyle={{ color: 'green' }}
            maxFractionDigits={2}
            minFractionDigits={2}
          />
        </div>
      </div>
   

    </div>
  );
};

export default AlunosFormMatricula;
