import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import PlanoPagamentoForm from './PlanoPagamentoForm';
import IPlanoPagamento, { IPlanoPagamentoFiltro } from '../interfaces/IPlanoPagamento';
import { IoMdCheckboxOutline } from 'react-icons/io';


const PlanoPagamento = () => {
  const toast = useToast();
  const { usuario, periodoSelecionado } = useAuth();
  const tarefa = 'PlanoPagamento';

  let emptyData: IPlanoPagamento = {
    Codigo: '',
    Descricao: '',
    ValorDesconto: 0,
  };

  const [lista, setLista] = useState<any>(null);
  const [cadastroDialog, setCadastroDialog] = useState<boolean>(false);
  const [deleteRegistro, setDeleteRegistro] = useState<boolean>(false);
  const [registro, setRegistro] = useState<IPlanoPagamento>(emptyData);
  const [selectedRegistro, setSelectedRegistro] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [novoComBase, setNovoComBase] = useState<any>()
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);
  const [filtros, setFiltros] = useState({} as IPlanoPagamentoFiltro);
  const methods = useForm({ defaultValues: emptyData });
  const { handleSubmit, getValues, reset, trigger } = methods;


  useEffect(() => {
    onSearch();
  }, []);

  const openNew = () => {
    setNovoComBase(null)
    setRegistro(emptyData);
    reset(emptyData);
    setSubmitted(false);
    setCadastroDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setCadastroDialog(false);
  };
  const editar = (reg: any) => {
    setNovoComBase(null)
    setRegistro(reg);
    reset(reg);
    setCadastroDialog(true);
  };

  const confirmDelete = (reg: any) => {
    setNovoComBase(null)
    setRegistro(reg);
    reset(reg);
    setDeleteRegistro(true);
    setCadastroDialog(true);
  };

  const deletar = async () => {
    try {
      setLoading(true);
      const resp = await api.delete('/planosPagamento', { params: { PlanosPagamentoId: registro.PlanosPagamentoId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      onSearch();
      setDeleteRegistro(false);
      setCadastroDialog(false);
      setRegistro(emptyData);
      reset(emptyData);
    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
    setLoading(false);

  };

  const onSearch = async () => {
    setLoading(true);
    const result = await api.get('/planosPagamento/list', { params: { 
      Descricao: filtros.Descricao, Ano: periodoSelecionado?.Ano } });
    console.log(result.data)
    if (result.data)
      setLista(result.data);
    else
      setLista([]);

    setLoading(false);
  }

  const onSubmitCad = async () => {
    setLoading(true);
    const formValido = await trigger();
    console.log('formValido', formValido);
    if (!formValido) {
      setLoading(false);
      return false;
    }

    const data = getValues();

    if (data?.PlanoPagamentoParcelas && data?.PlanoPagamentoParcelas.length === 0) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'É necessário informar as parcelas do plano de pagamento.' });
      return;
    }
    data.Ano = periodoSelecionado?.Ano;

    data?.PlanoPagamentoParcelas?.forEach((item: any) => {
      delete item.PlanosPagamentoParcelasId;
    })

    let resp: any = undefined;
    try {
      if (data.PlanosPagamentoId === undefined)
        resp = await api.post('/planosPagamento', data);
      else
        resp = await api.put('/planosPagamento', data);

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      onSearch();
      setCadastroDialog(false);
    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
    setLoading(false);
  }

  const novoComBaseNesse = (item: any) => {
    setNovoComBase(item.PlanosPagamentoId);
    setRegistro({ ...item, PlanosPagamentoId: undefined })
    reset({ ...item, PlanosPagamentoId: undefined })
    setCadastroDialog(true)
  };

  const cancelar = (e: any) => {
    setDeleteRegistro(false);
    setCadastroDialog(false);
    reset(emptyData);
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Plano de Pagamento
            </h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <div className='grid'>
            <div className="col-12 md:col-6">
              <SCInputText
                onChange={(e) => setFiltros({ ...filtros, Descricao: e.target.value })}
                name='Descricao'
                label='Descrição'
                className='w-full'
              />
            </div>
            <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
              <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={onSearch} />
              <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

              <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
              <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
            </div>
          </div>

          <Divider />
          <DataTable
            loading={loading}
            size='small'
            stripedRows
            ref={dt}
            value={lista}
            onSelectionChange={(e) => setSelectedRegistro(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            header={null}
            responsiveLayout="scroll">
            <Column header="Código" field='Codigo' bodyStyle={{ width: 90 }} sortable />
            <Column header="Descrição" field='Descricao' sortable />
            <Column header="Desconto" bodyStyle={{ width: 80 }} body={(rowData: any) => (
              <div className="flex justify-content-center align-items-center">
                <span>{rowData.ValorDesconto?.toString().replace('.', ',')} % </span>
              </div>

            )} sortable />
            <Column header="Valor 1ª Parcela" bodyStyle={{ width: 100 }} body={(rowData: any) => (
              <div className="flex justify-content-center align-items-center">
                <span>R$ {rowData.Valor?.toFixed(2).replace('.', ',')}</span>
              </div>

            )} sortable />
            <Column header="Desc. 1ª Parcela" bodyStyle={{ width: 80 }} body={(rowData: any) => (
              <div className="flex justify-content-center align-items-center">
                <span>R$ {rowData.Desconto?.toFixed(2).replace('.', ',')}</span>
              </div>

            )} sortable />
            <Column bodyStyle={{ width: 60 }} field="Desativado" header="Status" align={'center'} alignHeader={'center'} sortable body={(rowData: any) => {
              return (
                <IoMdCheckboxOutline size={20} color={rowData.Desativado ? 'red' : "#01902f"} />
              )
            }} ></Column>
            <Column body={(rowData: any) => {
              return (
                <div className="actions flex align-items-center justify-content-between">
                  <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                  <Button label="Novo Com Base Nesse" icon="pi pi-plus" className="p-button w-auto p-button-success" onClick={() => novoComBaseNesse(rowData)} type='button' />
                  <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                </div>
              );
            }} style={{ width: 250 }}
            />
          </DataTable>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitCad)} className='pt-3'>
              <SCDialog maximized={!util.isDesktop()} visible={cadastroDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
                header={`${registro.PlanosPagamentoId === undefined ? 'Cadastrando ' : (deleteRegistro ? 'Excluíndo' : 'Alterando')} Plano de Pagamento`}
                modal className="p-fluid" onHide={hideDialog}
                footer={<>
                  {deleteRegistro ? (
                    <div className="flex flex-1 justify-content-between pt-3 px-2">
                      <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" type='button' onClick={cancelar} />
                      <Button label="Confirmar Exclusão" icon="pi pi-check"
                        className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                        onClick={deletar} type='button' />
                    </div>
                  ) : (
                    <div className="flex flex-1 justify-content-between pt-3 px-2">
                      <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                      <SCButton onClick={onSubmitCad} label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" />
                    </div>
                  )}
                </>}
              >
                <PlanoPagamentoForm
                  setCadastroDialog={setCadastroDialog}
                  registro={registro}
                  reload={onSearch}
                  deleteRegistro={deleteRegistro}
                  setDeleteRegistro={setDeleteRegistro}
                  deletar={deletar}
                  novoComBase={novoComBase}
                  loading={loading}
                />
              </SCDialog>
            </form>
          </FormProvider>

        </div>
      </div>
    </div>
  );
};

export default PlanoPagamento;
