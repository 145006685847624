import { useToast } from '../context/ToastContext';
import { FormProvider, useForm } from 'react-hook-form';
import api from "../services/api";
import { useEffect, useState } from "react";
import { ALERTAS, AGENTEINTEGRADOR } from "../utilities/constantes";
import { SCButton, SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputText } from "../components";
import util from '../utilities/util';
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import IAlunosEstagios from "../interfaces/IAlunosEstagios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAuth } from '../providers/auth';
import { DropdownChangeParams } from 'primereact/dropdown';
import { format, parseISO } from 'date-fns';
import AlunoResumo from '../components/AlunoResumo';
import { IAlunoForm } from '../interfaces/IAlunos';

interface props {
    aluno?: IAlunoForm;
    estagiario?: IAlunosEstagios;
    setEstagioDialog(param: boolean): void;
    reload(): void;
    deletar(): void;
    deleteEstagiarios: boolean;
    setDeleteEstagiarios(param: boolean): void;
}

const AlunosEstagiosForm: React.FC<props> = ({ setEstagioDialog, estagiario, aluno, reload, deletar, deleteEstagiarios, setDeleteEstagiarios }) => {
    const tarefa = 'AlunosEstagiosForm';
    const toast = useToast();

    const { periodoSelecionado } = useAuth();

    const defaultValues: IAlunosEstagios = estagiario || {
        AlunosEstagioId: undefined,
        EmpresaId: 0,
        Matricula: undefined,
        TurmaId: '',
        DisciplinaId: '',

        AlunosEstagioItemId: undefined,
        ConvenioId: 0,
        FuncionarioId: 0,
        Funcionario: '',
        Detalhamento: '',
        DataInicial: new Date(''),
        DataFinal: new Date(''),
        CargaHoraria: 0,
        EmpresaOrgao: '',
        AgenteIntegrador: '',
        Remuneracao: 0,
        ValeTransporte: 0,
        ValeRefeicao: 0,
        Observacao: '',
        Concluido: false,

        AlunosEstagioItem: [],
    };    

    const methods = useForm({ defaultValues });
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset, clearErrors } = methods;

    const [supervisor, setSupervisor] = useState<any>()
    const [alunosEstagioItem, setAlunosEstagioItem] = useState<any[]>([]);
    const [editando, setEditando] = useState<boolean>(false);
    const [desabilitaCampos, setDesabilitaCampos] = useState<boolean>(true);
    const [disciplina, setDisciplina] = useState<any>()
    const [remuneracao, setRemuneracao] = useState<any>();
    const [valeTransporte, setValeTransporte] = useState<any>();
    const [valeRefeicao, setValeRefeicao] = useState<any>();

    async function load() {

        const resultEstagioAlunoItem = await api.get('/alunosEstagioItem/list', { params: { AlunosEstagioId: estagiario?.AlunosEstagioId } });
        if (resultEstagioAlunoItem)
            setAlunosEstagioItem(resultEstagioAlunoItem.data);
        else
            setAlunosEstagioItem([]);

        const resultSupervisor = await api.get('/funcionarios/list', { params: { TipoFuncionario: 4 } });
        if (resultSupervisor)
            setSupervisor(resultSupervisor.data);
        else
            setSupervisor([]);

        setDesabilitaCampos(false)

        if (estagiario?.AlunosEstagioId) {
            setDesabilitaCampos(true)
        }

        const disciplinas = await api.get('/disciplina/empresa', { params: { EmpresaId: periodoSelecionado?.EmpresaId } })
        if (disciplinas)
            setDisciplina(disciplinas.data);
        else
            setDisciplina([]);
    }

    useEffect(() => {
        load()
    }, []);

    const onSubmit = async (data: IAlunosEstagios) => {

        try {

            data.DataInicial ??= new Date('');
            data.DataFinal ??= new Date('');

            let resp: any = undefined;

            if (estagiario?.AlunosEstagioId === undefined) {

                let { ConvenioId, FuncionarioId, Funcionario, TurmaId, Detalhamento, CargaHoraria, EmpresaOrgao, AgenteIntegrador, Remuneracao, ValeTransporte, ValeRefeicao, Observacao, ...cleanData } = data;
                const postData: IAlunosEstagios = {
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Matricula: data.Matricula,
                    TurmaId: data.TurmaId,
                    DisciplinaId: data.DisciplinaId,
                    AlunosEstagioItem: data.AlunosEstagioItem,
                };
                postData.AlunosEstagioItem = postData?.AlunosEstagioItem?.map((item: any) => {
                    return {
                        ConvenioId: item.ConvenioId || undefined,
                        FuncionarioId: item.FuncionarioId || undefined,
                        Detalhamento: item.Detalhamento || undefined,
                        DataInicial: item.DataInicial || undefined,
                        DataFinal: item.DataFinal || undefined,
                        CargaHoraria: item.CargaHoraria || undefined,
                        EmpresaOrgao: item.EmpresaOrgao || undefined,
                        AgenteIntegrador: item.AgenteIntegrador || undefined,
                        Remuneracao: parseFloat(String(item.Remuneracao).includes(',') ? item.Remuneracao.replace(',', '.') : item.Remuneracao) || undefined,
                        ValeTransporte: parseFloat(String(item.ValeTransporte).includes(',') ? item.ValeTransporte.replace(',', '.') : item.ValeTransporte) || undefined,
                        ValeRefeicao: parseFloat(String(item.ValeRefeicao).includes(',') ? item.ValeRefeicao.replace(',', '.') : item.ValeRefeicao) || undefined,
                        // Remuneracao: parseFloat(remuneracao ? remuneracao.includes(',') ? remuneracao.replace(',', '.') : remuneracao : 0) || undefined,
                        // ValeTransporte: parseFloat(valeTransporte ? valeTransporte.includes(',') ? valeTransporte.replace(',', '.') : valeTransporte : 0) || undefined,
                        // ValeRefeicao: parseFloat(valeRefeicao ? valeRefeicao.includes(',') ? valeRefeicao.replace(',', '.') : valeRefeicao : 0) || undefined,
                        Observacao: item.Observacao || undefined,
                        Concluido: item.Concluido || false,
                    }
                })
                console.log(postData)
                resp = await api.post('/alunosEstagio', postData);
            } else {

                const postData: IAlunosEstagios = {
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Matricula: data.Matricula,
                    TurmaId: data.TurmaId,
                    DisciplinaId: data.DisciplinaId,
                    AlunosEstagioId: estagiario?.AlunosEstagioId,
                    AlunosEstagioItem: data.AlunosEstagioItem,
                };

                for (let i = 0; i < alunosEstagioItem.length; i++) {

                    alunosEstagioItem[i].Remuneracao = parseFloat(
                        alunosEstagioItem[i].Remuneracao
                            ? String(alunosEstagioItem[i].Remuneracao).includes(',')
                                ? alunosEstagioItem[i].Remuneracao.replace(',', '.')
                                : alunosEstagioItem[i].Remuneracao
                            : 0
                    );

                    alunosEstagioItem[i].ValeRefeicao = parseFloat(
                        alunosEstagioItem[i].ValeRefeicao
                            ? String(alunosEstagioItem[i].ValeRefeicao).includes(',')
                                ? alunosEstagioItem[i].ValeRefeicao.replace(',', '.')
                                : alunosEstagioItem[i].ValeRefeicao
                            : 0
                    );

                    alunosEstagioItem[i].ValeTransporte = parseFloat(
                        alunosEstagioItem[i].ValeTransporte
                            ? String(alunosEstagioItem[i].ValeTransporte).includes(',')
                                ? alunosEstagioItem[i].ValeTransporte.replace(',', '.')
                                : alunosEstagioItem[i].ValeTransporte
                            : 0
                    );

                    delete alunosEstagioItem[i].AlunosEstagioItem;
                    delete alunosEstagioItem[i].Funcionario;
                    delete alunosEstagioItem[i].AlunosEstagioItemId;

                    alunosEstagioItem[i].AlunosEstagioId = estagiario.AlunosEstagioId;
                    alunosEstagioItem[i].AlunosEstagioId = parseInt(alunosEstagioItem[i].AlunosEstagioId);
                    alunosEstagioItem[i].AlunosEstagioItemId = parseInt(alunosEstagioItem[i].AlunosEstagioItemId);
                    alunosEstagioItem[i].CargaHoraria = parseInt(alunosEstagioItem[i].CargaHoraria);
                    alunosEstagioItem[i].ConvenioId = parseInt(alunosEstagioItem[i].ConvenioId);
                    alunosEstagioItem[i].FuncionarioId = parseInt(alunosEstagioItem[i].FuncionarioId);
                    alunosEstagioItem[i].DataFinal = alunosEstagioItem[i].DataFinal;
                    alunosEstagioItem[i].DataInicial = alunosEstagioItem[i].DataInicial;
                }

                postData.AlunosEstagioItem = alunosEstagioItem;

                resp = await api.put('/alunosEstagio', postData);
            }

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            reload();
            setEstagioDialog(false);

        } catch (e: any) {
            if (e.response && e.response.data) {
                console.log(e.response.data);
                toast.showToast({ severity: 'error', summary: 'Erro', detail: e.response.data.error });
            } else {
                console.log(e);
                toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Erro desconhecido' });
            }
        }
    }

    const cancelar = (e: any) => {
        setDeleteEstagiarios(false);
        setEstagioDialog(false);
        reset();
    }

    const editar = (e: any) => {
        setEditando(true)
        setValue('AlunosEstagioItemId', e.AlunosEstagioItemId)
        setValue('AgenteIntegrador', e.AgenteIntegrador)
        setValue('CargaHoraria', e.CargaHoraria)
        setValue('Concluido', e.Concluido)
        setValue('ConvenioId', e.ConvenioId)
        setValue('DataFinal', parseISO(e.DataFinal))
        setValue('DataInicial', parseISO(e.DataInicial))
        setValue('Detalhamento', e.Detalhamento)
        setValue('EmpresaOrgao', e.EmpresaOrgao)
        setValue('FuncionarioId', e.FuncionarioId)
        setValue('Observacao', e.Observacao)
        setRemuneracao(e.Remuneracao ? e.Remuneracao.toLocaleString('pt-BR', { minimumFractionDigits: 2 }).replace('.', '') : undefined)
        setValeRefeicao(e.ValeRefeicao ? e.ValeRefeicao.toLocaleString('pt-BR', { minimumFractionDigits: 2 }).replace('.', '') : undefined)
        setValeTransporte(e.ValeTransporte ? e.ValeTransporte.toLocaleString('pt-BR', { minimumFractionDigits: 2 }).replace('.', '') : undefined)
    }

    const excluir = (e: any) => {
        const buscaIdx: any = alunosEstagioItem?.findIndex(x => x.ConvenioId === e.ConvenioId)
        alunosEstagioItem?.splice(buscaIdx, 1)
        setValue('AlunosEstagioItem', alunosEstagioItem);
        setAlunosEstagioItem([...alunosEstagioItem]);
    }

    const cancelarEdicao = (e: any) => {
        setEditando(false);
        setValue('AlunosEstagioItemId', undefined)
        setValue('AgenteIntegrador', undefined)
        setValue('CargaHoraria', 0)
        setValue('Concluido', undefined)
        setValue('ConvenioId', 0)
        setValue('DataFinal', undefined)
        setValue('DataInicial', undefined)
        setValue('Detalhamento', '')
        setValue('EmpresaOrgao', '')
        setValue('FuncionarioId', undefined)
        setValue('Observacao', '')
        setRemuneracao(0)
        setValeRefeicao(0)
        setValeTransporte(0)
    }

    const adicionarEstagioCursoItem = () => {
        clearErrors();

        const dados = getValues();
        dados.Remuneracao = remuneracao;
        dados.ValeRefeicao = valeRefeicao;
        dados.ValeTransporte = valeTransporte;

        let dadosGrid = []

        if (editando) {

            // Se o item já existe no GRID, atualize os dados
            const indice = alunosEstagioItem.findIndex(x => x.AlunosEstagioItemId === dados.AlunosEstagioItemId);
            const dadosAtuais = [...alunosEstagioItem]
            dadosAtuais[indice] = {
                ...dadosAtuais[indice],
                ConvenioId: dados.ConvenioId,
                FuncionarioId: dados.FuncionarioId,
                Funcionario: supervisor.find((x: { FuncionarioId: number | undefined; }) => x.FuncionarioId === dados.FuncionarioId)?.Nome,
                Detalhamento: dados.Detalhamento,
                DataInicial: dados.DataInicial,
                DataFinal: dados.DataFinal,
                CargaHoraria: dados.CargaHoraria,
                EmpresaOrgao: dados.EmpresaOrgao,
                AgenteIntegrador: dados.AgenteIntegrador,
                Remuneracao: dados.Remuneracao,
                ValeTransporte: dados.ValeTransporte,
                ValeRefeicao: dados.ValeRefeicao,
                Observacao: dados.Observacao,
                Concluido: dados.Concluido,
                AlunosEstagioItem: dados.AlunosEstagioItem,
            };

            setAlunosEstagioItem(dadosAtuais);

            reset({
                EmpresaId: periodoSelecionado?.EmpresaId,
                ConvenioId: dados.ConvenioId,
                FuncionarioId: dados.FuncionarioId,
                Funcionario: dados.Funcionario,
                Detalhamento: dados.Detalhamento,
                DataInicial: dados.DataInicial,
                DataFinal: dados.DataFinal,
                CargaHoraria: dados.CargaHoraria,
                EmpresaOrgao: dados.EmpresaOrgao,
                AgenteIntegrador: dados.AgenteIntegrador,
                Remuneracao: remuneracao,
                ValeTransporte: valeTransporte,
                ValeRefeicao: valeRefeicao,
                Concluido: dados.Concluido,
                Observacao: dados.Observacao,
                AlunosEstagioItem: dadosAtuais,
                Matricula: dados.Matricula,
                TurmaId: dados.TurmaId,
                DisciplinaId: dados.DisciplinaId,
            })

            setRemuneracao(undefined)
            setValeRefeicao(undefined)
            setValeTransporte(undefined)

            setEditando(false);
        } else {
            dadosGrid = [...alunosEstagioItem, {
                ConvenioId: dados.ConvenioId,
                FuncionarioId: dados.FuncionarioId,
                Funcionario: supervisor.find((x: { FuncionarioId: number | undefined; }) => x.FuncionarioId === dados.FuncionarioId)?.Nome,
                Detalhamento: dados.Detalhamento,
                DataInicial: dados.DataInicial,
                DataFinal: dados.DataFinal,
                CargaHoraria: dados.CargaHoraria,
                EmpresaOrgao: dados.EmpresaOrgao,
                AgenteIntegrador: dados.AgenteIntegrador,
                Remuneracao: remuneracao,
                ValeTransporte: valeTransporte,
                ValeRefeicao: valeRefeicao,
                Observacao: dados.Observacao,
                Concluido: dados.Concluido,
            }];

            setAlunosEstagioItem(dadosGrid);

            reset({
                ConvenioId: dados.ConvenioId,
                EmpresaId: periodoSelecionado?.EmpresaId,
                FuncionarioId: dados.FuncionarioId,
                Funcionario: dados.Funcionario,
                Detalhamento: dados.Detalhamento,
                DataInicial: dados.DataInicial,
                DataFinal: dados.DataFinal,
                CargaHoraria: dados.CargaHoraria,
                EmpresaOrgao: dados.EmpresaOrgao,
                AgenteIntegrador: dados.AgenteIntegrador,
                Remuneracao: dados.Remuneracao,
                ValeTransporte: dados.ValeTransporte,
                ValeRefeicao: dados.ValeRefeicao,
                Concluido: dados.Concluido,
                Observacao: dados.Observacao,
                AlunosEstagioItem: dadosGrid,
                Matricula: dados.Matricula,
                TurmaId: dados.TurmaId,
                DisciplinaId: dados.DisciplinaId,
            })

            setRemuneracao(undefined)
            setValeRefeicao(undefined)
            setValeTransporte(undefined)

        }
    }

    const formatarMoeda = (valor: any, tipo: any) => {
        let valorNumerico = valor.replace(/\D/g, '');

        let parteDecimal = valorNumerico.slice(-2);
        let parteInteira = valorNumerico.slice(0, -2);
        let valorFormatado = parteInteira + ',' + parteDecimal;

        if (tipo === "VT") {
            setValeTransporte(valorFormatado)
        } else if (tipo === "VR") {
            setValeRefeicao(valorFormatado)
        }
        else {
            setRemuneracao(valorFormatado);
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className='pt-3' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
                    <div className='grid'>
                        <AlunoResumo mostraFoto aluno={aluno as IAlunoForm} />
                        <div className='md:flex col-12 pl-0 pt-0'>
                            <div className="col-12 md:col-4 pt-0">
                                <SCDropdown
                                    control={control}
                                    errors={errors}
                                    tarefa={tarefa}
                                    options={disciplina}
                                    name="DisciplinaId"
                                    label='Disciplina'
                                    optionLabel='Descricao'
                                    optionValue='DisciplinaId'
                                    disabled={deleteEstagiarios || desabilitaCampos}
                                />
                            </div>
                        </div>
                        <Divider />
                        <div className="col-12 md:col-2 pt-0">
                            <SCInputText
                                control={control}
                                errors={errors}
                                name="ConvenioId"
                                label='Código Convênio'
                                maxLength={10}
                                //required={!deleteEstagiarios}
                                disabled={deleteEstagiarios}
                                tooltip='Máximo de 10 caracteres'
                                tooltipOptions={{ event: 'focus' }}
                            />
                        </div>
                        <div className="col-12 md:col-4 pt-0">
                            <SCDropdown
                                control={control}
                                errors={errors}
                                options={supervisor}
                                name="FuncionarioId"
                                label='Supervisor'
                                optionLabel='Nome'
                                optionValue='FuncionarioId'
                                disabled={deleteEstagiarios}
                                tarefa={tarefa}
                            />
                        </div>
                        <div className='md:flex col-12 pl-0 pt-0'>
                            <div className="col-12 md:col-6 pt-0">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="Detalhamento"
                                    label='Detalhamento'
                                    disabled={deleteEstagiarios}
                                    tarefa={tarefa}
                                />
                            </div>
                        </div>
                        <div className='md:flex col-12 pl-0 pt-0'>
                            <div className="col-12 md:col-2 pt-0">
                                <SCCalendar
                                    label='Data Inicial'
                                    control={control}
                                    name='DataInicial'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    showIcon
                                    showTime={false}
                                />
                            </div>
                            <div className="col-12 md:col-2 pt-0">
                                <SCCalendar
                                    label='Data Final'
                                    control={control}
                                    name='DataFinal'
                                    dateFormat='dd/mm/yy'
                                    mask='99/99/9999'
                                    showIcon
                                    showTime={false}
                                />
                            </div>
                            <div className="col-12 md:col-3 pt-0">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="CargaHoraria"
                                    label='Carga Horária'
                                    maxLength={10}
                                    //required={!deleteEstagiarios}
                                    disabled={deleteEstagiarios}
                                    tooltip='Máximo de 3 caracteres'
                                    tooltipOptions={{ event: 'focus' }}
                                    numberOnly={true}
                                />
                            </div>
                        </div>
                        <div className='md:flex col-12 pl-0 pt-0'>
                            <div className="col-12 md:col-4 pt-0">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="EmpresaOrgao"
                                    label='Empresa/Órgao'
                                    disabled={deleteEstagiarios}
                                    tarefa={tarefa}
                                />
                            </div>
                            <div className="col-12 md:col-4 pt-0">
                                <SCDropdown
                                    control={control}
                                    errors={errors}
                                    options={AGENTEINTEGRADOR}
                                    name="AgenteIntegrador"
                                    label='Agente Integrador'
                                    optionLabel='descricao'
                                    optionValue='value'
                                    disabled={deleteEstagiarios}
                                    tarefa={tarefa}
                                />
                            </div>
                        </div>
                        <div className='md:flex col-12 pl-0 pt-0'>
                            <div className="col-12 md:col-4 pt-0">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="Remuneracao"
                                    label='Remuneração'
                                    disabled={deleteEstagiarios}
                                    tarefa={tarefa}
                                    numberOnly={true}
                                    onChange={(e) => {
                                        const tipo = "R"
                                        formatarMoeda(e.target.value, tipo);
                                    }}
                                    onBlur={(e) => {
                                        setRemuneracao(e.target.value)
                                    }}
                                    value={remuneracao}
                                />
                            </div>
                            <div className="col-12 md:col-4 pt-0">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="ValeTransporte"
                                    label='Vale Transporte'
                                    disabled={deleteEstagiarios}
                                    tarefa={tarefa}
                                    numberOnly={true}
                                    onChange={(e) => {
                                        const tipo = "VT"
                                        formatarMoeda(e.target.value, tipo);
                                    }}
                                    onBlur={(e) => {
                                        setValeTransporte(e.target.value)
                                    }}
                                    value={valeTransporte}
                                />
                            </div>
                            <div className="col-12 md:col-4 pt-0">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="ValeRefeicao"
                                    label='Vale Refeição'
                                    disabled={deleteEstagiarios}
                                    tarefa={tarefa}
                                    numberOnly={true}
                                    onChange={(e) => {
                                        const tipo = "VR"
                                        formatarMoeda(e.target.value, tipo);
                                    }}
                                    onBlur={(e) => {
                                        setValeRefeicao(e.target.value)
                                    }}
                                    value={valeRefeicao}
                                />
                            </div>
                        </div>
                        <div className='md:flex col-12 pl-0 pt-0'>
                            <div className="col-12 md:col-2 pt-0">
                                <SCCheckbox
                                    checked={false}
                                    control={control}
                                    disabled={deleteEstagiarios}
                                    tarefa={tarefa}
                                    name="Concluido"
                                    label="Concluído"
                                />
                            </div>
                        </div>
                        <div className="col-12 md:col-6 pt-0">
                            <SCInputText
                                control={control}
                                errors={errors}
                                name="Observacao"
                                label='Observação'
                                disabled={deleteEstagiarios}
                                tarefa={tarefa}
                            />
                        </div>
                        <div className="col-1 flex ">
                            {editando ? (
                                <>
                                    <SCButton type='button' icon="pi pi-times" className="p-button-danger mt-auto mr-2" onClick={cancelarEdicao} />
                                    <SCButton type='button' icon="pi pi-check" className="p-button-success mt-auto" onClick={adicionarEstagioCursoItem} />
                                </>
                            ) : (
                                <SCButton type='button' icon="pi pi-plus" className="p-button-success mt-auto" onClick={adicionarEstagioCursoItem} />
                            )}
                        </div>
                    </div>
                    <Divider />

                    <div className='grid'>
                        <div className='col-12'>
                            <DataTable
                                size='small'
                                stripedRows
                                value={alunosEstagioItem}
                                dataKey="AlunosEstagioItemId"
                                emptyMessage="Nenhum registro."
                                responsiveLayout="scroll">

                                <Column header="Convênio" field='ConvenioId' sortable />
                                <Column header="Empresa/Órgão" field='EmpresaOrgao' sortable />
                                
                                <Column header="CHR" field='CargaHoraria' sortable />

                                <Column
                                    body={(rowData: any) => (
                                        <>
                                            <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" type='button' onClick={() => editar(rowData)} />
                                            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" type='button' onClick={() => excluir(rowData)} />
                                        </>
                                    )}
                                    style={{ width: 90 }}
                                />
                            </DataTable>
                        </div>
                    </div>

                    <Divider />
                    {deleteEstagiarios ? (
                        <div className="flex flex-1 justify-content-between pt-3 px-2">
                            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
                            <Button label="Confirmar Exclusão" icon="pi pi-check"
                                className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                                onClick={deletar} type='button' />
                        </div>
                    ) : (
                        <div className="flex flex-1 justify-content-between pt-3 px-2">
                            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
                        </div>
                    )}

                </form>
            </FormProvider >
        </>
    );
};

export default AlunosEstagiosForm