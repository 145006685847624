import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCCheckbox, SCDropdown, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import ISetor from '../interfaces/ISetor';
import { useAuth } from '../providers/auth';
import { IPeriodo } from '../interfaces/IUsuario';
import { CheckboxChangeParams } from 'primereact/checkbox';


interface Props {
    setor?: ISetor;
    setSetorDialog(param: boolean): void;
    deletar(): void;
    reload(): void;
    deleteSetor: boolean;
    setDeleteSetor(param: boolean): void;
}

const SetorForm: React.FC<Props> = ({ setSetorDialog, setor, reload, deletar, deleteSetor, setDeleteSetor }) => {
    // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
    // por padrão vamos utilizar o mesmo nome do componente
    const tarefa = 'OrigemForm';

    const toast = useToast();

    const defaultValues: ISetor = setor || {
        SetorId: undefined,
        Descricao: ''
    };

    const methods = useForm({ defaultValues });
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

    const onSubmit = async (data: ISetor) => {
        let resp: any = undefined;

        try {
            if (data.SetorId === undefined) {
                resp = await api.post('/setores', data);
            } else {
                resp = await api.put('/setores', data);
            }

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            reload();
            setSetorDialog(false);

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    }

    const cancelar = (e: any) => {
        setDeleteSetor(false);
        setSetorDialog(false);
        reset();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
            <div className='grid'>
                <div className="col-12 md:col-6">
                    <SCInputText
                        autoFocus={true}
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="Descricao"
                        label='Setor'
                        maxLength={30}
                        required={!deleteSetor}
                        disabled={deleteSetor || setor?.SetorId !== undefined}
                        tarefa={tarefa}
                        tooltip='Máximo de 30 caracteres'
                        tooltipOptions={{event: 'focus' }}
                    />
                </div>

                <Divider />
                {deleteSetor ? (
                    <div className="flex flex-1 justify-content-between pt-3 px-2">
                        <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
                        <Button label="Confirmar Exclusão" icon="pi pi-check"
                            className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                            onClick={deletar} type='button' />
                    </div>
                ) : (
                    <div className="flex flex-1 justify-content-between pt-3 px-2">
                        <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                        <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
                    </div>
                )}

            </div>

        </form >

    );
};

export default SetorForm;
