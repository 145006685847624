import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCCheckbox, SCDropdown, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import IDisciplina from '../interfaces/IDisciplina';
import api from "../services/api";
import { ALERTAS, AREAS_CONHECIMENTO } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import { useAuth } from '../providers/auth';


interface Props {
  disciplina?: IDisciplina;
  setDisciplinaDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteDisciplina: boolean;
  setDeleteDisciplina(param: boolean): void;
}

const DisciplinaForm: React.FC<Props> = ({ setDisciplinaDialog, disciplina, reload, deletar, deleteDisciplina, setDeleteDisciplina }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'DisciplinaForm';

  const toast = useToast();
  const [areaSelecionada, setAreaSelecionada] = useState<any>(null);
  const { usuario, periodoSelecionado } = useAuth();

  const defaultValues: IDisciplina = disciplina || {
    EmpresaId: periodoSelecionado?.EmpresaId,
    DisciplinaId: undefined,
    Descricao: '',
    Codigo: '',
    DescricaoHistorico: '',
    AreaConhecimento: 'I',
    ControlaFaltas: 0,
    PermiteLancamentoNotas: 0,
    MontagemHorario: 0,
    Itinerario: 0
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  useEffect(() => {
    if (disciplina) {
      console.log(disciplina);
    }
  }, [])

  const load = async () => {

    if (disciplina?.DisciplinaId === undefined) {
      setValue('PermiteLancamentoNotas', 1)
      setValue('ControlaFaltas', 1)
    }
  }

  const watchAvulso = watch('MontagemHorario')

  const avulso = () => {
    if (watchAvulso == 1) {
      setValue('PermiteLancamentoNotas', 2)
      setValue('ControlaFaltas', 2)
      setValue('AreaConhecimento', 'I')
    } else {
      setValue('PermiteLancamentoNotas', 1)
      setValue('ControlaFaltas', 1)
      setValue('AreaConhecimento', 'I')
    }
  }

  useEffect(() => {
    load()
    avulso()
  }, [watchAvulso])

  const onSubmit = async (data: any) => {

    let resp: any = undefined;

    // Transformando Descricao em UPPER
    data.Descricao = data.Descricao.toLocaleUpperCase();

    let { CodigoDescricao, ...newData } = data;

    try {
      if (newData.DisciplinaId === undefined) {
        console.log('newData', newData)
        resp = await api.post('/disciplina', newData);

        const idDisciplina = resp.data

        if (watchAvulso != 1) {
          await api.post('/disciplinaAprovaMedia', {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            DisciplinaId: idDisciplina,
            AprovaMedia: 1,
          })
        }

      } else {
        resp = await api.put('/disciplina', newData);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setDisciplinaDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  }

  const cancelar = (e: any) => {
    setDeleteDisciplina(false);
    setDisciplinaDialog(false);
    reset();
  }

  const watchArea = watch('AreaConhecimento');

  useEffect(() => {
    if (watchArea)
      setAreaSelecionada(AREAS_CONHECIMENTO.find((i: any) => i.value === watchArea))
    else
      setAreaSelecionada(null);

  }, [watchArea])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-3">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código'
            maxLength={6}
            required={!deleteDisciplina}
            disabled={disciplina?.DisciplinaId !== undefined}
            tarefa={tarefa}
            tooltip='Máximo de 6 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
        <div className="col-12 md:col-9">
          <SCInputText
            control={control}
            errors={errors}
            name="Descricao"
            label='Descrição'
            minLength={3}
            required={!deleteDisciplina}
            disabled={deleteDisciplina}
            onBlur={(e: any) => {
              const dados = getValues();
              if (dados.DescricaoHistorico?.trim() === '')
                setValue('DescricaoHistorico', e?.target?.value);
            }}
            tarefa={tarefa}
            tooltip='Máximo de 60 caracteres'
            tooltipOptions={{ event: 'focus' }}
            style={{ textTransform: 'uppercase' }}
          />
        </div>
        <div className="col-12">
          <SCInputText
            control={control}
            errors={errors}
            name="DescricaoHistorico"
            label='Descrição para o histórico'
            required={!deleteDisciplina}
            disabled={deleteDisciplina}
            tarefa={tarefa}
            tooltip='Máximo de 60 caracteres'
            tooltipOptions={{ event: 'focus' }}
            style={{ textTransform: 'uppercase' }}
          />
        </div>

        <div className="col-12">
          <SCCheckbox
            control={control}
            name="Itinerario"
            label='Essa disciplina será utilizada para Itinerário Formativo.'
            disabled={deleteDisciplina}
            tarefa={tarefa}
          />
        </div>

        <div className="col-12">
          <SCCheckbox
            control={control}
            name="MontagemHorario"
            label='Essa disciplina é AVULSA e será utilizada para montagem do horário.'
            disabled={deleteDisciplina}
            tarefa={tarefa}
          />
        </div>

        <div className="col-12 md:col-6">
          <SCDropdown
            control={control}
            errors={errors}
            name="AreaConhecimento"
            label='Área de Conhecimento'
            options={AREAS_CONHECIMENTO}
            optionLabel='descricao'
            disabled={deleteDisciplina}
            tarefa={tarefa}
            required={!deleteDisciplina}
          />

        </div>
        <div className="col-12 md:col-6" style={{ marginTop: 10 }}>
          {(areaSelecionada?.detalhes) && (
            <div className='card'>{areaSelecionada.detalhes}</div>
          )}
        </div>
        <div className="col-12">
          <div className='pb-2'>Permite Lançamento de Notas <AjudaForm tarefa={tarefa} campo='PermiteLancamentoNotas' /></div>
          <div className="flex align-items-center">
            <SCRadioButton
              control={control}
              name="PermiteLancamentoNotas"
              label='Sim'
              value={1}
              disabled={deleteDisciplina}
            />
            <SCRadioButton
              control={control}
              name="PermiteLancamentoNotas"
              label='Não'
              value={2}
              className='ml-3'
              disabled={deleteDisciplina}
            />
          </div>
        </div>

        <div className="col-12">
          <div className='pb-2'>Controla Faltas <AjudaForm tarefa={tarefa} campo='ControlaFaltas' /></div>
          <div className="flex align-items-center">
            <SCRadioButton
              control={control}
              name="ControlaFaltas"
              label='Sim'
              value={1}
              disabled={deleteDisciplina}
            />
            <SCRadioButton
              control={control}
              name="ControlaFaltas"
              label='Não'
              value={2}
              className='ml-3'
              disabled={deleteDisciplina}
            />
          </div>
        </div>
        {/* <div className="col-12">
                    <SCCheckbox 
                        control={control}
                        name="LinguaEstrangeira" 
                        label='Essa disciplina pertence ao grupo de disciplinas de língua estrangeira.'
                        disabled={deleteDisciplina}
                        tarefa={tarefa}
                    />                        
                </div> */}
        {/* <div className="col-12">
                    <SCCheckbox 
                        control={control}
                        name="AtividadeComplementar" 
                        label='Essa disciplina pertence ao grupo de disciplinas de atividades complementares.'
                        disabled={deleteDisciplina}
                    />                        
                </div>
                <div className="col-12">
                    <SCCheckbox 
                        control={control}
                        name="PerformanceAluno" 
                        label='NÃO considerar essa disciplina no cálculo da "Performance de Alunos por Turma".'
                        disabled={deleteDisciplina}
                    />                        
                </div> */}


        <Divider />
        {deleteDisciplina ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>
    </form>

  );
};

export default DisciplinaForm;
