import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, MESES } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDropdown, SCInputText } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { InputText } from 'primereact/inputtext';


const AlunosFrequenciaGlobal = () => {
    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const tarefa = 'FuncionarioForm';

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [turmaDisabled, setTurmaDisabled] = useState<boolean>(true);
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
    const [gravarDisabled, setGravarDisabled] = useState<boolean>(true);
    const [turmas, setTurmas] = useState<any>(null);
    const [turma, setTurma] = useState<any>(null);
    const [mes, setMes] = useState<any>();
    const [boletim, setBoletim] = useState<any>();
    const [saveTime, setSaveTime] = useState<string | null>(null);
    const dt = useRef<any>(null);

    const methods = useForm();
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

    const load = async () => {

        const turma = await api.get('/turma/listaTurmas', {
            params: {
                TemProfessorPrincipal: '>',
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        })
        if (turma.data) {
            setTurmas(turma.data);
        } else {
            setTurmas([]);
        }
    }

    useEffect(() => {
        load();
    }, []);

    const watchTurmaId = watch('AlunosPeriodo.TurmaCodigo')

    const handleTurmas = (e: any) => {
        setValue('AlunosPeriodo.TurmaCodigo', e.target.value);
        setTurma(e.target.value)
        setBtnDisabled(false);
    }

    const handleMes = (e: any) => {
        setMes(e.target.value);
        setTurmaDisabled(false);
    }

    const searchBoletins = async () => {
        setSaveTime('');

        const boletimFaltas = await api.get('/boletim/faltasGlobal', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Sequencial: periodoSelecionado?.Sequencial,
                Ano: periodoSelecionado?.Ano,
                Mes: mes,
                Turma: turma,
            }
        })

        setBoletim(boletimFaltas.data)
        if (boletimFaltas.data.length > 0) {
            setGravarDisabled(false);
        }
    }

    const onEditorValueChange = (props: any, value: any) => {
        let updatedBoletim = [...boletim];
        updatedBoletim[props.rowIndex][props.field] = value;
        setBoletim(updatedBoletim);
    };

    const inputTextEditor = (props: any) => {
        const field = props.field;
        const rowIndex = props.rowIndex;

        const handleBlur = (e: any) => {
            const value = e.target.value;
            onEditorValueChange({ field, rowIndex }, value);
        };

        const handleControl = (e: any) => {
            const charCode = e.charCode;
            const newValue = e.target.value + String.fromCharCode(charCode);

            if (!/^\d{0,3}$/.test(newValue)) {
                e.preventDefault();
            }
        };

        return (
            <InputText
                type="number"
                maxLength={2}
                defaultValue={props.rowData[props.field]}
                onBlur={handleBlur}
                onKeyPress={handleControl}
                style={{ maxWidth: '100%' }}
            />
        );
    };

    const saveState = async () => {
        try {
            boletim.forEach(async (element: any) => {
                await api.put('/boletim/updateFaltaGlobal', {
                    Matricula: element.Matricula,
                    TurmaCodigo: element.TurmaCodigo,
                    Boletim: {
                        FaltaMes01: element.FaltaMes01 !== null ? element.FaltaMes01 : null,
                        FaltaMes02: element.FaltaMes02 !== null ? element.FaltaMes02 : null,
                        FaltaMes03: element.FaltaMes03 !== null ? element.FaltaMes03 : null,
                        FaltaMes04: element.FaltaMes04 !== null ? element.FaltaMes04 : null,
                        FaltaMes05: element.FaltaMes05 !== null ? element.FaltaMes05 : null,
                        FaltaMes06: element.FaltaMes06 !== null ? element.FaltaMes06 : null,
                        FaltaMes07: element.FaltaMes07 !== null ? element.FaltaMes07 : null,
                        FaltaMes08: element.FaltaMes08 !== null ? element.FaltaMes08 : null,
                        FaltaMes09: element.FaltaMes09 !== null ? element.FaltaMes09 : null,
                        FaltaMes10: element.FaltaMes10 !== null ? element.FaltaMes10 : null,
                        FaltaMes11: element.FaltaMes11 !== null ? element.FaltaMes11 : null,
                        FaltaMes12: element.FaltaMes12 !== null ? element.FaltaMes12 : null,
                    }
                });
            });

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });

            const currentTime = new Date();
            const formattedTime = `${currentTime.getHours()}h ${currentTime.getMinutes()}m`;
            setSaveTime(formattedTime);

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Alunos - Frequência Global</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <div className='grid'>
                        <div className="col-12 md:col-2">
                            <SCDropdown
                                name="Meses"
                                options={MESES}
                                optionLabel='des'
                                optionValue='mes_'
                                placeholder='Selecione o Mês'
                                label='Mês'
                                value={mes}
                                onChange={handleMes}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-12 md:col-6">
                            <SCDropdown
                                name="Turma"
                                options={turmas}
                                optionLabel='DescricaoCodigo'
                                optionValue='Codigo'
                                placeholder='Selecione a Turma'
                                label='Turma'
                                value={watchTurmaId}
                                onChange={handleTurmas}
                                style={{ width: '100%' }}
                                disabled={turmaDisabled}
                            />
                        </div>
                        <div className='col-12 md:col-4 flex align-items-end'>
                            <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined" disabled={btnDisabled} onClick={searchBoletins} />
                        </div>
                    </div>

                    <Divider />

                    <DataTable
                        size='small'
                        stripedRows
                        ref={dt}
                        value={boletim}
                        dataKey="id"
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhum registro encontrado."
                        header={null}
                        showGridlines
                        responsiveLayout="scroll">

                        <Column field="Ordem" header="Ordem" sortable headerStyle={{ width: 50 }}></Column>
                        <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }} />
                        <Column field="Nome" header="Nome" sortable headerStyle={{ width: 450 }} />
                        <Column field={`FaltaMes${mes}`} align={'center'} header="Faltas" bodyStyle={{ maxWidth: 100 }} headerStyle={{ width: 100 }} editor={inputTextEditor} />
                    </DataTable>
                    <div className='grid flex justify-content-center'>
                        <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'>
                            {saveTime && <p style={{ color: '#689F38' }}>Faltas Atualizadas às {saveTime}</p>}
                        </div>
                        <div className='col-12 md:col-2 flex justify-content-end'>
                            <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} disabled={gravarDisabled} onClick={saveState} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlunosFrequenciaGlobal;
