import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDropdown, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import { ALERTAS, APROVA_BASE } from '../utilities/constantes';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IDisciplinaAprovaBaseMedia from '../interfaces/IDisciplinaAprovaBaseMedia';
import Loading from '../components/Loading';


const DisciplinaAprovaBaseMedia = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'DisciplinaAprovaBaseMedia';

  const periodo = periodoSelecionado?.Id

  let defaultValues: IDisciplinaAprovaBaseMedia = {
    DisciplinaId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Descricao: '',
    AprovaMedia: null,
  };

  const [disciplinasAprovaBase, setDisciplinasAprovaBase] = useState<any>(null);
  const [disciplinasOriginal, setDisciplinasOriginal] = useState<any>(null);
  const [selectedDisciplinaAprovaBase, setSelectedDisciplinaAprovaBase] = useState<any>(null);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [aprovaMediaValues, setAprovaMediaValues] = useState<any>({});
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;


  const load = async () => {
    setLoading(true);

    try {
      const result = await api.get('/disciplina', { params: { Descricao: getValues()?.Descricao } });
      const resultadoDisciplina = result.data;


      if (resultadoDisciplina) {

        const response = await api.get('/disciplinaAprovaMedia/list', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
          }
        });

        let aprova = response.data;

        aprova.forEach((aprovaMedia: any) => {
          const disciplinaComBaseMedia = resultadoDisciplina.find((disciplina: any) => disciplina.Codigo === aprovaMedia.Codigo)

          if (disciplinaComBaseMedia) {
            disciplinaComBaseMedia.AprovaBaseMedia = aprovaMedia.AprovaMedia
          }
        })

        const filtraDisciplinasNaoAvulsas = resultadoDisciplina.filter((naoAvulsas: any) => naoAvulsas.MontagemHorario === false)

        setDisciplinasAprovaBase(filtraDisciplinasNaoAvulsas)

      } else {
        setDisciplinasAprovaBase([]);
      }
    } catch (e: any) {
      console.error(e.response?.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownChange = (selectedValue: any, id: any) => {
    setDisciplinasAprovaBase((prevDisciplinas: any) => {
      const updatedDisciplinas = prevDisciplinas.map((disciplina: any) => {
        if (disciplina.DisciplinaId === id) {
          return { ...disciplina, AprovaBaseMedia: selectedValue };
        }
        return disciplina;
      });

      return updatedDisciplinas;
    });
  };

  const onSubmit = async (data: any) => {
    data = disciplinasAprovaBase.map((disciplina: any) => ({
      EmpresaId: periodoSelecionado?.EmpresaId,
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      DisciplinaId: disciplina?.DisciplinaId,
      AprovaMedia: disciplina?.AprovaBaseMedia != undefined ? disciplina?.AprovaBaseMedia : null,
    }));

    data.forEach((item: any) => {
      const findDisciplina = disciplinasAprovaBase.find((disciplinas: any) => disciplinas.DisciplinaId === item.DisciplinaId)

      if (findDisciplina) {
        findDisciplina.AprovaBaseMedia = item.AprovaMedia
      }
    })

    try {
      if (disciplinasAprovaBase) {

        const deleteDisciplinaAprovaMedia = async () => {
          const deletePromises = data.map(async (item: any) => {
            await api.delete('/disciplinaAprovaMedia', {
              params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                DisciplinaId: item?.DisciplinaId
              }
            });
          });

          return await Promise.all(deletePromises);
        }

        await deleteDisciplinaAprovaMedia();

        const filtraAprovaMedia = data.filter((item: any) => item.AprovaMedia != null);
        console.log('filtraAprovaMedia', filtraAprovaMedia)

        if (filtraAprovaMedia.length > 0) {
          await api.post('/disciplinaAprovaMedia', filtraAprovaMedia);
        } else {
          load();
          toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
        }

        load();
        toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      }
    } catch (e: any) {
      console.error(e.response?.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    } finally {
      console.log('ok')
    }
  }



  useEffect(() => {
    load();
  }, []);

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },

    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };


  return (

    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Disciplina x Aprova com Base na Média [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <div className='grid pt-2'>
            <div className="col-12 md:col-6">
              <SCInputText
                control={control}
                errors={errors}
                name="Descricao"
                label='Nome da Disciplina'
                className='w-full'
              />
            </div>
            <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
              <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
              <Button label='Gravar Alterações' icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' onClick={onSubmit} />

              <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
              <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
            </div>
          </div>

          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={disciplinasAprovaBase}
              onSelectionChange={(e) => setSelectedDisciplinaAprovaBase(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Codigo" header="Código" sortable headerStyle={{ width: 92 }}></Column>
              <Column field="Descricao" header="Disciplina" sortable ></Column>

              <Column
                header="Aprova com Base na Média"
                body={(rowData: any) => (
                  <div className="col-12 flex justify-content-center">
                    <SCDropdown
                      value={rowData.AprovaBaseMedia}
                      onChange={(e) => handleDropdownChange(e.value, rowData.DisciplinaId)}
                      options={APROVA_BASE}
                      optionLabel='descricao'
                      style={{ width: 220 }}
                      showClear={false}
                      filter={false}
                    />
                  </div>
                )}
                alignHeader='center'
                align='center'
              />
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisciplinaAprovaBaseMedia;
