import React from 'react';
import { Password } from 'primereact/password';
import './SCPassword.css';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import IPassword from '../../../interfaces/components/form/IPassword';
import FormErrorMessage from '../../shared/FormErrorMessage';
import TagRequired from '../../shared/TagRequired';

// Documentation: https://www.primefaces.org/primereact/password/
const SCPassword: React.FC<IPassword> = ({
    label,
    name,
    required,
    maxLength,
    minLength,
    min,
    max,
    pattern,
    validate,
    control,
    errors = [],
    weakLabel,
    mediumLabel,
    strongLabel,
    feedback = false,
    ...restProps
}) => {

    const handleFocus = (event: any) => event.target.select();

    return (
        <>
            {label && (
                <label
                    htmlFor={name}
                    className={classNames({
                        'p-error': errors[name],
                        block: true,
                    })}>
                    {label} {required ? <TagRequired /> : null}
                </label>
            )}
            {control ? (
                <Controller
                    name={name}
                    control={control}
                    rules={{
                        required,
                        minLength,
                        maxLength,
                        min,
                        max,
                        pattern,
                        validate,
                    }}
                    render={({ field }) => (
                        <Password
                            onFocus={handleFocus}
                            id={field.name}
                            weakLabel={weakLabel}
                            mediumLabel={mediumLabel}
                            strongLabel={strongLabel}
                            feedback={feedback}
                            autoComplete="off"
                            {...restProps}
                            {...field}
                            className={`${restProps.className} ${restProps.className} ${classNames({
                                'p-invalid': errors[name],
                                block: true,
                            })}`}
                        />
                    )}
                />
            ) : (
                <Password
                    onFocus={handleFocus}
                    id={name}
                    weakLabel={weakLabel}
                    mediumLabel={mediumLabel}
                    strongLabel={strongLabel}
                    feedback={feedback}
                    autoComplete="off"
                    {...restProps}
                />
            )}
            {control && (
                <FormErrorMessage
                    errors={errors[name]}
                    label={label}
                    maxLength={maxLength}
                    max={max}
                    minLength={minLength}
                    min={min}
                />
            )}
        </>
    );
};
export default SCPassword;
