import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCCalendar, SCDialog, SCDropdown, SCFieldset, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import SetorForm from './SetorForm';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { format, setDefaultOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import AbastecimentoCaixaForm from './AbastecimentoCaixaForm';
import IAbastecimento from '../interfaces/IAbastecimento';

const AbastecimentoCaixa = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const tarefa = 'SetorForm';

  let emptySetor: IAbastecimento = {
    AbastecimentoId: undefined,
    AbastecimentoData: '',
    AbastecimentoHora: '',
    UsuarioId: 0,
    Valor: 0,
    CadastradoData: new Date(''),
    CadastradoHora: '',
    CadastradoOperador: '',
    DataInicio: new Date(),
    DataFinal: new Date(),
  };

  const [abastecimentoCaixa, setAbastecimentoCaixa] = useState<any>(null);
  const [abastecimentoDialog, setAbastecimentoDialog] = useState<boolean>(false);
  const [deleteAbastecimento, setDeleteAbastecimento] = useState<boolean>(false);
  const [abastecimento, setAbastecimento] = useState<IAbastecimento>(emptySetor);
  const [selectedAbastecimento, setSelectedAbastecimento] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);
  const [usuarios, setUsuarios] = useState<any[]>([]);
  const [todosAbastecimentos, setTodosAbastecimentos] = useState<any>(null);
  const [dataFiltroInicial, setDataFiltroInicial] = useState<any>(null);
  setDefaultOptions({ locale: ptBR });

  const methods = useForm({ defaultValues: emptySetor });
  const { handleSubmit, control, formState: { errors }, getValues, setValue, watch } = methods;

  const watchUsuario = watch('UsuarioId');
  const watchDataInicio = watch('DataInicio');
  const watchDataFinal = watch('DataFinal');
  const isMaster = usuario?.Master;
  const usuarioIdLogado = usuario?.UsuarioId;

  const load = async () => {
    setLoading(true);

    const dataI = watchDataInicio ? format(watchDataInicio, 'yyyy-MM-dd') : '';
    const dataF = watchDataFinal ? format(watchDataFinal, 'yyyy-MM-dd') : '';

    console.log(watchUsuario)
    try {
      const resp = await api.get('/abastecimentocaixa/filtroLista', {
        params: {
          DataI: dataI,
          DataF: dataF,
          UsuarioId: watchUsuario ? watchUsuario : undefined,
          Master: isMaster,
          UsuarioIdLogado: usuarioIdLogado,
        },
      });

      if (resp.data) {
        setAbastecimentoCaixa(resp.data);
      } else {
        setAbastecimentoCaixa([]);
      }
    } catch (error) {
      console.error('Erro ao carregar abastecimentos:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadUsuarios = async () => {
    try {
      const resp = await api.get('/usuario/listaUsuarioEmpresa', {
        params: { EmpresaId: periodoSelecionado?.EmpresaId },
      });

      console.log('Dados dos usuários:', resp.data);

      if (resp.data) {
        setUsuarios(resp.data);
      } else {
        setUsuarios([]);
      }
    } catch (error) {
      console.error('Erro ao carregar usuários:', error);
    }
  };

  useEffect(() => {
    loadUsuarios();
  }, []);

  useEffect(() => {
    setValue('UsuarioId', usuarioIdLogado); // Seta o usuário logado no dropdown
    const today = new Date();
    setValue('DataInicio', today); // Define a data inicial como hoje
    setValue('DataFinal', today); // Define a data final como hoje

    if (watchUsuario != 0) {
      load();
    }
  }, [usuarios]);

  function formataData(date: any) {
    if (typeof date !== 'string') {
      date = new Date(date).toISOString();
    }

    const [year, month, day] = date.substr(0, 10).split('-');
    return format(new Date(year, month - 1, day), 'dd/MM/yyyy');
  }

  useEffect(() => {
    if (!watchDataFinal) {
      setValue('DataFinal', watchDataInicio);
    }
  }, [watchDataInicio]);

  useEffect(() => {
    if (!watchDataInicio) {
      setValue('DataInicio', watchDataFinal);
    }
  }, [watchDataFinal]);

  const openNew = () => {
    setAbastecimento(emptySetor);
    setSubmitted(false);
    setAbastecimentoDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setAbastecimentoDialog(false);
  };

  const editar = (documento: any) => {
    setAbastecimento(documento);
    setAbastecimentoDialog(true);
  };

  const confirmDelete = (abastecimento: any) => {
    setAbastecimento(abastecimento);
    setDeleteAbastecimento(true);
    setAbastecimentoDialog(true);
    abastecimento.AbastecimentoData = abastecimento.AbastecimentoData ? new Date(abastecimento.AbastecimentoData) : new Date('');
    abastecimento.CadastradoData = abastecimento.CadastradoData ? new Date(abastecimento.CadastradoData) : new Date('');
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/abastecimentocaixa', { params: { AbastecimentoId: abastecimento.AbastecimentoId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteAbastecimento(false);
      setAbastecimentoDialog(false);
      setAbastecimento(emptySetor);
    } catch (e: any) {
      console.log('Erro ao deletar:', e.response.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data });
    }
  };

  const onSubmit = async (data: any) => {
    load();
  };

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      },
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      },
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      },
    },
  ];

  const openMenu = (event: any) => {
    menu?.current?.toggle(event);
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className="flex justify-content-center align-items-center p-2 border-round-top-md text-white" style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Abastecimento de Caixa</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="pt-2">
            <SCFieldset legend="Filtros" className="col-12 mt-1 p-0">
              <div className="grid align-items-center">
                <div className="col-12 sm:col-6 md:col-2 mb-2 sm:mb-0">
                  <SCCalendar
                    control={control}
                    name="DataInicio"
                    dateFormat="dd/mm/yy"
                    mask="99/99/9999"
                    placeholder="Data Início"
                    showIcon
                  />
                </div>

                <div className="col-12 sm:col-6 md:col-2 mb-2 sm:mb-0">
                  <SCCalendar
                    control={control}
                    name="DataFinal"
                    dateFormat="dd/mm/yy"
                    mask="99/99/9999"
                    placeholder="Data Final"
                    showIcon
                  />
                </div>

                <div className="col-12 sm:col-12 md:col-4 mb-2 sm:mb-0">
                  <SCDropdown
                    name="UsuarioId"
                    options={usuarios}
                    optionLabel="Apelido"
                    optionValue="UsuarioId"
                    placeholder="Selecione o Usuário"
                    style={{ width: '100%' }}
                    value={watchUsuario}
                    onChange={(e) => setValue('UsuarioId', e.target.value)}
                    disabled={!isMaster}
                  />
                </div>

                <div className="col-12 sm:col-12 md:col-4 flex flex-wrap sm:flex-nowrap justify-content-end">
                  <Button label="Pesquisar" icon="pi pi-search" className="p-button-info p-button-outlined mb-2 sm:mb-0 sm:mr-2" type="button" onClick={load} />
                  <Button label="Novo" icon="pi pi-file-o" className="p-button-success mb-2 sm:mb-0 sm:mr-2" type="button" onClick={openNew} />
                  <Button label="Opções" className="p-button-secondary" type="button" icon="pi pi-plus" onClick={openMenu} />
                </div>
              </div>
            </SCFieldset>
          </form>


          <Divider />

          {loading ? (
            <Loading full={false} />
          ) : (
            <DataTable
              size="small"
              stripedRows
              ref={dt}
              value={abastecimentoCaixa}
              onSelectionChange={(e) => setSelectedAbastecimento(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll"
            >
              <Column
                field="AbastecimentoData"
                header="Data"
                dataType="data"
                sortable
                body={(rowData: any) => <span>{rowData.AbastecimentoData ? formataData(rowData.AbastecimentoData) : ''}</span>}
                style={{ width: '10%' }}
              />
              <Column field="AbastecimentoHora" header="Hora" sortable headerStyle={{ width: '5%' }} />
              <Column field="Apelido" header="Usuário" sortable headerStyle={{ width: '30%' }} />
              <Column field="CadastradoOperador" header="Abastecido Por" sortable headerStyle={{ width: '30%' }} />
              <Column field="CadastradoData" header="Abastecido Em" align='center' sortable headerStyle={{ width: '15%' }}
                body={(rowData: any) => <span>{rowData.CadastradoData ? formataData(rowData.CadastradoData) : ''}</span>}
              />
              <Column field="Valor" header="Valor" headerStyle={{ width: '15%' }} align='center' sortable body={(rowData: any) => <span>R$ {rowData.Valor?.toFixed(2).replace('.', ',')}</span>}

              />
              <Column
                body={(rowData: any) => (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                )}
                style={{ width: 90 }}
              />
            </DataTable>
          )}

          <SCDialog
            maximized={!util.isDesktop()}
            visible={abastecimentoDialog}
            style={{ width: util.isDesktop() ? '80%' : '100%' }}
            closable={!util.isDesktop()}
            header={`${abastecimento.AbastecimentoId === undefined ? 'Cadastrando ' : deleteAbastecimento ? 'Excluíndo' : 'Alterando'} Abastecimento`}
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
            <AbastecimentoCaixaForm
              setAbastecimentoDialog={setAbastecimentoDialog}
              abastecimento={abastecimento}
              reload={load}
              deleteAbastecimento={deleteAbastecimento}
              setDeleteAbastecimento={setDeleteAbastecimento}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>



  );
};

export default AbastecimentoCaixa;
