import React, { useEffect, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';

import { SCButton, SCCheckbox, SCDropdown, SCInputMask, SCInputNumber, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import { HiCurrencyDollar, HiOutlineDocumentText } from 'react-icons/hi';
import { Divider } from 'primereact/divider';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { LOCAL_PAGAMENTO } from '../utilities/constantes';
import SelectConvenio from '../components/SelectConvenio';
import SelectPlanoPagamento from '../components/SelectPlanoPagamento';
import { useQuery, useQueryClient } from 'react-query';
import api from '../services/api';
import { Dialog } from 'primereact/dialog';
import AlunosFormResponsavel from './AlunosFormResponsavel';
import { classNames } from 'primereact/utils';
import { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';
import InputCPFCNPJ from '../components/form/inputCPFCNPJ/inputCPFCNPJ';
import { FaUserPlus } from 'react-icons/fa';
import SelectEstadoCivil from '../components/SelectEstadoCivil';
import AlunosFormPai from './AlunosFormPai';
import AlunosFormMae from './AlunosFormMae';
import util from '../utilities/util';

type Props = any;

const AlunosFormPaisResponsaveis: React.FC<Props> = ({showDelete, ...props }) => {

  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const [parcelaContratoLiquida, setParcelaContratoLiquida] = React.useState<number>(0);
  const [primeiraParcelaContratoLiquida, setPrimeiraParcelaContratoLiquida] = React.useState<number>(0);
  const [viewFormResponsavelFin, setViewFormResponsavelFin] = useState(false);
  const [viewFormResponsavelAca, setViewFormResponsavelAca] = useState(false);
  const [isCPF, setIsCPF] = useState(true);
  const [viewFormPai, setViewFormPai] = useState(false);
  const [viewFormMae, setViewFormMae] = useState(false);

  const { control, formState: { errors }, getValues, setValue, watch, setError, clearErrors } = useFormContext();

  const watchRecalcularContrato = watch('RecalcularContrato');
  const watchCPF =  watch('ResponsavelFIN.CPF');
  const watchMoramJuntos = watch('MoramJuntos');
  const queryClient = useQueryClient()
  useEffect(() => {
    if (watchCPF){
      setIsCPF(watchCPF.length === 14);
    }
  }, [watchCPF])

  
  useEffect(() => {
    calculaValorLiquidoParcela();
    calculaValorLiquidoPrimeiraParcela();
  }, []);

  const calculaValorLiquidoPrimeiraParcela = () => {    
    const valor = getValues('AlunosPeriodo.PrimeiraParcelaContrato');
    const desconto = getValues('AlunosPeriodo.DescontoPrimeiraContrato');
    const liquido = valor - desconto;
    setPrimeiraParcelaContratoLiquida(liquido);
  }

  const calculaValorLiquidoParcela = () => {
    const valor = getValues('AlunosPeriodo.ValorParcelaContrato');
    const desconto = getValues('AlunosPeriodo.ValorDescontoContrato');
    const liquido = valor - desconto;
    setParcelaContratoLiquida(liquido);
  }

  useEffect(() => {
    calculaValorLiquidoParcela();
    calculaValorLiquidoPrimeiraParcela();
  }, [watchRecalcularContrato]);

  useEffect(() => {
    if (watchMoramJuntos === 'S'){
      setValue('ReacaoFilho', '')
    }

  }, [watchMoramJuntos])

  const copiaDados = (e: any, tipo: string) => {    
    const from = e.target.value.toUpperCase();
    const dados = getValues() as IAlunoForm;
    switch (from) {
      case '=':
      {
        setValue(`Responsavel${tipo}.Nome`, dados.Nome);
        setValue(`Responsavel${tipo}.DataNascimento`, dados.DataNascimento);
        setValue(`Responsavel${tipo}.ParentescoId`, 0);
        setValue(`Responsavel${tipo}.EstadoCivil`, dados.EstadoCivil);
        setValue(`Responsavel${tipo}.Natural`, dados.Natural);
        setValue(`Responsavel${tipo}.Nacionalidade`, dados.Nacionalidade);
        setValue(`Responsavel${tipo}.RG`, dados.RG);
        setValue(`Responsavel${tipo}.CPF`, dados.CPF);
        setValue(`Responsavel${tipo}.CNPJ`, '');
        setValue(`Responsavel${tipo}.DDD`, dados.DDD);
        setValue(`Responsavel${tipo}.FoneCelular`, dados.FoneCelular);
        setValue(`Responsavel${tipo}.FoneOutro`, dados.FoneOutro);
        setValue(`${tipo}Endereco.Logradouro`, dados?.ALUNOEndereco?.Logradouro);
        setValue(`${tipo}Endereco.Cep`, dados?.ALUNOEndereco?.Cep);
        setValue(`${tipo}Endereco.Numero`, dados?.ALUNOEndereco?.Numero);
        setValue(`${tipo}Endereco.Complemento`, dados?.ALUNOEndereco?.Complemento);
        setValue(`${tipo}Endereco.Bairro`, dados?.ALUNOEndereco?.Bairro);
        setValue(`${tipo}Endereco.UF`, dados?.ALUNOEndereco?.UF);
        setValue(`${tipo}Endereco.Cidade`, dados?.ALUNOEndereco?.Cidade);
        break;
      }           
      case 'PAI':
      {
        setValue(`Responsavel${tipo}.Nome`, dados.Pai);
        setValue(`Responsavel${tipo}.DataNascimento`, dados.PaiDataNascimento);
        setValue(`Responsavel${tipo}.ParentescoId`, 5);
        setValue(`Responsavel${tipo}.EstadoCivil`, dados.PaiEstadoCivil);
        setValue(`Responsavel${tipo}.Natural`, '');
        setValue(`Responsavel${tipo}.Nacionalidade`, dados.PaiNacionalidade);
        setValue(`Responsavel${tipo}.RG`, dados.PaiRG);
        setValue(`Responsavel${tipo}.CPF`, dados.PaiCPF);
        setValue(`Responsavel${tipo}.CNPJ`, '');
        setValue(`Responsavel${tipo}.DDD`, dados.PaiDDD);
        setValue(`Responsavel${tipo}.FoneCelular`, dados.PaiFoneCelular);
        setValue(`Responsavel${tipo}.FoneOutro`, dados.PaiFoneOutro);
        setValue(`${tipo}Endereco.Logradouro`, dados?.PAIEndereco?.Logradouro);
        setValue(`${tipo}Endereco.Cep`, dados?.PAIEndereco?.Cep);
        setValue(`${tipo}Endereco.Numero`, dados?.PAIEndereco?.Numero);
        setValue(`${tipo}Endereco.Complemento`, dados?.PAIEndereco?.Complemento);
        setValue(`${tipo}Endereco.Bairro`, dados?.PAIEndereco?.Bairro);
        setValue(`${tipo}Endereco.UF`, dados?.PAIEndereco?.UF);
        setValue(`${tipo}Endereco.Cidade`, dados?.PAIEndereco?.Cidade);
        break;
      } 
      case 'MAE':
      {
        setValue(`Responsavel${tipo}.Nome`, dados.Mae);
        setValue(`Responsavel${tipo}.DataNascimento`, dados.MaeDataNascimento);
        setValue(`Responsavel${tipo}.ParentescoId`, 4);
        setValue(`Responsavel${tipo}.EstadoCivil`, dados.MaeEstadoCivil);
        setValue(`Responsavel${tipo}.Natural`, '');
        setValue(`Responsavel${tipo}.Nacionalidade`, dados.MaeNacionalidade);
        setValue(`Responsavel${tipo}.RG`, dados.MaeRG);
        setValue(`Responsavel${tipo}.CPF`, dados.MaeCPF);
        setValue(`Responsavel${tipo}.CNPJ`, '');
        setValue(`Responsavel${tipo}.DDD`, dados.MaeDDD);
        setValue(`Responsavel${tipo}.FoneCelular`, dados.MaeFoneCelular);
        setValue(`Responsavel${tipo}.FoneOutro`, dados.MaeFoneOutro);
        setValue(`${tipo}Endereco.Logradouro`, dados?.MAEEndereco?.Logradouro);
        setValue(`${tipo}Endereco.Cep`, dados?.MAEEndereco?.Cep);
        setValue(`${tipo}Endereco.Numero`, dados?.MAEEndereco?.Numero);
        setValue(`${tipo}Endereco.Complemento`, dados?.MAEEndereco?.Complemento);
        setValue(`${tipo}Endereco.Bairro`, dados?.MAEEndereco?.Bairro);
        setValue(`${tipo}Endereco.UF`, dados?.MAEEndereco?.UF);
        setValue(`${tipo}Endereco.Cidade`, dados?.MAEEndereco?.Cidade);
        break;
      }
      case 'MÃE':
      {
        setValue(`Responsavel${tipo}.Nome`, dados.Mae);
        setValue(`Responsavel${tipo}.DataNascimento`, dados.MaeDataNascimento);
        setValue(`Responsavel${tipo}.ParentescoId`, 4);
        setValue(`Responsavel${tipo}.EstadoCivil`, dados.MaeEstadoCivil);
        setValue(`Responsavel${tipo}.Natural`, '');
        setValue(`Responsavel${tipo}.Nacionalidade`, dados.MaeNacionalidade);
        setValue(`Responsavel${tipo}.RG`, dados.MaeRG);
        setValue(`Responsavel${tipo}.CPF`, dados.MaeCPF);
        setValue(`Responsavel${tipo}.CNPJ`, '');
        setValue(`Responsavel${tipo}.DDD`, dados.MaeDDD);
        setValue(`Responsavel${tipo}.FoneCelular`, dados.MaeFoneCelular);
        setValue(`Responsavel${tipo}.FoneOutro`, dados.MaeFoneOutro);
        setValue(`${tipo}Endereco.Logradouro`, dados?.MAEEndereco?.Logradouro);
        setValue(`${tipo}Endereco.Cep`, dados?.MAEEndereco?.Cep);
        setValue(`${tipo}Endereco.Numero`, dados?.MAEEndereco?.Numero);
        setValue(`${tipo}Endereco.Complemento`, dados?.MAEEndereco?.Complemento);
        setValue(`${tipo}Endereco.Bairro`, dados?.MAEEndereco?.Bairro);
        setValue(`${tipo}Endereco.UF`, dados?.MAEEndereco?.UF);
        setValue(`${tipo}Endereco.Cidade`, dados?.MAEEndereco?.Cidade);
        break;
      }
    }
  }
  

  return (
    <div className='col-12' > 

      <Divider align="left">
        <div className="inline-flex align-items-center">
          <i className="pi pi-id-card mr-2"></i>
          <b>Filiação</b>
        </div>
      </Divider>
      
      {/* PAI */}
      <div className='grid'>
        <div className="col-5">
          <SCInputText
            label='Pai'
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Pai"
            disabled={showDelete}
            maxLength={60}
          />
        </div>
        <div className="col-1">
          <SCInputMask
            label='DDD'
            control={control}
            errors={errors}
            name="PaiDDD"
            mask='(999)'
            disabled={showDelete}
            maxLength={5}
          />
        </div>
        <div className="col-3">
          <label
            htmlFor="PaiFoneCelular"
            className={classNames({
              'p-error': false,
              block: true,
            })}>
            Celular 
          </label>
          <div className='p-inputgroup'>
            <SCInputText
              control={control}
              errors={errors}
              name="PaiFoneCelular"
              disabled={showDelete}
              maxLength={20}
              className='w-full'
            />
            <SCButton style={{width: 36, maxHeight: 27}} className='p-button-info p-button-outlined'
              onClick={() => setViewFormPai(true)}
              icon={<FaUserPlus />} />

          </div>
        </div>
      </div>

      {/* MÃE */}
      <div className='grid'>
        <div className="col-5">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            label='Mãe'              
            name="Mae"
            disabled={showDelete}
            maxLength={60}
            required
          />
        </div>
        <div className="col-1">
          <SCInputMask
            control={control}
            errors={errors}
            label='DDD'              
            name="MaeDDD"
            mask='(999)'
            disabled={showDelete}
            maxLength={5}
          />
        </div>
        <div className="col-3">
          <label
            htmlFor="MaeFoneCelular"
            className={classNames({
              'p-error': false,
              block: true,
            })}>
            Celular               
          </label>
          <div className="p-inputgroup"> 
            <SCInputText
              control={control}
              errors={errors}
              name="MaeFoneCelular"
              disabled={showDelete}
              maxLength={20}
              className='w-full'
            />
            <SCButton style={{width: 36, maxHeight: 27}} className='p-button-info p-button-outlined' onClick={() => setViewFormMae(true)} icon='pi pi-user-plus' />
          </div>
        </div>
      </div>
      
      <div className='grid'>
        <div className="col-3">
          <SelectEstadoCivil
            control={control}
            errors={errors}
            name="PaisEstadoCivil"
            label='Estado Civil'
            disabled={showDelete}
          />
        </div>
        <div className="col-2">
          <SCDropdown
            control={control}
            errors={errors}
            name="MoramJuntos"
            label='Moram juntos'
            disabled={showDelete}
            options={[
              { label: 'Sim', value: "S" },
              { label: 'Não', value: "N" },
            ]}
          />
        </div>
        <div className="col-6">
          <SCInputText
            control={control}
            errors={errors}
            name="ReacaoFilho"
            label='Reação da criança com a situação?'
            disabled={showDelete || watchMoramJuntos === 'S'}
            maxLength={10}
          />
        </div>
      </div>
          
      <Dialog
        appendTo='self'
        closeOnEscape
        header='Dados do Pai'
        visible={viewFormPai}
        style={{ width: '70vw' }}
        onHide={() => { if (!viewFormPai) return; setViewFormPai(false); }}>
        <AlunosFormPai showDelete={showDelete} matricula={watch('Matricula')} />
      </Dialog>

      <Dialog
        appendTo='self'
        closeOnEscape
        header='Dados da Mãe'
        visible={viewFormMae}
        style={{ width: '70vw' }}
        onHide={() => { if (!viewFormMae) return; setViewFormMae(false); }}>
        <AlunosFormMae showDelete={showDelete} matricula={watch('Matricula')} />
      </Dialog>
        

      <Divider align="left">
        <div className="inline-flex align-items-center">
          <i className="pi pi-id-card mr-2"></i>
          <b>Responsáveis</b>
        </div>
      </Divider>

      <div className='grid'>
        <div className="col-5">
          <SCInputText
            label='Resp. Financeiro'
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase'}}
            name={`ResponsavelFIN.Nome`}
            disabled={showDelete}
            maxLength={60}
            onBlur={(e) => copiaDados(e, 'FIN')}
            required
          />
        </div>
        <div className="col-3">
          <label
            htmlFor="Contato"
            className={classNames({
              'p-error': get(errors, 'ResponsavelFIN.CPF'),
              block: true,
            })}>
            CPF / CNPJ *
          </label>
          <div className="p-inputgroup w-full"> 
          <InputCPFCNPJ
            control={control}
            errors={errors}
            name={`ResponsavelFIN.CPF`}
            disabled={showDelete}
            required={isCPF}
            className='w-full p-inputtext p-component p-inputtext-sm block'
            onBlur={(e) => util.cpfValido(e.target.value) ? clearErrors(`ResponsavelFIN.CPF`) : setError(`ResponsavelFIN.CPF`, { type: 'manual', message: 'CPF inválido' })}
          />
              
          <SCButton style={{width: 36, maxHeight: 27}} className='p-button-info p-button-outlined'
            onClick={() => setViewFormResponsavelFin(true)}
            icon={<FaUserPlus />} />

          </div>
        </div>
      </div>
      <div className='grid'>

        <div className="col-5">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            label='Resp. Acadêmico'              
            name="ResponsavelACA.Nome"
            disabled={showDelete}
            maxLength={60}
            onBlur={(e) => copiaDados(e, 'ACA')}
          />
        </div>
        <div className="col-3">
          <label
            htmlFor="ResponsavelACA.CPF"
            className={classNames({
              'p-error': false,
              block: true,
            })}>
            CPF 
          </label>
          <div className="p-inputgroup w-full">
            <SCInputMask
              control={control}
              errors={errors}
              name="ResponsavelACA.CPF"
              disabled={showDelete}
              maxLength={14}
              mask='999.999.999-99'
              className='w-full'
              onBlur={(e) => util.cpfValido(e.target.value) ? clearErrors(`ResponsavelACA.CPF`) : setError(`ResponsavelACA.CPF`, { type: 'manual', message: 'CPF inválido' })}
            />
            <SCButton style={{width: 36, maxHeight: 27}} className='p-button-info p-button-outlined' onClick={() => setViewFormResponsavelAca(true)} icon='pi pi-user-plus' />
          </div>
        </div>
      </div>

      <Dialog
        appendTo='self'
        closeOnEscape
        header='Responsável Financeiro'
        visible={viewFormResponsavelFin}          
        style={{ width: '70vw' }}
        onHide={() => { if (!viewFormResponsavelFin) return; setViewFormResponsavelFin(false); }}>
          <AlunosFormResponsavel disabled={showDelete} tipo='FIN' />
      </Dialog>

      <Dialog
        appendTo='self'
        closeOnEscape
        header='Responsável Acadêmico'
        visible={viewFormResponsavelAca}
        style={{ width: '70vw' }}
        onHide={() => { if (!viewFormResponsavelAca) return; setViewFormResponsavelAca(false); }}>
        <AlunosFormResponsavel disabled={showDelete} tipo='ACA' />
      </Dialog>
            
    </div>
  );
};

export default AlunosFormPaisResponsaveis;
