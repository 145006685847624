import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCalendar, SCCheckbox, SCFieldset, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IOrigem from '../interfaces/IOrigem';
import IFonteFinanceira from '../interfaces/IFonteFinanceira';
import { useAuth } from '../providers/auth';
import { subDays } from 'date-fns'

interface Props {
  fonte?: IFonteFinanceira;
  setFonteDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteFonte: boolean;
  setDeleteFonte(param: boolean): void;
}

const FonteFinanceiraForm: React.FC<Props> = ({ setFonteDialog, fonte, reload, deletar, deleteFonte, setDeleteFonte }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'FonteFinanceiraForm';

  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const [validaBanco, setValidaBanco] = useState(false)
  const [validaBancoCaixa, setValidaBancoCaixa] = useState(false)

  const defaultValues: IFonteFinanceira = fonte || {
    FonteId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Codigo: '',
    Desativado: false,
    Tipo: 0,
    BancoCodigo: "",
    BancoAgencia: "",
    BancoConta: "",
    BancoSaldo: 0,
    BancoDataSaldo: null,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset, setError, clearErrors } = methods;

  const onSubmit = async (data: IFonteFinanceira) => {

    let resp: any = undefined;

    if (camposBancoObrigatorio()) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Os campos Código do Banco, Agência e Conta Corrente são obrigatórios' });
      return;
    }


    try {
      data.BancoSaldo = data.BancoSaldo ? parseFloat(data.BancoSaldo.toString().replace(',', '.')) : 0;
      if (data.FonteId === undefined) {
        resp = await api.post('/fontes', data);
      } else {
        resp = await api.put('/fontes', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setFonteDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setDeleteFonte(false);
    setFonteDialog(false);
    reset();
  }

  const watchTipo = watch('Tipo');

  useEffect(() => {
    if (watchTipo == 2) {
      setValidaBanco(false)
      setValidaBancoCaixa(false)
    }
    else if (watchTipo === 1) {
      setValue("BancoCodigo", "")
      setValue("BancoAgencia", "")
      setValue("BancoConta", "")

      setValidaBanco(true)
      setValidaBancoCaixa(false)
    }
    else {
      setValue("BancoCodigo", "")
      setValue("BancoAgencia", "")
      setValue("BancoConta", "")
      setValue("BancoSaldo", 0)
      setValue("BancoDataSaldo", new Date(''))

      setValidaBanco(true)
      setValidaBancoCaixa(true)
    }
  }, [watchTipo])

  useEffect(() => {
    if (fonte?.FonteId === undefined) {
      setValue('Tipo', 3);
    }
  }, [fonte?.FonteId]);

  const camposBancoObrigatorio = () => {
    if (watchTipo === 2) {
      if (getValues('BancoCodigo') === '' || getValues('BancoAgencia') === '' || getValues('BancoConta') === '') {
        return true
      }
    }
    return false
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Fonte Financeira'
            maxLength={10}
            disabled={deleteFonte || fonte?.FonteId !== undefined}
            tarefa={tarefa}
          />
        </div>
        <div className='col-12'>
          <SCCheckbox
            control={control}
            name="Desativado"
            label='Desabilitar esse código'
            disabled={deleteFonte}
            tarefa={tarefa}
          />
        </div>

        <Divider />

        <div className='col-12'>
          <SCRadioButton
            control={control}
            name="Tipo"
            label='Essa fonte é Caixa Geral'
            value={1}
            disabled={deleteFonte}
          />

          <SCRadioButton
            control={control}
            name="Tipo"
            label='Essa fonte é BANCO'
            value={2}
            disabled={deleteFonte}
          />

          <SCRadioButton
            control={control}
            name="Tipo"
            label='Outros'
            value={3}
            disabled={deleteFonte}
          />
        </div>

        <SCFieldset legend='Dados Bancários' className='col-12 mt-3' >
          <div className='grid flex'>
            <div className="col-4 md:col-2">
              <SCInputText
                control={control}
                errors={errors}
                style={{ textTransform: 'uppercase' }}
                name="BancoCodigo"
                label='Código do Banco'
                maxLength={3}
                disabled={deleteFonte || validaBanco}
                tarefa={tarefa}
                required={camposBancoObrigatorio()}
              />
            </div>
            <div className="col-4 md:col-2">
              <SCInputText
                control={control}
                errors={errors}
                style={{ textTransform: 'uppercase' }}
                name="BancoAgencia"
                label='Agência'
                maxLength={6}
                disabled={deleteFonte || validaBanco}
                tarefa={tarefa}
                required={camposBancoObrigatorio()}
              />
            </div>
            <div className="col-4 md:col-2">
              <SCInputText
                control={control}
                errors={errors}
                style={{ textTransform: 'uppercase' }}
                name="BancoConta"
                label='Conta Corrente'
                maxLength={15}
                disabled={deleteFonte || validaBanco}
                tarefa={tarefa}
                required={camposBancoObrigatorio()}
              />
            </div>
          </div>

          <div className='grid flex'>
            <div className="col-4 md:col-2">
              <SCInputText
                control={control}
                errors={errors}
                style={{ textTransform: 'uppercase' }}
                name="BancoSaldo"
                label='Saldo R$'
                maxLength={7}
                disabled={deleteFonte || validaBancoCaixa}
                tarefa={tarefa}
              />
            </div>
            <div className="col-4 md:col-2">
              <SCCalendar
                label='Data do Saldo'
                control={control}
                name='BancoDataSaldo'
                dateFormat='dd/mm/yy'
                mask='99/99/9999'
                maxDate={new Date()}
                showIcon
                disabled={deleteFonte || validaBancoCaixa}
              />
            </div>
          </div>
        </SCFieldset>


        {deleteFonte ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>
    </form >
  );

};

export default FonteFinanceiraForm;
