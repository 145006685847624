import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputText, SCInputTextarea, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, TIPO_ESTADO_CIVIL, TIPO_GRAU_INSTRUCAO, TIPO_REGIME_TRABALHO, TIPO_SEXO } from '../utilities/constantes';
import util from '../utilities/util';
import { useAuth } from '../providers/auth';
import { TabPanel, TabView } from 'primereact/tabview';
import Loading from '../components/Loading';
import IFuncionario from '../interfaces/IFuncionario';
import { isValid } from 'cpf';
import FormEndereco from '../components/FormEndereco';
import { InputSwitch } from 'primereact/inputswitch';
import { differenceInYears, getYear } from 'date-fns';
import validator from 'validator';

interface Props {
  funcionario?: IFuncionario;
  setFuncionarioDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteFuncionario: boolean;
  setDeleteFuncioanrio(param: boolean): void;
}


const FuncionarioForm: React.FC<Props> = ({ setFuncionarioDialog, funcionario, reload, deletar, deleteFuncionario, setDeleteFuncioanrio }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'FuncionarioForm';

  const toast = useToast();
  const { usuario, empresaSelecionada, periodoSelecionado } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [setor, setSetor] = useState([]);
  const [validaEndereco, setValidaEndereco] = useState(false)
  const [validandoCPF, setValidandoCPF] = useState(false)
  const [checked, setChecked] = useState(false);
  const [checkProfessor, setCheckProfessor] = useState(false)

  const defaultValues: IFuncionario = funcionario || {
    FuncionarioId: undefined,
    Matricula: 0,
    Nome: '',
    Guerra: '',
    DataNascimento: new Date(''),
    Sexo: '',
    EstadoCivil: '',
    LecionaRegular: 0,
    LecionaGraduacao: 0,
    LecionaPos: 0,
    LecionaTec: 0,
    RG: '',
    CPF: '',
    EnderecoId: 0,
    Cep: '',
    Logradouro: '',
    Complemento: '',
    Bairro: '',
    Numero: '',
    Cidade: '',
    Uf: '',
    TelefoneOutros: '',
    TelefoneCelular: '',
    Email: '',
    GrauInstrucao: '',
    Formacao: '',
    Cargo: '',
    Contratado: 0,
    Observacao: '',
    Ativado: false,
    DataAdimissao: new Date(''),
    DataDemissao: null,
    RegimeTrabalho: '',
    CargaHoraria: 0,
    Regencia: 0,
    FuncaoAdministrativa: 0,
    SetorId: 0,
    Titulacao: '',
    AcessoProfessorTurma: 0,
    CadastraAgenda: 0,
    AtendimentoApp: 0,
    AdministradorOs: 0,
    CadastraOs: 0,
    FUNCIOEndereco: [],
    RGORGAO: '',
    TipoAdm: 0,
    TipoProfessor: 0,
    TipoCoordenador: 0,
    DDD: '',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, register, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const watchDemissao = watch('DataDemissao')
  const watchAdimissao = watch('DataAdimissao')

  useEffect(() => {
    load();
  }, [])

  const load = async () => {
    const parametroDDD = await api.get('/parametro', {
      params: {
        EmpresaId: 0,
        Ano: '0000',
        Sequencial: '0',
        Codigo: 'DDDPADRAO',
        Grupo: 'S',
      }
    });

    if (funcionario?.FuncionarioId === undefined) {
      console.log('Parametro Aplicado')
      setValue('DDD', parametroDDD.data.Valor)
    }
  }

  const onSubmit = async (data: any) => {

    setLoading(true)

    let resp: any = undefined;

    data.Guerra = data.Guerra.toLocaleUpperCase()
    data.Nome = data.Nome.toLocaleUpperCase()

    if (data.CPF) {
      data.CPF = data.CPF.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    }

    let { Bairro, Cep, Cidade, Uf, Logradouro, Complemento, EnderecoId, Numero, FUNCIOEndereco, ...newData } = data;

    if (newData.DataDemissao && newData.DataAdimissao) {
      if (newData.DataDemissao < newData.DataAdimissao) {
        setLoading(false)
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Data de Demissão não pode ser menor que a Data de Adimissão' });
        return false
      }
    }

    if (!watchAdimissao) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Informe a Data de Admissão' });
      setLoading(false);
      return
    }

    if (watchDemissao) {
      newData.Ativado = 0;
    }

    const validaIdade = differenceInYears(new Date(), newData.DataNascimento)

    if (newData.DataNascimento) {
      if (getYear(newData.DataNascimento) > getYear(new Date())) {
        setLoading(false)
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Data de Nascimento Inválida!' });
        return false
      }

      if (validaIdade < 18) {
        setLoading(false)
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Usuário menor de 18 anos!' });
        return false
      }

      if (validaIdade > 85) {
        setLoading(false)
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Usuário com idade superior a 85 anos!' });
        return false
      }
    }

    newData.DataAdimissao ??= null;
    newData.DataDemissao ??= null;
    newData.TelefoneCelular = util.removeMascaraCelular(newData.TelefoneCelular) // Removendo máscara de número de celular //

    let formataCep;

    if (data.FUNCIOEndereco?.Cep === undefined || data.FUNCIOEndereco?.Cep === null) {
      formataCep = data.FUNCIOEndereco?.Cep
    } else {
      formataCep = data.FUNCIOEndereco?.Cep.replace('-', '');
    }

    try {

      if (newData.FuncionarioId === undefined) {

        resp = await api.post('/funcionarios', newData);

        const idFun = resp?.data.FuncionarioId[0]
        const matriculaFuncionario = 8500000 + idFun

        newData.Matricula = matriculaFuncionario
        newData.FuncionarioId = idFun

        resp = await api.put('/funcionarios', newData)

        await api.post('/enderecos', {
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Matricula: matriculaFuncionario,
          Tipo: "FUNCIO",
          Cep: formataCep,
          Logradouro: data.FUNCIOEndereco?.Logradouro,
          Numero: data.FUNCIOEndereco?.Numero,
          Complemento: data.FUNCIOEndereco?.Complemento,
          Bairro: data.FUNCIOEndereco?.Bairro,
          Cidade: data.FUNCIOEndereco?.Cidade,
          Uf: data.FUNCIOEndereco?.UF
        })

      } else {

        resp = await api.put('/funcionarios', newData);

        console.log('UF', data.FUNCIOEndereco?.Uf)

        await api.put('/enderecos', {
          Matricula: data?.Matricula,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Tipo: "FUNCIO",
          Cep: formataCep,
          Logradouro: data.FUNCIOEndereco?.Logradouro,
          Numero: data.FUNCIOEndereco?.Numero,
          Complemento: data.FUNCIOEndereco?.Complemento,
          Bairro: data.FUNCIOEndereco?.Bairro,
          Cidade: data.FUNCIOEndereco?.Cidade,
          Uf: data.FUNCIOEndereco?.UF
        });

      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setLoading(false);
      reload();
      setFuncionarioDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
      setLoading(false);
    }
  }

  const consultaSetor = async () => {
    const buscaSetor = await api.get('/setores/todos')
    const resultSetor = buscaSetor.data

    if (resultSetor) {
      setSetor(resultSetor)
    }
  }

  const validaCPF = (value: any) => {
    const numeroDigitos = value ? value.replace(/\D/g, '').length : 0;

    if (numeroDigitos <= 11) {
      if (numeroDigitos === 11) {
        const valida = isValid(value); // Supondo que isValid seja a função que você está usando para validar o CPF

        if (!valida) {
          toast.showToast({ severity: 'error', summary: 'Erro', detail: 'CPF inválido' });
        }

        return valida; // Retorna o resultado da validação do CPF
      }

      return true; // Se o CPF tiver menos ou igual a 11 dígitos, permitimos que o usuário continue digitando
    }

    return false; // Se o CPF tiver mais de 11 dígitos, impedimos o usuário de continuar digitando
  };

  const validaEmail = (value: any) => {
    const valida = validator.isEmail(value)

    if (value) {
      if (!valida) {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'E-mail inválido' });
        return false
      }

      return valida; // Retorna o resultado da validação do E-mail
    }
  }

  const watchTipoProfessor = watch('TipoProfessor')

  if (watchTipoProfessor == 0) {
    setValue('AcessoProfessorTurma', 0)
  }

  useEffect(() => {
    consultaSetor()
  }, [])

  useEffect(() => {
    setChecked(funcionario?.FuncionarioId === undefined ? true : funcionario.Ativado);
  }, [funcionario]);

  useEffect(() => {
    const dataDemissao = getValues()?.DataDemissao;

    if (watchDemissao) {
      setChecked(false)
    }

    if (dataDemissao && checked) {
      setChecked(false);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: "Não é possível ativar um funcionário demitido"
      });
      return;
    }

    setValue('Ativado', checked);
  }, [checked, watchDemissao]);

  const cancelar = (e: any) => {
    setDeleteFuncioanrio(false);
    setFuncionarioDialog(false);
    reset();
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className='pt-3' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

        {loading && (<Loading />)}

        <TabView >
          <TabPanel header="Principal">
            <div className='grid'>
              <div className="col-12 md:col-7">
                <SCInputText
                  control={control}
                  autoFocus={true}
                  errors={errors}
                  name="Nome"
                  label='Nome'
                  minLength={3}
                  required={!deleteFuncionario}
                  disabled={funcionario?.FuncionarioId !== undefined}
                  tarefa={tarefa}
                  tooltip='Máximo de 60 caracteres'
                  tooltipOptions={{ event: 'focus' }}
                />
              </div>

              <div className="col-6 md:col-3">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Guerra"
                  label='Apelido / Sobrenome'
                  maxLength={20}
                  required={!deleteFuncionario}
                  disabled={funcionario?.FuncionarioId !== undefined}
                  tarefa={tarefa}
                  tooltip='Máximo de 20 caracteres'
                  tooltipOptions={{ event: 'focus' }}
                />
              </div>

              <div className="col-6 md:col-2">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Matricula"
                  label='Matrícula'
                  maxLength={8}
                  disabled
                  readOnly
                  type='number'
                  className='readonly'
                />
              </div>
            </div>
            <div className='grid'>
              <div className="col-12 md:col-2">
                <div className='pb-2'>Ativado</div>
                <InputSwitch
                  name='Ativado' checked={checked} onChange={(e) => setChecked(e.value)} />
              </div>

              <div className="col-12 md:col-3">
                <SCDropdown
                  control={control}
                  errors={errors}
                  name="SetorId"
                  label='Setor'
                  options={setor}
                  optionLabel='Descricao'
                  optionValue='SetorId'
                  disabled={deleteFuncionario}
                />
              </div>
              <div className="col-12 md:col-5">
                <SCInputText
                  control={control}
                  errors={errors}
                  maxLength={40}
                  name="Cargo"
                  label='Cargo'
                  disabled={deleteFuncionario}
                  tooltip='Máximo de 40 caracteres'
                  tooltipOptions={{ event: 'focus' }}
                  style={{ textTransform: 'uppercase' }}
                />
              </div>
            </div>

            <div className='grid mt-3'>
              <SCFieldset legend='Tipo / Função' className='col-5' >
                <div className='grid'>
                  <div className="col-12 flex-collunm">
                    <div className="col-12 md:col-3">
                      <SCCheckbox
                        control={control}
                        name="TipoAdm"
                        label='Administrativo'
                        disabled={deleteFuncionario}
                      />
                    </div>
                    <div className="col-12 md:col-3">
                      <SCCheckbox
                        control={control}
                        name="TipoProfessor"
                        label='Professor'
                        disabled={deleteFuncionario}

                      />
                    </div>
                    <div className="col-12 md:col-4">
                      <SCCheckbox
                        control={control}
                        name="TipoCoordenador"
                        label='Coordenador'
                        disabled={deleteFuncionario}
                      />
                    </div>
                  </div>
                </div>

              </SCFieldset>

              <SCFieldset legend='Outras Configurações' className='col-6' >
                <div className="col-12 md:col-12">
                  <SCCheckbox
                    control={control}
                    name="AcessoProfessorTurma"
                    label='Permite ser selecionado na tarefa Professores por Turma'
                    disabled={deleteFuncionario || watchTipoProfessor == 0}
                  />
                </div>

                <div className="col-12 md:col-12">
                  <SCCheckbox
                    control={control}
                    name="CadastraAgenda"
                    label='Autorizado a Cadastrar Agenda / Conteúdo pelo App MaisEscola'
                    disabled={deleteFuncionario}

                  />
                </div>
                <div className="col-12 md:col-12">
                  <SCCheckbox
                    control={control}
                    name="AtendimentoApp"
                    label='Efetua atendimento pelo App MaisEscola'
                    disabled={deleteFuncionario}

                  />
                </div>

                <div className="col-12 md:col-12">
                  <SCCheckbox
                    control={control}
                    name="AdministradorOs"
                    label='Administrador de OS (Ordem de Serviço)'
                    disabled={deleteFuncionario}

                  />
                </div>

                <div className="col-12 md:col-12">
                  <SCCheckbox
                    control={control}
                    name="CadastraOs"
                    label='Autorizado a Cadastrar OS de outro usuário'
                    disabled={deleteFuncionario}

                  />
                </div>
              </SCFieldset>
            </div>

          </TabPanel>

          <TabPanel header="Dados Pessoais">
            <div className='grid'>
              <div className='col-12 md:flex justify-content-between'>
                <div className="col-12 md:col-2">
                  <SCCalendar
                    label='Data de Nascimento'
                    control={control}
                    name='DataNascimento'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    showIcon
                    disabled={deleteFuncionario}
                  />
                </div>
                <div className="col-12 md:col-2">
                  <SCDropdown
                    control={control}
                    errors={errors}
                    name="Sexo"
                    label='Sexo'
                    options={TIPO_SEXO}
                    optionLabel='descricao'
                    disabled={deleteFuncionario}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCDropdown
                    control={control}
                    errors={errors}
                    name="EstadoCivil"
                    label='Estado Civil'
                    options={TIPO_ESTADO_CIVIL}
                    optionLabel='descricao'
                    disabled={deleteFuncionario}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    control={control}
                    errors={errors}
                    style={{ textTransform: 'uppercase' }}
                    name="RG"
                    label='Número do RG'
                    maxLength={20}
                    disabled={deleteFuncionario}
                    type='number'
                    tooltip='Máximo de 20 caracteres'
                    tooltipOptions={{ event: 'focus' }}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    control={control}
                    errors={errors}
                    style={{ textTransform: 'uppercase' }}
                    name="RGORGAO"
                    label='Orgão Expedidor'
                    maxLength={30}
                    disabled={deleteFuncionario}
                    tooltip='Máximo de 30 caracteres'
                    tooltipOptions={{ event: 'focus' }}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputMask
                    control={control}
                    errors={errors}
                    style={{ textTransform: 'uppercase' }}
                    name="CPF"
                    label='Número do CPF'
                    maxLength={14}
                    disabled={deleteFuncionario}
                    mask='999.999.999-99'
                    validate={validaCPF}
                    tooltip='Máximo de 11 caracteres'
                    tooltipOptions={{ event: 'focus' }}
                  />
                </div>
              </div>
            </div>

            <SCFieldset legend='Endereço' className='col-12 mt-3'>
              <FormEndereco matricula={watch('Matricula')} tipo="FUNCIO" />
            </SCFieldset>

            <SCFieldset legend='Contato' className='col-12 mt-3' >
              <div className='grid'>
                {/* <div className="col-6 md:col-2">
                  <SCInputMask
                    control={control}
                    errors={errors}
                    name="TelefoneCelular"
                    label='Celular'
                    disabled={deleteFuncionario}
                    tooltip='Máximo de 11 caracteres'
                    tooltipOptions={{ event: 'focus' }}
                    mask='(99)99999-9999'
                  />
                </div>

                <div className="col-6 md:col-4">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="TelefoneOutros"
                    label='Outros'
                    disabled={deleteFuncionario}
                    tooltip='Máximo de 40 caracteres'
                    tooltipOptions={{ event: 'focus' }}
                  />
                </div> */}

                <div className="col-1">
                  <SCInputMask
                    control={control}
                    errors={errors}
                    name="DDD"
                    label='DDD'
                    mask='999'
                    maxLength={5}
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputMask
                    control={control}
                    errors={errors}
                    name="TelefoneCelular"
                    label='Celular'
                    mask='9 9999-9999'
                    tooltip='Máximo de 11 caracteres'
                    tooltipOptions={{ event: 'focus' }}
                  />
                </div>
                <div className='col-12 md:col-4'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="TelefoneOutros"
                    label='Outros Fones'
                    maxLength={40}
                  />
                </div>

                <div className="col-12 md:col-5">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="Email"
                    label='E-mail'
                    disabled={deleteFuncionario}
                    style={{ textTransform: 'lowercase' }}
                    tarefa={tarefa}
                    validate={validaEmail}
                    upperCase={false}
                  />
                </div>
              </div>
            </SCFieldset>

          </TabPanel>

          <TabPanel header="Formação / RH">
            <div className='grid'>
              <SCFieldset legend='Formação' className='col-12 mt-3' >
                <div className='grid'>
                  <div className="col-12 md:col-3">
                    <SCDropdown
                      control={control}
                      errors={errors}
                      name="GrauInstrucao"
                      label='Grau de Instrução'
                      options={TIPO_GRAU_INSTRUCAO}
                      optionLabel='descricao'
                      disabled={deleteFuncionario}
                    />
                  </div>

                  <div className="col-12 md:col-5">
                    <SCInputText
                      control={control}
                      errors={errors}
                      maxLength={40}
                      name="Formacao"
                      label='Curso / Formação'
                      disabled={deleteFuncionario}
                      tarefa={tarefa}
                      tooltip='Máximo de 40 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <SCInputText
                      control={control}
                      errors={errors}
                      maxLength={40}
                      name="Titulacao"
                      label='Titulação'
                      disabled={deleteFuncionario}
                      tarefa={tarefa}
                      tooltip='Máximo de 40 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                </div>
              </SCFieldset>
            </div>

            <SCFieldset legend='Onde Leciona' className='col-12 mt-3' >
              <div className='grid'>
                <div className="col-12 md:col-3">
                  <SCCheckbox
                    control={control}
                    name="LecionaRegular"
                    label='Regular'
                    disabled={deleteFuncionario}

                  />
                </div>
                <div className="col-12 md:col-3">
                  <SCCheckbox
                    control={control}
                    name="LecionaGraduacao"
                    label='Graduação'
                    disabled={deleteFuncionario}

                  />
                </div>
                <div className="col-12 md:col-4">
                  <SCCheckbox
                    control={control}
                    name="LecionaPos"
                    label='Pós-Graduação'
                    disabled={deleteFuncionario}

                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCCheckbox
                    control={control}
                    name="LecionaTec"
                    label='Tecnologico'
                    disabled={deleteFuncionario}

                  />
                </div>
              </div>
            </SCFieldset>

            <div className='grid mt-3'>
              <div className="col-12 md:col-3">
                <SCDropdown
                  control={control}
                  errors={errors}
                  name="RegimeTrabalho"
                  label='Regime de Trabalho'
                  options={TIPO_REGIME_TRABALHO}
                  optionLabel='descricao'
                  disabled={deleteFuncionario}
                />
              </div>

              <div className="col-12 md:col-2 mt-4">
                <SCCheckbox
                  control={control}
                  name="Regencia"
                  label='Sem Regência'
                  disabled={deleteFuncionario}

                />
              </div>

              <div className="col-12 md:col-1">
                <SCInputText
                  control={control}
                  errors={errors}
                  maxLength={4}
                  name="CargaHoraria"
                  label='CHR'
                  disabled={deleteFuncionario}
                  tooltip='Máximo de 4 caracteres'
                  tooltipOptions={{ event: 'focus' }}
                />
              </div>

              <div className="col-12 md:col-2">
                <div className='pb-2'>Contratado</div>
                <div className="flex align-items-center">
                  <SCRadioButton
                    control={control}
                    name="Contratado"
                    label='Sim'
                    value={1}
                    disabled={deleteFuncionario}
                  />
                  <SCRadioButton
                    control={control}
                    name="Contratado"
                    label='Não'
                    value={2}
                    className='ml-3'
                    disabled={deleteFuncionario}
                  />
                </div>
              </div>

              <div className="col-12 md:col-2">
                <SCCalendar
                  label='Data de Admissão'
                  control={control}
                  name='DataAdimissao'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  showIcon
                  disabled={deleteFuncionario}
                // onBlur={(e) => {
                //   setValue('DataAdimissao', new Date(e.target.value))
                // }}
                />
              </div>

              <div className="col-12 md:col-2">
                <SCCalendar
                  label='Data de Demissão'
                  control={control}
                  name='DataDemissao'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  showIcon
                  disabled={deleteFuncionario}
                // onBlur={(e) => {
                //   setValue('DataDemissao', new Date(e.target.value))
                // }}
                />
              </div>
            </div>

            <div className='grid'>
              <div className="col-12">
                <SCInputTextarea
                  style={{ minHeight: 100 }}
                  control={control}
                  errors={errors}
                  name="Observacao"
                  label='Observação'
                  disabled={deleteFuncionario}
                  rows={5}
                />
              </div>
            </div>

          </TabPanel>


        </TabView>

        <div className='grid'>

          <Divider />

          {deleteFuncionario ? (
            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
              <Button label="Confirmar Exclusão" icon="pi pi-check"
                className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                onClick={deletar} type='button' />
            </div>
          ) : (
            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
              <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
            </div>
          )}

        </div>
      </form>
    </FormProvider>
  );
};

export default FuncionarioForm;
