import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../providers/auth';
import { useNavigate } from 'react-router';
import { useToast } from '../context/ToastContext';

import { Button } from 'primereact/button';
import SCInputText from '../components/form/inputText/SCInputText';
import Loading from '../components/Loading';
import config from '../utilities/config';
import ISignIn from '../interfaces/ISigIn';
import { SCPassword } from '../components';
import { useParams } from 'react-router-dom';


const Login: React.FC = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const { signIn, isLoged, loading } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const { Codigo='12345' } = useParams();

    const defaultValues: ISignIn = {
        Codigo: Codigo || '',
        Email: '',
        Senha: '',
    }

    const uform = useForm({defaultValues})

    const { control, handleSubmit, formState: {errors} } = uform;
   
    const onSubmit = async ({Email, Senha}: ISignIn) =>{
        setIsLoading(true);
        const resLogin = await signIn({Codigo, Email, Senha});
        setIsLoading(false);
        if (!resLogin?.Error)
        {
            toast.showToast({severity: 'success', summary:'Login', detail: 'Login efetuado com sucesso!', life: 3000});
            navigate('/Periodos');
        }
        else
            toast.showToast({ severity: 'error', summary: 'Atenção', detail: resLogin?.Error, life: 3000});
    }

    useEffect(() => {
        if (Codigo)
            localStorage.setItem('harpia.codigo', Codigo);
        else
        {
            localStorage.setItem('harpia.codigo', '12345');
        }
    }, [Codigo])

    useEffect(() => {
        if (isLoged)
            navigate('/Periodos');
    }, [isLoged])

    return (
        <>
            {isLoading || loading ? (
                <Loading full={true}/>
            ):(
                <div className="login-body">  
                    <div className="card login-panel p-fluid flex">
                        <form onSubmit={handleSubmit(onSubmit)} className="col-12 flex flex-1 flex-column justify-content-center align-items-center">
                            <div className="col-12 flex flex-1 flex-column justify-content-center align-items-center">
                                <div className="col-12 lg:col-12 sm:col-6 md:col-6 logo-container">
                                    <div className="flex justify-content-center align-items-center">
                                        <img width="75%" height="75%" src="assets/layout/images/logo_login.png" alt='Logo'/>
                                    </div>
                                </div>
                                
                                <div className="col-12 lg:col-12 sm:col-6 md:col-6 username-container">
                                    <div className="login-input">
                                        <SCInputText
                                            label="E-mail"
                                            name="Email"
                                            control={control}
                                            type="email"                                            
                                            required
                                            errors={errors}     
                                            upperCase={false}                                           
                                        />
                                    </div>

                                    <div className="login-input">
                                        <SCPassword
                                            label="Senha"
                                            name="Senha"
                                            control={control}
                                            type="password"
                                            required
                                            minLength={4}
                                            errors={errors}
                                        />
                                    </div>

                                    <div className="col-12 lg:col-12 sm:col-6 md:col-6 forgetpassword-container p-0 mt-2">
                                        <a style={{ color: "#0f68b8" }} href="/" className="forget-password">
                                            Esqueci a minha senha
                                        </a>
                                    </div>
                                </div>

                                <div className="col-12 lg:col-12 sm:col-6 md:col-6 mt-2">
                                    <Button type='submit' style={{ backgroundColor: "#0f68b8" }} label="Acessar" icon="pi pi-check" />
                                </div>
                                    
                                <div className="col-12 lg:col-12 sm:col-6 md:col-6 mt-2">
                                    V {config.versao}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default Login;