import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCCheckbox, SCDropdown } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import { useAuth } from '../providers/auth';
import { CheckboxChangeParams } from 'primereact/checkbox';


interface IFormAlunoConsulta {
    Nome?: string;
}

const Formados = () => {
    const toast = useToast();
    const tarefa = 'Formados';
    const { periodoSelecionado } = useAuth();

    const [alunos, setAlunos] = useState<any>([]);
    const [turmas, setTurmas] = useState<any>();
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFormAlunoConsulta>({
        Nome: ''
    });

    const dt = useRef<any>(null);
    const menu = useRef<any>(null);

    const methods = useForm();
    const { formState: { errors }, watch, setValue, getValues } = methods;

    const load = async () => {
        const resultTurmas = await api.get('/turmas/combo', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        })

        if (resultTurmas.data)
            setTurmas(resultTurmas.data)
        else
            setTurmas([])

        const result = await api.get('/aluno/list', {
            params: {
                Nome: filter.Nome,
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                SituacaoAcademica: 'M',
            }
        });

        if (result.data) {
            setAlunos(result.data);
        }
        else
            setAlunos([]);
    }

    const watchTurmaId = watch('AlunosPeriodo.TurmaCodigo')

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (watchTurmaId)
            pesquisaAlunosTurma();
    }, [watchTurmaId]);

    const pesquisaAlunosTurma = async () => {
        setLoading(true)

        try {
            const result = await api.get('/aluno/list', {
                params: {
                    Nome: filter.Nome,
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Ano: periodoSelecionado?.Ano,
                    Sequencial: periodoSelecionado?.Sequencial,
                    SituacaoAcademica: 'M',
                    CodigoTurma: watchTurmaId,
                }
            });

            const alunosTurma = result.data;

            if (alunosTurma) {
                setAlunos(alunosTurma)

            } else {
                setAlunos([]);
            }
        } catch (e: any) {
            console.error(e.response?.data);
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        } finally {
            setLoading(false);
        }
    }

    const handleCheckboxChange = (rowData: any, checked: boolean) => {
        try {
            api.put('/alunosPeriodo/atualizaAluno', {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Matricula: rowData.Matricula,
                Formado: checked,
            })

            const updatedAlunos = alunos.map((aluno: any) => {
                if (aluno.Matricula === rowData.Matricula) {
                    return { ...aluno, Formado: checked };
                }
                return aluno;
            });

            setAlunos(updatedAlunos);

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    };

    const opcoes = [
        {
            label: 'Imprimir',
            icon: 'pi pi-print',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Exp. para Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Ajuda',
            icon: 'pi pi-question-circle',
            command: () => {
                dt.current.exportCSV();
            }
        },
    ];

    const openMenu = (event: any) => {
        menu?.current?.toggle(event)
    };

    const loadAlunos = async (codigoTurma: string) => {
        setLoading(true);
        try {
            const result = await api.get('/aluno/list', {
                params: {
                    Nome: filter.Nome,
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Ano: periodoSelecionado?.Ano,
                    Sequencial: periodoSelecionado?.Sequencial,
                    SituacaoAcademica: 'M',
                    CodigoTurma: codigoTurma,
                }
            });
            setAlunos(result.data || []);
        } catch (error) {
            console.error(error);
            toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar alunos.' });
        } finally {
            setLoading(false);
        }
    };

    const handleTurmaChange = (e: any) => {
        const codigoTurma = e.target.value;
        setValue('AlunosPeriodo.TurmaCodigo', codigoTurma);
        loadAlunos(codigoTurma);
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Formados</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <div className='grid'>
                        <div className="col-12 md:col-6 mt-2">
                            <SCDropdown
                                name="Codigo"
                                options={turmas}
                                optionLabel='TurmaDescricao'
                                optionValue='Codigo'
                                placeholder='Selecione a Turma'
                                label='Turma'
                                style={{ width: 400 }}
                                value={watchTurmaId}
                                onChange={handleTurmaChange}
                            />
                        </div>
                        <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                            <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                            <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
                        </div>
                    </div>
                    <Divider />

                    <DataTable
                        size='small'
                        stripedRows
                        ref={dt}
                        value={alunos}
                        //onSelectionChange={(e) => setSelectedCartao(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhum registro encontrado."
                        header={null}
                        responsiveLayout="scroll">
                        <Column
                            field='TurmaCodigo'
                            header='Turma'
                            sortable
                            headerStyle={{ width: 90 }}
                        />
                        <Column
                            field="Matricula"
                            header="Matricula"
                            sortable
                            headerStyle={{ width: 90 }}
                        />
                        <Column
                            field="Nome"
                            header="Alunos"
                            sortable
                            headerStyle={{ width: 140 }}
                        />
                        <Column
                            header='Formado'
                            headerStyle={{ width: 90 }}
                            body={(rowData: any) => (
                                <div className="col-12 flex justify-content-center">
                                    <SCCheckbox
                                        name='Formado'
                                        checked={rowData.Formado}
                                        onChange={(e: CheckboxChangeParams) => {
                                            handleCheckboxChange(rowData, e.checked);
                                        }}
                                    />
                                </div>
                            )}
                            alignHeader='center'
                            align='center'
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default Formados;
