import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IOrigem from '../interfaces/IOrigem';
import IFuncionario from '../interfaces/IFuncionario';


interface Props {
  funcionario?: IFuncionario;
  setEditaNomeApelidoDialog(param: boolean): void;
  reload(): void;
}

const FuncionarioEditaNomeForm: React.FC<Props> = ({ setEditaNomeApelidoDialog, funcionario, reload }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'FuncionarioEditaNomeForm';

  const toast = useToast();

  const defaultValues: IFuncionario = funcionario || {
    FuncionarioId: undefined,
    Matricula: 0,
    Nome: '',
    Guerra: '',
    DataNascimento: new Date(''),
    Sexo: '',
    EstadoCivil: '',
    LecionaRegular: 0,
    LecionaGraduacao: 0,
    LecionaPos: 0,
    LecionaTec: 0,
    RG: '',
    CPF: '',
    EnderecoId: 0,
    Cep: '',
    Logradouro: '',
    Complemento: '',
    Bairro: '',
    Numero: '',
    Cidade: '',
    Uf: '',
    TelefoneOutros: '',
    TelefoneCelular: '',
    Email: '',
    GrauInstrucao: '',
    Formacao: '',
    Cargo: '',
    TipoFuncionario: 0,
    Contratado: 0,
    Observacao: '',
    Ativado: false,
    DataAdimissao: new Date(''),
    DataDemissao: new Date(''),
    RegimeTrabalho: '',
    CargaHoraria: 0,
    Regencia: 0,
    FuncaoAdministrativa: 0,
    SetorId: 0,
    CadastraAgenda: 0,
    AtendimentoApp: 0,
    AdministradorOs: 0,
    CadastraOs: 0,
    Titulacao: ''
  };

  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const onSubmit = async (data: IFuncionario) => {

    try {

      await api.put('/funcionarios/edita', {
        FuncionarioId: data?.FuncionarioId,
        Nome: data?.Nome,
        Guerra: data?.Guerra
      });

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setEditaNomeApelidoDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setEditaNomeApelidoDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-6">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Nome"
            label='Nome'
            maxLength={60}
            required={true}
            tarefa={tarefa}
            tooltip='Máximo de 60 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-12 md:col-4">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Guerra"
            label='Apelido'
            maxLength={30}
            required={true}
            tarefa={tarefa}
            tooltip='Máximo de 30 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
      </div>

      <div className='grid'>
        <Divider />
        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
          <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
        </div>
      </div>

    </form >

  );
};

export default FuncionarioEditaNomeForm;
