import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToggleButton } from 'primereact/togglebutton';
import { SCButton, SCCheckbox, SCDropdown, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, MODULO_PERFIL } from '../utilities/constantes';
import util from '../utilities/util';
import IPerfil from '../interfaces/IPerfil';
import { useQuery } from 'react-query';
import { classNames } from 'primereact/utils';
import { VirtualScroller } from 'primereact/virtualscroller';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';


interface Props {
  registro?: IPerfil;
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteRegistro: boolean;
  setDeleteRegistro(param: boolean): void;
  novoComBase: any;
}

const PerfilForm: React.FC<Props> = ({ setDeleteRegistro, registro, reload, deletar, deleteRegistro, setShowDialog, novoComBase }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'PerfilForm';

  const toast = useToast();
  const [lista, setLista] = useState<any>([]);
  const [grupo, setGrupo] = useState<string>('');
  const [todosVisivel, setTodosVisivel] = useState<boolean>(false);	
  const [todosIncluir, setTodosIncluir] = useState<boolean>(false);
  const [todosEditar, setTodosEditar] = useState<boolean>(false);
  const [todosDeletar, setTodosDeletar] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const defaultValues: IPerfil = registro || {};

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, reset, watch } = methods;

  const watchModulo = watch('Modulo');
  useEffect(() => {
    if (watchModulo)
    {
      setGrupo(watchModulo);
    }
  }, [watchModulo])

  useEffect(() => {
    const load = async () => {
      const result = await api.get('/perfil', {
        params: {
          PerfilId: novoComBase,
        }
      })
      console.log('tarefas', result.data)
      setLista(result.data);
    }
    if (novoComBase) {
      setLoading(true);
      load();
      setLoading(false);
    }
  }, [novoComBase]);

  const loadTarefasPerfil = async () => {
    const result = await api.get('/perfil', {
      params: {
        PerfilId: registro?.PerfilId,
      }
    })
    setLista(result.data);
  }

  const loadTarefas = async () => {
    const result = await api.get('/perfil/tarefas')
    setLista(result.data);
  }

  useEffect(() => {
    setLoading(true);
    
    if (registro?.PerfilId) {
      loadTarefasPerfil();
    }else{
      loadTarefas();
    }
    setLoading(false);
  }, [registro])

  const onSubmit = async (data: IPerfil) => {

    let resp: any = undefined;

    const postData = {
      PerfilId: data?.PerfilId,
      Descricao: data.Descricao,
      Modulo: data.Modulo,
      EmpresaId: data?.EmpresaId,
      Codigo: data.Codigo,
      Lista: lista.filter((item: any) => grupo == '' || !grupo || item.CaminhoCompleto.includes(grupo)),
      SistemaId: 1,
    }

    try {
      if (data.PerfilId === undefined) {
        resp = await api.post('/perfil', postData);
      } else {
        resp = await api.put('/perfil', postData);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setShowDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data });
    }
  }

  const cancelar = (e: any) => {
    setDeleteRegistro(false);
    setShowDialog(false);
    reset();
  }

  const setChecked = async (column: string, menuId: any, value: boolean) => {
    lista.forEach((item: any) => {
      if (item.MenuId === menuId) {
        item[column] = value;
        if (column === 'Visivel') {
          item.Incluir = value;
          item.Editar = value;
          item.Deletar = value;
        }else if (column !== 'Visivel' && !!value) {
          item.Visivel = true;
        }
      }
    });
    setLista([...lista]);
  }

  const setAll = (column: string) => {
    lista.filter((item: any) => grupo == '' || !grupo || item.CaminhoCompleto.includes(grupo)).forEach((item: any) => {
      const value = !item[column];
      item[column] = value;
      if (column === 'Visivel') {
        item.Incluir = value;
        item.Editar = value;
        item.Deletar = value;
      }else if (column !== 'Visivel' && value === true) {
        item.Visivel = true;
      }
    });
    setLista([...lista]);
    
    switch (column) {
      case 'Visivel':
        setTodosVisivel(!todosVisivel);
        setTodosIncluir(!todosVisivel);
        setTodosEditar(!todosVisivel);
        setTodosDeletar(!todosVisivel);
        break;
      case 'Incluir':
        setTodosIncluir(!todosIncluir);
        break;
      case 'Editar':
        setTodosEditar(!todosEditar);
        break;
      case 'Deletar':
        setTodosDeletar(!todosDeletar);
        break;
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCDropdown
            control={control}
            errors={errors}
            name='Modulo'
            label='Módulo'
            options={MODULO_PERFIL}
            optionLabel='descricao'
            optionValue='value'
            required={!deleteRegistro}
            disabled={deleteRegistro || registro?.PerfilId !== undefined}
          />
        </div>   
        <div className="col-12 md:col-2">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código'
            maxLength={10}
            required={!deleteRegistro}
            disabled={deleteRegistro || registro?.PerfilId !== undefined}
            tarefa={tarefa}
          />
        </div>
        <div className="col-12 md:col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Descricao"
            label='Descrição'
            maxLength={40}
            required={!deleteRegistro}
            disabled={deleteRegistro}
            tarefa={tarefa}
          />
        </div>
      </div>

      <div className='grid'>
        <div className='col-12'>
          <DataTable
            lazy
            loading={isLoading}
            size='small'
            stripedRows
            value={lista.filter((item: any) => grupo == '' || !grupo || item.CaminhoCompleto.includes(grupo))}
            dataKey="id"    
            scrollable
            scrollHeight='65vh'
            emptyMessage="Nenhum registro encontrado."
            responsiveLayout="scroll">
            <Column field="CaminhoCompleto" header="Tarefa" sortable ></Column>

            <Column header={() => {
              return (
                  <SCButton type='button' className={`p-button-outlined p-button-success`}  
                  label={`Visível`} onClick={() => setAll('Visivel')} />
              );
            }} headerStyle={{maxWidth: 80, justifyContent: 'center'}} 
            bodyStyle={{ maxWidth: 80 }} body={(rowData: any) => {
              return (
                <ToggleButton onLabel='Sim' offLabel='Não' checked={rowData.Visivel} onChange={(e) => setChecked('Visivel', rowData.MenuId, e.value)} onIcon="pi pi-check" offIcon="pi pi-times" />
              );
            }}/>

            <Column header={() => {
              return (
                  <SCButton type='button' className={`p-button-outlined p-button-success`}  
                  label={`Incluir`} onClick={() => setAll('Incluir')} />
              );
            }} headerStyle={{maxWidth: 80, justifyContent: 'center'}} bodyStyle={{ maxWidth: 80 }} body={(rowData: any) => {
              return (
                <ToggleButton onLabel='Sim' offLabel='Não' checked={rowData.Incluir} onChange={(e) => setChecked('Incluir', rowData.MenuId, e.value)} onIcon="pi pi-check" offIcon="pi pi-times" />
              );
            }}/>

            <Column header={() => {
              return (
                  <SCButton type='button' className={`p-button-outlined p-button-success`}  
                  label={`Editar`} onClick={() => setAll('Editar')} />
              );
            }} headerStyle={{maxWidth: 80, justifyContent: 'center'}} bodyStyle={{ maxWidth: 80 }} body={(rowData: any) => {
              return (
                <ToggleButton onLabel='Sim' offLabel='Não' checked={rowData.Editar} onChange={(e) => setChecked('Editar', rowData.MenuId, e.value)} onIcon="pi pi-check" offIcon="pi pi-times" />
              );
            }}/>

            <Column header={() => {
              return (
                  <SCButton type='button' className={`p-button-outlined p-button-success`}  
                  label={`Deletar`} onClick={() => setAll('Deletar')} />
              );
            }} headerStyle={{maxWidth: 80, justifyContent: 'center'}} bodyStyle={{ maxWidth: 80 }} body={(rowData: any) => {
              return (
                <ToggleButton onLabel='Sim' offLabel='Não' checked={rowData.Deletar} onChange={(e) => setChecked('Deletar', rowData.MenuId, e.value)} onIcon="pi pi-check" offIcon="pi pi-times" />
              );
            }}/>
          </DataTable>
        </div>
      </div>

      {/* <div className='grid fotter-form'> */}
        <Divider />
        {deleteRegistro ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      {/* </div> */}

    </form >

  );
};

export default PerfilForm;
