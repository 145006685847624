import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import INivelSequencia from '../interfaces/INivelSequencia';
import NivelSequenciaForm from './NivelSequenciaForm';
import { ALERTAS, TIPO_CURSO } from '../utilities/constantes';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const NivelSequencia = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'NivelSequenciaForm';

  const periodo = periodoSelecionado?.Id

  let emptyNivelSequencia: INivelSequencia = {
    NivelSequenciaId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Nivel: '',
    Sequencia: '',
    Descricao: '',
    GeraHistorico: 0,
    ConceitoNota: 0,
    CursoLivre: 0,
    BaseadoEm: 0
  };

  const [niveisSequencias, setNiveisSequencias] = useState<any>(null);
  const [nivelSequenciaDialog, setNivelSequenciaDialog] = useState<boolean>(false);
  const [deleteNivelSequencia, setDeleteNivelSequencia] = useState<boolean>(false);
  const [nivelSequencia, setNivelSequencia] = useState<INivelSequencia>(emptyNivelSequencia);
  const [selectedNivelSequencia, setSelectedNivelSequencia] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [novoComBase, setNovoComBase] = useState<any>()
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyNivelSequencia });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    const result = await api.get('/nivelSequencia/list', {
      params: {
        Descricao: getValues()?.Descricao,
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
      }
    });
    if (result.data)
      setNiveisSequencias(result.data);
    else
      setNiveisSequencias([]);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setNovoComBase(null)
    setNivelSequencia(emptyNivelSequencia);
    setSubmitted(false);
    setNivelSequenciaDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setNivelSequenciaDialog(false);
  };

  const editar = (nivelsequencia: any) => {
    setNovoComBase(null)
    setNivelSequencia(nivelsequencia);
    setNivelSequenciaDialog(true);
  };

  const novoComBaseNesse = (nivelsequencia: any) => {
    setNovoComBase(nivelsequencia.NivelSequenciaId)
    nivelsequencia.NivelSequenciaId = undefined;
    setNivelSequencia(nivelsequencia);
    setNivelSequenciaDialog(true);
  };

  const confirmDelete = (nivelsequencia: any) => {
    setNovoComBase(null)
    setNivelSequencia(nivelsequencia);
    setDeleteNivelSequencia(true);
    setNivelSequenciaDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/nivelSequencia', { params: { NivelSequenciaId: nivelSequencia.NivelSequenciaId } });
      console.log(resp.data)

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteNivelSequencia(false);
      setNivelSequenciaDialog(false);
      setNivelSequencia(emptyNivelSequencia);

    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

    console.log('deletar')

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Nível e Escolaridade [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />

          <DataTable
            size='small'
            stripedRows
            value={niveisSequencias}
            onSelectionChange={(e) => setSelectedNivelSequencia(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            header={null}
            responsiveLayout="scroll">

            <Column header="Nível"
            body={(rowData: any) => {
              return TIPO_CURSO.find((x: any) => x.value == rowData.Nivel)?.descricao
            }} sortable headerStyle={{ width: 180 }} ></Column>
            <Column field="Sequencia" header="Escolaridade" sortable headerStyle={{ width: 150 }}></Column>
            <Column field="Descricao" header="Descrição" sortable ></Column>

            <Column body={(rowData: any) => {
              return (
                <div className="actions flex align-items-center justify-content-between">
                  <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                  <Button label="Novo Com Base Nesse" icon="pi pi-plus" className="p-button w-auto p-button-success" onClick={() => novoComBaseNesse(rowData)} type='button' />
                  <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                </div>
              );
            }} style={{ width: 280 }}
            />
          </DataTable>

          <SCDialog maximized={!util.isDesktop()} visible={nivelSequenciaDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${nivelSequencia.NivelSequenciaId === undefined ? 'Cadastrando ' : (deleteNivelSequencia ? 'Excluíndo' : 'Alterando')} Nível e Escolaridade`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <NivelSequenciaForm
              setNivelSequenciaDialog={setNivelSequenciaDialog}
              nivelSequencia={nivelSequencia}
              NovoComBase={novoComBase}
              reload={load}
              deleteNivelSequencia={deleteNivelSequencia}
              setDeleteNivelSequencia={setDeleteNivelSequencia}
              deletar={deletar}
            />
          </SCDialog>

        </div>
      </div>
    </div>
  );
};

export default NivelSequencia;
