import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import AjudaFormTela from './AjudaFormTela';
import IMateria from '../interfaces/IMateria';
import Loading from '../components/Loading';
import IEmpresas from '../interfaces/IEmpresas';
import EmpresasForm from './EmpresasForm';
import EmpresasNovoForm from './EmpresasNovoForm';
import { SCDialog } from '../components';
import { Image } from 'primereact/image';
import semImagem from '../assets/sem-imagem.png';


const Empresas = () => {
  const toast = useToast();
  const { usuario, periodoSelecionado, empresaSelecionada } = useAuth();
  const tarefa = 'DisciplinaForm';

  let emptyData: IEmpresas = {
    EmpresaId: periodoSelecionado?.EmpresaId,
    Empresa: empresaSelecionada?.Empresa,
    RazaoSocial: '',
    IdRelatorio: '',
    Tipo: 0,
    Cnpj: '',
    Contato: '',
    Telefone: '',
    Email: '',
    IDUnidade: '',
    MensagemReciboLinha01: '',
    MensagemReciboLinha02: '',
    MensagemReciboLinha03: '',
    MensagemReciboLinha04: '',
    MensagemRelatorioLinha01: '',
    MensagemRelatorioLinha02: '',
    MensagemRelatorioLinha03: '',
    MensagemRelatorioLinha04: '',
    Prefixo: '',
    CodigoMobile: 0,
  };

  const [empresa, setEmpresa] = useState<any>(null);
  const [empresaDialog, setEmpresaDialog] = useState<boolean>(false);
  const [registro, setRegistro] = useState<IEmpresas>(emptyData);
  const [selectedRegistro, setSelectedRegistro] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [novoComBase, setNovoComBase] = useState<any>()
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyData });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/empresa/list', { params: { Empresa: empresaSelecionada?.Empresa } });
    if (result.data)
      setEmpresa(result.data);
    else
      setEmpresa([]);

    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setRegistro(emptyData);
    setSubmitted(false);
    setEmpresaDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setEmpresaDialog(false);
  };
  const editar = (reg: any) => {
    setRegistro(reg);
    setEmpresaDialog(true);
  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const novoComBaseNesse = (item: any) => {
    setNovoComBase(item.EmpresaId)
    item.EmpresaId = undefined;
    setRegistro(item)
    setEmpresaDialog(true)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Empresas</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <Divider />

          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={empresa}
              onSelectionChange={(e) => setSelectedRegistro(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Empresa" header="Empresa" />
              <Column field="Foto" header="" body={(rowData: any) => {
                return <Image imageClassName='border-round-md border-1' src={rowData.Logo || semImagem} alt="sem-foto" width='36' height='36' />
              }} ></Column>
              <Column field="IDUnidade" header="ID da Unidade" />
              <Column field="RazaoSocial" header="Razão Social" />
              <Column field="Cnpj" header="CNPJ" />
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button label="Novo Com Base Nesse" icon="pi pi-plus" className="p-button w-auto p-button-success" onClick={() => novoComBaseNesse(rowData)} type='button' />
                  </div>
                );
              }} style={{ width: 250 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={empresaDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${registro.EmpresaId === undefined ? 'Cadastrando ' : ('Alterando')} Empresa`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <EmpresasForm
              setEmpresaDialog={setEmpresaDialog}
              registro={registro}
              reload={load}
            />
          </SCDialog>

          <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`Cadastrando Empresa`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <EmpresasForm
              setEmpresaDialog={setEmpresaDialog}
              registro={registro}
              novoComBase={novoComBase}
              reload={load}
            />
          </SCDialog>


        </div>
      </div>
    </div>
  );
};

export default Empresas;
