import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SCCalendar, SCCheckbox, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GrCertificate } from 'react-icons/gr';
import api from '../services/api';
import { useAuth } from '../providers/auth';
import { CheckboxChangeParams } from 'primereact/checkbox';


type Props = any;

const AlunosFormDocumentos: React.FC<Props> = ({ showDelete, matricula, ...props }) => {

  const toast = useToast();
  const queryClient = useQueryClient()  
  const { periodoSelecionado, usuario } = useAuth();
  const { control, formState: { errors }, getValues, setValue, watch } = useFormContext();
  const [documentos, setDocumentos] = useState<any>([]);

  const watchNivelSequenciaId = watch('NivelSequenciaId')

  const loadDocumentos = async () => {
    let data = [];
    if (matricula)
    {
      const result = await api.get('/alunosDocumento', {
        params: {
          Matricula: matricula,
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,        
        }
      });
      data = result.data;
    }
    else if (watchNivelSequenciaId)
    {
      const result = await api.get('/nivelSequenciaDocumentos/documentosMatricula', {
        params: {
          NivelSequenciaId: watchNivelSequenciaId,        
        }
      });
      data = result.data;
    }
    
    const docs = data.map((doc: any) => { doc.Checked = doc.documento_marcado === 1; return doc })
    setDocumentos(docs);
    setValue('Documentos', docs);
  }

  useEffect(() => {
    loadDocumentos();
  }, [watchNivelSequenciaId, matricula])


  const setCheckDocumentos = (e: CheckboxChangeParams) => {
    const { checked, id } = e.target;
    const res = documentos.map((doc: any) => {
      if (doc.DocumentoId == id)
        doc.Checked = checked;
      return doc
    });
    setValue('Documentos', res);
    setDocumentos(res);
  }

  useEffect(() => {
    if (documentos)
      {
        const docs = documentos.filter((doc: any) => !doc.Checked);
        console.log('docs', getValues('DataDeferimento'), docs)
        if (docs.length > 0)        
          setValue('DataDeferimento', null);
        else {
          if (!getValues('DataDeferimento'))
            setValue('DataDeferimento', new Date());
        }
          
      }
  }, [documentos])

  return (
    <div className='col-12' >
      <div className="col-12">
        <div className='pb-2' style={{ color: "#0772B3" }}>Documentos de Matrícula</div>
        <div className="grid">
          {documentos?.map((doc: any) => (
            <div className="col-3" key={doc.DocumentoId}>
            <SCCheckbox
              key={doc.DocumentoId}
              checked={doc.Checked}
              onChange={(e: CheckboxChangeParams) => setCheckDocumentos(e)}
              id={doc.DocumentoId}
              name={doc.DocumentoId}
              label={doc.Descricao}
              disabled={showDelete}
            />
            </div>
          ))}          
        </div>
        <div className="grid">
          <div className="col-3">
            <SCCalendar
              control={control}
              errors={errors}
              name="DataDeferimento"
              label="Data Deferimento"
              mask='99/99/9999'
              dateFormat='dd/mm/yy'
              showIcon
              disabled={showDelete}
              showOnFocus={false}            
            />
          </div>
        </div>
      </div>

      <Divider align="left">
        <div className="inline-flex align-items-center">
          <i className="pi pi-id-card mr-2"></i>
          <b>Certificado</b>
        </div>
      </Divider>

      <div className='grid'>
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="CertificadoRegistro"
            label='Nº do Registro'
            disabled={showDelete}
            maxLength={35}
          />
        </div>     
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="CertificadoFolha"
            label='Folha'
            disabled={showDelete}
            maxLength={20}
          />
        </div>     
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="CertificadoLivro"
            label='Livro'
            disabled={showDelete}
            maxLength={20}
          />
        </div>  
      </div>
    </div>
  );
};

export default AlunosFormDocumentos;
