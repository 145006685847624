import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';

import { SCButton, SCCalendar, SCDropdown, SCInputMask, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import { UF_LISTA } from '../utilities/constantes';
import SelectSexo from '../components/SelectSexo';
import SelectEstadoCivil from '../components/SelectEstadoCivil';
import SelectCorRaca from '../components/SelectCorRaca';
import SelectCidade from '../components/SelectCidade';
import FormEndereco from '../components/FormEndereco';
import SelectOrigem from '../components/SelectOrigem';
import util from '../utilities/util';
import { useQuery } from 'react-query';
import api from '../services/api';
import SelectReligiao from '../components/SelectReligiao';

type Props = any;

const AlunosFormPrincipal: React.FC<Props> = ({ showDelete, matricula, ...props }) => {

  const toast = useToast();

  const { control, formState: { errors }, watch, setValue, getValues, setError, clearErrors } = useFormContext();

  const watchSexo = watch('Sexo');
  
  useEffect(() => {
    if (watchSexo === 'M') {
      setValue('Nacionalidade', 'BRASILEIRO');
    }else{
      setValue('Nacionalidade', 'BRASILEIRA');    
    }
    
    atualizaDDD();

  }, [watchSexo])

  const atualizaDDD = async () => {
    const parametroDDD = await api.get('/parametro', {
      params: {
        EmpresaId: 0,
        Ano: '0000',
        Sequencial: '0',
        Codigo: 'DDDPADRAO',
        Grupo: 'S',
      }
    });

    if (matricula === undefined) {
      setValue('DDD', parametroDDD.data.Valor)
    }
  }
  
  return (
    <div className='grid' >
      <div className="col">
        <div className='grid'>
          <div className="col-2">
            <SelectSexo
              control={control}
              errors={errors}
              name="Sexo"
              label="Sexo"
              disabled={showDelete}
              required={!showDelete}
            />
          </div>
          <div className="col-2">
            <SelectEstadoCivil
              control={control}
              errors={errors}
              name="EstadoCivil"
              label="Estado Civil"
              disabled={showDelete}
              required={!showDelete}
            />
          </div>
          <div className="col-3">
            <SelectReligiao
              control={control}
              errors={errors}
              name="Religiao"
              label='Religião'
              disabled={showDelete}
            />
          </div>
          <div className="col-4">
            <SelectCorRaca
              control={control}
              errors={errors}
              name="Cor"
              label='Cor/Raça'
              required={!showDelete}
            />
          </div>
        </div> 

        <div className='grid'>
          <div className="col-2">
            <SCCalendar
              control={control}
              errors={errors}
              name="DataNascimento"
              label="Data de Nascimento"
              mask='99/99/9999'
              dateFormat='dd/mm/yy'
              showIcon
              disabled={showDelete}
              required={!showDelete}
              showOnFocus={false}
            />
          </div>
          <div className="col-2">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="Nacionalidade"
              label='Nacionalidade'
              required={!showDelete}
              disabled={showDelete}
              maxLength={10}
              upperCase
            />
          </div>
          <div className="col-3">
            <SCDropdown
              control={control}
              errors={errors}
              name="Natural"
              label='Natural'
              options={UF_LISTA}
              optionLabel='nome'
              optionValue='sigla'
              required={!showDelete}
            />
          </div>
          <div className="col-4">
            <SelectCidade
              uf={watch('Natural')}
              control={control}
              errors={errors}
              name="LocalNascimento"
              label='Local de Nascimento'
              required={!showDelete}
            />
          </div>
        </div> 

         <div className='grid'>
          <div className="col-2">
            <SCInputText
              control={control}
              errors={errors}
              numberOnly
              name="RG"
              label='RG'
              disabled={showDelete}
              maxLength={20}
            />
          </div>
          <div className="col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="RGOrgaoExpeditorUF"
              label='Orgão/UF'
              disabled={showDelete}
              maxLength={25}
              onBlur={(e) => {
                const rg = getValues('RG');
                clearErrors('RG');
                if ((rg == '' || rg == null)  && e.target?.value?.length > 0)
                  setError('RG', { type: 'manual', message: 'Preencha o campo' });
              }}
            />
          </div>
          <div className="col-2">
            <SCInputMask
              control={control}
              errors={errors}
              name="CPF"
              label='CPF'
              disabled={showDelete}
              maxLength={14}
              required
              mask='999.999.999-99'
              onBlur={(e) => util.cpfValido(e.target.value) ? clearErrors('CPF') : setError('CPF', { type: 'manual', message: 'CPF inválido' })}
            />
          </div>
        
          <div className="col-5">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'lowercase' }}
              name="Email"
              label='E-mail'
              disabled={showDelete}
              maxLength={60}
            />
          </div>
        </div> 
       

        <Divider align="left">
          <div className="inline-flex align-items-center">
            <i className="pi pi-home mr-2"></i>
            <b>Endereço</b>
          </div>
        </Divider>

        <FormEndereco required={true} matricula={matricula} tipo="ALUNO" showDelete={showDelete} />

        <div className='grid'>
          <div className="col-1">
            <SCInputMask
              control={control}
              errors={errors}
              name="DDD"
              label='DDD'
              required={!showDelete}
              disabled={showDelete}
              mask='(999)'
              maxLength={5}
            />
          </div>
          <div className="col-2">
            <SCInputMask
              control={control}
              errors={errors}
              mask='99999-9999'
              name="FoneCelular"
              label='Celular'
              required={!showDelete}
              disabled={showDelete}
              maxLength={10}
            />
          </div>
          <div className="col-4">
            <SCInputText
              control={control}
              errors={errors}
              name="FoneOutro"
              label='Outros Fones'
              disabled={showDelete}
              maxLength={40}
            />
          </div>
        </div>

        <div className='grid'>
          <div className="col">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase', color: 'red' }}
              name="Obs"
              label='Obs'
              disabled={showDelete}
              maxLength={250}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default AlunosFormPrincipal;
