import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import api from "../services/api";
import { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';
import Loading from '../components/Loading';
import { IAlunosPeriodo } from '../interfaces/IAlunosPeriodo';
import { SCButton, SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputNumber, SCInputText } from '../components';
import { Divider } from 'primereact/divider';
import { addDays, format } from 'date-fns';
import { useToast } from '../context/ToastContext';

interface Props {
  mensalidade?: any;
  pagamento?: any;
  reload(): void;
  loading: boolean;
  setLoading(param: boolean): void;
  deletar(): void;
  showDelete: boolean;
  cancelar(): void;
}

const RecebimentoMensalidadeEstorno: React.FC<Props> = ({cancelar ,showDelete,  mensalidade, pagamento, reload, setLoading, loading }) => {
  const toast = useToast();
  
  console.log('mensalidade', mensalidade);
  console.log('pagamento', pagamento);
  
  const { periodoSelecionado } = useAuth();
  let defaultValues: any = {
    Mensalidade: mensalidade,
    ...pagamento
  };
  const methods = useForm({ defaultValues })
  const { handleSubmit, control, formState: { errors }, getValues, reset, watch, setValue, clearErrors, setError } = methods;

  const [contas, setContas] = useState<any[]>([]);
  const [formasPag, setFormasPag] = useState<any[]>([]);
  const [fontes, setFontes] = useState<any[]>([]);
  const [cartoesFiltrados, setCartoesFiltrados] = useState<any[]>([]);
  const [codigoEstorno, setCodigoEstorno] = useState<any[]>([]);
  const [desabilita, setDesabilita] = useState<boolean>(false);
  const [deleteRecebimentos, setDeleteRecebimentos] = useState<boolean>(showDelete);
  const { usuario } = useAuth();

  const watchTarifaTipo = watch('TarifaTipo')

  const loadConta = async () => {
    try {
      const { data } = await api.get('/planoContas/list', { params: { Tipo: 'ENTRADA' } });
      setValue('PlanoContas', data || []);
      setContas(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Contas:', error);
      setContas([]);
    }
  };

  const loadFormasPagamento = async () => {
    try {
      const { data } = await api.get('/formasPagamento/combo');
      setFormasPag(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Formas de Pagamento:', error);
      setFormasPag([]);
    }
  };

  const loadFontes = async () => {
    try {
      const { data } = await api.get('/fontes/fonteAtivas', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });
      setFontes(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Fontes:', error);
      setFontes([]);
    }
  };

  const loadCodigoEstorno = async () => {
    try {
      const { data } = await api.get('/codigosestorno/combo');

      setCodigoEstorno(data || [])
    } catch (error) {
      console.error('Erro ao carregar os Códigos de Estorno:', error);
      setCodigoEstorno([]);
    }
  }

  useEffect(() => {
    loadConta()
    loadFormasPagamento()
    loadFontes()
    loadCodigoEstorno()

    console.log('data pagamento', pagamento.PagamentoData);
    setValue('PagamentoData', format(new Date(pagamento.PagamentoData), 'dd/MM/yyyy'));    
    setValue('DataBomPara', format(new Date(pagamento.DataBomPara), 'dd/MM/yyyy'));    
    setValue('DataDisponivel', format(new Date(pagamento.DataDisponivel), 'dd/MM/yyyy'));    
    
  }, []);

  useEffect(() => {
    if (formasPag?.length > 0) {
      const formaPagamentoSelecionada = formasPag.find((fp: any) => fp.FormasPagamentoId === pagamento.FormasPagamentoId);
      buscaCartao(formaPagamentoSelecionada);
    }
  }, [formasPag])

  const buscaCartao = async (formaPagamentoSelecionada: any) => {
    if (!formaPagamentoSelecionada?.Codigo) {
      setCartoesFiltrados([]);
      return;
    }

    try {
      const { data } = await api.get('/cartoes/filtroLista', { params: { Codigo: formaPagamentoSelecionada?.Codigo } });

      if (data?.length > 0) {
        const cartoesInfo = data.map((cartao: any) => ({
          CartoesId: cartao.CartoesId,
          Maquina: `${cartao.Maquina} - ${cartao.FonteCodigo} - ${cartao.FormaDescricao} - ${cartao.Tipo === 'C' ? 'Crédito' : cartao.Tipo === 'D' ? 'Débito' : cartao.Tipo === 'P' ? 'Parcelado' : 'Desconhecido'
            } - ${cartao.QtdParcelas} Parcela(s) - ${cartao.QtdDiasRecebimento} dia(s)`
        }));

        setCartoesFiltrados(cartoesInfo);

        setValue('FonteId', pagamento.FonteId);
        const cartaoSelecionado = data.find((cartao: any) => cartao.CartoesId === pagamento.CartoesId);

          if (cartaoSelecionado) {
            setValue('TarifaTipo', cartaoSelecionado.TarifaTipo || null);
            setValue('TarifaValor', cartaoSelecionado.TarifaValor);
          }

      } else {
        setCartoesFiltrados([]);
      }
    } catch (error) {
      console.error('Erro ao buscar cartão:', error);
      setCartoesFiltrados([]);
    }
  };

  const verificaCaixaAberto = async () => {
        let dataFormat = format(new Date(), 'yyyy-MM-dd')

        try {
          const result = await api.get('/consultaEncerramento', {
            params: {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Data: dataFormat,
              Operador: usuario?.Apelido,
              Acao: 'F'
            }
          })

          if (result.data.length > 0) {
            toast.showToast({
              severity: 'error',
              summary: 'Estorno Cancelado',
              detail: `O Caixa está encerrado para o dia de hoje!`,
            });

            return false;
          }
        } catch (error) {
          toast.showToast({
            severity: 'error',
            summary: 'Atenção',
            detail: 'Erro na consulta',
          });
          return false;
      }
      return true;
  }

  const watchCodigoEstornoId = watch('CodigosEstornoId');

  const confirmaEstorno = async () => {
    setLoading(true)
      let resp;
  
      if (!watchCodigoEstornoId) {
        
        setError('CodigosEstornoId', { type: 'manual', message: 'Informe um Código de Estorno' });
        return false;
      }
  
      const caixaAberto = await verificaCaixaAberto();
      if (!caixaAberto)
          return false;

      try {
        resp = await api.put('/estornaMensalidadesPagamento', {
          ...pagamento, 
          Excluido: true,
          ExcluidoData: new Date(),
          ExcluidoHora: new Date().toLocaleTimeString(),
          ExcluidoOperador: usuario?.Apelido,
          CodigosEstornoId: watchCodigoEstornoId,
        })
  
        if (resp) {
          toast.showToast({
            severity: 'success',
            summary: 'Sucesso!',
            detail: 'Estorno realizado com sucesso!',
          });
        }
  
        reload();
        setLoading(false);
        cancelar();
      } catch (error) {
        console.error('Erro ao estornar registro:', error);
        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Erro ao deletar o registro',
        });
        setLoading(false);
      }
  };

  return (
    <FormProvider {...methods}>
      {loading && <Loading full={true} />}
      
      <div className='grid'>
        <div className="col-4 md:col-1">
          <SCInputText
            control={control}
            errors={errors}
            className='readonly-light'
            style={{ textTransform: 'uppercase' }}
            name="Mensalidade.Parcela"
            label='Parcela'
            disabled
          />
        </div>
        <div className="col-8 md:col-6 ">
          <SCInputText
            control={control}
            errors={errors}
            label='Descrição'
            className='readonly-light'
            style={{ textTransform: 'uppercase' }}
            name="Mensalidade.ParcelaDescricao"
            disabled
          />
        </div>
        <div className="col-4 md:col-2">
          <SCInputText
            className='readonly-light'
            label='Data do Venicmento'
            control={control}
            errors={errors}
            name="Mensalidade.DataVencimentoFormatado"
            disabled
          />
        </div>
      </div>

      <Divider />
      
      <SCFieldset legend='Pagamento' className='col-12 m-0'>
        <div className='grid align-items-center' style={{ marginTop: -20 }}>
          <div className="col-12 md:col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="PagamentoData"
              label='Data do Pagamento'
              disabled
              style={{ color: "#000", fontWeight: 'bold', width: "100%" }}
            />
          </div>

          <div className="col-12 md:col-5 mt-2 mb-2 md:mt-4">
            <SCCheckbox
              control={control}
              name="PagamentoSemValor"
              label='Pagamento sem valor recebido (Cortesia)'
              disabled={deleteRecebimentos}
            />
          </div>
        </div>

        <div className='grid flex align-items-center' style={{ marginTop: -10 }}>
          <div className="col-12 md:col-2">
            <SCInputNumber
              autoFocus={true}                         
              name='PagamentoMulta'
              control={control}
              errors={errors}
              label='Multa'
              allowEmpty={false}
              disabled={deleteRecebimentos}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
            />
          </div>

          <div className="col-12 md:col-2">
            <SCInputNumber
              name='PagamentoJuros'
              control={control}
              errors={errors}
              label='Juros'
              allowEmpty={false}
              disabled={deleteRecebimentos}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
            />
          </div>

          <div className="col-12 md:col-2">
            <SCInputNumber
              name='PagamentoValor'
              control={control}
              errors={errors}
              label='Valor Pago'
              allowEmpty={false}
              disabled={deleteRecebimentos}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
            />
          </div>

          <div className="col-12 md:col-6">
            <SCDropdown
              control={control}
              errors={errors}
              name="FormasPagamentoId"
              label='Forma de Pagamento'
              options={formasPag}
              optionLabel='Descricao'
              optionValue='FormasPagamentoId'
              disabled={deleteRecebimentos}
            />
          </div>
        </div>

        <div className='grid flex align-items-end'>

          <div className="col-12 md:col-5">
            <SCDropdown
              control={control}
              errors={errors}
              name="CartoesId"
              label='Cartão'
              options={cartoesFiltrados}
              optionLabel='Maquina'
              optionValue='CartoesId'
              disabled={deleteRecebimentos}
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-12 md:col-1">
            <SCInputText
              control={control}
              errors={errors}
              name="TarifaValor"
              label={watchTarifaTipo == 1 ? 'Tarifa(%)' : watchTarifaTipo == 2 ? 'Tarifa(R$)' : 'Tarifa'}
              disabled
              style={{ color: "#000", fontWeight: 'bold', width: "100%" }}
            />
          </div>
          <div className="col-12 md:col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="NumeroDocumentoPagto"
              label='Número do Documento'
              disabled={deleteRecebimentos}
            />
          </div>

          <div className="col-12 md:col-4">
            <SCDropdown
              control={control}
              errors={errors}
              name="FonteId"
              label='Fonte'
              options={fontes}
              optionLabel='Codigo'
              optionValue='FonteId'
              disabled={deleteRecebimentos}              
            />
          </div>
        </div>
        <div className='grid flex align-items-center'>
          <div className="col-12 md:col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="DataBomPara"
              label='Bom Para'
              disabled
              style={{ color: "#000", fontWeight: 'bold', width: "100%" }}
            />            
          </div>

          <div className="col-12 md:col-2">
          <SCInputText
              control={control}
              errors={errors}
              name="DataDisponivel"
              label='Disponível Em'
              disabled
              style={{ color: "#000", fontWeight: 'bold', width: "100%" }}
            />   
          </div>
        </div>
      
      </SCFieldset>

      <Divider type='solid' />
      <div className="flex flex-1 justify-content-between align-items-center">
        <SCButton label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
        <div className="col-6">
          <div className='grid align-items-center justify-content-around'>
            <span style={{ color: 'red', fontWeight: 'bold' }}>Informe o Código do Estorno</span>
            <div className='col-12 md:col-7 p-0'>
              <SCDropdown
                control={control}
                errors={errors}
                name="CodigosEstornoId"
                options={codigoEstorno}
                optionLabel='Descricao'
                optionValue='CodigosEstornoId'
                required
                showClear={false}
                filter={false}
              />
            </div>
          </div>
        </div>
        <SCButton type='button' label="Confirmar Exclusão" icon="pi pi-check" className="p-button w-auto p-button-success" onClick={() => confirmaEstorno()} />
      </div>
    </FormProvider>
  );
};

export default RecebimentoMensalidadeEstorno;
