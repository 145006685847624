import { Divider } from 'primereact/divider';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SCButton, SCDropdown, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, SITUACAO_ALUNO, TIPO_DISCIPLINA } from '../utilities/constantes';
import { useAuth } from '../providers/auth';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import AlunoResumo from '../components/AlunoResumo';
import { IAlunoForm } from '../interfaces/IAlunos';
import { InputText } from 'primereact/inputtext';

interface Props {
    aluno?: IAlunoForm;
    setAlunoFrequenciaDialog(param: boolean): void;
    reload(): void;
}

const AlunosFrequenciaForm: React.FC<Props> = ({ setAlunoFrequenciaDialog, aluno, reload }) => {
    // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
    // por padrão vamos utilizar o mesmo nome do componente
    const tarefa = 'FormaIngressoForm';

    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [boletim, setBoletim] = useState<any>(null)
    const dt = useRef<any>(null);

    const defaultValues: IAlunoForm = aluno || {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        AlunoId: undefined,
        CursoId: undefined,
    };

    const methods = useForm({ defaultValues });
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

    async function load() {

        const boletimAluno = await api.get('/boletim/list', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Matricula: aluno?.Matricula,
                Sequencial: aluno?.Sequencial,
                Ano: periodoSelecionado?.Ano,
            }
        });

        if (boletimAluno.data) {
            setBoletim(boletimAluno.data)
        }
        else {
            setBoletim([])
        }

        const { data } = await api.get('/aluno', {
            params: { AlunoId: aluno?.AlunoId }
        })

        const alunoperiodo = await api.get('/alunosPeriodo/matricula', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Matricula: aluno?.Matricula
            }
        });

        data.Turma = {
            Codigo: alunoperiodo.data.TurmaCodigo,
        };

        data.AlunosPeriodo = alunoperiodo.data;
        data.DataNascimento = data.DataNascimento ? new Date(data.DataNascimento) : new Date();

        reset(data);

    }

    useEffect(() => {
        load()
    }, [])

    const onSubmit = async () => {
        try {
            boletim.forEach(async (element: any) => {
                delete element.Descricao;

                console.log(element)
                const resp = await api.put('/boletim', {
                    EmpresaId: element.EmpresaId,
                    Matricula: element.Matricula,
                    Sequencial: element.Sequencial,
                    Ano: element.Ano,
                    DisciplinaCodigo: element.DisciplinaCodigo,
                    Boletim: {
                        FaltaMes01: element.FaltaMes01 !== null || element.FaltaMes01 > 0 ? element.FaltaMes01 : 0,
                        FaltaMes02: element.FaltaMes02 !== null || element.FaltaMes02 > 0 ? element.FaltaMes02 : 0,
                        FaltaMes03: element.FaltaMes03 !== null || element.FaltaMes03 > 0 ? element.FaltaMes03 : 0,
                        FaltaMes04: element.FaltaMes04 !== null || element.FaltaMes04 > 0 ? element.FaltaMes04 : 0,
                        FaltaMes05: element.FaltaMes05 !== null || element.FaltaMes05 > 0 ? element.FaltaMes05 : 0,
                        FaltaMes06: element.FaltaMes06 !== null || element.FaltaMes06 > 0 ? element.FaltaMes06 : 0,
                        FaltaMes07: element.FaltaMes07 !== null || element.FaltaMes07 > 0 ? element.FaltaMes07 : 0,
                        FaltaMes08: element.FaltaMes08 !== null || element.FaltaMes08 > 0 ? element.FaltaMes08 : 0,
                        FaltaMes09: element.FaltaMes09 !== null || element.FaltaMes09 > 0 ? element.FaltaMes09 : 0,
                        FaltaMes10: element.FaltaMes10 !== null || element.FaltaMes10 > 0 ? element.FaltaMes10 : 0,
                        FaltaMes11: element.FaltaMes11 !== null || element.FaltaMes11 > 0 ? element.FaltaMes11 : 0,
                        FaltaMes12: element.FaltaMes12 !== null || element.FaltaMes12 > 0 ? element.FaltaMes12 : 0,
                        TotalFaltas: element.TotalFaltas !== null ? element.TotalFaltas : 0,
                    }
                });
            });

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            reload();
            setAlunoFrequenciaDialog(false);

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    }

    const sitAluno = SITUACAO_ALUNO.filter(item => item.value !== 'R');

    const cancelar = (e: any) => {
        setAlunoFrequenciaDialog(false);
        reset();
    }

    const onEditorValueChange = (props: any, value: any) => {
        let updatedBoletim = [...boletim];
        updatedBoletim[props.rowIndex][props.field] = value;
        setBoletim(updatedBoletim);
    };

    const inputTextEditor = (props: any) => {
        const field = props.field;
        const rowIndex = props.rowIndex;

        const handleBlur = (e: any) => {
            const value = e.target.value;
            onEditorValueChange({ field, rowIndex }, value);
        };

        const handleControl = (e: any) => {
            const charCode = e.charCode;
            const newValue = e.target.value + String.fromCharCode(charCode);

            if (!/^\d{0,2}$/.test(newValue)) {
                e.preventDefault();
            }
        };

        return (
            <InputText
                type="number"
                maxLength={2}
                defaultValue={props.rowData[props.field]}
                onBlur={handleBlur}
                onKeyPress={handleControl}
            />
        );
    };

    const totalFaltasBodyTemplate = (rowData: any) => {
        const totalFaltas = calcularTotalFaltas(rowData);
        return (
            <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}>
                {totalFaltas}
            </span>
        );
    };

    const calcularTotalFaltas = (rowData: any) => {
        return Object.keys(rowData)
            .filter(key => key.startsWith('FaltaMes'))
            .reduce((sum, key) => sum + parseFloat(rowData[key] || 0), 0);
    };

    const tipoDisciplinaBodyTemplate = (rowData: any) => {
        const tipo = TIPO_DISCIPLINA.find(item => item.value === rowData.Tipo);
        return tipo ? tipo.descricao : rowData.Tipo;
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className='pt-3' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
                    <div className='grid'>
                        <AlunoResumo mostraFoto mostraTurma aluno={getValues() as IAlunoForm} />
                    </div>

                    <DataTable
                        size='small'
                        stripedRows
                        ref={dt}
                        value={boletim}
                        dataKey="id"
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhum registro encontrado."
                        header={null}
                        editMode='cell'
                        showGridlines
                        responsiveLayout="scroll">

                        <Column field="Tipo" header="Tipo" headerStyle={{ width: 150 }} body={tipoDisciplinaBodyTemplate} />
                        <Column field="DisciplinaCodigo" header="Disciplina" headerStyle={{ width: 100 }} />
                        <Column field="Descricao" header="Descrição" headerStyle={{ width: 200 }} />
                        <Column field="MateriaCodigo" header="Matéria" headerStyle={{ width: 50 }} />
                        <Column field="FaltaMes01" align={'center'} header="JAN" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes02" align={'center'} header="FEV" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes03" align={'center'} header="MAR" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes04" align={'center'} header="ABR" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes05" align={'center'} header="MAI" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes06" align={'center'} header="JUN" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes07" align={'center'} header="JUL" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes08" align={'center'} header="AGO" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes09" align={'center'} header="SET" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes10" align={'center'} header="OUT" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes11" align={'center'} header="NOV" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="FaltaMes12" align={'center'} header="DEZ" headerStyle={{ width: 50 }} editor={inputTextEditor} />
                        <Column field="TotalFaltas" align={'center'} alignHeader={'center'} header="Total" body={totalFaltasBodyTemplate} bodyStyle={{ backgroundColor: '#0F97C7' }} headerStyle={{ width: 50 }} />
                    </DataTable>

                    <Divider />

                    <div className="flex flex-1 justify-content-between pt-3 px-2">
                        <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                        <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
                    </div>
                </form>
            </FormProvider>
        </>

    );
};

export default AlunosFrequenciaForm;
