import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { useForm } from 'react-hook-form';
import { SCCheckbox, SCFieldset, SCInputText, SCRadioButton } from '../components';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { ALERTAS } from '../utilities/constantes';
import { TabPanel, TabView } from 'primereact/tabview';
import IConfiguracoesSecretaria from '../interfaces/IConfiguracoesSecretaria';

const ConfiguracoesFinanceiro = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'FuncionarioForm';
  const dt = useRef<any>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues: IConfiguracoesSecretaria = {
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    PER_IMPBOLDB: 0,
    PER_IMPBOLSE: 0,
    PER_IMPNCUR: 0,
    PER_BLNOVMAT: 0,
    PER_VALSEMLA: 0,
    PER_QTDAVA: 0,
    PER_QTDFAL: 0,
    PER_INIAPV: 0,
    PER_PERFAL: 0,
    PER_NOTMAX: 0,
    PER_NOTVER: 0,
    PER_NUMMAT: 0,
    GLO_LIMPALU: 0,
    GLO_EXBAPR: 0,
    GLO_VALCPF: 0,
    GLO_VALEMAIL: 0,
    GLO_MSGPDOC: 0,
    GLO_MSGPFIN: 0,
    GLO_MSGPANT: 0,
    GLO_PINSTAVA: 0,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

  const loadGlobal = async () => {
    setLoading(true);

    try {
      const resp = await api.get('/parametros/buscaParametro', {
        params: {
          Tipo: 'GLO',
          Grupo: 'F',
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial
        }
      });

      if (resp.data) {

        resp.data.forEach((item: any) => {
          let valor;

          if (item.TipoRetorno === 'N') {
            valor = Number(item.Valor)
          }

          if (item.TipoRetorno === 'S') {
            valor = item.Valor
          }

          if (item.TipoRetorno === 'B') {
            valor = Number(item.Valor)

            if (valor === 1) {
              valor = true
            } else {
              valor = false
            }
          }

          setValue(item.CodigoPesquisa.trim(), valor);
        });
      }
    } catch (error) {
      console.error('Erro ao carregar os parâmetros:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadPeriodo = async () => {
    setLoading(true);

    try {
      const resp = await api.get('/parametros/buscaParametro', {
        params: {
          Tipo: 'PER',
          Grupo: 'F',
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial
        }
      });

      if (resp.data) {

        resp.data.forEach((item: any) => {
          let valor;

          if (item.TipoRetorno === 'N') {
            valor = Number(item.Valor)
          }

          if (item.TipoRetorno === 'S') {
            valor = item.Valor
          }

          if (item.TipoRetorno === 'B') {
            valor = Number(item.Valor)

            if (valor === 1) {
              valor = true
            } else {
              valor = false
            }
          }

          setValue(item.CodigoPesquisa.trim(), valor);
        });
      }
    } catch (error) {
      console.error('Erro ao carregar os parâmetros:', error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    let resp;

    // Suponho que você deseja enviar um array de dados no corpo da requisição
    let arrayDados = [data];

    try {
      resp = await api.put('/parametros', arrayDados, {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
        }
      });

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setLoading(false);

    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
      setLoading(false);
    }
  };

  useEffect(() => {
    loadGlobal()
    loadPeriodo()
  }, [])

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Configurações do Financeiro</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          {loading ? (
            <Loading full={false} />
          ) : (
            <TabView>
              <TabPanel header="Período">
                <div className='grid'>
                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_LCTORETR"
                      label='Permitir Pagamento para Data Retroativa'
                    />
                  </div>
                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_PERDDESC"
                      label='Perde Desconto Após Vencimento'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_CORRDEB"
                      label='Corrigir Valores de Débitos'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_BLOQDEB"
                      label='Bloquear Pagamento com Débito Anterior'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_DIACAIXA"
                      label='Imprimir Lançamento de Entrada de Caixa com Caixa "Aberto"'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_IMPCNPJR"
                      label='Imprimir CNPJ nos Recibos'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_IMPCNPJ"
                      label='Imprimir CNPJ nos carnês de pagamento'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_IMPCVN"
                      label='Imprimir Convênio no Carnê de Pagamento'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_MULJURIP"
                      label='Considerar os Valores de Multa e Juros no Cálculo do Imposto de Renda?'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_VALPAGIR"
                      label='Considerar o Valor Pago no Cálculo do Imposto de Renda?'
                    />
                  </div>

                  <div className="col-12 md:col-4 mt-2">
                    <SCInputText
                      control={control}
                      errors={errors}
                      style={{ textTransform: 'uppercase' }}
                      name="PER_DIAPAGTO"
                      label='Dia padrão para o vencimento das Mensalidade'
                      maxLength={2}
                      tarefa={tarefa}
                      tooltip='Máximo de 2 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className="col-12 md:col-4 mt-2">
                    <SCInputText
                      control={control}
                      errors={errors}
                      style={{ textTransform: 'uppercase' }}
                      name="PER_TOLERDEB"
                      label='Tolerância para aplicação de multa e juros'
                      maxLength={2}
                      tarefa={tarefa}
                      tooltip='Máximo de 2 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className="col-12 md:col-4 mt-2">
                    <SCInputText
                      control={control}
                      errors={errors}
                      style={{ textTransform: 'uppercase' }}
                      name="PER_TOLCNE"
                      label='Tolerância para pagto no banco (FATOR DE VENCTO)'
                      maxLength={2}
                      tarefa={tarefa}
                      tooltip='Máximo de 2 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                </div>


                <div className='grid'>
                  <SCFieldset legend='Mensagem na 1ª linha do boleto' className='col-5 mt-3 mb-3'>
                    <div className="flex-collunm">
                      <SCRadioButton
                        control={control}
                        name="PER_MENDESCT"
                        label='Conceder desconto de R$ XXX,XX.'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="PER_MENDESCT"
                        label='Pagar somente o valor líquido de R$ XXX,XX.'
                        value={2}
                        className='mt-2'

                      />

                      <SCRadioButton
                        control={control}
                        name="PER_MENDESCT"
                        label='Não imprimir mensagem'
                        value={3}
                        className='mt-2'
                      />
                    </div>
                  </SCFieldset>

                  <SCFieldset legend='"Local de Pagamento" padrão' className='col-5 mt-3 mb-3'>
                    <div className="flex-collunm">
                      <SCRadioButton
                        control={control}
                        name="PER_TESBCO"
                        label='Tesouraria'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="PER_TESBCO"
                        label='Banco'
                        value={2}
                      />
                    </div>
                  </SCFieldset>
                </div>

                <div className='grid'>
                  <div className="col-6 mt-4">
                    <SCCheckbox
                      control={control}
                      name="PER_MENMULJU"
                      label='Em caso de atraso, cobrar multa de R$ XXX,XX + juros de R$ XXX,XX a.d.'
                    />
                  </div>

                  <div className="col-12 md:col-4 mt-3">
                    <div className='pb-2'></div>

                  </div>
                </div>

              </TabPanel>

              <TabPanel header="Global">
                <div className='grid'>
                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="GLO_VALCOB"
                      label='Imprimir Valor Cobrado na Boleta'
                    />
                  </div>

                  <div className="col-6">
                    <SCCheckbox
                      control={control}
                      name="GLO_BCODPAG"
                      label='Bloquear alteração do Plano de Pagamento para Alunos Matrículados'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="GLO_BLMATDEB"
                      label='Bloquear a realização da matrícula de Alunos com Débito'
                    />
                  </div>
                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="GLO_ENTCARAU"
                      label='Confirmação Automática para Entrada de Cartão'
                    />
                  </div>
                </div>

                <div className='grid'>
                  <div className="col-12 md:col-6 mt-3">
                    <div className='pb-2'>Qual data deve ser usada para "Bom para" e "Disponivel em"</div>
                    <div className="flex align-items-center">
                      <SCRadioButton
                        control={control}
                        name="GLO_DTBOMDIS"
                        label='Data Vencimento'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="GLO_DTBOMDIS"
                        label='Data do dia'
                        value={2}
                        className='ml-5'
                      />

                      <SCRadioButton
                        control={control}
                        name="GLO_DTBOMDIS"
                        label='Data de Pagamento'
                        value={3}
                        className='ml-5'
                      />
                    </div>
                  </div>

                  <div className="col-12 md:col-6 mt-3">
                    <div className='pb-2'>Data do Vencimento do Saldo</div>
                    <div className="flex align-items-center">
                      <SCRadioButton
                        control={control}
                        name="GLO_DTVENSAL"
                        label='Vencimento do Boleto'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="GLO_DTVENSAL"
                        label='Data do dia'
                        value={2}
                        className='ml-5'
                      />
                    </div>
                  </div>
                </div>

                <SCFieldset legend='Geração de Boleto' className='col-12 mt-3 mb-3'>
                  <div className="col-12 md:col-12">
                    <div className="flex align-items-center">
                      <SCRadioButton
                        control={control}
                        name="GLO_GERBOL"
                        label='Utilizar as informações do banco informado na tela'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="GLO_GERBOL"
                        label='Considerar o banco vinculado no cadastro da TURMA'
                        value={2}
                        className='ml-5'
                      />
                    </div>
                  </div>
                </SCFieldset>

                <div className='grid'>
                  <div className="col-12 md:col-6 mt-3">
                    <div className='pb-2'>No recebimento de mensalidades, informar o vencimento com base no</div>
                    <div className="flex align-items-center">
                      <SCRadioButton
                        control={control}
                        name="GLO_SUGVEN"
                        label='Dia e Parcela'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="GLO_SUGVEN"
                        label='Data do dia'
                        value={2}
                        className='ml-5'
                      />
                    </div>
                  </div>

                  <div className="col-12 md:col-6 mt-3">
                    <div className='pb-2'>No recebimento de mensalidades, informar o pagamento com base no</div>
                    <div className="flex align-items-center">
                      <SCRadioButton
                        control={control}
                        name="GLO_SUGPAG"
                        label='Vencimento'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="GLO_SUGPAG"
                        label='Data do dia'
                        value={2}
                        className='ml-5'
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel header="Senhas">
                <SCFieldset legend='Período' className='col-12 mb-3'>
                  <div className='grid'>
                    <div className="col-12 md:col-4">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_SENFGER"
                        label='Senha Geral'
                        maxLength={20}
                        tarefa={tarefa}
                        tooltip='Máximo de 20 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-4">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_SENFEST"
                        label='Estorno'
                        maxLength={20}
                        tarefa={tarefa}
                        tooltip='Máximo de 20 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-4">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_SENFQUI"
                        label='Quitação (Valor a menor)'
                        maxLength={20}
                        tarefa={tarefa}
                        tooltip='Máximo de 20 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-4">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_SENFDES"
                        label='Alterar Valor / Desconto'
                        maxLength={20}
                        tarefa={tarefa}
                        tooltip='Máximo de 20 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-4">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_SENFCAI"
                        label='Operações com Caixas'
                        maxLength={20}
                        tarefa={tarefa}
                        tooltip='Máximo de 20 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-4">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_SENFCPG"
                        label='Troca do Código de Pagamento'
                        maxLength={20}
                        tarefa={tarefa}
                        tooltip='Máximo de 20 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-4">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_SENFBLQD"
                        label='Pagamento com Débito Anterior'
                        maxLength={20}
                        tarefa={tarefa}
                        tooltip='Máximo de 20 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-4">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_SENFPTDR"
                        label='Pagto/Transferência de Fonte para data retroativa'
                        maxLength={20}
                        tarefa={tarefa}
                        tooltip='Máximo de 20 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>
                  </div>
                </SCFieldset>
              </TabPanel>
            </TabView>
          )}

          <div className='grid flex justify-content-end'>
            <div className='col-12 md:col-2'>
              <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='submit' style={{ width: '100%' }} onClick={handleSubmit(onSubmit)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfiguracoesFinanceiro;
