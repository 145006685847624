import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SCButton, SCDropdown, SCInputText } from '../components';
import AlunoResumo from '../components/AlunoResumo';
import Loading from '../components/Loading';
import { useToast } from '../context/ToastContext';
import { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { ALERTAS, TIPO_DISCIPLINA_COMPLEMENTAR } from '../utilities/constantes';
import IDisciplina from '../interfaces/IDisciplina';
import IMateria from '../interfaces/IMateria';
import ITurmaAuxiliar from '../interfaces/ITurmaAuxiliar';
import IDisciplinasComplementares from '../interfaces/IDisciplinasComplementares';
import { Button } from 'primereact/button';
import TurmaAuxiliar from './TurmaAuxiliar';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';


interface Props {
  aluno: IAlunoForm;
  setAlunoDialog(param: boolean): void;
  reload(): void;
}

const DisciplinasComplementaresForm: React.FC<Props> = ({ setAlunoDialog, aluno, reload }) => {
  const tarefa = 'NotasAlunosForm';
  const [loading, setLoading] = useState<boolean>(false);
  const [disciplinas, setDisciplinas] = useState<IDisciplina[]>([]);
  const [materias, setMaterias] = useState<IMateria[]>([]);
  const [turmaAuxiliar, setTurmaAuxiliar] = useState<ITurmaAuxiliar[]>([]);
  const [dadosDisciplinaComp, setDadosDisciplinaComp] = useState<any[]>([]);
  const [editando, setEditando] = useState<boolean>(false)
  const [codigoTurma, setCodigoTurma] = useState<string | null>(null);
  const [desabilitaDisciplina, setDesabilitaDisciplina] = useState<boolean>(false)
  const [desabilitaMateria, setDesabilitaMateria] = useState<boolean>(false)
  const [deleteDisciplina, setDeleteDisciplina] = useState(false)
  const [editaDisciplina, setEditaDisciplina] = useState(false)
  const [disciplinaCompId, setDisciplinaCompId] = useState(0)
  const [tipoAtual, setTipoAtual] = useState(0)

  const toast = useToast();
  const { periodoSelecionado } = useAuth();

  const defaultValues: IDisciplinasComplementares = {
    DisciplinaComplementarId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Matricula: 0,
    TurmaCodigo: '',
    DisciplinaId: 0,
    MateriaId: 0,
    Codigo: 0,
    TurmaAuxiliar: '',
    CargaHoraria: 0,
    Tipo: '',
    TipoDescricao: '',
    Indice: 0,
    Ordem: 0,
    Opcao: '',
  };

  const methods = useForm<IDisciplinasComplementares>({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, setError, clearErrors, getValues, reset } = methods;

  const selectedDisciplinaId = watch("DisciplinaId");

  const accept = async (rowData: any) => {

    if (getValues()?.Opcao === 'DELETE') {
      try {
        await api.delete('/disciplinaComplementar', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            Matricula: rowData?.Matricula,
            DisciplinaCodigo: rowData?.DisciplinaCodigo,
            MateriaCodigo: rowData?.MateriaCodigo,
            Tipo: rowData?.Tipo
          }
        })

        toast.showToast({ severity: 'success', summary: 'Concluído', detail: 'Operação Concluída' });
        loadDisciplinaComplementar()

      } catch (e: any) {
        console.log(e)
        toast.showToast({ severity: 'error', summary: 'Atenção', detail: e?.response?.data, life: 5000 });

      }
    }

    if (getValues()?.Opcao === 'EDITAR') {
      try {
        await api.put('')
        toast.showToast({ severity: 'success', summary: 'Concluído', detail: 'Operação Concluída' });
      } catch (error) {
        toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Não foi possível editar o registro.' });
      }
    }
  }

  const reject = () => {
    setValue('Opcao', '')
    toast.showToast({ severity: 'error', summary: 'Rejeitado', detail: 'Operação Cancelada', life: 3000 });
  }

  const confirm1 = (rowData: any) => {
    confirmDialog({
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i style={{ color: 'orange' }} className="pi pi-question-circle text-6xl"></i>
          <span style={{ textAlign: 'center', fontSize: 16 }}>A operação de EDIÇÃO é irreversível, ou seja, não será possível recuperar as informações alteradas.<br /> <br /> Você deseja EDITAR a disciplina/matéria selecionada?</span>
        </div>
      ),
      header: 'Confirmação de Edição',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => accept(rowData),
      reject
    });
  };

  const confirm2 = (rowData: any) => {
    confirmDialog({
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-question-circle text-6xl text-primary-500"></i>
          <span style={{ textAlign: 'center', fontSize: 16 }}>A operação de EXCLUSÃO é irreversível, ou seja, não será possível recuperar a disciplina/matéria com as suas respectivas notas e faltas lançadas.<br /> <br /> Você deseja EXCLUIR a disciplina/matéria selecionada?</span>
        </div>
      ),
      header: 'Confirmação de Exclusão',
      acceptClassName: 'p-button-danger',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => accept(rowData),
      reject
    });
  };


  useEffect(() => {
    const load = async () => {
      const { data } = await api.get('/aluno', {
        params: { AlunoId: aluno?.AlunoId }
      });

      const alunoperiodo = await api.get('/alunosPeriodo/matricula', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Matricula: data?.Matricula
        }
      });

      data.Turma = {
        Codigo: alunoperiodo.data.TurmaCodigo,
      };

      setCodigoTurma(alunoperiodo.data.TurmaCodigo)

      data.AlunosPeriodo = alunoperiodo.data;
      reset(data);
    }

    load();

  }, []);

  const loadDisciplinaComplementar = async () => {
    let resp = undefined;

    resp = await api.get('disciplinaComplementar', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: aluno?.Matricula
      }
    })

    if (resp.data) {
      const result = resp.data.map((item: any) => {
        return { ...item, TipoDescricao: TIPO_DISCIPLINA_COMPLEMENTAR.find(t => t.value === item.Tipo)?.descricao }
      })

      setDadosDisciplinaComp(result)
      console.log('DiscpComp', resp.data)
    }
  }

  useEffect(() => {
    loadDisciplinaComplementar()
  }, [])

  useEffect(() => {
    const loadDisciplina = async () => {
      const resultDisciplina = await api.get('/disciplina/combo', { params: { Descricao: '' } });
      setDisciplinas(resultDisciplina.data);
      console.log('disciplinas', resultDisciplina.data)
    }

    loadDisciplina();
  }, []);

  useEffect(() => {
    const loadMateria = async () => {
      if (selectedDisciplinaId) {
        const resultMateria = await api.get('/materia/buscaMateriaPorDisciplina', {
          params: {
            DisciplinaId: selectedDisciplinaId
          }
        });

        setMaterias(resultMateria.data);
        console.log('materias', resultMateria.data)
      }
    }

    loadMateria();
  }, [selectedDisciplinaId]);

  useEffect(() => {
    const loadTurmaAux = async () => {
      if (selectedDisciplinaId) {
        const disciplina = disciplinas.find(d => d.DisciplinaId === selectedDisciplinaId);
        if (disciplina) {
          const resultTurmaAux = await api.get('/ofertaDisciplina/buscaDisciplina', {
            params: {
              DisciplinaCodigo: disciplina.Codigo
            }
          });

          setTurmaAuxiliar(resultTurmaAux.data);
          console.log('turmaAuxiliar', resultTurmaAux.data);
        }
      }
    };

    loadTurmaAux();
  }, [selectedDisciplinaId, disciplinas]);

  const cancelarEdicao = () => {
    setEditando(false);
    setDesabilitaDisciplina(false)// habilita Disciplina
    setDesabilitaMateria(false)// habilita Materia
    setValue('DisciplinaId', 0)
    setValue('MateriaId', 0)
    setValue('TurmaAuxiliar', '')
    setValue('CargaHoraria', 0)
    setValue('Tipo', '')
  }

  const editar = (e: any) => {
    setEditando(true)
    setDesabilitaDisciplina(true)// desabilitando Disciplina
    setDesabilitaMateria(true)// desabilitando Materia
    setValue('DisciplinaId', e.DisciplinaId)
    setValue('MateriaId', e.MateriaId)
    setValue('TurmaAuxiliar', e.TurmaAuxiliar)
    setValue('CargaHoraria', e.CargaHoraria)
    setValue('Tipo', e.Tipo)
    setValue('Indice', e.Indice)
    setValue('Opcao', 'EDITAR')

    setDisciplinaCompId(e.DisciplinaComplementarId)
    setTipoAtual(e.Tipo)
  }

  const deletar = async (rowData: any) => {
    setValue('Opcao', 'DELETE')
    confirm2(rowData)
  }

  const adicionaDisciplinaComp = async (e: any) => {
    clearErrors();

    const dados = getValues();

    if (!dados.DisciplinaId) {
      setError('DisciplinaId', { message: 'campo obrigatório', type: 'manual' });
      return false;
    }

    if (!dados.TurmaAuxiliar) {
      setError('TurmaAuxiliar', { message: 'campo obrigatório', type: 'manual' });
      return false;
    }

    if (!dados.CargaHoraria) {
      setError('CargaHoraria', { message: 'campo obrigatório', type: 'manual' });
      return false;
    }

    if (!dados.Tipo) {
      setError('Tipo', { message: 'campo obrigatório', type: 'manual' });
      return false;
    }

    let resp;
    const materiaCodigo = materias.find(m => m.MateriaId === dados.MateriaId)?.Codigo

    const ordemNumero = await api.get('/disciplinaComplementar/ordem', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: aluno?.Matricula
      }
    })

    if (editando) {

      try {
        await api.put('/disciplinaComplementar', {
          EmpresaId: dados?.EmpresaId,
          Ano: dados?.Ano,
          Sequencial: dados?.Sequencial,
          Matricula: dados?.Matricula,
          DisciplinaCodigo: disciplinas.find(d => d.DisciplinaId === dados.DisciplinaId)?.Codigo,
          MateriaCodigo: materiaCodigo ? materiaCodigo : null,
          Tipo: dados?.Tipo,
          CargaHoraria: Number(dados.CargaHoraria),
          Ordem: ordemNumero?.data[0].maximo + 1,
          TurmaAuxiliar: dados.TurmaAuxiliar,
          TurmaCodigo: codigoTurma,
          DisciplinaComplementarId: disciplinaCompId,
          TipoAtual: tipoAtual
        })

        toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
        cancelarEdicao()
        loadDisciplinaComplementar();

      } catch (e: any) {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data.error });
      }

    } else {

      try {
        resp = await api.post('/disciplinaComplementar', {
          EmpresaId: dados?.EmpresaId,
          Ano: dados?.Ano,
          Sequencial: dados?.Sequencial,
          Matricula: dados?.Matricula,
          DisciplinaCodigo: disciplinas.find(d => d.DisciplinaId === dados.DisciplinaId)?.Codigo,
          MateriaCodigo: materiaCodigo ? materiaCodigo : null,
          Tipo: dados?.Tipo,
          CargaHoraria: Number(dados.CargaHoraria),
          Ordem: ordemNumero?.data[0].maximo + 1,
          TurmaAuxiliar: dados.TurmaAuxiliar,
          TurmaCodigo: codigoTurma
        }
        )
        toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
        loadDisciplinaComplementar();


      } catch (e: any) {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data.error });
      }
    }
  }

  const cancelar = (e: any) => {
    setAlunoDialog(false);
    reset();
  };



  return (
    <FormProvider {...methods}>
      <form className='pt-3' >
        <div className='grid'>
          <AlunoResumo mostraFoto mostraTurma aluno={getValues() as IAlunoForm} />
        </div>
        <Divider />

        <div className='grid flex justify-content-center'>
          <div className="col-12 md:col-4">
            <SCDropdown
              control={control}
              errors={errors}
              name="DisciplinaId"
              label='Disciplina'
              options={disciplinas}
              optionLabel='CodigoDescricao'
              optionValue='DisciplinaId'
              resetFilterOnHide={true}
              disabled={desabilitaDisciplina}
            />

          </div>

          <div className="col-12 md:col-3">
            <SCDropdown
              control={control}
              errors={errors}
              name="MateriaId"
              label='Matéria'
              options={materias}
              optionLabel='Descricao'
              optionValue='MateriaId'
              resetFilterOnHide={true}
              disabled={desabilitaMateria}
            />
          </div>

        </div>
        <div className='grid flex justify-content-center'>
          <div className="col-12 md:col-2">
            <SCDropdown
              control={control}
              errors={errors}
              name="TurmaAuxiliar"
              label='Turma Secundária'
              options={turmaAuxiliar}
              optionLabel='TurmaCodigo'
              optionValue='TurmaCodigo'
              resetFilterOnHide={true}
            />
          </div>

          <div className="col-12 md:col-1">
            <SCInputText
              control={control}
              errors={errors}
              name="CargaHoraria"
              label='CHR'
            />
          </div>

          <div className="col-12 md:col-3">
            <SCDropdown
              control={control}
              errors={errors}
              name="Tipo"
              label='Tipo'
              options={TIPO_DISCIPLINA_COMPLEMENTAR}
              optionLabel='descricao'
              optionValue='value'
              resetFilterOnHide={true}
            />
          </div>
          <div className="flex col-1 md:col-1">
            {editando ? (
              <>
                <SCButton type='button' icon="pi pi-times" className="p-button-danger mt-auto mr-2" onClick={cancelarEdicao} />
                <SCButton type='button' icon="pi pi-check" className="p-button-success mt-auto" onClick={adicionaDisciplinaComp} />
              </>
            ) : (
              <SCButton type='button' icon="pi pi-plus" className="p-button-success mt-auto" onClick={adicionaDisciplinaComp} />
            )}
          </div>
        </div>

        <Divider />

        {loading ? <Loading /> : (
          <DataTable
            size='small'
            stripedRows
            dataKey="id"
            value={dadosDisciplinaComp}
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            emptyMessage="Nenhum disciplina adicionada."
            editMode='cell'
            responsiveLayout="scroll"
          >
            <Column field="DisciplinaCodigo" header="Disciplina"  ></Column>
            <Column field="DisciplinaDescricao" header="Descrição"  ></Column>
            <Column
              style={{ maxWidth: 500 }}
              header="Matéria"
              body={(rowData: any) => (
                <>
                  <span >{rowData.MateriaCodigo} - {rowData.MateriaDescricao}</span>
                </>
              )}
            ></Column>
            <Column field="CargaHoraria" header="Carga Horária" align='center'  ></Column>
            <Column field="TipoDescricao" header="Tipo" headerStyle={{ width: 180 }}></Column>
            <Column field="TurmaAuxiliar" header="Turma Auxiliar"></Column>

            <ConfirmDialog />

            <Column
              headerStyle={{ maxWidth: 125 }}
              bodyStyle={{ maxWidth: 100, display: 'flex', justifyContent: 'space-evenly' }}
              body={(rowData: any) => (
                <>
                  <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" type='button' onClick={() => editar(rowData)} />
                  <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" type='button' onClick={() => deletar(rowData)} />
                </>
              )}
              style={{ width: 90, textAlign: 'center' }}
            />
          </DataTable>

        )}

        <Divider />

        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
        </div>

      </form >
    </FormProvider>
  );
};

export default DisciplinasComplementaresForm;
