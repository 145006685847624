import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, AVALIACAO, FEZ_AVALIACAO, SITUACAO_FINAL_ALUNO } from '../utilities/constantes';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCButton, SCDropdown, SCInputText, SCRadioButton } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { InputText } from 'primereact/inputtext';
import { confirmDialog } from 'primereact/confirmdialog';
import { format } from 'date-fns';


const ConselhoClasseEdInfantil = () => {
    const toast = useToast();
    const { periodoSelecionado, usuario } = useAuth();
    const tarefa = 'NotasTurma';

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [alunos, setAlunos] = useState<any>(null);
    const [turmas, setTurmas] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const dt = useRef<any>(null);

    const methods = useForm();
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

    const watchCodigoTurma = watch('Codigo');

    const load = async () => {
        const turmasEncontradas = await api.get('/turmas', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        })

        if (turmasEncontradas.data.length > 0) {
            setTurmas(turmasEncontradas.data)
        } else {
            setTurmas([])
        }
    };

    useEffect(() => {
        load();
    }, []);

    const handleTurmaChange = async (value: any) => {
        const alunosTurma = await api.get('/alunosPeriodo/listaAlunosTurma', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Alunos: 1,
                TurmaCodigo: value,
                SituacaoAcademica: 'M',
            }
        })

        if (alunosTurma.data.length > 0) {
            setAlunos(alunosTurma.data)
        } else {
            setAlunos([])
        }
    }

    const handleDropdownSituacaoChange = (e: any, rowIndex: number) => {
        const { value } = e.target;
        const updatedData = [...alunos];
        updatedData[rowIndex] = { ...updatedData[rowIndex], SituacaoFinal: value };
        setAlunos(updatedData);
    };

    const onEditorValueChange = (props: any, value: any) => {
        let updatedAlunos = [...alunos];
        updatedAlunos[props.rowIndex][props.field] = value;
        setAlunos(updatedAlunos);
    };

    const bodyTemplate = (rowData: any, field: any) => {
        return inputTextEditor({ rowData, field, rowIndex: alunos.indexOf(rowData) });
    };

    const inputTextEditor = (props: any) => {
        const field = props.field;
        const rowIndex = props.rowIndex;

        const handleBlur = (e: any) => {
            const value = e.target.value ? e.target.value.replace(',', '.') : '';
            onEditorValueChange({ field, rowIndex }, value);
        };

        const handleControl = (e: any) => {
            const charCode = e.charCode;
            const newValue = e.target.value + String.fromCharCode(charCode);

            if (!/^\d*\,?\d*$/.test(newValue)) {
                e.preventDefault();
            }
        };

        if (!props.rowData) {
            return null;
        }

        return (
            <InputText
                // value={props.rowData.ConselhoClasseFrequencia}
                type="text"
                maxLength={4}
                value={props.rowData.ConselhoClasseFrequencia}
                onChange={(e) => onEditorValueChange(props, e.target.value)}
                onBlur={handleBlur}
                onKeyPress={handleControl}
                style={{ maxWidth: 50 }}
            />
        );
    };

    const situacaoTemplate = (rowData: any, options: any) => {
        const { rowIndex } = options;
        return (
            <div className="col-12 flex justify-content-center">
                <SCDropdown
                    name='sit'
                    value={rowData.SituacaoFinal}
                    onChange={(e) => handleDropdownSituacaoChange(e, rowIndex)}
                    options={SITUACAO_FINAL_ALUNO}
                    optionLabel='descricao'
                    style={{ width: 180 }}
                />
            </div>
        );
    };


    const onSubmit = async () => {

        let resp: any = undefined;

        try {
            setLoading(true)
            alunos.forEach( async (item: any) => {
                if (item.ConselhoClasseFrequencia !== '' && item.SituacaoFinal === '') {
                    item.ConselhoClasseFrequencia = undefined;
                }
                if (item.ConselhoClasseFrequencia === '' && item.SituacaoFinal !== '') {
                    item.SituacaoFinal = '';
                }
                const arrayUpdate = {
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Ano: periodoSelecionado?.Ano,
                    Sequencial: periodoSelecionado?.Sequencial,
                    Matricula: item.Matricula,
                    ConselhoClasseFrequencia: item.ConselhoClasseFrequencia,
                    SituacaoFinal: item.SituacaoFinal,
                    ConselhoClasseOperador: usuario?.UsuarioId,
                    ConselhoClasseData: new Date(),
                    ConselhoClasseHora: format(new Date(), 'HH:mm'),
                }
                console.log(arrayUpdate)

                await api.put('/alunosPeriodo/updateSituacao', arrayUpdate)
            })

            setLoading(false)
            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            window.location.reload();

        } catch (e: any) {
            setLoading(false)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    };

    const accept = () => {
        const updatedAlunos = alunos.map((item: any) => {
            item.ConselhoClasseFrequencia = '';
            item.SituacaoFinal = '';

            return item;
        })

        setAlunos(updatedAlunos)
    }

    const confirm = () => {
        confirmDialog({
            message: (<div style={{ fontSize: '16px' }} className='col-12'>
                <div>
                Essa operação irá LIMPAR todos os registros de lançamento do conselho de classe dessa seleção.
                </div>
                <div className='pt-2'>
                    Essa operação é IRREVERSÍVEL, ou seja, uma vez confirmada não poderá reverter.
                </div>
                <div className='pt-2'>
                    VOCÊ TEM CERTEZA QUE QUER CONTINUAR?
                </div>
            </div>),
            header: 'Atenção!',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept,
            style: { maxWidth: '600px', width: '80%' }
        });
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Conselho de Classe (Educação Infantil)</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <div className='grid pt-2'>
                        <div className="col-12 md:col-6">
                            <SCDropdown
                                name="Codigo"
                                options={turmas}
                                optionLabel="TurmaDescricao"
                                optionValue='Codigo'
                                placeholder='Selecione a Turma'
                                label='Turma'
                                style={{ width: '100%' }}
                                value={watchCodigoTurma}
                                onChange={(e) => {
                                    setValue('Codigo', e.target.value);
                                    handleTurmaChange(e.target.value)
                                }}
                                required
                            />
                        </div>
                    </div>

                    <DataTable
                        size='small'
                        stripedRows
                        dataKey="id"
                        value={alunos}
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        emptyMessage="Selecione uma avaliação."
                        editMode='cell'
                        responsiveLayout="scroll"
                        className='pt-3'>

                        <Column field="NumeroOrdem" header="Nº Ordem" sortable headerStyle={{ width: 130 }} />
                        <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }} />
                        <Column field="Nome" header="Nome" sortable headerStyle={{ width: '40%' }} />
                        <Column field='ConselhoClasseFrequencia' header="Frequência (%)" align='center' headerStyle={{ width: 120 }} body={(rowData) => bodyTemplate(rowData, 'ConselhoClasseFrequencia')} />
                        <Column field="SituacaoFinal" alignHeader='center' align='center' header="Situação Final" body={situacaoTemplate} />
                    </DataTable>
                    <div className='grid flex justify-content-center mt-2'>
                        <div className='col-6 md:col-2 flex justify-content-start mt-2'>
                            <Button label='Limpar Lançamentos' className="p-button-danger p-button-outlined" type='button' style={{ width: '100%' }} onClick={confirm} />
                        </div>
                        <div className='col-12 md:col-8 pt-4 flex justify-content-center align-items-center'></div>
                        <div className='col-6 md:col-2 flex justify-content-end mt-2 '>
                            <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={onSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ConselhoClasseEdInfantil;
