import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputText, SCRadioButton } from '../components';
import Loading from '../components/Loading';
import IEmpresas from '../interfaces/IEmpresas';
import FormEndereco from '../components/FormEndereco';
import { ALERTAS } from '../utilities/constantes';
import { TabPanel, TabView } from 'primereact/tabview';
import { format } from 'date-fns';
import util from '../utilities/util';
import imageResize from 'image-resize';
import { Image } from 'primereact/image';
import semImagem from '../assets/sem-imagem.png';

interface Props {
  registro?: IEmpresas;
  setEmpresaDialog(param: boolean): void;
  reload(): void;
  novoComBase?: any;
}

const EmpresasForm: React.FC<Props> = ({ setEmpresaDialog, registro, reload }) => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'FuncionarioForm';

  const [loading, setLoading] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const defaultValues: IEmpresas = registro || {
    EmpresaId: periodoSelecionado?.EmpresaId,
    Empresa: '',
    RazaoSocial: '',
    IdRelatorio: '',
    Tipo: 0,
    Cnpj: '',
    Contato: '',
    Ddd: '',
    Telefone: '',
    Email: '',
    EnderecoId: 0,
    IDUnidade: '',
    Logo: '',
    MensagemReciboLinha01: '',
    MensagemReciboLinha02: '',
    MensagemReciboLinha03: '',
    MensagemReciboLinha04: '',
    MensagemRelatorioLinha01: '',
    MensagemRelatorioLinha02: '',
    MensagemRelatorioLinha03: '',
    MensagemRelatorioLinha04: '',
    Prefixo: '',
    CodigoMobile: 0,
    EMPRESAEndereco: []
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, clearErrors, setError, setValue, getValues } = methods;
  const { empresaSelecionada, setEmpresa } = useAuth();
  const watchEmpresaId = watch('EmpresaId', undefined);
  const watchIdRelatorio = watch('IdRelatorio');

  const onSubmit = async (data: any) => {
    setLoading(true)

    const cnpj = getValues().Cnpj?.replace(/\D/g, '');
    if (cnpj !== undefined) {
      if (util.cnpjValido(cnpj)) {
        clearErrors('Cnpj')
      } else {
        setError('Cnpj', { type: 'manual', message: 'CNPJ inválido' });
        return;
      }
    }

    let dadosEmpresa: IEmpresas = {
      EmpresaId: data.EmpresaId,
      Empresa: data.Empresa,
      RazaoSocial: data.RazaoSocial,
      IDUnidade: data.IDUnidade,
      IdRelatorio: data.IdRelatorio,
      Tipo: data.Tipo,
      Cnpj: data.Cnpj,
      Contato: data.Contato,
      Logo: data.Logo,
      Telefone: data.Telefone,
      Ddd: data.Ddd,
      Email: data.Email,
      MensagemReciboLinha01: data.MensagemReciboLinha01,
      MensagemReciboLinha02: data.MensagemReciboLinha02,
      MensagemReciboLinha03: data.MensagemReciboLinha03,
      MensagemReciboLinha04: data.MensagemReciboLinha04,
      MensagemRelatorioLinha01: data.MensagemRelatorioLinha01,
      MensagemRelatorioLinha02: data.MensagemRelatorioLinha02,
      MensagemRelatorioLinha03: data.MensagemRelatorioLinha03,
      MensagemRelatorioLinha04: data.MensagemRelatorioLinha04,
      Prefixo: data.Prefixo,
      CodigoMobile: data.CodigoMobile,
      DataGravacao: format(new Date(), 'yyyy-MM-dd')
    }
    
    try {
      let { EMPRESAEndereco, Bairro, Cep, Complemento, Endereco, Municipio, Numero, Uf, ...newDados } = data

      if (dadosEmpresa.EmpresaId) {    
          await api.put('/empresa', dadosEmpresa)
          await api.put('/enderecos', {
            Matricula: data.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            Tipo: "EMPRESA",
            Bairro: EMPRESAEndereco.Bairro,
            Logradouro: EMPRESAEndereco.Endereco,
            Cep: EMPRESAEndereco.Cep,
            Complemento: EMPRESAEndereco.Complemento,
            Numero: Number(EMPRESAEndereco.Numero),
            Cidade: EMPRESAEndereco.Municipio,
            Uf: EMPRESAEndereco.Uf,
          });

          if (dadosEmpresa.EmpresaId === empresaSelecionada?.EmpresaId)
          {
            setEmpresa({ ...empresaSelecionada, 
              IDUnidade: dadosEmpresa.IDUnidade, 
              Logo: dadosEmpresa.Logo,
              RazaoSocial: dadosEmpresa.RazaoSocial,
              IdRelatorio: dadosEmpresa.IdRelatorio,
              EmpresaId: dadosEmpresa.EmpresaId,
            })
            
          }

          setEmpresaDialog(false)      
      }else{
        const responEmpresa = await api.post('/empresa', dadosEmpresa)
        await api.post('/enderecos', {
          Matricula: responEmpresa.data,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Tipo: "EMPRESA",
          Bairro: EMPRESAEndereco.Bairro,
          Logradouro: EMPRESAEndereco.Endereco,
          Cep: EMPRESAEndereco.Cep,
          Complemento: EMPRESAEndereco.Complemento,
          Numero: Number(EMPRESAEndereco.Numero),
          Cidade: EMPRESAEndereco.Municipio,
          Uf: EMPRESAEndereco.Uf,
        });

      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setEmpresaDialog(false);
      setLoading(false)
      reload();

    } catch (e: any) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const cancelar = () => {
    setEmpresaDialog(false);
  }

  useEffect(() => {
    load();
  }, [])
  
  const load = async () => {
    const parametroDDD = await api.get('/parametro', {
      params: {
        EmpresaId: 0,
        Ano: '0000',
        Sequencial: '0',
        Codigo: 'DDDPADRAO',
        Grupo: 'S',
      }
    });

    if (getValues().Ddd === '') {
      console.log('Parametro Aplicado')
      setValue('Ddd', parametroDDD.data.Valor)
    }
  }
  
  const [loadingFoto, setLoadingFoto] = useState<boolean>(false);
  const watchLogo = watch('Logo')
  const imgUpload = async () => {
    if (inputRef?.current?.files && inputRef?.current?.files?.length > 0) 
    {
      let res = await imageResize(inputRef?.current.files[0], {
        width: 800,
        outputType: 'base64',
        quality: 0.7,        
        bgColor: '#fff',
        format: 'png',
      });
      setValue('Logo', res);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{minHeight: '60vh'}}>
        {loading && <Loading full={true}/>}
        <TabView renderActiveOnly={false} className='mb-3'>
          <TabPanel header="Principal">
            <div className='grid'>
              <div className='col-6'>
                <div className='grid'>
                  <div className="col-12">
                    <SCInputText
                      control={control}
                      name="Empresa"
                      label='Empresa'
                      style={{ width: '100%' }}
                      disabled={true}
                    />
                  </div>

                  <div className="col-6">
                    <SCInputText
                      errors={errors}
                      control={control}
                      autoFocus={true}
                      name="IDUnidade"
                      label='ID da Unidade'
                      style={{ width: '100%' }}
                      maxLength={30}
                      tooltip='Máximo de 30 caracteres'
                      required={true}
                      tarefa={tarefa}
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className="col-6">
                    <SCInputMask
                      control={control}
                      name="Cnpj"
                      label='CNPJ'
                      style={{ width: '100%' }}
                      mask='99.999.999/9999-99'
                      errors={errors}
                      required={true}
                      onBlur={(e) => {
                        util.cnpjValido(e.target.value.replace(/\D/g, '')) ? clearErrors('Cnpj') : setError('Cnpj', { type: 'manual', message: 'CNPJ inválido' })
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <SCInputText
                      control={control}
                      name="RazaoSocial"
                      label='Razão Social'
                      style={{ width: '100%' }}
                      required={true}
                      errors={errors}
                    />
                  </div>

                  <div className="col-12">
                    <SCInputText
                      control={control}
                      name="IdRelatorio"
                      label='Identificação no Relatório'
                      style={{ width: '100%' }}
                      errors={errors}
                      required={true}
                    />
                  </div>

                  

                  <div className="col-6">
                    <div className='pb-2'>Tipo</div>
                    <div className="flex align-items-center">
                      <SCRadioButton
                        control={control}
                        name="Tipo"
                        label='Escola'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="Tipo"
                        label='Curso Técnico'
                        value={2}
                        className='ml-3'
                      />
                      <SCRadioButton
                        control={control}
                        name="Tipo"
                        label='IES'
                        value={3}
                        className='ml-3'
                      />
                    </div>
                  </div>
                </div>
                                
                <div className="grid">
                  <div className="col-3">
                    <SCInputText
                      control={control}
                      name="Prefixo"
                      label='Prefixo'
                      style={{ width: '100%' }}
                      maxLength={10}
                    />
                  </div>

                  <div className="col-4">
                    <SCInputText
                      control={control}
                      name="CodigoMobile"
                      label='Código Mobile'
                      style={{ width: '100%' }}
                      maxLength={10}
                      inputMode='numeric'
                    />
                  </div>
                </div>
                <div className='grid mt-2'>
                  <div className="col-2" style={{ marginTop: -10 }}>
                    <SCInputMask
                      control={control}
                      errors={errors}
                      name="Ddd"
                      label='DDD'
                      mask='999'
                      maxLength={5}
                    />
                  </div>
                  <div className='col-12 md:col-4' style={{ marginTop: -10 }}>
                    <SCInputMask
                      control={control}
                      errors={errors}
                      name="Telefone"
                      label='Celular'
                      mask='9 9999-9999'
                      tooltip='Máximo de 11 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                  <div className='col-12 md:col-6' style={{ marginTop: -10 }}>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Contato"
                      label='Outros Fones'
                      maxLength={40}
                    />
                  </div>
                </div>
                <div className='grid'>

                  <div className="col-12">
                    <SCInputText
                      control={control}
                      name="Email"
                      label='E-mail'
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='flex align-center justify-content-center'>
                  <div className="thumbnail-wrapper" style={{ width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <input
                      style={{ display: 'none' }}
                      ref={inputRef}
                      type="file"
                      name="myImage"
                      onChange={imgUpload}
                    />
                    {loadingFoto ?
                      <div className='flex w-full align-items-center justify-content-center '><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></div>
                      : (
                        <a onClick={() => inputRef?.current?.click()}>
                          <Image imageClassName='border-round-md' src={watchLogo || semImagem} alt="sem-foto" width='250' height='200' />
                          </a>
                      )}
                      
                  </div>
                </div>
                <div className='flex align-center justify-content-center'>
                  <SCButton
                    type='button'
                    style={{ width: 250, marginTop: 1, fontSize: '0.8rem'}}
                    label='ALTERAR IMAGEM'
                    onClick={() => inputRef?.current?.click()}/>
                    </div>
              </div>
            </div>
            
            
          </TabPanel>

          <TabPanel header="Endereço">
           
              <SCFieldset legend='Endereço' className='col-12 mt-3'>
                <FormEndereco matricula={watchEmpresaId} tipo='EMPRESA' />
              </SCFieldset>
            
          </TabPanel>

          <TabPanel header="Mensagens">
            <SCFieldset legend='Recibo' className='col-12 mt-3 mb-3'>
              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemReciboLinha01"
                    label='Linha 01'
                    style={{ width: '100%' }}
                    errors={errors}
                    disabled
                    value={watchIdRelatorio}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemReciboLinha02"
                    label='Linha 02'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemReciboLinha03"
                    label='Linha 03'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemReciboLinha04"
                    label='Linha 04'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>
              </div>
            </SCFieldset>

            <SCFieldset legend='Relatório' className='col-12 mt-3 mb-3'>
              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemRelatorioLinha01"
                    label='Linha 01'
                    style={{ width: '100%' }}
                    errors={errors}
                    disabled
                    value={watchIdRelatorio}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemRelatorioLinha02"
                    label='Linha 02'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemRelatorioLinha03"
                    label='Linha 03'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemRelatorioLinha04"
                    label='Linha 04'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>
              </div>
            </SCFieldset>
          </TabPanel>

        </TabView>
        <div className='grid fotter-form'>
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-primary" style={{ width: '100%' }} type='submit' onClick={handleSubmit(onSubmit)} />
          </div>
        </div>

      </form>
    </FormProvider>
  );
};

export default EmpresasForm;
