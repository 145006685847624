import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SCButton, SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputText, SCInputTextarea } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import IVisitantes from '../interfaces/IVisitantes';
import util from '../utilities/util';
import { Button } from 'primereact/button';
import { ALERTAS, TURNOS, PARENTESCO } from '../utilities/constantes';
import { isValid } from 'cpf';
import { TabPanel, TabView } from 'primereact/tabview';
import FormEndereco from '../components/FormEndereco';
import { useAuth } from '../providers/auth';
import { formatInTimeZone } from 'date-fns-tz';
import { format } from 'date-fns';


interface Props {
  visitante?: IVisitantes;
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
}

const VisitantesForm: React.FC<Props> = ({ setShowDialog, visitante, deletar, showDelete, setShowDelete, reload }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'VisitantesForm';

  const toast = useToast();

  let defaultValues: IVisitantes = {
    VisitantesId: undefined,
    Nome: '',
    AlunoNome: '',
    Parentesco: 0,
    CPF: '',
    Agendamento: null,
    FuncionarioId: undefined,
    CursoId: undefined,
    NivelSequenciaId: null,
    Turno: '',
    EscolaAnterior: '',
    ConhecimentoIndicacao: false,
    ConhecimentoTV: false,
    ConhecimentoJornal: false,
    ConhecimentoInternet: false,
    ConhecimentoOutdoor: false,
    ConhecimentoOutros: false,
    ContatoEmail: '',
    DDD: '',
    ContatoTelefone: '',
    ContatoCelular: '',
    Observacao: '',
    DataAtendimento: null,
    ColaboradorId: 0,
    Nivel: '',
    VISITANTEEndereco: []
  };

  const methods = useForm({ defaultValues })
  const { periodoSelecionado } = useAuth();
  const { handleSubmit, control, formState: { errors }, getValues, reset, watch, setValue } = methods;
  const [curso, setCurso] = useState<any>();
  const [funcionario, setFuncionario] = useState<any>();
  const [nivelSequencia, setNivelSequencia] = useState<any>();
  const [editar, setEditar] = useState<boolean>(true);

  const watchVisitantesId = watch('VisitantesId', undefined);
  const timeZone = 'America/Sao_Paulo'; // Definindo o fuso horário desejado

  const load = async () => {

    const resultCurso = await api.get('/curso');
    setCurso(resultCurso.data);

    try {
      const resp = await api.get('/nivelSequencia/list', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
        }
      });

      const parametroDDD = await api.get('/parametro', {
        params: {
          EmpresaId: 0,
          Ano: '0000',
          Sequencial: '0',
          Codigo: 'DDDPADRAO',
          Grupo: 'S',
        }
      });

      if (visitante?.VisitantesId === undefined) {
        setValue('DDD', parametroDDD.data.Valor)
      }

      setNivelSequencia(resp.data);
    } catch (error) {
      // Trate o erro aqui, se necessário
      console.error('Erro ao buscar dados:', error);
    }

    const resultFuncionario = await api.get('/funcionarios/list')

    if (visitante?.VisitantesId === undefined) {
      if (resultFuncionario.data) {
        let funcio = resultFuncionario.data
        let filtraFuncioAtivado = funcio.filter((f: any) => f.Ativado == 1)
        if (filtraFuncioAtivado) {
          setFuncionario(filtraFuncioAtivado)
        }
      }
    } else {
      setFuncionario(resultFuncionario.data)
    }
  }

  const watchCursoId = watch('CursoId');
  const watchNivel = watch('Nivel');

  useEffect(() => {
    if (watchCursoId && curso) {
      const resp = curso.find((item: any) => item.CursoId == watchCursoId);

      console.log(resp)
      setValue('Nivel', resp?.TipoCurso);
    }
  }, [nivelSequencia, watchCursoId])

  useEffect(() => {
    load();
    reset(visitante);
  }, []);

  const cancelar = (e: any) => {
    setShowDelete(false);
    setShowDialog(false);
    reset();
  }

  let resp: any = undefined;

  const salvaVisitante = async (newData: any) => {
    resp = await api.post('/visitantes', newData);

    const idVisitante = resp?.data.VisitantesId[0];
    newData.VisitantesId = idVisitante;
  }

  const salvaEndereco = async (newData: any, data: any, formataCep: any) => {
    await api.post('/enderecos', {
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Matricula: newData.VisitantesId,
      Tipo: "VISITANTE",
      Cep: formataCep,
      Logradouro: data.VISITANTEEndereco.Logradouro,
      Numero: data.VISITANTEEndereco.Numero,
      Complemento: data.VISITANTEEndereco.Complemento,
      Bairro: data.VISITANTEEndereco.Bairro,
      Cidade: data.VISITANTEEndereco.Cidade,
      Uf: data.VISITANTEEndereco.Uf
    })
  }

  const onSubmit = async (data: any) => {
    if (data.CPF) {
      data.CPF = data.CPF.replace(/\D/g, '');
    }

    data.Agendamento = data.Agendamento ? format(data.Agendamento, 'yyyy-MM-dd HH:mm:ss') : null
    data.DataAtendimento = data.DataAtendimento ? format(data.DataAtendimento, 'yyyy-MM-dd HH:mm:ss') : null

    let { VISITANTEEndereco, Nivel, SomenteAtendidos, DataAgendamentoString, DataAtendimentoString, SomenteNaoAtendidos, ...newData } = data;

    // Verificando se pelo menos uma das datas foi preenchida
    if (!newData.Agendamento && !newData.DataAtendimento) {
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Preencha a Data de Agendamento ou a Data de Atendimento!'
      });

      return false;
    }

    let formataCep;

    if (data.VISITANTEEndereco.Cep) {
      formataCep = data.VISITANTEEndereco.Cep.replace('-', '');
    }

    newData.DDD = newData.DDD.replace(/[()]/g, '');

    try {
      console.log('Submit', newData)
      if (newData.VisitantesId === undefined) {
        await salvaVisitante(newData);
        await salvaEndereco(newData, data, formataCep);
      } else {
        resp = await api.put('/visitantes', newData);

        await api.put('/enderecos', {
          Matricula: data?.VisitantesId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Tipo: "VISITANTE",
          Cep: formataCep,
          Logradouro: data.VISITANTEEndereco.Logradouro,
          Numero: data.VISITANTEEndereco.Numero,
          Complemento: data.VISITANTEEndereco.Complemento,
          Bairro: data.VISITANTEEndereco.Bairro,
          Cidade: data.VISITANTEEndereco.Cidade,
          Uf: data.VISITANTEEndereco.Uf
        });
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setShowDialog(false);
    } catch (e: any) {
      console.log(e.response.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }


  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className='pt-0' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
          <TabView renderActiveOnly={false}>
            <TabPanel header="Principal">
              <div className="col-12 md:col-4 p-0">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="AlunoNome"
                  label='Nome do Aluno'
                  maxLength={60}
                  required={!showDelete}
                  disabled={showDelete || !editar}
                  tooltip='Máximo de 60 caracteres'
                  tooltipOptions={{ event: 'focus' }}
                  style={{ textTransform: 'uppercase' }}
                />
              </div>
              <SCFieldset legend='Requerendo' className='col-12 mt-1 p-0'>
                <div className='grid'>
                  <div className="col-12 md:col-4" style={{ marginTop: -10 }}>
                    <SCDropdown
                      control={control}
                      errors={errors}
                      name="CursoId"
                      label='Curso'
                      options={curso}
                      optionLabel='Descricao'
                      optionValue='CursoId'
                      disabled={showDelete}
                      required={!showDelete}
                      tarefa={tarefa}
                    />
                  </div>
                  <div className="col-12 md:col-3" style={{ marginTop: -10 }}>
                    <SCDropdown
                      control={control}
                      errors={errors}
                      name="NivelSequenciaId"
                      label='Nível/Escolaridade'
                      options={nivelSequencia?.filter((item: any) => item.Nivel == watchNivel)}
                      filter={false}
                      optionLabel='Descricao'
                      optionValue='NivelSequenciaId'
                      disabled={showDelete}
                      tarefa={tarefa}
                    />
                  </div>
                  <div className="col-12 md:col-2" style={{ marginTop: -10 }}>
                    <SCDropdown
                      control={control}
                      errors={errors}
                      name="Turno"
                      label='Turno'
                      options={TURNOS}
                      optionLabel='descricao'
                      optionValue='value'
                      disabled={showDelete}
                      required={!showDelete}
                      tarefa={tarefa}
                    />
                  </div>

                  <div className="col-12 md:col-3" style={{ marginTop: -9 }}>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="EscolaAnterior"
                      label='Escola Anterior'
                      disabled={showDelete}
                      maxLength={30}
                      tooltip='Máximo de 30 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                      tarefa={tarefa}
                    />
                  </div>
                </div>

              </SCFieldset>

              <SCFieldset legend='Contato' className='col-12 p-0 mt-2'>
                <div className='grid'>
                  <div className="col-12 md:col-4" style={{ marginTop: -10 }}>
                    <SCInputText
                      control={control}
                      errors={errors}
                      style={{ textTransform: 'uppercase' }}
                      name="Nome"
                      label='Nome do Responsável'
                      maxLength={50}
                      required={!showDelete}
                      disabled={showDelete || !editar}
                      tooltip='Máximo de 50 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                  <div className="col-12 md:col-4" style={{ marginTop: -10 }}>
                    <SCInputMask
                      control={control}
                      errors={errors}
                      style={{ textTransform: 'uppercase' }}
                      name="CPF"
                      label='CPF do Responsável'
                      maxLength={14}
                      disabled={showDelete}
                      mask='999.999.999-99'
                      tooltip='Máximo de 11 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                  <div className="col-12 md:col-4" style={{ marginTop: -10 }}>
                    <SCDropdown
                      control={control}
                      errors={errors}
                      options={PARENTESCO}
                      name="Parentesco"
                      label='Parentesco'
                      optionLabel='descricao'
                      optionValue='value'
                      disabled={showDelete}
                      tarefa={tarefa}
                    />
                  </div>
                  <div className='col-12 md:col-4' style={{ marginTop: -10 }}>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="ContatoEmail"
                      label='Email'
                      maxLength={60}
                      disabled={showDelete}
                      tooltip='Máximo de 60 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className="col-1" style={{ marginTop: -10 }}>
                    <SCInputMask
                      control={control}
                      errors={errors}
                      name="DDD"
                      label='DDD'
                      required={!showDelete}
                      disabled={showDelete}
                      mask='999'
                      maxLength={5}
                    />
                  </div>

                  <div className='col-12 md:col-2' style={{ marginTop: -10 }}>
                    <SCInputMask
                      control={control}
                      errors={errors}
                      name="ContatoCelular"
                      label='Celular'
                      required={!showDelete}
                      disabled={showDelete}
                      mask='9 9999-9999'
                      tooltip='Máximo de 11 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className='col-12 md:col-5' style={{ marginTop: -10 }}>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="ContatoTelefone"
                      label='Outros Fones'
                      disabled={showDelete}
                      maxLength={40}
                    />
                  </div>
                </div>
              </SCFieldset>

              <div className='md:flex mt-2'>
                <SCFieldset legend='Agendado para' className='col-12 md:col-6 p-0'>
                  <div className='grid flex align-items-center justify-content-evenly'>
                    <div className="col-12 md:col-4 p-0">
                      <SCCalendar
                        label='Dia'
                        control={control}
                        name='Agendamento'
                        dateFormat='dd/mm/yy'
                        mask='99/99/9999 99:99'
                        disabled={showDelete}
                        showIcon
                        showTime
                      />
                    </div>

                    <div className="col-12 md:col-6 p-0">
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name="FuncionarioId"
                        label='Por'
                        options={funcionario}
                        optionLabel='Nome'
                        optionValue='FuncionarioId'
                        disabled={showDelete}
                        tarefa={tarefa}
                      />
                    </div>
                  </div>
                </SCFieldset>

                <SCFieldset legend='Atendido por' className='col-12 md:col-6 p-0'>
                  <div className='grid flex justify-content-evenly'>
                    <div className="col-12 md:col-4 p-0">
                      <SCCalendar
                        label='Dia'
                        control={control}
                        name='DataAtendimento'
                        dateFormat='dd/mm/yy'
                        mask='99/99/9999 99:99'
                        disabled={showDelete}
                        showIcon
                        showTime
                      />
                    </div>

                    <div className="col-12 md:col-6 p-0">
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name="ColaboradorId"
                        label='Por'
                        options={funcionario}
                        optionLabel='Nome'
                        optionValue='FuncionarioId'
                        disabled={showDelete}
                        tarefa={tarefa}
                      />
                    </div>
                  </div>
                </SCFieldset>
              </div>

              <SCFieldset legend='Como tomou conhecimento da escola' className='col-12 mt-2 p-0'>
                <div className='flex-collunm md:flex justify-content-evenly'>
                  <SCCheckbox
                    name='ConhecimentoIndicacao'
                    control={control}
                    label='Indicação'
                    disabled={showDelete}
                    value={false}
                  />

                  <SCCheckbox
                    name='ConhecimentoTV'
                    control={control}
                    label='Televisão'
                    disabled={showDelete}
                    value={false}
                  />

                  <SCCheckbox
                    name='ConhecimentoJornal'
                    control={control}
                    label='Jornal'
                    disabled={showDelete}
                    value={false}
                  />

                  <SCCheckbox
                    name='ConhecimentoInternet'
                    control={control}
                    label='Internet'
                    disabled={showDelete}
                    value={false}
                  />

                  <SCCheckbox
                    name='ConhecimentoOutdoor'
                    control={control}
                    label='Outdoor'
                    disabled={showDelete}
                    value={false}
                  />

                  <SCCheckbox
                    name='ConhecimentoOutros'
                    control={control}
                    label='Outros'
                    disabled={showDelete}
                    value={false}
                  />
                </div>
              </SCFieldset>
            </TabPanel>

            <TabPanel header="Endereço">
              <SCFieldset legend='Endereço' className='col-12 mt-3'>
                <FormEndereco matricula={watchVisitantesId} tipo='VISITANTE' />
              </SCFieldset>
            </TabPanel>

            <TabPanel header="Observações">
              <SCFieldset legend='Observação' className='col-12 mt-3'>
                <div className='grid'>
                  <div className="col-12">
                    <SCInputTextarea
                      style={{ minHeight: 300 }}
                      control={control}
                      errors={errors}
                      name="Observacao"
                      //label='Observação'
                      disabled={showDelete}
                      rows={10}
                    />
                  </div>
                </div>
              </SCFieldset>
            </TabPanel>
          </TabView>

          {showDelete ? (
            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
              <Button label="Confirmar Exclusão" icon="pi pi-check"
                className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                onClick={deletar} type='button' />
            </div>
          ) : (
            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
              <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
            </div>
          )}

        </form>
      </FormProvider >
    </>
  );
};

export default VisitantesForm;
