import React, { } from 'react';
import { useFormContext } from 'react-hook-form';

import { SCCalendar, SCCheckbox, SCDropdown, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { GrUser, GrUserFemale } from "react-icons/gr";
import FormEndereco from '../components/FormEndereco';
import SelectEstadoCivil from '../components/SelectEstadoCivil';


type Props = any;

const AlunosFormNomeSocial: React.FC<Props> = ({ showDelete, matricula, ...props }) => {

  const toast = useToast();

  const { control, formState: { errors }, getValues, setValue, watch } = useFormContext();

  return (
    <div className='col-12' >
      <div className='grid'>
        <div className="col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="NomeSocial"
            label='Nome Social'
            disabled={showDelete}
            maxLength={60}
          />
        </div>        
      </div>
      <Divider align="left">
        <div className="inline-flex align-items-center">
            <i className="pi pi-flag-fill mr-2"></i>
            <b>Filiação 1</b>
        </div>
      </Divider>
      <div className='grid'>
        <div className="col-6 flex flex-row">
          <SCCheckbox control={control} name='TipoFiliacao1' label='Pai' trueValue={1} />
          <SCCheckbox control={control} style={{marginLeft: 10}} name='TipoFiliacao1' label='Mãe' trueValue={2} />
          <SCCheckbox control={control} style={{marginLeft: 10}} name='TipoFiliacao1' label='Não declarado' trueValue={3} />
        </div>        
      </div>
      <div className='grid'>
        <div className="col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Filiacao1"
            label=''
            disabled={showDelete}
            maxLength={60}
          />
        </div>        
      </div>

      <Divider align="left">
        <div className="inline-flex align-items-center">
            <i className="pi pi-flag-fill mr-2"></i>
            <b>Filiação 2</b>
        </div>
      </Divider>
      <div className='grid'>
        <div className="col-6 flex flex-row">
          <SCCheckbox name='TipoFiliacao2' label='Pai' trueValue={1} />
          <SCCheckbox style={{marginLeft: 10}} name='TipoFiliacao1' label='Mãe' trueValue={2} />
          <SCCheckbox style={{marginLeft: 10}} name='TipoFiliacao1' label='Não declarado' trueValue={3} />
        </div>        
      </div>
      <div className='grid'>
        <div className="col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Filiacao2"
            label=''
            disabled={showDelete}
            maxLength={60}
          />
        </div>        
      </div>
    </div>
  );
};

export default AlunosFormNomeSocial;
