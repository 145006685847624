import React, { useEffect, useRef, useState } from 'react';

import { Tooltip } from 'primereact/tooltip';
import AppTopbar from './AppTopbar';
import MenuService from '../services/MenuService';

import { classNames } from 'primereact/utils';
import AppMenu from './AppMenu';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { useAuth } from '../providers/auth';
import { Outlet, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { NavLink } from 'react-router-dom';

const MainLayout = () => {
    
    const [layoutMode] = useState('static');
    const [overlayMenuActive, setOverlayMenuActive] = useState<any>(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState<any>(false);
    const [inlineUser] = useState<any>(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState<any>(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState<any>(null);
    const [rightPanelMenuActive, setRightPanelMenuActive] = useState<any>(null);
    const [menuActive, setMenuActive] = useState<any>(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState<any>(false);
    const [menu, setMenu] = useState<any>([]);
    const [loadMenu, setLoadMenu] = useState<any>([])
    const copyTooltipRef = useRef<any>();
    const layoutClassName = classNames('layout-wrapper', 'layout-static', 'layout-topbar-blue', 'layout-menu-light', 'p-input-filled','p-ripple-disabled', 
        {'layout-static-inactive': staticMenuDesktopInactive, 'layout-mobile-active': staticMenuMobileActive},
    );

    
    const navigate = useNavigate();
    const menuService = new MenuService();

    const { loading, isLoged, periodoSelecionado, empresaSelecionada } = useAuth();
    
    if (!isLoged)
        navigate('/');

    if (!periodoSelecionado)
        navigate('/Periodos');

    let topbarItemClick: any;
    let menuClick: any;
    let rightMenuClick: any;

    useEffect(() => {
        async function load(){
            setLoadMenu(true);
            const menu_ = await menuService.getSistemMenu(2);
            setMenu(menu_);
            setLoadMenu(false);
        }
        load()
    }, []);

    const isDesktop = () => {
        return window.innerWidth > 896;
    };

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };
    
    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightPanelMenuActive(false);
        }        

        if (!menuClick) {
            if (isHorizontal()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }
       
        topbarItemClick = false;
        menuClick = false;
        rightMenuClick = false;
    };


    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onMenuButtonClick = (event: any) => {
        menuClick = true;
        setTopbarMenuActive(false);
        setRightPanelMenuActive(false);

        if (isDesktop()) setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive: any) => !prevStaticMenuDesktopInactive);
        else {
            setStaticMenuMobileActive((prevStaticMenuMobileActive: any) => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }

        event.preventDefault();
    };

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && isHorizontal()) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevMenuActive: any) => !prevMenuActive);
    };

    const onTopbarMenuButtonClick = (event: any) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevTopbarMenuActive: any) => !prevTopbarMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;

        if (activeTopbarItem === event.item) setActiveTopbarItem(null);
        else setActiveTopbarItem(event.item);

        event.originalEvent.preventDefault();
    };

    const onMenuClick = () => {
        menuClick = true;
    };
   
    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    
    if (loading || !isLoged)
        return <Loading />
    
    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar
                topbarMenuActive={topbarMenuActive}
                activeTopbarItem={activeTopbarItem}
                inlineUser={inlineUser}
                onMenuButtonClick={onMenuButtonClick}
                onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                onTopbarItemClick={onTopbarItemClick}
            />

            {/* <AppRightMenu rightPanelMenuActive={rightPanelMenuActive} ></AppRightMenu> */}

            <div className="layout-menu-container " onClick={onMenuClick}>
                <div className='flex p-2 flex-column'>
                    <div className='flex flex-column align-items-center justify-content-center '>
                        <img src={empresaSelecionada?.Logo} style={{maxWidth: 120}} alt={`Logo ${empresaSelecionada?.IDUnidade}`}/>                    
                        <div className='flex flex-column w-full'>
                            <div className='text-lg p-text-nowrap p-text-truncate w-full p-text-bold text-center'>{empresaSelecionada?.IDUnidade}</div>
                            <div className='flex align-items-center justify-content-center'>
                                <span className='text-xl'>{periodoSelecionado?.Descricao}</span>
                                <NavLink className={'p-button p-button-sm p-button-info p-button-outlined ml-2'} to='/Periodos'>
                                    <i className="ph ph-bold ph-swap mr-2"></i>TROCAR
                                </NavLink>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {loadMenu ? (
                    <Loading />
                ):(
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} layoutMode={'static'} active={menuActive} mobileMenuActive={staticMenuMobileActive} />
                )}
            </div>

            <div className="layout-main">
                <div className="layout-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
