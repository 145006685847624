import { Divider } from 'primereact/divider';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCalendar, SCDropdown, SCFieldset, SCInputMask, SCInputText, SCInputTextarea } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, SITUACAO_ALUNO } from '../utilities/constantes';
import { useAuth } from '../providers/auth';
import IAlunosSituacao from '../interfaces/IAlunosSituacao';
import { CalendarChangeParams } from 'primereact/calendar';

interface Props {
  aluno?: IAlunosSituacao;
  setAlunoDialog(param: boolean): void;
  reload(): void;
}

const AlunoSituacaoForm: React.FC<Props> = ({ setAlunoDialog, aluno, reload }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'FormaIngressoForm';

  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();

  const defaultValues: IAlunosSituacao = aluno || {
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Matricula: 0,
    SituacaoAcademica: '',
    SituacaoData: new Date(''),
    SituacaoMotivo: ''
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const onSubmit = async (data: any) => {
    const horaFormatada = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    try {
      const situacao = {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: data?.Matricula,
        Situacao: data?.SituacaoAcademica,
        AlteracaoData: new Date(),
        AlteracaoHora: horaFormatada.toString(),
        AlteracaoOperador: usuario?.Nome
      }

      const resp = await api.post('/alunosSituacao/createSituacao', situacao);
      const idSituacao = resp.data.AlunosSituacaoId[0];

      await api.put('/alunosPeriodo/atualizaAluno', {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: data?.Matricula,
        SituacaoAcademica: data?.SituacaoAcademica,
        SituacaoData: data?.SituacaoData,
        SituacaoMotivo: data?.SituacaoMotivo,
        AlunosSituacaoId: idSituacao,
      })

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setAlunoDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const sitAluno = SITUACAO_ALUNO.filter(item => item.value !== 'R');

  const cancelar = (e: any) => {
    setAlunoDialog(false);
    reset();
  }

  useEffect(() => {
    dadosSituacao()
    setValue('SituacaoData', new Date());
  }, [setValue]);

  const dadosSituacao = async () => {
    const resp = await api.get('/alunosSituacao', {
      params: {
        Matricula: aluno?.Matricula
      }
    })
    const timestamp = resp.data.AlteracaoData;
    if (timestamp) {
      const data = new Date(timestamp);
      const dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
      setValue('AlteracaoDataString', dataFormatada);
    }
    setValue('AlteracaoHora', resp.data.AlteracaoHora)
    setValue('AlteracaoOperador', resp.data.AlteracaoOperador)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-6">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Nome"
            label='Nome'
            maxLength={60}
            disabled={true}
            tarefa={tarefa}
          />
        </div>

        <div className="col-6 md:col-2">
          <SCInputText
            control={control}
            errors={errors}
            name="Matricula"
            label='Matrícula'
            maxLength={8}
            disabled={false}
            readOnly={true}
            type='number'
            style={{ fontWeight: "bolder", backgroundColor: "#0f97c7", color: "#fff" }}
          />
        </div>

        <div className='grid col-12'>
          <div className="col-12 md:col-3">
            <SCDropdown
              control={control}
              name="SituacaoAcademica"
              label='Situação'
              options={sitAluno}
              optionLabel='descricao'
              required={true}
            />
          </div>

          <div className="col-12 md:col-2">
            <SCCalendar
              control={control}
              errors={errors}
              name="SituacaoData"
              label='Data de Alteração'
              dateFormat='dd/mm/yy'
              mask='99/99/9999'
              required={true}
            />
          </div>
        </div>

        <div className="col-12 md:col-12">
          <SCInputTextarea
            control={control}
            errors={errors}
            name="SituacaoMotivo"
            label='Motivo / Estabelecimento de Destino'
            rows={10}
            required={true}
          />
        </div>

        <SCFieldset legend='Última Alteração' className='col-12 mt-1'>
          <div className="grid">
            <div className='col-12 md:col-2'>
              <SCInputMask
                errors={errors}
                control={control}
                name='AlteracaoDataString'
                label='Data'
                mask='99/99/9999'
                disabled
              />
            </div>
            <div className='col-12 md:col-2'>
              <SCInputMask
                errors={errors}
                control={control}
                name='AlteracaoHora'
                label='Hora'
                mask='99:99'
                disabled
              />
            </div>
            <div className='col-12 md:col-4'>
              <SCInputText
                control={control}
                name='AlteracaoOperador'
                label='Operador'
                value={aluno?.AlteracaoOperador?.toString()}
                disabled
              />
            </div>
          </div>
        </SCFieldset>

        <Divider />

        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
          <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
        </div>

      </div>

    </form >

  );
};

export default AlunoSituacaoForm;
