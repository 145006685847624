import { IAlunoForm, IAlunoPost } from "../interfaces/IAlunos";
import { IAlunosPeriodo } from "../interfaces/IAlunosPeriodo";

import util from "./util";

function updateFieldsFromObject(obj1: any, obj2: any): void {
    for (const key in obj1) {
        if (obj2.hasOwnProperty(key)) {
            obj1[key] = obj2[key];
        }
    }
}

export function loadDadosAlunos(dados: IAlunoForm): IAlunoPost {
    
    let alunoPost: IAlunoPost = {
        AlunoId: null,
        EmpresaId: null,
        Ano: null,
        Sequencial: null,
        Nome: null,
        Matricula: null,
        DataCadastro: null,
        HoraCadastro: null,
        DataNascimento: null,
        Sexo: null,
        EstadoCivil: null,
        Nacionalidade: null,
        Natural: null,
        LocalNascimento: null,
        Religiao: null,
        DDD: null,
        FoneCelular: null,
        FoneOutro: null,
        Contato: null,
        Obs: null,
        Esporte1: null,
        Esporte2: null,
        CPF: null,
        RG: null,
        RGOrgaoExpeditorUF: null,
        Email: null,
        Senha: null,
        UltimoEstabelecimento: null,
        UltimoNivelSequenciaId: null,
        UltimoEndereco: null,
        UltimoBairro: null,
        UltimoComplemento: null,
        UltimaCidade: null,
        UltimaUF: null,
        UltimoCep: null,
        UltimoFone: null,
        UltimoContato: null,
        UltimoCargo: null,
        UltimoMotivoSaida: null,
        UltimoStatus: null,
        Pai: null,
        PaiEmail: null,
        PaiDataNascimento: null,
        PaiDDD: null,
        PaiFoneOutro: null,
        PaiFoneCelular: null,
        PaiProfissao: null,
        PaiEndereco: null,
        PaiRG: null,
        PaiRGOrgaoExpeditorUF: null,
        PaiCPF: null,
        PaiEmpresa: null,
        PaiBairro: null,
        PaiCidade: null,
        PaiUF: null,
        PaiCep: null,
        PaiComplemento: null,
        PaiEstadoCivil: null,
        PaiNacionalidade: null,
        Mae: null,
        MaeEmail: null,
        MaeDataNascimento: null,
        MaeDDD: null,
        MaeFoneOutro: null,
        MaeFoneCelular: null,
        MaeProfissao: null,
        MaeEndereco: null,
        MaeRG: null,
        MaeRGOrgaoExpeditorUF: null,
        MaeCPF: null,
        MaeEmpresa: null,
        MaeBairro: null,
        MaeCidade: null,
        MaeUF: null,
        MaeCep: null,
        MaeComplemento: null,
        MaeEstadoCivil: null,
        MaeNacionalidade: null,
        PaisEstadoCivil: null,
        MoramJuntos: 'S',
        ReacaoFilho: null,
        DiaPadraoVencimento: null,
        CodigoBanco: null,
        CodigoAgencia: null,
        Conta: null,
        TipoConta: null,
        Carteirinha: null,
        ValidadeCarteirinha: null,
        FormaOrigem: null,
        AnoConclusao: null,
        SituacaoBiblioteca: null,
        DataSituacaoBiblioteca: null,
        ObsSituacaoBiblioteca: null,
        Cor: null,
        DataConclusao: null,
        NumeroRegistroOcorrencia: null,
        CertidaoNascimentoRegistro: null,
        CertidaoNascimentoFolha: null,
        CertidaoNascimentoLivro: null,
        CertidaoNascimentoCartorio: null,
        CertidaoNascimentoLocal: null,
        CertidaoNascimentoData: null,
        cartorio: null,
        IdInep: null,
        Nis: null,
        NomeSocial: null,
        Filiacao1: null,
        TipoFiliacao1: null,
        Filiacao2: null,
        TipoFiliacao2: null,
        CertificadoRegistro: null,
        CertificadoFolha: null,
        CertificadoLivro: null,
        BoletoDigital: false,
        SaidaLivre: false,
        CartaoRFID: null,
        DiplomaDataRegistro: null,
        DiplomaNumeroExpedicao: null,
        DiplomaNumeroRegistro: null,
        DiplomaIdDataDOU: null,
        DiplomaIdObs: null,
        Falecido: false,
        DataDeferimento: undefined,
    }
    updateFieldsFromObject(alunoPost, dados);
    alunoPost = util.transformStringsToUppercase(alunoPost);
    return alunoPost;

}

export function loadAlunosPeriodo(dados: any): IAlunosPeriodo {

    let novoAlunoPeriodo: IAlunosPeriodo = {
        AlunosPeriodoId: 0,
        EmpresaId: null,
        Ano: null,
        Sequencial: null,
        Matricula: null,
        Isento: false,
        AlineaLei: null,
        TurmaCodigo: null,
        NumeroOrdem: null,
        DataMatricula: null,
        PlanoPagamentoId: null,
        ObservacaoPagamento: null,
        ConvenioId: null,
        SituacaoAcademica: null,
        SituacaoFinal: null,
        SituacaoMotivo: '',
        SituacaoData: null,
        EmiteCarne: null,
        LocalPagamento: null,
        ParcelaMatricula: null,
        ObservacaoEF: null,
        MeiaPassagem: null,
        FormaIngresso: null,
        ValorContrato: null,
        PrimeiraParcelaContrato: null,
        DescontoPrimeiraContrato: null,
        ParcelaContrato: null,
        ValorParcelaContrato: null,
        ValorDescontoContrato: null,
        RetornoMatricula: false,
        Repetente: false,
        CodigoMensagemBanco: null,
        ResultadoAvaliacao: null,
        Formado: false,
        ConselhoClasseData: null,
        ConselhoClasseHora: null,
        ConselhoClasseOperador: null,
        Ouvinte: false,
        MatriculaCondicionada: null,
        Concluiu: false,
        ParcelaExtraInicio: null,
        ParcelaExtraFim: null,
        ParcelaExtraValor: null,
        ParcelaExtraDesconto: null,
        DataLiberaMobile: null,
        OperadorLiberaMobile: null,
        DataCarteira: null,
        HoraCarteira: null,
        OperadorCarteira: null,
        SequencialBanco: null,
        NivelSequenciaId: null,
        TipoBoleto: null,
        DataAtualizacaoCadastro: null,
        ItinerarioFormativoId: null,
        MatriculaEspecial: false,
      };
      
      updateFieldsFromObject(novoAlunoPeriodo, dados);
      novoAlunoPeriodo = util.transformStringsToUppercase(novoAlunoPeriodo);

    return novoAlunoPeriodo;
}