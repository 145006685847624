import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCheckbox, SCDropdown, SCFieldset, SCInputNumber, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, TURNOS, TIPO_FREQUENCIA_CONTROLE, CALCULA_FALTA_BASEADO, MODELO_CONTROLE_FALTAS, TIPO_LANCAMENTO_FALTAS } from '../utilities/constantes';
import util from '../utilities/util';
import ITurmaRegular from '../interfaces/ITurmas';
import { useAuth } from '../providers/auth';
import Loading from '../components/Loading';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputNumberChangeParams } from 'primereact/inputnumber';
import axios from 'axios';
import config from '../utilities/config';
import ITurmaAuxiliar from '../interfaces/ITurmaAuxiliar';

interface Props {
  turmaAuxiliar?: ITurmaAuxiliar;
  novoComBase?: any;
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
}

const TurmaAuxiliarForm: React.FC<Props> = ({ setShowDialog, turmaAuxiliar, novoComBase, reload, deletar, showDelete, setShowDelete }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'TurmaRegularForm';
  const toast = useToast();

  const { periodoSelecionado } = useAuth()

  const [loading, setLoading] = useState(false);

  const defaultValues: ITurmaAuxiliar = turmaAuxiliar || {
    TurmaAuxiliarId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Codigo: '',
    Descricao: '',
    VagasPrevistas: 0,
    TurmaEFis: false,
    ModeloDiarioClasse: '',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, reset, setError, clearErrors, getValues, setValue, watch } = methods;

  async function load() {

  }

  useEffect(() => {
    load()
  }, [])

  const onSubmit = async (data: ITurmaAuxiliar) => {
    let resp: any = undefined;

    try {

      if (novoComBase) {
        data.TurmaAuxiliarId = undefined;
      }

      // Transformando para caixa alta 
      data.Codigo = data.Codigo?.toUpperCase();
      data.Descricao = data.Descricao?.toUpperCase();

      const dados = getValues();
      if (dados.VagasPrevistas !== undefined) {
        let vagas = parseInt(String(dados.VagasPrevistas), 10);
        console.log(vagas)

        if (vagas <= 0) {
          setError('VagasPrevistas', { message: 'valor inválido', type: 'manual' });
          return false;
        }
      } else {
        setError('VagasPrevistas', { message: 'campo obrigatório', type: 'manual' });
        return false;
      }

      if (turmaAuxiliar?.TurmaAuxiliarId) {
        resp = await api.put('/turmaAuxiliar', data);
      } else {
        resp = await api.post('/turmaAuxiliar', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setShowDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setShowDelete(false);
    setShowDialog(false);
    reset();
    if (novoComBase) {
      reload();
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCInputText
            control={control}
            errors={errors}
            name="Codigo"
            label='Código'
            maxLength={10}
            required
            disabled={showDelete || turmaAuxiliar?.TurmaAuxiliarId !== undefined}
            tooltip='Máximo de 10 caracteres'
            tooltipOptions={{ event: 'focus' }}
            style={{ textTransform: 'uppercase' }}
          />
        </div>
        <div className="col-12 md:col-6">
          <SCInputText
            control={control}
            errors={errors}
            name="Descricao"
            label='Descrição'
            maxLength={60}
            required
            disabled={showDelete}
            tooltip='Máximo de 60 caracteres'
            tooltipOptions={{ event: 'focus' }}
            style={{ textTransform: 'uppercase' }}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-3 md:col-1">
          <SCInputText
            control={control}
            errors={errors}
            name="VagasPrevistas"
            label='Vagas'
            maxLength={3}
            disabled={showDelete}
            numberOnly
            required
          />
        </div>
      </div>
      <div className='grid pt-3'>
        <div className="col-4">
          <SCCheckbox
            control={control}
            name="TurmaEncerrada"
            label='Turma Encerrada'
            disabled={showDelete}
            tarefa={tarefa}
          />
        </div>
      </div>
      <div className='grid pt-3'>
        <div className="col-4">
          <SCCheckbox
            control={control}
            name="TurmaEFis"
            label='Turma Educação Física'
            disabled={showDelete}
            tarefa={tarefa}
          />
        </div>
      </div>
      <div className='grid pt-3'>
        <div className='col-12 md:col-4'>
          <SCDropdown
            label='Modelo de Diário de Classe Padrão'
          />
        </div>
      </div>

      <Divider />
      {showDelete ? (
        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
          <Button label="Confirmar Exclusão" icon="pi pi-check"
            className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
            onClick={deletar} type='button' />
        </div>
      ) : (
        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
          <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
        </div>
      )}
    </form >

  );
};

export default TurmaAuxiliarForm;
