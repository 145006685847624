import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IParcelaExtra from '../interfaces/IParcelaExtra';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import ParcelaExtraForm from './ParcelaExtraForm';
import AjudaFormTela from './AjudaFormTela';
import { set } from 'date-fns';
import Loading from '../components/Loading';
import { IoMdCheckboxOutline } from 'react-icons/io';


const ParcelaExtra = () => {
  const toast = useToast();
  const tarefa = 'ParcelaExtra';

  let emptyParcelaExtra: IParcelaExtra = {
    ParcelaExtraId: undefined,
    RegistraFicha: false,
    MatriculaParcelada: false,
    ImpostoRenda: false,
    NegociacaoDebito: false,
    Dependencia: false,
    Descricao: '',
    Desativada: false,
  };

  const [parcelasExtra, setParcelasExtra] = useState<any>(null);
  const [parcelaExtraDialog, setParcelaExtraDialog] = useState<boolean>(false);
  const [deleteParcelaExtra, setDeleteParcelaExtra] = useState<boolean>(false);
  const [parcelaExtra, setParcelaExtra] = useState<IParcelaExtra>(emptyParcelaExtra);
  const [selectedParcelaExtra, setSelectedParcelaExtra] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [novoComBase, setNovoComBase] = useState<any>()

  const [contas, setContas] = useState<any>(null);

  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyParcelaExtra });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/parcelaExtra/list', { params: { Descricao: getValues()?.Descricao } });
    if (result.data) {
      setParcelasExtra(result.data);
      setLoading(false);
    } else {
      setParcelasExtra([]);
      setLoading(false);
    }
  }

  const clearState = () => {
    dt?.current?.reset();
  }


  useEffect(() => {
    load().finally(() => clearState());
  }, []);


  const openNew = () => {
    setParcelaExtra(emptyParcelaExtra);
    setSubmitted(false);
    setParcelaExtraDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setParcelaExtraDialog(false);
  };
  const editar = (item: any) => {
    console.log(item)
    setParcelaExtra(item);
    setParcelaExtraDialog(true);
  };

  const confirmDelete = (item: any) => {
    setParcelaExtra(item);
    setDeleteParcelaExtra(true);
    setParcelaExtraDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/parcelaExtra', { params: { ParcelaExtraId: parcelaExtra.ParcelaExtraId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteParcelaExtra(false);
      setParcelaExtraDialog(false);
      setParcelaExtra(emptyParcelaExtra);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const novoComBaseNesse = (item: any) => {
    setNovoComBase(true)
    setParcelaExtra(item)
    setParcelaExtraDialog(true)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Parcela Extra</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={parcelasExtra}
              onSelectionChange={(e) => setSelectedParcelaExtra(e.value)}
              dataKey="id"
              stateKey='tabParcelaExtra'
              stateStorage='local'
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Parcela" header="Parcela" sortable headerStyle={{ width: 92 }}></Column>
              <Column field="ParcelaDescricao" header="Descrição" sortable ></Column>

              <Column field="RegistraFicha" header="Ficha" body={(rowData: any) => {
                if (rowData.RegistraFicha === true) {
                  return (
                    <p>Sim</p>
                  );
                } else {
                  return (
                    <p>Não</p>
                  );
                }
              }} ></Column>
              <Column field="MatriculaParcelada" header="Mat. Par." body={(rowData: any) => {
                if (rowData.MatriculaParcelada === true) {
                  return (
                    <p>Sim</p>
                  );
                } else {
                  return (
                    <p>Não</p>
                  );
                }
              }} ></Column>
              <Column field="ImpostoRenda" header="IR" body={(rowData: any) => {
                if (rowData.ImpostoRenda === true) {
                  return (
                    <p>Sim</p>
                  );
                } else {
                  return (
                    <p>Não</p>
                  );
                }
              }} ></Column>
              <Column field="NegociacaoDebito" header="Neg. Déb." body={(rowData: any) => {
                if (rowData.NegociacaoDebito === true) {
                  return (
                    <p>Sim</p>
                  );
                } else {
                  return (
                    <p>Não</p>
                  );
                }
              }} ></Column>

              <Column field="Dependencia" header="Dep." body={(rowData: any) => {
                if (rowData.Dependencia === true) {
                  return (
                    <p>Sim</p>
                  );
                } else {
                  return (
                    <p>Não</p>
                  );
                }
              }} ></Column>
              <Column field="PLConta" header="Conta" ></Column>
              <Column bodyStyle={{ width: 60 }} field="Desativada" header="Status" align={'center'} alignHeader={'center'} sortable body={(rowData: any) => {
                return (
                  <IoMdCheckboxOutline size={20} color={rowData.Desativada ? 'red' : "#01902f"} />
                )
              }} ></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button label="Novo Com Base Nesse" icon="pi pi-plus" className="p-button w-auto p-button-success" onClick={() => novoComBaseNesse(rowData)} type='button' />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 280 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={parcelaExtraDialog} style={{ width: util.isDesktop() ? '60%' : '100%' }} closable={!util.isDesktop()}
            header={`${parcelaExtra.ParcelaExtraId === undefined ? 'Cadastrando ' : (deleteParcelaExtra ? 'Excluíndo' : 'Alterando')} Parcela Extra`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <ParcelaExtraForm
              setParcelaExtraDialog={setParcelaExtraDialog}
              parcelaExtra={parcelaExtra}
              reload={load}
              deleteParcelaExtra={deleteParcelaExtra}
              setDeleteParcelaExtra={setDeleteParcelaExtra}
              deletar={deletar}
              setNovoComBase={setNovoComBase}
              novoComBase={novoComBase}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default ParcelaExtra;
