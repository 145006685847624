import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCDropdown, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IMateria from '../interfaces/IMateria';
import IDisciplina from '../interfaces/IDisciplina';

interface Props {
  registro?: IMateria;
  setCadastroDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteRegistro: boolean;
  setDeleteRegistro(param: boolean): void;
}

const MateriaForm: React.FC<Props> = ({ setCadastroDialog, registro, reload, deletar, deleteRegistro, setDeleteRegistro }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'MateriaForm';

  const toast = useToast();
  const [disciplinas, setDisciplinas] = useState<IDisciplina[]>([]);

  const defaultValues: IMateria = registro || {
    MateriaId: undefined,
    DisciplinaId: undefined,
    Descricao: '',
    Codigo: '',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  useEffect(() => {
    async function load() {
      const result = await api.get('/disciplina/combo', { params: { Descricao: '' } });
      setDisciplinas(result.data);
      console.log(result.data)
    }

    load()
  }, [])

  const onSubmit = async (data: IMateria) => {

    const pesquisaCodigoDisciplina = disciplinas?.find((d: any) => d.Codigo === data?.Codigo);

    if (pesquisaCodigoDisciplina) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Código da Matéria é igual ao Código da Disciplina' });
      return false
    }

    let resp: any = undefined;
    try {
      if (data.MateriaId === undefined)
        resp = await api.post('/materia', data);
      else
        resp = await api.put('/materia', data);

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setCadastroDialog(false);
    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setDeleteRegistro(false);
    setCadastroDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-6">
          <SCDropdown
            autoFocus={true}
            control={control}
            errors={errors}
            name="DisciplinaId"
            label='Disciplina'
            options={disciplinas}
            optionLabel='CodigoDescricao'
            optionValue='DisciplinaId'
            disabled={deleteRegistro || registro?.MateriaId !== undefined}
            tarefa={tarefa}
            required={!deleteRegistro}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-3 md:col-2">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código da Matéria'
            required={!deleteRegistro}
            disabled={registro?.MateriaId !== undefined}
            tarefa={tarefa}
            maxLength={6}
            tooltip='Máximo de 6 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />

        </div>
      </div>
      <div className='grid'>
        <div className="col-12 md:col-6">
          <SCInputText
            control={control}
            errors={errors}
            name="Descricao"
            label='Descrição da Matéria'
            minLength={3}
            required={!deleteRegistro}
            disabled={deleteRegistro}
            tarefa={tarefa}
            tooltip='Máximo de 60 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>


        <Divider />

        {deleteRegistro ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" type='button' onClick={cancelar} />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>
    </form>

  );
};

export default MateriaForm;
