import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import PlanoPagamentoForm from './PlanoPagamentoForm';
import IPlanoPagamento from '../interfaces/IPlanoPagamento';
import OfertaDisciplinaForm from './OfertaDisciplinaForm';
import OfertaDisciplinaMateriaForm from './OfertaDisciplinaMateriaForm';
import { set } from 'date-fns';
import OfertaDisciplinaMateriaProfessorForm from './OfertaDisciplinaMateriaProfessorForm';
import IOfertaDisciplina from '../interfaces/IOfertaDisciplina';


const OfertaDisciplina = () => {
  const toast = useToast();
  const { usuario, periodoSelecionado } = useAuth();
  const tarefa = 'OfertaDisciplina';

  let emptyData: IOfertaDisciplina = {};

  const [lista, setLista] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [registro, setRegistro] = useState<any>(emptyData);
  const [selectedRegistro, setSelectedRegistro] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);
  const [showForm, setShowForm] = useState<string>('DIS');

  const methods = useForm({ defaultValues: emptyData });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/ofertaDisciplina/list', {
      params: {
        Ano: periodoSelecionado?.Ano,
        EmpresaId: periodoSelecionado?.EmpresaId,
        Sequencial: periodoSelecionado?.Sequencial,
        Descricao: getValues().Descricao,
      }
    });

    if (result.data)
      setLista(result.data);
    else
      setLista([]);

    setLoading(false);
  }

  const clearState = () => {
    dt?.current?.reset();      
  }

  useEffect(() => {
    load().finally(() => clearState());
  }, []);

  const openNew = (codigo: string) => {
    emptyData = { ...emptyData, TurmaCodigo: codigo };
    setRegistro(emptyData);
    setSubmitted(false);
    setShowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setShowDialog(false);
  };

  const editar = (reg: any) => {
    setRegistro(reg);
    setShowForm('DIS');
    setShowDialog(true);
  };

  const editarMaterias = (reg: any) => {
    setRegistro(reg);
    setShowForm('MAT');
    setShowDialog(true);
  };

  const editarProfessores = (reg: any) => {
    setRegistro(reg);
    setShowForm('PROF');
    setShowDialog(true);
  };

  const confirmDelete = (reg: any) => {
    setRegistro(reg);
    setShowDelete(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/materia', { params: { PlanoPagamentoId: registro.PlanosPagamentoId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setShowDialog(false);
      setRegistro(emptyData);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const renderForm = () => {
    switch (showForm) {
      case 'DIS':
        return <OfertaDisciplinaForm
          setShowDialog={setShowDialog}
          registro={registro}
          reload={load}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          deletar={deletar}
        />
      case 'MAT':
        return <OfertaDisciplinaMateriaForm
          setShowDialog={setShowDialog}
          registro={registro}
          reload={load}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          deletar={deletar}
        />
      default:
        return <OfertaDisciplinaMateriaProfessorForm
          setShowDialog={setShowDialog}
          registro={registro}
          reload={load}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          deletar={deletar}
        />
    }
  }

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Oferta de Disciplinas / Matérias
            </h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição da Turma'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                {/* <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} /> */}

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={lista}
              onSelectionChange={(e) => setSelectedRegistro(e.value)}
              dataKey="id"
              stateKey='tabOferta'
              stateStorage='local'
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll"
            >
              <Column header="Código" field='Codigo' bodyStyle={{ width: 90 }} sortable />
              <Column header="Descricao" field='Descricao' sortable />
              <Column header="CHR Total" field='CargaHorariaTotal' bodyStyle={{ width: 60 }} />
              <Column header='Disciplinas' body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-center">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => rowData.OfertaDisciplinaId ? editar(rowData) : openNew(rowData.Codigo)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
              <Column header='Matérias' body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-center">
                    <Button icon="pi pi-book" className="p-button-rounded p-button-info" onClick={() => editarMaterias(rowData)} />
                  </div>
                );
              }} style={{ width: 60 }}
              />
              <Column header='Professores' body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-center">
                    <Button icon="pi pi-users" className="p-button-rounded p-button-info" onClick={() => editarProfessores(rowData)} />
                  </div>
                );
              }} style={{ width: 60 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${registro.OfertaDisciplinaId === undefined ? 'Cadastrando ' : (showDelete ? 'Excluíndo' : 'Alterando')} Oferta de ${showForm === 'DIS' ? 'Disciplina' : showForm === 'MAT' ? 'Matéria' : 'Professor'}`}
            modal className="p-fluid" onHide={hideDialog}
          >
            {renderForm()}
          </SCDialog>


        </div>
      </div>
    </div>
  );
};

export default OfertaDisciplina;
