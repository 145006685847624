import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, AVALIACAO, FEZ_AVALIACAO, MESES } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDropdown, SCFieldset, SCInputText } from '../components';
import AjudaFormTela from './AjudaFormTela';
import Disciplina from './Disciplina';
import { InputText } from 'primereact/inputtext';
import IInstrumentoAvaliacao from '../interfaces/IInstrumentoAvaliacao';
import { isTime } from 'validator';


const InstrumentoAvaliacao = () => {
    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const tarefa = 'FuncionarioForm';

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [turmas, setTurmas] = useState<any>(null);
    const [turma, setTurma] = useState<any>(null);
    const [disciplinas, setDisciplinas] = useState<any>(null);
    const [disciplina, setDisciplina] = useState<any>(null);
    const [materias, setMaterias] = useState<any>(null);
    const [materia, setMateria] = useState<any>(null);
    const [instrumentoAvaliacao, setInstrumentoAvaliacao] = useState<any>();
    const [avaliacao, setAvaliacao] = useState<any>();
    const [formula, setFormula] = useState<string>();
    const [fezAvaliacao, setFezAvaliacao] = useState<any>();
    const [cabecalhoDesabilitado, setCabecalhoDesabilitado] = useState<boolean>(true);
    const [materiaObrigatoria, setMateriaObrigatoria] = useState<boolean>(true);


    const [cabecalho1, setCabecalho1] = useState<string>('');
    const [cabecalho2, setCabecalho2] = useState<string>('');
    const [cabecalho3, setCabecalho3] = useState<string>('');
    const [cabecalho4, setCabecalho4] = useState<string>('');
    const [cabecalho5, setCabecalho5] = useState<string>('');
    const [cabecalho6, setCabecalho6] = useState<string>('');

    const dt = useRef<any>(null);

    const methods = useForm();
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

    const load = async () => {

        const turma = await api.get('/turmas', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        })
        if (turma.data) {
            setTurmas(turma.data);
        } else {
            setTurmas([]);
        }
    }

    useEffect(() => {
        load();
    }, []);

    const handleTurmas = async (e: any) => {
        setInstrumentoAvaliacao('');
        setDisciplina('');
        setMateria('');
        setTurma(e.target.value)

        let listDisciplina;
        if (e.target.value) {
            listDisciplina = await api.get('/ofertaDisciplina', {
                params: {
                    TurmaCodigo: e.target.value,
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Ano: periodoSelecionado?.Ano,
                    Sequencial: periodoSelecionado?.Sequencial,
                }
            })
        }
        if (listDisciplina?.data.length > 0) {
            setDisciplinas(listDisciplina?.data)
        } else {
            setDisciplinas([])
        }
    }

    const handleDisciplina = async (e: any) => {
        setInstrumentoAvaliacao('');
        setMateria('');
        setDisciplina(e.target.value)

        const array: any[] = [];
        disciplinas.forEach((item: any) => {
            if (item.MateriaCodigo1 !== "") {
                if (item.DisciplinaCodigo === e.target.value) {
                    array.push(item.MateriaCodigo1);
                }
            }
            if (item.MateriaCodigo2 !== "") {
                if (item.DisciplinaCodigo === e.target.value) {
                    array.push(item.MateriaCodigo2);
                }
            }
            if (item.MateriaCodigo3 !== "") {
                if (item.DisciplinaCodigo === e.target.value) {
                    array.push(item.MateriaCodigo3);
                }
            }
            if (item.MateriaCodigo4 !== "") {
                if (item.DisciplinaCodigo === e.target.value) {
                    array.push(item.MateriaCodigo4);
                }
            }
            if (item.MateriaCodigo5 !== "") {
                if (item.DisciplinaCodigo === e.target.value) {
                    array.push(item.MateriaCodigo5);
                }
            }
            if (item.MateriaCodigo6 !== "") {
                if (item.DisciplinaCodigo === e.target.value) {
                    array.push(item.MateriaCodigo6);
                }
            }
        })

        try {
            const promises = array.map(async (item) => {
                const response = await api.get('/materia/listMateria', {
                    params: { Codigo: item }
                });
                return response.data[0];
            });

            const arrayMateria = await Promise.all(promises);

            setMaterias(arrayMateria);

            if (arrayMateria.length > 0) {
                setMateriaObrigatoria(true);
            } else {
                setMateriaObrigatoria(false);
            }

        } catch (error) {
            console.error("Error fetching materias: ", error);
            setMateriaObrigatoria(false);
        }
    }

    const searchInstrumentoAvaliacao = async () => {
        if (!turma) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Selecione Turma!' });
            return;
        }

        if (!disciplina) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Selecione Disciplina!' });
            return;
        }

        if (materiaObrigatoria) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Selecione Materia!' });
            return;
        }

        if (!avaliacao) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Selecione Avaliação!' });
            return;
        }

        const instrumentoAv = await api.get('/instrumentoAvaliacao/listInstrumentoAvaliacao', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Sequencial: periodoSelecionado?.Sequencial,
                Ano: periodoSelecionado?.Ano,
                TurmaCodigo: turma,
                DisciplinaCodigo: disciplina,
                MateriaCodigo: materia,
                Avaliacao: avaliacao.slice(-2),
            }
        });

        //console.log('teste', instrumentoAv.data);

        if (instrumentoAv.data && instrumentoAv.data.length > 0) {
            setCabecalhoDesabilitado(false)

            const ultimosCabecalhos: any = {
                Cabecalho1: '',
                Cabecalho2: '',
                Cabecalho3: '',
                Cabecalho4: '',
                Cabecalho5: '',
                Cabecalho6: ''
            };
            let Formula: string;
            let Tipo: string;
            let Avaliacao: string;

            const updatedInstrumentoAv = instrumentoAv.data.map((item: any) => {

                item.Formula = item.Formula || Formula;
                if (item.Formula) Formula = item.Formula;

                item.Tipo = item.Tipo || Tipo;
                if (item.Tipo) Tipo = item.Tipo;

                item.Avaliacao = item.Avaliacao || Avaliacao;
                if (item.Avaliacao) Avaliacao = item.Avaliacao;

                item.EmpresaId = periodoSelecionado?.EmpresaId;
                item.Ano = periodoSelecionado?.Ano;
                item.Sequencial = periodoSelecionado?.Sequencial;
                item.DisciplinaCodigo = disciplina;
                item.TurmaCodigo = turma;
                item.MateriaCodigo = materia;

                for (let i = 1; i <= 6; i++) {
                    const cabecalhoIndx = `Cabecalho${i}`;
                    if (item[cabecalhoIndx]) {
                        ultimosCabecalhos[cabecalhoIndx] = item[cabecalhoIndx];
                    } else {
                        item[cabecalhoIndx] = ultimosCabecalhos[cabecalhoIndx];
                    }
                }

                const notas = [
                    item.Cabecalho1 ? item.Nota1 : undefined,
                    item.Cabecalho2 ? item.Nota2 : undefined,
                    item.Cabecalho3 ? item.Nota3 : undefined,
                    item.Cabecalho4 ? item.Nota4 : undefined,
                    item.Cabecalho5 ? item.Nota5 : undefined,
                    item.Cabecalho6 ? item.Nota6 : undefined
                ];
                const notasValidas = notas.filter(nota => nota !== undefined && nota !== null && !isNaN(nota));
                const numNotasPreenchidas = notasValidas.length;
                const soma = notasValidas.reduce((acc, nota) => acc + parseFloat(nota), 0);

                if (item.Formula === 'MEDIA') {
                    item.Media = numNotasPreenchidas > 0 ? (soma / numNotasPreenchidas).toFixed(2) : '0.00';
                } else if (item.Formula === 'SOMA') {
                    item.Media = soma.toFixed(2);
                }

                const fezProva = 'FezProva' + avaliacao.slice(-2);
                item.FezAvaliacao = item[fezProva] !== '' ? item[fezProva] : 'L';

                return item;
            });

            setInstrumentoAvaliacao(updatedInstrumentoAv);

            setCabecalho1(updatedInstrumentoAv[0].Cabecalho1 || '');
            setCabecalho2(updatedInstrumentoAv[0].Cabecalho2 || '');
            setCabecalho3(updatedInstrumentoAv[0].Cabecalho3 || '');
            setCabecalho4(updatedInstrumentoAv[0].Cabecalho4 || '');
            setCabecalho5(updatedInstrumentoAv[0].Cabecalho5 || '');
            setCabecalho6(updatedInstrumentoAv[0].Cabecalho6 || '');

            const instrumentoComFormula = updatedInstrumentoAv.find((item: any) => item.Formula);

            if (instrumentoComFormula) {
                setFormula(instrumentoComFormula.Formula);
            }

        } else {
            const cabecalhosTurma = await api.get('/turma/cabecalhosTurma', {
                params: {
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Sequencial: periodoSelecionado?.Sequencial,
                    Ano: periodoSelecionado?.Ano,
                    Codigo: turma,
                }
            });
            if (cabecalhosTurma.data) {
                setCabecalhoDesabilitado(false)

                setCabecalho1(cabecalhosTurma.data.Cabecalho1 || '');
                setCabecalho2(cabecalhosTurma.data.Cabecalho2 || '');
                setCabecalho3(cabecalhosTurma.data.Cabecalho3 || '');
                setCabecalho4(cabecalhosTurma.data.Cabecalho4 || '');
                setCabecalho5(cabecalhosTurma.data.Cabecalho5 || '');
                setCabecalho6(cabecalhosTurma.data.Cabecalho6 || '');
            } else {
                setInstrumentoAvaliacao([]);
            }
        }
    };

    const saveState = async () => {
        try {
            for (const item of instrumentoAvaliacao) {
                const instrumentoAvaliacaoUpdate: IInstrumentoAvaliacao = {
                    InstrumentoAvaliacaoId: item.InstrumentoAvaliacaoId,
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Ano: periodoSelecionado?.Ano,
                    Sequencial: periodoSelecionado?.Sequencial,
                    Matricula: item.Matricula,
                    TurmaCodigo: item.TurmaCodigo,
                    DisciplinaCodigo: item.DisciplinaCodigo,
                    MateriaCodigo: item.MateriaCodigo,
                    Tipo: item.Tipo,
                    Avaliacao: avaliacao.slice(-2),
                    Cabecalho1: cabecalho1,
                    Cabecalho2: cabecalho2,
                    Cabecalho3: cabecalho3,
                    Cabecalho4: cabecalho4,
                    Cabecalho5: cabecalho5,
                    Cabecalho6: cabecalho6,
                    Nota1: item.Nota1,
                    Nota2: item.Nota2,
                    Nota3: item.Nota3,
                    Nota4: item.Nota4,
                    Nota5: item.Nota5,
                    Nota6: item.Nota6,
                    Media: item.Media,
                    Formula: formula,
                };

                if (item.Media !== undefined) {
                    if (item.FezAvaliacao === undefined) {
                        item.FezAvaliacao = 'S';
                    } else {
                        if (item.FezAvaliacao !== 'I') {
                            item.FezAvaliacao = 'S';
                        }
                    }
                }

                const allHeadersEmpty = [cabecalho1, cabecalho2, cabecalho3, cabecalho4, cabecalho5, cabecalho6].every(header => !header || header.trim() === '');
                if (allHeadersEmpty) {
                    toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Preencha ao menos um cabeçalho.' });
                    return;
                }

                console.log(instrumentoAvaliacaoUpdate);
                await api.put('/boletim/updateFezProva', {
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Ano: periodoSelecionado?.Ano,
                    Sequencial: periodoSelecionado?.Sequencial,
                    Matricula: item.Matricula,
                    TurmaCodigo: item.TurmaCodigo,
                    DisciplinaCodigo: item.DisciplinaCodigo,
                    MateriaCodigo: item.MateriaCodigo,
                    Avaliacao: avaliacao.slice(-2),
                    FezAvaliacao: item.FezAvaliacao,
                })
                if (item.InstrumentoAvaliacaoId) {
                    await api.put('/instrumentoAvaliacao', instrumentoAvaliacaoUpdate)
                } else {
                    await api.post('/instrumentoAvaliacao', instrumentoAvaliacaoUpdate)
                }
            }

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            setTimeout(() => {
                window.location.reload();
            }, 1000);

        } catch (e: any) {
            console.log(e.response.data);
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    };


    const updateNotasWithCabecalho = (index: any, value: any) => {
        setInstrumentoAvaliacao((prevInstrumentoAvaliacao: any) => {
            console.log(prevInstrumentoAvaliacao)
            if (prevInstrumentoAvaliacao !== undefined && prevInstrumentoAvaliacao.length > 0) {
                return prevInstrumentoAvaliacao.map((item: any) => {
                    item[`Cabecalho${index}`] = value;
                    item[`Nota${index}`] = value ? item[`Nota${index}`] : 0.00;
                    item.Media = calculateTotal(item, '');
                    return item;
                });
            }
        });
    };

    const calculateTotal = (item: any, formu: any) => {
        const notas = [
            item.Cabecalho1 ? item.Nota1 : undefined,
            item.Cabecalho2 ? item.Nota2 : undefined,
            item.Cabecalho3 ? item.Nota3 : undefined,
            item.Cabecalho4 ? item.Nota4 : undefined,
            item.Cabecalho5 ? item.Nota5 : undefined,
            item.Cabecalho6 ? item.Nota6 : undefined,
        ];
        const notasValidas = notas.filter((nota) => nota !== undefined && nota !== null && !isNaN(nota));
        const numNotasPreenchidas = notasValidas.length;
        const soma = notasValidas.reduce((acc, nota) => acc + parseFloat(nota), 0);

        if (item.Formula === 'MEDIA') {
            return numNotasPreenchidas > 0 ? (soma / numNotasPreenchidas).toFixed(2) : '0.00';
        } else if (item.Formula === 'SOMA') {
            return soma.toFixed(2);
        } else if (item.Formula === '') {
            return '0.00';
        } else {
            try {
                let formulaComValores = formu ? formu.toUpperCase() : formula;

                formulaComValores = formulaComValores.replace(/IA(\d+)/g, (match: any, p1: any) => {
                    const index = parseInt(p1, 10) - 1;
                    const nota = notas[index];
                    return (nota !== undefined && nota !== null && !isNaN(nota)) ? nota : '0';
                });

                const result = new Function(`return ${formulaComValores}`)();
                return parseFloat(result).toFixed(2);
            } catch (error) {
                console.log('Erro ao calcular fórmula personalizada:' + error);
                return '0.00';
            }
        }
    };

    const onEditorValueChange = (props: any, value: any) => {
        let updatedInstrumentoAvaliacao = [...instrumentoAvaliacao];
        updatedInstrumentoAvaliacao[props.rowIndex][props.field] = value;

        updatedInstrumentoAvaliacao[props.rowIndex].Media = calculateTotal(updatedInstrumentoAvaliacao[props.rowIndex], '');

        setInstrumentoAvaliacao(updatedInstrumentoAvaliacao);
    };

    const inputTextEditor = (props: any) => {
        const field = props.field;
        const rowIndex = props.rowIndex;

        const handleBlur = (e: any) => {
            const value = e.target.value ? e.target.value.replace(',', '.') : 0;
            onEditorValueChange({ field, rowIndex }, value);
        };

        const handleControl = (e: any) => {
            const charCode = e.charCode;
            const newValue = e.target.value + String.fromCharCode(charCode);

            if (!/^\d*\,?\d*$/.test(newValue)) {
                e.preventDefault();
            }
            // if (!/^\d{0,2}$/.test(newValue)) {
            //     e.preventDefault();
            // }
        };

        return (
            <InputText
                type="text"
                maxLength={4}
                defaultValue={props.rowData[props.field]}
                onBlur={handleBlur}
                onKeyPress={handleControl}
                style={{ maxWidth: 50 }}
            />
        );
    };

    const handleFormulaChange = async (e: any) => {
        setFormula(e.target.value);
        const form = e.target.value
        if (instrumentoAvaliacao && instrumentoAvaliacao.length > 0) {
            setInstrumentoAvaliacao((prevInstrumentoAvaliacao: any) => {
                return prevInstrumentoAvaliacao.map((item: any) => {
                    item.Formula = form;
                    return {
                        ...item,
                        Media: calculateTotal(item, form)
                    };
                });
            });
        }
    };

    const handleDropdownChange = (e: any, rowIndex: number) => {
        const { value } = e.target;
        const updatedData = [...instrumentoAvaliacao];
        updatedData[rowIndex] = { ...updatedData[rowIndex], FezAvaliacao: value };
        setInstrumentoAvaliacao(updatedData);
    };

    const fezAvaliacaoTemplate = (rowData: any, options: any) => {
        const { rowIndex } = options;
        return (
            <div className="col-12 flex justify-content-center">
                <SCDropdown
                    name='fezavaliacao'
                    value={rowData.FezAvaliacao}
                    onChange={(e) => handleDropdownChange(e, rowIndex)}
                    options={FEZ_AVALIACAO}
                    optionLabel='descricao'
                    optionValue='value'
                    style={{ width: 110 }}
                />
            </div>
        );
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Instrumentos de Avaliação</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <div className='grid pt-2'>
                        <div className="col-12 md:col-4">
                            <SCDropdown
                                name="Turma"
                                options={turmas}
                                optionLabel='TurmaDescricao'
                                optionValue='Codigo'
                                placeholder='Selecione a Turma'
                                label='Turma'
                                value={turma}
                                onChange={handleTurmas}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <SCDropdown
                                name="DisciplinaCodigo"
                                options={disciplinas}
                                optionLabel='DisciplinaDescricao'
                                optionValue='DisciplinaCodigo'
                                placeholder='Selecione a Disciplina'
                                label='Disciplina'
                                onChange={handleDisciplina}
                                value={disciplina}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <SCDropdown
                                name="MateriaCodigo"
                                options={materias}
                                optionLabel='Descricao'
                                optionValue='Codigo'
                                placeholder='Selecione a Matéria'
                                label='Matéria'
                                onChange={(e) => {
                                    setMateria(e.target.value);
                                    setMateriaObrigatoria(false);
                                }}
                                value={materia}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <SCDropdown
                                name="Avaliacao"
                                options={AVALIACAO}
                                optionLabel='descricao'
                                optionValue='value'
                                placeholder='Selecione a Avaliação'
                                label='Avaliação'
                                value={avaliacao}
                                style={{ width: '100%' }}
                                onChange={(e: any) => setAvaliacao(e.target.value)}
                            />
                        </div>
                        <div className='col-12 md:col-4 flex align-items-end'>
                            <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined" onClick={searchInstrumentoAvaliacao} />
                        </div>
                    </div>

                    <Divider />

                    <div className='grid mt-2 mb-3'>
                        <div className='col-3 md:col-2'>
                            <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA1</label>
                            <SCInputText
                                control={control}
                                name='Cabecalho1'
                                value={cabecalho1}
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    setCabecalho1(e.target.value);
                                    updateNotasWithCabecalho(1, e.target.value);
                                }}
                                onBlur={(e) => {
                                    setCabecalho1(e.target.value);
                                    updateNotasWithCabecalho(1, e.target.value);
                                }}
                                className='pr-0'
                                disabled={cabecalhoDesabilitado}
                            />
                        </div>
                        <div className='col-3 md:col-2'>
                            <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA2</label>
                            <SCInputText
                                control={control}
                                name='Cabecalho2'
                                value={cabecalho2}
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    setCabecalho2(e.target.value);
                                    updateNotasWithCabecalho(2, e.target.value);
                                }}
                                onBlur={(e) => {
                                    setCabecalho2(e.target.value);
                                    updateNotasWithCabecalho(2, e.target.value);
                                }}
                                className='pr-0'
                                disabled={cabecalhoDesabilitado}
                            />
                        </div>
                        <div className='col-3 md:col-2'>
                            <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA3</label>
                            <SCInputText
                                control={control}
                                name='Cabecalho3'
                                value={cabecalho3}
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    setCabecalho3(e.target.value);
                                    updateNotasWithCabecalho(3, e.target.value);
                                }}
                                onBlur={(e) => {
                                    setCabecalho3(e.target.value);
                                    updateNotasWithCabecalho(3, e.target.value);
                                }}
                                className='pr-0'
                                disabled={cabecalhoDesabilitado}
                            />
                        </div>
                        <div className='col-3 md:col-2'>
                            <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA4</label>
                            <SCInputText
                                control={control}
                                name='Cabecalho4'
                                value={cabecalho4}
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    setCabecalho4(e.target.value);
                                    updateNotasWithCabecalho(4, e.target.value);
                                }}
                                onBlur={(e) => {
                                    setCabecalho4(e.target.value);
                                    updateNotasWithCabecalho(4, e.target.value);
                                }}
                                className='pr-0'
                                disabled={cabecalhoDesabilitado}
                            />
                        </div>
                        <div className='col-3 md:col-2'>
                            <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA5</label>
                            <SCInputText
                                control={control}
                                name='Cabecalho5'
                                value={cabecalho5}
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    setCabecalho5(e.target.value);
                                    updateNotasWithCabecalho(5, e.target.value);
                                }}
                                onBlur={(e) => {
                                    setCabecalho5(e.target.value);
                                    updateNotasWithCabecalho(5, e.target.value);
                                }}
                                className='pr-0'
                                disabled={cabecalhoDesabilitado}
                            />
                        </div>
                        <div className='col-3 md:col-2'>
                            <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA6</label>
                            <SCInputText
                                control={control}
                                name='Cabecalho6'
                                value={cabecalho6}
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    setCabecalho6(e.target.value);
                                    updateNotasWithCabecalho(6, e.target.value);
                                }}
                                onBlur={(e) => {
                                    setCabecalho6(e.target.value);
                                    updateNotasWithCabecalho(6, e.target.value);
                                }}
                                className='pr-0'
                                disabled={cabecalhoDesabilitado}
                            />
                        </div>
                    </div>

                    <DataTable
                        size='small'
                        stripedRows
                        ref={dt}
                        value={instrumentoAvaliacao}
                        dataKey="id"
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhum registro encontrado."
                        showGridlines
                        responsiveLayout="scroll">

                        <Column field="NumeroOrdem" header="Ordem" sortable headerStyle={{ width: 50 }} />
                        <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }} />
                        <Column field="Nome" header="Nome" sortable headerStyle={{ width: 250 }} />
                        <Column field="" header="Fez Avaliação" headerStyle={{ width: 120 }} body={fezAvaliacaoTemplate} />
                        <Column field="Nota1" header="IA1" headerStyle={{ width: 60 }} editor={inputTextEditor} />
                        <Column field="Nota2" header="IA2" headerStyle={{ width: 60 }} editor={inputTextEditor} />
                        <Column field="Nota3" header="IA3" headerStyle={{ width: 60 }} editor={inputTextEditor} />
                        <Column field="Nota4" header="IA4" headerStyle={{ width: 60 }} editor={inputTextEditor} />
                        <Column field="Nota5" header="IA5" headerStyle={{ width: 60 }} editor={inputTextEditor} />
                        <Column field="Nota6" header="IA6" headerStyle={{ width: 60 }} editor={inputTextEditor} />
                        <Column field="Media" header="Total" headerStyle={{ width: 70 }} />
                    </DataTable>
                    <div className='grid flex justify-content-center pt-3'>
                        <div className='col-12 md:col-10'></div>
                        <div className='col-12 md:col-2 flex justify-content-end'>
                            <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={saveState} />
                        </div>
                    </div>
                </div>
                <SCFieldset legend='Para obtenção do "Total"'>
                    <div><p>1) Pela soma, informe a palavra SOMA</p></div>
                    <div><p>2) Pela média por 6, informe a palavra MEDIA ou, informe uma fórmula específica (Nesse caso solicite assessoria Path)</p></div>
                    <div><p>3) Se você não informar nada, o sistema assumirá a Média Aritmética com base na quantidade de títulos informados na linha "Cabeçalhos", neste caso, preencher pelo menos um título.</p></div>
                    <div className='col-12 flex'><div className='col-2'>Fórmula de cálculo:</div> <SCInputText name='Formula' control={control} style={{ width: '80%', textTransform: 'uppercase' }} value={formula} onChange={(e) => { setFormula(e.target.value); handleFormulaChange(e) }} /></div>
                </SCFieldset>
            </div>
        </div>
    );
};

export default InstrumentoAvaliacao;
