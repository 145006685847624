import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { SpeedDial } from 'primereact/speeddial';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import IMateria from '../interfaces/IMateria';
import MateriaForm from './MateriaForm';
import Loading from '../components/Loading';


const Materia = () => {
  const toast = useToast();
  const { usuario, periodoSelecionado } = useAuth();
  const tarefa = 'DisciplinaForm';

  let emptyData: IMateria = {
    MateriaId: undefined,
    DisciplinaId: undefined,
    Descricao: '',
    Codigo: '',
  };

  const [lista, setLista] = useState<any>(null);
  const [cadastroDialog, setCadastroDialog] = useState<boolean>(false);
  const [deleteRegistro, setDeleteRegistro] = useState<boolean>(false);
  const [registro, setRegistro] = useState<IMateria>(emptyData);
  const [selectedRegistro, setSelectedRegistro] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyData });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/materia', { params: { Descricao: getValues()?.Descricao } });
    if (result.data)
      setLista(result.data);
    else
      setLista([]);

    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setRegistro(emptyData);
    setSubmitted(false);
    setCadastroDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setCadastroDialog(false);
  };
  const editar = (reg: any) => {
    setRegistro(reg);
    setCadastroDialog(true);
  };

  const confirmDelete = (reg: any) => {
    setRegistro(reg);
    setDeleteRegistro(true);
    setCadastroDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/materia', { params: { EmpresaId: periodoSelecionado?.EmpresaId, Codigo: registro.Codigo } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteRegistro(false);
      setCadastroDialog(false);
      setRegistro(emptyData);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Matérias</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição da Matéria'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={lista}
              onSelectionChange={(e) => setSelectedRegistro(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column header="Disciplina" body={(rowData: any) => {
                return (
                  <div className="">
                    {rowData?.DisciplinaCodigo} - {rowData?.DisciplinaDescricao}
                  </div>
                );
              }}
                sortable
              />
              <Column header="Matéria" body={(rowData: any) => {
                return (
                  <div className="">
                    {rowData?.Codigo} - {rowData?.Descricao}
                  </div>
                );
              }}
                sortable
              />
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={cadastroDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${registro.MateriaId === undefined ? 'Cadastrando ' : (deleteRegistro ? 'Excluíndo' : 'Alterando')} Matéria`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <MateriaForm
              setCadastroDialog={setCadastroDialog}
              registro={registro}
              reload={load}
              deleteRegistro={deleteRegistro}
              setDeleteRegistro={setDeleteRegistro}
              deletar={deletar}
            />
          </SCDialog>


        </div>
      </div>
    </div>
  );
};

export default Materia;
