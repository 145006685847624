import api from "./api";


export default class MenuService {
   
    async getSistemMenu(SistemaId: any){
        const resulMenu = await api.get('/menu', { params: { SistemaId }});

        const menuData = resulMenu.data.filter((i: any) => i.Descricao !== '-').map((item: any) => ({
            ...item,
            label: item.Descricao,
            icon: `ph ph-bold ${item.Icone} menu-icon`,            
        }));          

        const menu = this.createMenu(menuData);
        
        return menu;
    }

    // Função para criar um menu recursivamente
    createMenu(menuItems: any, parentId = null) {
        const menu = [];
    
        for (const item of menuItems) {
            if (item.MenuIdReferencia === parentId) {
                const submenu = this.createMenu(menuItems, item.MenuId);
                if (submenu.length > 0) {
                    item.items = submenu;
                }
                menu.push(item);
            }
        }
    
        return menu;
    }
}
