import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { TabPanel, TabView } from 'primereact/tabview';
import { SCButton, SCCalendar, SCDropdown, SCFieldset, SCInputText } from '../components';
import FormEndereco from '../components/FormEndereco';
import Loading from '../components/Loading';
import { useToast } from '../context/ToastContext';
import IConvenio from '../interfaces/IConvenio';
import IEstagio from '../interfaces/IEstagio';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';


interface Props {
  estagio?: IEstagio;
  setEstagioDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteEstagio: boolean;
  setDeleteEstagio(param: boolean): void;
}


const EstagioForm: React.FC<Props> = ({ setEstagioDialog, estagio, reload, deletar, deleteEstagio, setDeleteEstagio }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'EstagioForm';

  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [convenios, setConvenios] = useState<IConvenio[]>([])
  const [editando, setEditando] = useState<boolean>(false);
  const [listaCurso, setListaCurso] = useState<any[]>([]);
  const [estagioCursoDados, setEstagioCursoDados] = useState<any[]>([]);

  const defaultValues: IEstagio = estagio || {
    EstagiosCursosId: undefined,
    EstagiosId: undefined,
    ConvenioId: 0,
    Descricao: '',
    ContatoEmail: '',
    ContatoTelefone: '',
    ContatoNome: '',
    PeriodoContratoInicial: new Date(''),
    PeriodoContratoFinal: new Date(''),
    Codigo: 0,
    VagasOfertadas: 0,
    CursoId: 0,
    EstagioCursoItem: [],
    ESTAGIOEndereco: []
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset, clearErrors } = methods;

  useEffect(() => {
    const load = async () => {
      const resp = await api.get('/estagiosCursos/estagiocurso', { params: { EstagiosId: estagio?.EstagiosId } })
      setEstagioCursoDados(resp.data);
    }

    if (estagio?.EstagiosId)
      load()

  }, [])

  let resp: any = undefined;

  const salvaEstagios = async (newData: any, data: any) => {

    resp = await api.post('/estagios', newData);

    const idEstagio = resp?.data.EstagiosId[0]
    newData.EstagiosId = idEstagio

    if (data.EstagioCursoItem) {
      const salvaEstagioItens = async () => {
        const response = data.EstagioCursoItem.map(async (item: any) => {
          await api.post('estagiosCursos', {
            EstagiosId: idEstagio,
            CursoId: item?.CursoId,
            VagasOfertadas: item?.VagasOfertadas
          })
        })

        return await Promise.all(response);
      }

      await salvaEstagioItens();
    }
  }

  const atualizaEstagios = async (newData: any, data: any) => {

    resp = await api.put('/estagios', newData);

    if (data.EstagioCursoItem) {
      const salvaEstagioItens = async () => {
        const response = data.EstagioCursoItem.map(async (item: any) => {
          await api.post('estagiosCursos', {
            EstagiosId: data?.EstagiosId,
            CursoId: item?.CursoId,
            VagasOfertadas: item?.VagasOfertadas
          })
        })

        return await response
      }

      await salvaEstagioItens();
    }

  }

  const salvaEnderecos = async (newData: any, data: any, formataCep: any) => {
    await api.post('/enderecos', {
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Matricula: newData.EstagiosId,
      Tipo: "ESTAGIO",
      Cep: formataCep,
      Logradouro: data.ESTAGIOEndereco?.Logradouro,
      Numero: data.ESTAGIOEndereco?.Numero,
      Complemento: data.ESTAGIOEndereco?.Complemento,
      Bairro: data.ESTAGIOEndereco?.Bairro,
      Cidade: data.ESTAGIOEndereco?.Cidade,
      Uf: data.ESTAGIOEndereco?.Uf
    })
  }

  const atualizaEndereco = async (data: any, formataCep: any) => {
    await api.put('/enderecos', {
      Matricula: data?.EstagiosId,
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Tipo: "ESTAGIO",
      Cep: formataCep,
      Logradouro: data.ESTAGIOEndereco?.Logradouro,
      Numero: data.ESTAGIOEndereco?.Numero,
      Complemento: data.ESTAGIOEndereco?.Complemento,
      Bairro: data.ESTAGIOEndereco?.Bairro,
      Cidade: data.ESTAGIOEndereco?.Cidade,
      Uf: data.ESTAGIOEndereco?.Uf
    });
  }

  const deletaEstagiosCurso = async (data: any) => {
    if (data.EstagioCursoItem) {
      await api.delete('/estagiosCursos', {
        params: {
          EstagiosId: data.EstagiosId,
        }
      });
    }
  }

  const onSubmit = async (data: any) => {
    setLoading(true)

    let { Codigo, EstagioCursoItem, CursoId, VagasOfertadas, ESTAGIOEndereco, EstagiosCursosId, ...newData } = data;

    if (newData.PeriodoContratoInicial && newData.PeriodoContratoFinal) {
      if (newData.PeriodoContratoFinal < newData.PeriodoContratoInicial) {
        setLoading(false)
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Data do Período Final não pode ser menor que a Data do Período Inicial' });
        return false
      }
    }

    newData.PeriodoContratoInicial ??= new Date('');
    newData.PeriodoContratoFinal ??= new Date('');

    const formataCep = data.ESTAGIOEndereco.Cep.replace('-', '');

    try {

      if (newData.EstagiosId === undefined) {
        await salvaEstagios(newData, data)
        await salvaEnderecos(newData, data, formataCep)

      } else {
        await deletaEstagiosCurso(data)
        await atualizaEstagios(newData, data)
        await atualizaEndereco(data, formataCep)
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setLoading(false);
      reload();
      setEstagioDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
      setLoading(false);
    }
  }

  const consultaConvenios = async () => {
    const result = await api.get('/convenio/list', { params: { Descricao: '' } });
    if (result.data) {
      setConvenios(result.data);
    } else {
      setConvenios([])
    }
  }

  const consultaCursos = async () => {
    const result = await api.get('/curso', { params: { Descricao: '' } });
    setListaCurso(result.data);
  }

  useEffect(() => {
    consultaConvenios()
    consultaCursos()
  }, [])

  const cancelarEdicao = () => {
    setEditando(false);
    setValue('CursoId', 0)
    setValue('VagasOfertadas', 0)
  }

  const editar = (e: any) => {
    setEditando(true)
    setValue('EstagiosCursosId', e.EstagiosCursosId)
    setValue('CursoId', e.CursoId)
    setValue('VagasOfertadas', e.VagasOfertadas)
  }

  const excluir = (e: any) => {
    console.log('excluir')
    const buscaIdx: any = estagioCursoDados?.findIndex(c => c.CursoId === e.CursoId)
    estagioCursoDados?.splice(buscaIdx, 1)
    setValue('EstagioCursoItem', estagioCursoDados);
    setEstagioCursoDados([...estagioCursoDados]);
  }

  const adicionarEstagioCursoItem = () => {
    clearErrors();

    const dados = getValues();

    let dadosGrid = []

    if (!dados.CursoId) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Curso é obrigatório!' });
      return false;
    }

    if (!dados.VagasOfertadas) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Vagas Ofertadas é obrigatório!' });
      return false;
    }

    if (dados.VagasOfertadas <= 0) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Vagas Ofertadas é inválido' });
      return false;
    }

    if (editando) {
      // Verifica se o item já existe no GRID
      let verifica = estagioCursoDados.find(x => x.EstagiosCursosId === dados.EstagiosCursosId);

      // Se o item já existe no GRID
      if (verifica) {
        // Verifica se o curso já está cadastrado
        let verificaCurso = estagioCursoDados.find(x => x.CursoId === dados.CursoId && x.EstagiosCursosId !== dados.EstagiosCursosId);

        if (verificaCurso) {
          toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Curso já cadastrada no Período' });
          return false;
        }
      }

      // Se o item já existe no GRID, atualize os dados
      const indice = estagioCursoDados.findIndex(x => x.EstagiosCursosId === dados.EstagiosCursosId);
      const dadosAtuais = [...estagioCursoDados];
      dadosAtuais[indice] = {
        ...dadosAtuais[indice],
        CursoId: dados.CursoId,
        EstagiosCursosId: dados.EstagiosCursosId,
        EstagiosId: dados.EstagiosId,
        CursoCodigo: listaCurso.find(c => c.CursoId === dados.CursoId)?.Codigo,
        CursoDescricao: listaCurso.find(c => c.CursoId === dados.CursoId)?.Descricao,
        VagasOfertadas: Number(dados.VagasOfertadas),
      };

      reset({
        EstagiosId: dados.EstagiosId,
        EstagiosCursosId: dados.EstagiosCursosId,
        ESTAGIOEndereco: dados.ESTAGIOEndereco,
        ConvenioId: dados.ConvenioId,
        Descricao: dados.Descricao,
        ContatoEmail: dados.ContatoEmail,
        ContatoTelefone: dados.ContatoTelefone,
        ContatoNome: dados.ContatoNome,
        PeriodoContratoInicial: dados.PeriodoContratoInicial,
        PeriodoContratoFinal: dados.PeriodoContratoFinal,
        Codigo: dados.Codigo,
        CursoId: dados.CursoId,
        VagasOfertadas: 0,
        EstagioCursoItem: dadosAtuais
      });

      setEstagioCursoDados(dadosAtuais);

    } else {
      // Se não estiver editando, adiciona um novo item ao GRID
      let verificaCurso = estagioCursoDados.find(c => c.CursoId === dados.CursoId);

      // Se o CURSO já estiver cadastrada no GRID
      if (verificaCurso) {
        toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Curso já cadastrado' });
        return false;
      }

      dadosGrid = [...estagioCursoDados, {
        CursoId: dados.CursoId,
        EstagiosCursosId: dados.EstagiosCursosId,
        EstagiosId: dados.EstagiosId,
        CursoCodigo: listaCurso.find(c => c.CursoId === dados.CursoId)?.Codigo,
        CursoDescricao: listaCurso.find(c => c.CursoId === dados.CursoId)?.Descricao,
        VagasOfertadas: Number(dados.VagasOfertadas),

      }];

      reset({
        EstagiosId: dados.EstagiosId,
        EstagiosCursosId: dados.EstagiosCursosId,
        ESTAGIOEndereco: dados.ESTAGIOEndereco,
        ConvenioId: dados.ConvenioId,
        Descricao: dados.Descricao,
        ContatoEmail: dados.ContatoEmail,
        ContatoTelefone: dados.ContatoTelefone,
        ContatoNome: dados.ContatoNome,
        PeriodoContratoInicial: dados.PeriodoContratoInicial,
        PeriodoContratoFinal: dados.PeriodoContratoFinal,
        Codigo: dados.Codigo,
        CursoId: dados.CursoId,
        VagasOfertadas: 0,
        EstagioCursoItem: dadosGrid
      });

      setEstagioCursoDados(dadosGrid);
    }
  }

  const cancelar = (e: any) => {
    setDeleteEstagio(false);
    setEstagioDialog(false);
    reset();
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className='pt-3' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

        {loading && (<Loading />)}

        <div className='grid'>
          <div className="col-12 md:col-8">
            <SCInputText
              control={control}
              autoFocus={true}
              errors={errors}
              name="Descricao"
              label='Descrição'
              minLength={3}
              required={!deleteEstagio}
              disabled={estagio?.EstagiosId !== undefined}
              tarefa={tarefa}
              tooltip='Máximo de 60 caracteres'
              tooltipOptions={{ event: 'focus' }}
            />
          </div>
        </div>
        <div className='grid'>
          <div className="col-12 md:col-4">
            <SCDropdown
              autoFocus={true}
              control={control}
              errors={errors}
              name="ConvenioId"
              label='Convênios'
              options={convenios}
              optionLabel='Codigo'
              optionValue='ConvenioId'
              tarefa={tarefa}
              disabled={deleteEstagio}
            />
          </div>
        </div>
        <TabView renderActiveOnly={false}>
          <TabPanel header="Principal" >

            <SCFieldset legend='Endereço' className='col-12 mt-3'>
              <FormEndereco matricula={watch('EstagiosId')} tipo="ESTAGIO" />
            </SCFieldset>

            <SCFieldset legend='Contato' className='col-12 mt-3' >
              <div className='grid'>
                <div className="col-6 md:col-4">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="ContatoNome"
                    label='Nome'
                    required={!deleteEstagio}
                    disabled={deleteEstagio}
                    maxLength={60}
                    tooltip='Máximo de 60 caracteres'
                    tooltipOptions={{ event: 'focus' }}
                  />
                </div>

                <div className="col-6 md:col-3">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="ContatoTelefone"
                    label='Telefone'
                    required={!deleteEstagio}
                    disabled={deleteEstagio}
                    maxLength={30}
                    tooltip='Máximo de 30 caracteres'
                    tooltipOptions={{ event: 'focus' }}
                  />
                </div>

                <div className="col-12 md:col-4">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="ContatoEmail"
                    label='E-mail'
                    required={!deleteEstagio}
                    disabled={deleteEstagio}
                    style={{ textTransform: 'lowercase' }}
                    tarefa={tarefa}
                    maxLength={60}
                    tooltip='Máximo de 60 caracteres'
                    tooltipOptions={{ event: 'focus' }}
                  />
                </div>
              </div>
            </SCFieldset>

            <div className='grid mt-3'>
              <div className="col-12 md:col-2">
                <SCCalendar
                  label='Período Contratual Início'
                  control={control}
                  name='PeriodoContratoInicial'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  showIcon
                  disabled={deleteEstagio}
                />
              </div>

              <div className="col-12 md:col-2">
                <SCCalendar
                  label='Período Contratual Final'
                  control={control}
                  name='PeriodoContratoFinal'
                  dateFormat='dd/mm/yy'
                  mask='99/99/9999'
                  showIcon
                  disabled={deleteEstagio}
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel header="Cursos">
            <div className='grid'>
              <div className='col-6'>
                <SCDropdown
                  control={control}
                  name="CursoId"
                  label='Curso'
                  options={listaCurso}
                  optionLabel='Descricao'
                  optionValue='CursoId'
                  filter={false}
                />
              </div>

              <div className="col-12 md:col-2">
                <SCInputText
                  control={control}
                  autoFocus={true}
                  errors={errors}
                  name="VagasOfertadas"
                  label='Vagas Ofertadas'
                  // required={!deleteFuncionario}
                  disabled={deleteEstagio}
                  tarefa={tarefa}
                  tooltip='Máximo de 60 caracteres'
                  type='number'
                />
              </div>

              <div className="col-1 flex ">
                {editando ? (
                  <>
                    <SCButton type='button' icon="pi pi-times" className="p-button-danger mt-auto mr-2" onClick={cancelarEdicao} />
                    <SCButton type='button' icon="pi pi-check" className="p-button-success mt-auto" onClick={adicionarEstagioCursoItem} />
                  </>
                ) : (
                  <SCButton type='button' icon="pi pi-plus" className="p-button-success mt-auto" onClick={adicionarEstagioCursoItem} />
                )}
              </div>
            </div>

            <Divider />
            <div className='grid'>
              <div className='col-12'>
                <DataTable
                  size='small'
                  stripedRows
                  value={estagioCursoDados}
                  dataKey="id"
                  emptyMessage="Nenhum registro."
                  responsiveLayout="scroll">

                  <Column header="Curso" field='CursoCodigo' bodyStyle={{ width: 100 }} sortable />
                  <Column header="Descrição" field='CursoDescricao' sortable />
                  <Column header="Vagas Ofertadas" field='VagasOfertadas' bodyStyle={{ width: 50 }} sortable />

                  <Column
                    body={(rowData: any) => (
                      <>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" type='button' onClick={() => editar(rowData)} />
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" type='button' onClick={() => excluir(rowData)} />
                      </>
                    )}
                    style={{ width: 90 }}
                  />
                </DataTable>
              </div>
            </div>
          </TabPanel>
        </TabView>

        <div className='grid'>

          <Divider />

          {deleteEstagio ? (
            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
              <Button label="Confirmar Exclusão" icon="pi pi-check"
                className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                onClick={deletar} type='button' />
            </div>
          ) : (
            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
              <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
            </div>
          )}

        </div>
      </form>
    </FormProvider>
  );
};

export default EstagioForm;
