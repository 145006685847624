import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IMatrizCurricular from '../interfaces/IMatrizCurricular';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import DocMatricForm from './DocumentosMatriculaForm';
import AjudaFormTela from './AjudaFormTela';
import MatrizCurricularForm from './MatrizCurricularForm';


const MatrizCurricular = () => {
  const toast = useToast();
  const { usuario } = useAuth();
  const tarefa = 'MatrizCurricularForm';

  let emptyMatrizCurricular: IMatrizCurricular = {
    MatrizCurricularItemId: undefined,
    MatrizCurricularId: undefined,
    EmpresaId: 0,
    Sequencia: 0,
    CursoId: 0,
    Cursos: '',
    Descricao: '',
    Periodo: 0,
    DisciplinaId: 0,
    Disciplina: '',
    CargaHoraria: 0,
    Credito: 0,
    Ordem: 0,
    Optativa: 0,
    MatrizCurricularItem: []
  };

  const [matrizes, setMatrizes] = useState<any>(null);
  const [matrizDialog, setMatrizDialog] = useState<boolean>(false);
  const [deleteMatriz, setDeleteMatriz] = useState<boolean>(false);
  const [matriz, setMatriz] = useState<IMatrizCurricular>(emptyMatrizCurricular);
  const [selectedMatriz, setSelectedMatriz] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [novoComBase, setNovoComBase] = useState<any>()
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyMatrizCurricular });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    const result = await api.get('/matrizCurricular', { params: { Descricao: getValues()?.Descricao, ExigeMatrizCurricular: 1 } });

    if (result.data)
      setMatrizes(result.data);
    else
      setMatrizes([]);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setNovoComBase(null)
    setMatriz(emptyMatrizCurricular);
    setSubmitted(false);
    setMatrizDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setMatrizDialog(false);
  };

  const editar = (matriz: any) => {
    setNovoComBase(null)
    setMatriz(matriz);
    setMatrizDialog(true);
  };

  const confirmDelete = (matriz: any) => {
    setNovoComBase(null)
    setMatriz(matriz);
    setDeleteMatriz(true);
    setMatrizDialog(true);
  };

  const deletar = async () => {

    try {
      const resp = await api.delete('/matrizCurricular', { params: { MatrizCurricularId: matriz.MatrizCurricularId } });

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteMatriz(false);
      setMatrizDialog(false);
      setMatriz(emptyMatrizCurricular);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const novoComBaseNesse = (matrizCurricular: any) => {
    setNovoComBase(matrizCurricular.MatrizCurricularId)
    matrizCurricular.MatrizCurricularId = undefined;
    setMatriz(matrizCurricular);
    setMatrizDialog(true);
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Matrizes Curriculares</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição da Matriz'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <DataTable
            size='small'
            stripedRows
            ref={dt}
            value={matrizes}
            onSelectionChange={(e) => setSelectedMatriz(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            header={null}
            responsiveLayout="scroll">
            <Column field="CursoDescricao" header="Curso" sortable></Column>
            <Column field="Sequencia" align={'center'} header="Sequência" sortable ></Column>
            <Column field="Descricao" header="Descrição" sortable ></Column>
            <Column hidden={!util.isDesktop()} field="CargaHorariaTotal" header="Carga Horária Total"
              alignHeader='center'
              align='center'
            />

            <Column body={(rowData: any) => {
              return (
                <div className="actions flex align-items-center justify-content-between">
                  <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                  <Button label="Novo Com Base Nesse" icon="pi pi-plus" className="p-button w-auto p-button-success" onClick={() => novoComBaseNesse(rowData)} type='button' />
                  <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                </div>
              );
            }} style={{ width: 280 }}
            />
          </DataTable>

          <SCDialog maximized={!util.isDesktop()} visible={matrizDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${matriz.MatrizCurricularId === undefined ? 'Cadastrando ' : (deleteMatriz ? 'Excluíndo' : 'Alterando')} Matriz`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <MatrizCurricularForm
              setMatrizDialog={setMatrizDialog}
              matriz={matriz}
              NovoComBase={novoComBase}
              reload={load}
              deleteMatriz={deleteMatriz}
              setDeleteMatriz={setDeleteMatriz}
              deletar={deletar}
            />
          </SCDialog>

        </div>
      </div>
    </div>
  );
};

export default MatrizCurricular;
