import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, TIPO_CURSO, TURNOS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import TurmasForm from './TurmasForm';
import ITurmas from '../interfaces/ITurmas';
import Loading from '../components/Loading';


const Turmas = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'TurmaRegularForm';

  let emptyRegistro: ITurmas = {
    TurmaId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Codigo: '',
    Descricao: '',
    CodigoTurmaEF: '',
    EducacaoFisica: false,
    Turno: '',
    NivelSequenciaId: undefined,
    VagasPrevistas: 0,
    ProfessorPrincipal: undefined,
    SituacaoFinal: '',
    DiasRealizados: 0,
    NumeroDependencias: 0,
    DiasLetivos: 0,
    UsuarioFechamentoId: 0,
    DataFechamento: new Date(),
    HoraFechamento: '',
    CursoId: 0,
    QuatidadeDisciplinaRecuperacao: 0,
    DataProcessamentoBoletim: new Date(),
    HoraProcessamentoBoletim: '',
    UsuarioProcessamentoBoletimId: 0,
    AvaliacaoProcessamentoBoletim: '',
    QuatidadeDisciplinaRecuperacaoNormal: 0,
    QuatidadeDisciplinaRecuperacaoDependecia: 0,
    QuatidadeDisciplinaRecuperacaoAdaptacao: 0,
    QuatidadeDisciplinaRecuperacaoMaxima: 0,
    QuatidadeDisciplinaDependeciaNormal: 0,
    QuatidadeDisciplinaDependeciaDependencia: 0,
    QuatidadeDisciplinaDependenciaAdaptacao: 0,
    QuatidadeDisciplinaDependenciaMaxima: 0,
    QuatidadeDisciplinaReprovadoDireto: 0,
    EstatisticaExibir: true,
    EstatisticaComputaVagas: true,
    NFSeCodigoAtividade: '',
    NFSeTipo: '',
    NFSeAliquota: 0,
    ModeloControleFaltas: 0,
    TipoLancamentoFaltas: 0,
    BloqueiaTurmaNovasMatriculas: false,
    FaltaProcessar: false,
    TurmaEncerrada: false,
    MatriculaParcelada: false,
    ModeloAvaliacaoQualitativaId: 0,
    BancoId: 0,
    EscolaNFSe: 0,
    ItinerarioId: 0,
    ModeloBoletim: '',
    ModeloDiario: '',
    ModeloHistorico: '',
    ModeloAgendaMob: '',
    Ativo: false,
  };

  const [lista, setLista] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [registro, setRegistro] = useState<ITurmas>(emptyRegistro);
  const [registroSelecionado, setRegistroSelecionado] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [header, setHeader] = useState<any>();
  const [novoComBase, setNovoComBase] = useState<any>()

  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyRegistro });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/turmas', {
      params: {
        Descricao: getValues()?.Descricao,
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
      }
    });
    if (result.data)
      setLista(result.data);
    else
      setLista([]);
    setLoading(false);
  }

  useEffect(() => {
    load().finally(() => clearState());
  }, []);

  const openNew = () => {
    setNovoComBase(null)
    setRegistro(emptyRegistro);
    setSubmitted(false);
    setShowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setShowDialog(false);
  };
  const editar = (item: any) => {
    setNovoComBase(null)
    setRegistro(item);
    setShowDialog(true);
  };

  const confirmDelete = (item: any) => {
    setNovoComBase(null)
    setRegistro(item);
    setShowDelete(true);
    setShowDialog(true);
  };

  const deletar = async () => {
    try {
      await api.delete('/turma', { params: { TurmaId: registro.TurmaId } });

      const listOfertas = await api.get('/ofertaDisciplina/listProfessorDisciplina', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaCodigo: registro?.Codigo,
        }
      });

      if (listOfertas.data.length > 0) {
        await api.delete('/ofertaDisciplina/deleteOfertas', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            TurmaCodigo: registro?.Codigo,
          }
        });
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setShowDelete(false);
      setShowDialog(false);
      setRegistro(emptyRegistro);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    await load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const clearState = () => {
    dt?.current?.reset();      
  }

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const novoComBaseNesse = (item: any) => {
    setNovoComBase(item.TurmaId)
    delete item.TurmaId;
    setRegistro(item)
    setShowDialog(true)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Turmas [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={lista}
              onSelectionChange={(e) => setRegistroSelecionado(e.value)}
              dataKey="TurmaId"
              stateKey='tabTurmas'
              stateStorage='local'
              paginator
              rows={20}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={filter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column field="Codigo" header="Código" sortable headerStyle={{ width: 92 }}></Column>
              <Column field="Descricao" header="Descrição" sortable ></Column>
              <Column header="Nível"
                body={(rowData: any) => {
                  return TIPO_CURSO.find((x: any) => x.value == rowData.Nivel)?.descricao
                }} sortable headerStyle={{ width: 120 }} ></Column>
              <Column field="Sequencia" header="Escolaridade" sortable headerStyle={{ width: 80 }} align='center'></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button label="Novo Com Base Nesse" icon="pi pi-plus" className="p-button w-auto p-button-success" onClick={() => novoComBaseNesse(rowData)} type='button' />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 280 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={header}
            modal className="p-fluid" onHide={hideDialog}
          >
            <TurmasForm
              setShowDialog={setShowDialog}
              setHeader={setHeader}
              registro={registro}
              novoComBase={novoComBase}
              reload={load}
              showDelete={showDelete}
              setShowDelete={setShowDelete}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default Turmas;
