import React, { } from 'react';
import { useFormContext } from 'react-hook-form';

import { SCCalendar, SCDropdown, SCInputMask, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { GrUser, GrUserFemale } from "react-icons/gr";
import FormEndereco from '../components/FormEndereco';
import SelectEstadoCivil from '../components/SelectEstadoCivil';
import { isValid } from 'date-fns';
import util from '../utilities/util';

type Props = any;

const AlunosFormMae: React.FC<Props> = ({ showDelete, matricula, ...props }) => {

  const toast = useToast();

  const { control, formState: { errors }, getValues, setValue, clearErrors, setError } = useFormContext();

  const validaDataNascimentoMae = (e: any) => {
    const data = e.target.value;
    if (data !== null && isValid(new Date(data))) 
      if (util.calcularIdade(data) < 10) {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Mãe menor de 10 anos' });
        setValue('MaeDataNascimento', '');
      }
    return true;
  }

  return (
    <div className='col-12' >

      <div className='grid'>
        <div className="col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Mae"
            label='Mãe'
            disabled={showDelete}
            maxLength={60}
            required
            onBlur={(e) => {
              if (e.target.value.length > 0 && getValues('MaeNacionalidade') === ''){
                setValue('MaeNacionalidade', 'BRASILEIRA');
              }
            }}
          />
        </div>
        <div className="col-3">
          <SCCalendar
            control={control}
            errors={errors}
            name="MaeDataNascimento"
            label="Data de Nascimento"
            mask='99/99/9999'
            dateFormat='dd/mm/yy'
            showIcon
            disabled={showDelete}
            onBlur={validaDataNascimentoMae}
            showOnFocus={false}   
          />
        </div>
      </div>
      <div className='grid'>
      <div className="col-3">
          <SelectEstadoCivil
            control={control}
            errors={errors}
            name="MaeEstadoCivil"
            label='Estado Civil'
            disabled={showDelete}
          />
        </div>
        
        <div className="col-2">
          <SCInputText
            control={control}
            errors={errors}
            name="MaeRG"
            label='RG'
            disabled={showDelete}
            maxLength={20}
          />
        </div>
        <div className="col-2">
          <SCInputText
            control={control}
            errors={errors}
            name={`MaeRGOrgaoExpeditorUF`}
            label='Orgão Exp./UF'
            disabled={showDelete}
            maxLength={25}
            onBlur={(e) => {
              const rg = getValues(`MaeRG`);
              clearErrors(`MaeRG`);
              if ((rg == '' || rg == null)  && e.target?.value?.length > 0)
                setError(`MaeRG`, { type: 'manual', message: 'Preencha o campo' });
            }}
          />
        </div>  
        <div className="col-2">
          <SCInputMask
            control={control}
            errors={errors}
            name="MaeCPF"
            label='CPF'
            disabled={showDelete}
            maxLength={11}
            mask='999.999.999-99'
            onBlur={(e) => util.cpfValido(e.target.value) ? clearErrors(`MaeCPF.CPF`) : setError(`MaeCPF.CPF`, { type: 'manual', message: 'CPF inválido' })}
          />
        </div>
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            name="MaeNacionalidade"
            label='Nacionalidade'
            disabled={showDelete}
            maxLength={10}
          />
        </div>
      </div>


      <FormEndereco tipo="MAE" required={false} matricula={matricula} />

      <div className='grid'>
      <div className="col-1">
          <SCInputMask
            control={control}
            errors={errors}
            name="MaeDDD"
            label='DDD'
            mask='(999)'            
            disabled={showDelete}
            maxLength={5}
          />
        </div>        
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            name="MaeFoneCelular"
            label='Celular'
            disabled={showDelete}
            maxLength={20}
          />
        </div>
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            name="MaeFoneOutro"
            label='Outro'
            disabled={showDelete}
            maxLength={40}
          />
        </div>
        <div className="col-4">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="MaeEmail"
            label='E-mail'
            disabled={showDelete}
            maxLength={60}
          />
        </div>
        
      </div>

      <div className='grid'>
        <div className="col-5">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="MaeEmpresa"
            label='Empresa'
            disabled={showDelete}
            maxLength={60}
          />
        </div>
        <div className="col-5">
          <SCInputText
            control={control}
            errors={errors}
            name="MaeProfissao"
            label='Prof./Cargo/Ativ.'
            disabled={showDelete}
            maxLength={20}
          />
        </div>
      </div>
      
    </div>
  );
};

export default AlunosFormMae;
