import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, TURNOS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import ITurmaAuxiliar from '../interfaces/ITurmaAuxiliar';
import TurmaAuxiliarForm from './TurmaAuxiliarForm';


const TurmaAuxiliar = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'TurmaRegularForm';

  let emptyRegistro: ITurmaAuxiliar = {
    TurmaAuxiliarId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Codigo: '',
    Descricao: '',
    VagasPrevistas: 0,
    TurmaEFis: false,
    ModeloDiarioClasse: '',
  };

  const [turma, setTurma] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [turmaAuxiliar, setTurmaAuxiliar] = useState<ITurmaAuxiliar>(emptyRegistro);
  const [registroSelecionado, setRegistroSelecionado] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [novoComBase, setNovoComBase] = useState<any>()

  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyRegistro });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/turmaAuxiliar/list');
    if (result.data)
      setTurma(result.data);
    else
      setTurma([]);
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setTurmaAuxiliar(emptyRegistro);
    setNovoComBase(null)
    console.log(turmaAuxiliar)
    setSubmitted(false);
    setShowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setShowDialog(false);
  };
  const editar = (item: any) => {
    setTurmaAuxiliar(item);
    setNovoComBase(null)
    setShowDialog(true);
  };

  const confirmDelete = (item: any) => {
    setTurmaAuxiliar(item);
    setNovoComBase(null)
    setShowDelete(true);
    setShowDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/turmaAuxiliar', { params: { TurmaAuxiliarId: turmaAuxiliar.TurmaAuxiliarId } });
      console.log(resp);
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setShowDelete(false);
      setShowDialog(false);
      setTurmaAuxiliar(emptyRegistro);
    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const novoComBaseNesse = (item: any) => {
    setNovoComBase(item.TurmaAuxiliarId)
    delete item.TurmaAuxiliarId;
    setTurmaAuxiliar(item)
    setShowDialog(true)
  };

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Turma Auxiliar [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={turma}
              onSelectionChange={(e) => setRegistroSelecionado(e.value)}
              dataKey="TurmaId"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={filter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column field="Codigo" header="Código" sortable headerStyle={{ width: 92 }}></Column>
              <Column field="Descricao" header="Descrição" sortable ></Column>
              <Column field="TurmaEncerrada" align={'center'} alignHeader={'center'} header="Encerrada" sortable body={(rowData: any) => {
                if (rowData.TurmaEncerrada === true) {
                  return (
                    <div>
                      <p style={{ color: 'red' }}>SIM</p>
                    </div>
                  )
                } else {
                  return (
                    <div>
                      <p>NÃO</p>
                    </div>
                  );
                }
              }} ></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button label="Novo Com Base Nesse" icon="pi pi-plus" className="p-button w-auto p-button-success" onClick={() => novoComBaseNesse(rowData)} type='button' />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 280 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${turmaAuxiliar.TurmaAuxiliarId === undefined || turmaAuxiliar.TurmaAuxiliarId === 0 ? 'Cadastrando ' : (showDelete ? 'Excluíndo' : 'Alterando')} Turma Auxiliar`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <TurmaAuxiliarForm
              setShowDialog={setShowDialog}
              turmaAuxiliar={turmaAuxiliar}
              novoComBase={novoComBase}
              reload={load}
              showDelete={showDelete}
              setShowDelete={setShowDelete}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default TurmaAuxiliar;
