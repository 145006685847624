import React, { useEffect, useState } from 'react';
import { FormProvider, set, useForm, useFormContext } from 'react-hook-form';

import { useToast } from '../context/ToastContext';
import api from "../services/api";
import IAluno, { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';

import { TabPanel, TabView } from 'primereact/tabview';
import AlunosFormPrincipal from './AlunosFormPrincipal';
import Loading from '../components/Loading';
import AlunosFormMaisInformacoes from './AlunosFormMaisInformacoes';
import AlunoResumo from '../components/AlunoResumo';
import AlunosFormNomeSocial from './AlunosFormNomeSocial';
import AlunosFormDocumentos from './AlunosFormDocumentos';
import AlunosFormPaisResponsaveis from './AlunosFormPaisResponsaveis';
import AlunosFormMatricula from './AlunosFormMatricula';
import { SCButton } from '../components';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Divider } from 'primereact/divider';
import SelectTurmaResumido from '../components/SelectTurmaResumido';
import SelectTurmaAuxiliar from '../components/SelectTurmaAuxiliar';
import { Button } from 'primereact/button';

interface Props {
  alunoId?: any;
  setShowDialog(param: boolean): void;
}

const AlunosTurmasAuxiliaresForm: React.FC<Props> = ({ alunoId = undefined, setShowDialog }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'AlunoForm';

  const { periodoSelecionado } = useAuth();
  const [aluno, setAluno] = useState<IAlunoForm>();
  const [alunoTurmas, setAlunoTurmas] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [lista, setLista] = useState<any>([]);

  const methods = useForm({ defaultValues: {
    TurmaId: '',
    TurmaAuxiliar: undefined,
    Matricula: ''
  } });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  async function load(id: any) {
    setLoading(true);
    const { data } = await api.get('/aluno', {
      params: { AlunoId: id }
    });    

    const { data: turmas } = await api.get('/alunoTurmasAuxiliares/list', {
      params: { 
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: data?.Matricula 
      }
    }); 
    setAluno(data);
    setAlunoTurmas(turmas);
    setLoading(false);
  }

  useEffect(() => {
    if (alunoId !== undefined)
      load(alunoId)

  }, [alunoId])

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const result = await api.post('/alunoTurmasAuxiliares', {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: aluno?.Matricula,
        alunoTurmas: alunoTurmas
      });
      setLoading(false);
      setShowDialog(false);
    } catch (error) {
      setLoading(false);
      console.error('Erro ao salvar dados:', error);
    }
  }

  const remover = (rowData: any) => {
    const newAlunoTurmas = alunoTurmas.filter((item: any) => item.TurmaAuxiliarId !== rowData.TurmaAuxiliarId);
    setAlunoTurmas(newAlunoTurmas);
  }

  const add = () => {
    const turmaAuxiliar: any = getValues()?.TurmaAuxiliar;
    console.log('turmaSelecionada', turmaAuxiliar);

    if (turmaAuxiliar) {
      setAlunoTurmas([...alunoTurmas, {
        TurmaAuxiliarId: turmaAuxiliar.TurmaAuxiliarId,
        Codigo: turmaAuxiliar.Codigo,
        Descricao: turmaAuxiliar.Descricao
      }]);
    }
    setValue('TurmaAuxiliar', undefined);
    setValue('TurmaId', '');
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className='pt-3'>
        {loading && <Loading full={true} />}

        <div className='grid'>
          {aluno && (
            <AlunoResumo aluno={aluno} mostraPlanoPagamento={true} mostraFoto mostraTurma alteraFoto/>
          )}
        </div>

        <div className='grid'>
          <div className='col-10'>
            <SelectTurmaAuxiliar placeholder="..." listaFiltro={alunoTurmas} setValue={setValue} name='TurmaId' className="w-full" control={control} errors={errors} label='Turma Auxiliar' />
          </div>
          <div className='col-2'>
            <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mt-3" type='button' onClick={add} />
          </div>
        </div>

        <Divider />
        <div className='grid' style={{ minHeight: '30vh'}}>
          <div className='col-12'>
            <DataTable
              size='small'
              stripedRows
              value={alunoTurmas}
              scrollHeight='300px'
              dataKey="id"
              emptyMessage="Nenhum registro."
              responsiveLayout="scroll">
              <Column header="Turma" field='Codigo' bodyStyle={{ width: 50, textAlign: 'left' }} sortable />              
              <Column header="Descrição" field='Descricao' bodyStyle={{ textAlign: 'left' }} sortable />              
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">                      
                    <SCButton type='button' icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => remover(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          </div>
        </div>

        <Divider />

        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={() => setShowDialog(false)} />
          <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
        </div>

      </form>
    </FormProvider>
  );
};

export default AlunosTurmasAuxiliaresForm;
