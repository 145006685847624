import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { useAuth } from '../providers/auth';
import { IAcessos, IEmpresa, IPeriodo } from '../interfaces/IUsuario';
import api from "../services/api";
import {SISTEMA_ID} from '../utilities/constantes';
import Loading from '../components/Loading';

const Periodo = () => {
    const navigate = useNavigate();
    const { usuario, setEmpresaPeriodo } = useAuth();
    const [empresa, setEmpresa] = useState<IEmpresa>({} as IEmpresa);
    const [empresas, setEmpresas] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [periodo, setPeriodo] = useState<any>([]);
    const [periodos, setPeriodos] = useState<[]>([]);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const result = await api.get('/usuario/listaEmpresas', {params: { UsuarioId: usuario?.UsuarioId, SistemaId: SISTEMA_ID }});
            const lista: any = result.data?.map((i: any) => {
                return {...i, code: i?.EmpresaId, name: i?.IdRelatorio}
            }) ;
            setEmpresas(lista);
            setEmpresa(lista[0]);
            setLoading(false);
        }

        load();
    }, [usuario])

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const result = await api.get('/usuario/listaPeriodosEmpresa', {params: { EmpresaId: empresa?.EmpresaId }});
            const lista: any = result.data?.map((i: any) => {
                return {...i, code: i?.Id, name: i?.Descricao }
            });
            console.log('periodos', lista);
            setPeriodos(lista);
            setPeriodo(lista[0]);
            setLoading(false);
        }
        if (empresa?.EmpresaId)
            load();
    }, [empresa])

    const handleEmpresa = (e:any) =>{
         const empresaSel: IEmpresa = empresas.find((i: IEmpresa) => i.EmpresaId === e.value)!; 
         if (empresaSel)  
            setEmpresa(empresaSel);
    }

    const handlePeriodo = (e:any) =>{
        const periodoSel: IPeriodo = periodos.find((i: any) => i.Id === e.value)!;
        setPeriodo(periodoSel);
   }

   const onSubmit = async (e: any) => {
        e.preventDefault();
        await setEmpresaPeriodo(empresa, periodo);
        navigate('/home');
   }

   if (loading)
    return <Loading full={true}/>;

    return (
        <div className="flex align-items-center justify-content-center login-body" style={{height: '100vh'}}>
            <form onSubmit={onSubmit} className='p-3 border-round-md bg-white m-3'>
                <div className='grid'>    
                    <div className="col-12">
                        <label className='mr-3'>Empresa:</label>
                        <Dropdown value={empresa?.EmpresaId} className='w-full text-lg' optionLabel='IDUnidade' optionValue='code' options={empresas} onChange={handleEmpresa}/>
                    </div>                
                    <div className="col-12">
                        <label className='mr-3'>Período:</label>
                        <Dropdown value={periodo?.Id} className='w-full text-lg' optionLabel='name' optionValue='code' options={periodos} onChange={handlePeriodo}/>
                    </div>
                    <div className="col-12 mt-2">
                        <Button type='submit' style={{ backgroundColor: "#0f68b8" }} label="Selecionar" icon="pi pi-check" />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Periodo;