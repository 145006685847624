import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCDropdown, SCFieldset, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, TIPO_CARTAO } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import ICartao from '../interfaces/ICartao';
import { useAuth } from '../providers/auth';

interface Props {
  cartao?: ICartao;
  setCartaoDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteCartao: boolean;
  setDeleteCartao(param: boolean): void;
}

const CartaoForm: React.FC<Props> = ({ setCartaoDialog, cartao, reload, deletar, deleteCartao, setDeleteCartao }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'CartaoForm';

  const toast = useToast();

  const defaultValues: ICartao = cartao || {
    CartoesId: undefined,
    Codigo: '',
    Tipo: '',
    QtdParcelas: 0,
    QtdDiasRecebimento: 0,
    TarifaTipo: 0,
    TarifaValor: 0,
    ContaId: null,
    FonteId: null,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, setError, reset, clearErrors } = methods;
  const [contas, setContas] = useState<any>(null);
  const [fontes, setFontes] = useState<any>(null);
  const [tipoCartao, setTipoCartao] = useState<any>(null);
  const { periodoSelecionado } = useAuth()

  const load = async () => {

    if (cartao?.CartoesId === undefined) {
      setValue('TarifaTipo', 1)
    }

    const resultContas = await api.get('/planoContas/list', { params: { Tipo: 'SAÍDA' } });
    setContas(resultContas.data);

    const resultFontes = await api.get('/fontes/list', { params: { Tipo: 2, EmpresaId: periodoSelecionado?.EmpresaId } });
    setFontes(resultFontes.data);

    const resultadoFontes = await api.get('/formasPagamento/list');
    const formasdePagto = resultadoFontes.data;
    const filtraCartao = formasdePagto.filter((cartao: any) => cartao.Tipo == '3')

    setTipoCartao(filtraCartao);
  }


  useEffect(() => {
    load()
  }, []);

  const watchTipo = watch('Tipo')


  const formValido = (data: ICartao) => {
    clearErrors();
    if (data.QtdParcelas === 0) {
      // toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Informe a quantidade de parcelas' });
      setError('QtdParcelas', { type: 'manual', message: 'Informe a quantidade de parcelas' });
      return false;
    }

    return true;
  }

  const onSubmit = async (data: ICartao) => {

    if (!formValido(data)) return false;

    data.QtdDiasRecebimento = Number(data.QtdDiasRecebimento);
    data.TarifaValor = Number(data.TarifaValor);

    let resp: any = undefined;

    try {
      if (data.CartoesId === undefined) {
        resp = await api.post('/cartoes', data);

      } else {
        resp = await api.put('/cartoes', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setCartaoDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setDeleteCartao(false);
    setCartaoDialog(false);
    reset();
  }

  const rplcVirgula = (value: string) => {
    if (value.includes(',')) {
      const newValue = value.replace(/,/g, '.');
      setValue('TarifaValor', parseFloat(newValue));
    }
  }

  useEffect(() => {
    if (watchTipo) {
      if (watchTipo === 'D') {
        setValue('QtdParcelas', 1);
        setValue('QtdDiasRecebimento', 0);
      }
    }
  }, [watchTipo])

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCInputText
            control={control}
            errors={errors}
            name="Maquina"
            label='Nome da Máquina'
            maxLength={20}
            disabled={deleteCartao || cartao?.CartoesId !== undefined}
            required={!deleteCartao}
            tooltip='Máximo de 20 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-12">
          <div className='grid'>
            <div className="col-12 md:col-4">
              <SCDropdown
                control={control}
                errors={errors}
                name="FonteId"
                label='Fonte'
                options={fontes}
                optionLabel='Codigo'
                optionValue='FonteId'
                disabled={deleteCartao || cartao?.CartoesId !== undefined}
                tarefa={tarefa}
                required={!deleteCartao}
              />
            </div>
            <div className="col-12 md:col-4">
              <SCDropdown
                control={control}
                errors={errors}
                name="Codigo"
                label='Cartão'
                options={tipoCartao}
                optionLabel='Descricao'
                optionValue='Codigo'
                required={!deleteCartao}
                tarefa={tarefa}
                disabled={deleteCartao || cartao?.CartoesId !== undefined}
              />
            </div>

            <div className="col-12 md:col-4">
              <SCDropdown
                control={control}
                errors={errors}
                name="Tipo"
                label='Tipo'
                options={TIPO_CARTAO}
                optionLabel='descricao'
                optionValue='value'
                required={!deleteCartao}
                tarefa={tarefa}
                onChange={() => setValue('QtdParcelas', undefined)}
                disabled={deleteCartao || cartao?.CartoesId !== undefined}
              />
            </div>
          </div>
          <SCFieldset legend='Quantidades' className='col-12 mt-3 pt-0' >
            <div className='grid'>
              <div className="col-2">
                <div className="col-12">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="QtdParcelas"
                    label='Máxima de Parcelas'
                    maxLength={3}
                    min={1}
                    disabled={deleteCartao || cartao?.CartoesId !== undefined || watchTipo === 'D'}
                    required={!deleteCartao}
                    numberOnly={true}
                  />
                </div>
                <div className="col-12">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="QtdDiasRecebimento"
                    label='Dias para Recebimento'
                    maxLength={3}
                    disabled={deleteCartao || cartao?.CartoesId !== undefined}
                    required={!deleteCartao}
                    numberOnly={true}
                  />
                </div>
              </div>
              <div className="col-6 ">
                <div className=' p-4' style={{ backgroundColor: '#0772B3', color: 'white' }}>
                  <b>Informativo para Dias para Recebimento</b><br />
                  - Informe 0 para 'Recebimento imediato (mesmo dia)'<br />
                  - Informe 1 para 'Recebimento com 1 dia útil (D+1)<br />
                  - Informe maior que 1 para 'os dias para recebimento' (D+N para cada parcela)<br />
                </div>
              </div>
            </div>
          </SCFieldset>
        </div>
      </div>

      <SCFieldset legend='Tarifa' className='col-12 mt-3 pt-0' >
        <div className='grid'>
          <div className="col-12 md:col-2">
            <label>Tipo</label>
            <SCRadioButton
              control={control}
              name="TarifaTipo"
              label='Percentual'
              value={1}
              disabled={deleteCartao}
              className='mt-2'
            />
          </div>
          <div className="col-12 md:col-2">
            <label style={{ color: 'white' }}>.</label>
            <SCRadioButton
              control={control}
              name="TarifaTipo"
              label='Valor'
              value={2}
              disabled={deleteCartao}
              className='mt-2'
            />
          </div>
          <div className="col-12 md:col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="TarifaValor"
              maxLength={5}
              label="Tarifa"
              disabled={deleteCartao}
              required={!deleteCartao}
              onBlur={(e) => rplcVirgula(e.target.value)}
              min={0.01}
            />
          </div>
        </div>
      </SCFieldset>

      <div className='grid mt-2'>
        <div className="col-12 md:col-6">
          <SCDropdown
            control={control}
            errors={errors}
            name="ContaId"
            label='Conta'
            options={contas}
            optionLabel='ContaDescricao'
            optionValue='PlanoContaId'
            tarefa={tarefa}
            placeholder='Selecione a conta...'
            required={!deleteCartao}
          />
        </div>

        <Divider />
        {deleteCartao ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default CartaoForm;
