import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCCheckbox, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import { useAuth } from '../providers/auth';
import { IPeriodo } from '../interfaces/IUsuario';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { getValue } from '@testing-library/user-event/dist/utils';
import INivelSequencia from '../interfaces/INivelSequencia';
import { set } from 'date-fns';
import Loading from '../components/Loading';
import NivelSequencia from './NivelSequencia';
import SelectNivel from '../components/SelectNivel';


interface Props {
  nivelSequencia?: INivelSequencia;
  setNivelSequenciaDialog(param: boolean): void;
  NovoComBase?: any;
  deletar(): void;
  reload(): void;
  deleteNivelSequencia: boolean;
  setDeleteNivelSequencia(param: boolean): void;
}

const NivelSequenciaForm: React.FC<Props> = ({ setNivelSequenciaDialog, NovoComBase, nivelSequencia, reload, deletar, deleteNivelSequencia, setDeleteNivelSequencia }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'NivelSequenciaForm';

  const toast = useToast();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [geraHistoricoCheck, setGeraHistoricoCheck] = useState<boolean>(false)
  const [cursoLivreCheck, setCursoLivreCheck] = useState<boolean>(false)
  const [documentos, setDocumentos] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { periodoSelecionado, empresaSelecionada } = useAuth()
  const periodo = periodoSelecionado?.Id


  const defaultValues: INivelSequencia = nivelSequencia || {
    NivelSequenciaId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Nivel: '',
    Sequencia: '',
    Descricao: '',
    GeraHistorico: 0,
    ConceitoNota: 0,
    CursoLivre: 0,
    BaseadoEm: 0,
    DescricaoCompleta: ''
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const load = async () => {

    if (nivelSequencia?.NivelSequenciaId === undefined) {
      setValue('ConceitoNota', 2)
      setValue('BaseadoEm', 1)
    }

    try {
      setIsLoading(true);

      let result;
      if (NovoComBase) {
        result = await api.get('/nivelSequenciaDocumento/list', {
          params:
          {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            NivelSequenciaId: NovoComBase
          }
        });
      } else {
        result = await api.get('/nivelSequenciaDocumento/list', {
          params:
          {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            NivelSequenciaId: nivelSequencia?.NivelSequenciaId
          }
        });
      }

      setDocumentos(result.data.map((doc: any) => { doc.Checked = doc.checked === true; return doc }));

      setIsLoading(false);

    } catch (error: any) {
      console.error('Erro ao carregar documentos:', error);
    }
  };

  useEffect(() => {
    load();
  }, []);


  useEffect(() => {
    if (nivelSequencia) {
      setGeraHistoricoCheck(nivelSequencia.GeraHistorico === 1)
      setCursoLivreCheck(nivelSequencia.CursoLivre === 1)
    }
  }, [nivelSequencia]);

  const onSubmit = async (data: INivelSequencia) => {
    setIsLoading(true);
    let resp: any = undefined;
    let doc = documentos.filter((doc: any) => doc.Checked);
    let docs: any[] = [];

    if (!data?.BaseadoEm) {
      setIsLoading(false);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Por favor, selecione o Baseado Em' });
      return false
    }

    let { DescricaoCompleta, ...newArray } = data

    newArray.Sequencia = newArray.Sequencia.toUpperCase();

    try {

      if (newArray.NivelSequenciaId === undefined) {
        resp = await api.post('/nivelSequencia', newArray);

        const nivelSequenciaId = Number(resp.data);
        doc.forEach((item: any) => {
          docs.push({ NivelSequenciaId: nivelSequenciaId, DocumentoId: item.DocumentoId, Checked: item.Checked })
        });
        await api.post('/nivelSequenciaDocumento', { docs });

      } else {

        resp = await api.put('/nivelSequencia', newArray);
        const nivelSequenciaId = data.NivelSequenciaId

        doc.forEach((item: any) => {
          docs.push({ NivelSequenciaId: nivelSequenciaId, DocumentoId: item.DocumentoId, Checked: item.Checked })
        });

        await api.delete('/nivelSequenciaDocumento', { params: { NivelSequenciaId: nivelSequenciaId } });

        if (docs.length > 0)
          await api.post('/nivelSequenciaDocumento', { docs });
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setTimeout(() => {
        setIsLoading(false);
        reload();
        setNivelSequenciaDialog(false);
      }, 3000)


    } catch (e: any) {
      setIsLoading(false);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setDeleteNivelSequencia(false);
    setNivelSequenciaDialog(false);
    reset();
    if(NovoComBase){
      reload();
    }
  }

  const setCheckDocumentos = (e: CheckboxChangeParams) => {
    const { checked, id } = e.target;
    const res = documentos.map((doc: any) => {
      if (doc.DocumentoId == id)
        doc.Checked = checked;
      return doc
    });

    setDocumentos(res);
  }



  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      {isLoading && (
        <Loading full />
      )}
      <div className='grid'>
        <div className="col-6 md:col-2">
          <SelectNivel
            autoFocus={true}
            control={control}
            errors={errors}
            name="Nivel"
            label='Nível'
            required={!deleteNivelSequencia}
            disabled={deleteNivelSequencia || nivelSequencia?.NivelSequenciaId !== undefined}
          />
        </div>
        <div className="col-6 md:col-2">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Sequencia"
            label='Escolaridade'
            maxLength={2}
            required={!deleteNivelSequencia}
            disabled={deleteNivelSequencia || nivelSequencia?.NivelSequenciaId !== undefined}
            tarefa={tarefa}
            tooltip='Máximo de 2 caractere'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-12 md:col-10">
          <SCInputText
            control={control}
            errors={errors}
            name="Descricao"
            label='Descrição'
            minLength={3}
            maxLength={60}
            required={!deleteNivelSequencia}
            disabled={deleteNivelSequencia}
            tarefa={tarefa}
            tooltip='Máximo de 60 caracteres'
            tooltipOptions={{ event: 'focus' }}
            style={{ textTransform: 'uppercase' }}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-6">
          <div className='pb-2' style={{ color: "#0772B3" }}>Tipo de Avaliação
            <AjudaForm tarefa={tarefa} campo='ConceitoNota' /></div>
          <div className="flex align-items-center">
            <SCRadioButton
              control={control}
              name="ConceitoNota"
              label='Conceito'
              value={1}
              disabled={deleteNivelSequencia}
            />
            <SCRadioButton
              control={control}
              name="ConceitoNota"
              label='Nota'
              value={2}
              className='ml-3'
              disabled={deleteNivelSequencia}
            />
          </div>
        </div>
        <div className="col-6">
          <div className='pb-2' style={{ color: "#0772B3" }}>Baseado em
            <AjudaForm tarefa={tarefa} campo='BaseadoEm' /></div>
          <div className="flex align-items-center">

            <SCRadioButton
              control={control}
              name="BaseadoEm"
              label='Serie'
              value={4}
              disabled={deleteNivelSequencia}
            />

            <SCRadioButton
              control={control}
              name="BaseadoEm"
              label='Ano'
              value={1}
              className='ml-3'
              disabled={deleteNivelSequencia}
            />
            <SCRadioButton
              control={control}
              name="BaseadoEm"
              label='Módulo'
              value={2}
              className='ml-3'
              disabled={deleteNivelSequencia}
            />
            <SCRadioButton
              control={control}
              name="BaseadoEm"
              label='Período'
              value={3}
              className='ml-3'
              disabled={deleteNivelSequencia}
            />
          </div>
        </div>
      </div>

      <div className='grid'>
        <div className="col-12">
          <div className='pb-2' style={{ color: "#0772B3" }}>Outras Configurações</div>
          <div className='flex justify-content-start'>
            <div className="flex align-items-center">
              <SCCheckbox
                onChange={(e) => setGeraHistoricoCheck(e.target.checked)}
                control={control}
                name="GeraHistorico"
                label='Gera Histórico'
                disabled={deleteNivelSequencia}
                tarefa={tarefa}
              />
            </div>

            <div className="flex align-items-center">
              <SCCheckbox
                checked={true}
                onChange={(e) => setCursoLivreCheck(e.target.checked)}
                control={control}
                name="CursoLivre"
                label='Curso Livre'
                disabled={deleteNivelSequencia}
                tarefa={tarefa}
              />

            </div>
          </div>
        </div>

        <div className="col-12">
          <div className='pb-2' style={{ color: "#0772B3" }}>Documentos de Matrícula</div>
          <div className="grid">
            {documentos.map((doc: any) => (
              <div className='col-3'>
              <SCCheckbox
                key={doc.DocumentoId}
                checked={doc.Checked}
                onChange={(e: CheckboxChangeParams) => setCheckDocumentos(e)}
                id={doc.DocumentoId}
                name={doc.DocumentoId}
                label={doc.Descricao}
                disabled={deleteNivelSequencia}
              />
              </div>
            ))}

          </div>

        </div>

        <Divider />
        {deleteNivelSequencia ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default NivelSequenciaForm;
