import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCCheckbox, SCDialog, SCDropdown, SCInputText, SCRadioButton } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import { ALERTAS, APROVA_BASE, EXIBIR_ALUNOS, SIM_NAO } from '../utilities/constantes';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IDisciplinaAprovaBaseMedia from '../interfaces/IDisciplinaAprovaBaseMedia';
import Loading from '../components/Loading';
import { Dialog } from 'primereact/dialog';
import util from '../utilities/util';
import OrganizaNumeroOrdemForm from './OrganizaNumeroOrdemForm';


const OrganizaNumeroOrdem = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'DisciplinaAprovaBaseMedia';

  const periodo = periodoSelecionado?.Id

  let defaultValues: IDisciplinaAprovaBaseMedia = {
    DisciplinaId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Descricao: '',
    AprovaMedia: null,
  };

  const [selectedDisciplina, setSelectedDisciplina] = useState<any>(null);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [lista, setLista] = useState<any>([]);
  const [listaTurma, setListaTurma] = useState<any>(1);
  const [marcar, setMarcar] = useState(false)
  const [organizaNumeroOrdemDialog, setOrganizaNumeroOrdemDialog] = useState<boolean>(false);
  const [turma, setTurma] = useState<any>([]);
  const [parametros, setParametros] = useState<any>(null);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;


  const load = async () => {
    setLoading(true);
    const result = await api.get('/turma/listaVagasOcupadas', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Situacao: listaTurma
      }
    });

    if (result.data) {
      setLoading(false);
      setLista(result.data);
      setMarcar(false)
    } else {
      setLista([]);
      setLoading(false);
    }

    const parametro = await api.get('/parametro', {
      params: {
        EmpresaId: '0',
        Sequencial: '0',
        Ano: '0000',
        Codigo: 'NORDEXC',
        Grupo: 'S',
      }
    });

    setParametros(parametro.data.Valor)
  }

  useEffect(() => {
    load();
  }, [listaTurma]);

  useEffect(() => {
    if (lista && lista.length > 0) {
      setLista((prevLista: any) => {
        return prevLista.map((item: any) => ({
          ...item,
          Selecionado: marcar ? 'S' : 'N',
        }));
      });
    }
  }, [marcar]);

  const handleDropdownChange = (selectedValue: any, id: any) => {
    setLista((prevTurmas: any) => {
      const updatedTurma = prevTurmas.map((turma: any) => {
        if (turma.TurmaId === id) {
          return { ...turma, Selecionado: selectedValue };
        }

        return turma;
      });

      return updatedTurma;
    });
  };

  const onSubmit = async () => {
    setLoading(true)

    const selectedItems = lista.filter((item: any) => item.Selecionado === 'S');

    const results = await Promise.all(
      selectedItems.map(async (item: any) => {
        const result = await api.get('/turma/listaAlunos', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            Turma: item.Codigo,
            Situacao: listaTurma
          }
        });
        return result.data;
      })
    );

    const valoresInvalidos = parametros.split(',').map(Number);
    const ordenandoAlunos = results.map((data, index) => {
      if (data) {
        let contador = 0;

        const alunosOrdem = data.map((aluno: any) => {
          contador += 1;
          let numeroOrdem = contador;
          if (valoresInvalidos.includes(numeroOrdem)) {
            numeroOrdem = 0;
          }
          return { ...aluno, NumeroOrdem: numeroOrdem };
        });

        return alunosOrdem;
      }
    });

    // Aplana o array de arrays em um único array de objetos
    const alunosComNumeroOrdem = ordenandoAlunos.flat();
    console.log('alunosComNumeroOrdem', alunosComNumeroOrdem)

    try {
      await api.put('/alunosPeriodo/multiUpdate', alunosComNumeroOrdem)
      setLoading(false)
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });

      load();
    } catch (e: any) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },

    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const hideDialog = () => {
    setOrganizaNumeroOrdemDialog(false)
  };

  const editar = (turma: any) => {
    console.log(turma)
    setTurma(turma)
    setOrganizaNumeroOrdemDialog(true);
  };

  return (

    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Organiza Número de Ordem [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <div className='grid pt-4'>

            <div className='col-12 md:col-12 flex justify-content-end'>
              <Button label='Organizar o Número de Ordem' icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' onClick={onSubmit} />
              <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
            </div>
          </div>

          <Divider />

          <div className='grid col-12'>
            <div className="col-12 md:col-4 mb-4">
              <SCDropdown
                value={listaTurma}
                onChange={(e) => setListaTurma(e.target.value)}
                options={EXIBIR_ALUNOS}
                optionLabel='descricao'
                style={{ width: 220 }}
                editable={false}
              />
            </div>
            <div className="col-12 md:col-4 mb-4"></div>
            <div className='col-12 md:col-4 flex justify-content-center'>
              <SCCheckbox
                name="Marcar"
                label='Selecionar Todas as Turmas'
                checked={marcar}
                onChange={e => setMarcar(e.target.checked)}
              />
            </div>
          </div>


          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={lista}
              onSelectionChange={(e) => setSelectedDisciplina(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Codigo" header="Turma" sortable headerStyle={{ width: 92 }}></Column>
              <Column field="Descricao" header="Descrição" sortable ></Column>
              <Column field="UltimoNumeroOrdem" header="Último Número" align='center' ></Column>
              <Column field="VagasOcupadas" header="Vagas Ocupadas" align='center'  ></Column>
              <Column body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" onClick={() => editar(rowData)} className="p-button-rounded p-button-info" />
                  </div>
                );
              }} style={{ width: 50 }}
              />
              <Column
                header="Organizar Numeração?"
                body={(rowData: any) => (
                  <div className="col-12 flex justify-content-center">
                    <SCDropdown
                      value={rowData.Selecionado}
                      onChange={(e) => handleDropdownChange(e.target.value, rowData.TurmaId)}
                      options={SIM_NAO}
                      optionLabel='descricao'
                      style={{ width: 220 }}
                      editable={false}
                    />
                  </div>
                )}
                alignHeader='center'
                align='center'
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={organizaNumeroOrdemDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`Alterando Número de Ordem do Aluno`}
            modal className="p-fluid" onHide={hideDialog}>

            <OrganizaNumeroOrdemForm
              turma={turma}
              setOrganizaNumeroOrdemDialog={setOrganizaNumeroOrdemDialog}
              reload={load}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default OrganizaNumeroOrdem;
