import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCheckbox, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IFormaIngresso from '../interfaces/IFormaIngresso';


interface Props {
    formaIngresso?: IFormaIngresso;
    setFormaIngressoDialog(param: boolean): void;
    deletar(): void;
    reload(): void;
    deleteFormaIngresso: boolean;
    setDeleteFormaIngresso(param: boolean): void;
}

const FormaIngressoForm: React.FC<Props> = ({ setFormaIngressoDialog, formaIngresso, reload, deletar, deleteFormaIngresso, setDeleteFormaIngresso }) => {
    // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
    // por padrão vamos utilizar o mesmo nome do componente
    const tarefa = 'FormaIngressoForm';

    const toast = useToast();
    const [geraHistoricoCheck, setGeraHistoricoCheck] = useState<boolean>(false)
    const [cursoLivreCheck, setCursoLivreCheck] = useState<boolean>(false)
    const [documentos, setDocumentos] = useState<any>([]);

    const defaultValues: IFormaIngresso = formaIngresso || {
        FormaIngressoId: undefined,
        Codigo: '',
        Descricao: '',
    };

    const methods = useForm({ defaultValues });
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

    const onSubmit = async (data: IFormaIngresso) => {
        let resp: any = undefined;
        let doc = documentos.filter((doc: any) => doc.checked);

        try {
            if (data.FormaIngressoId === undefined) {
                resp = await api.post('/origem', data);
            } else {
                resp = await api.put('/origem', data);
            }

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            reload();
            setFormaIngressoDialog(false);

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }


    }

    const cancelar = (e: any) => {
        setDeleteFormaIngresso(false);
        setFormaIngressoDialog(false);
        reset();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
            <div className='grid'>
                <div className="col-12 md:col-2">
                    <SCInputText
                        autoFocus={true}
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="Codigo"
                        label='Código'
                        maxLength={10}
                        required={!deleteFormaIngresso}
                        disabled={deleteFormaIngresso || formaIngresso?.FormaIngressoId !== undefined}
                        tarefa={tarefa}
                    />
                </div>
                <div className="col-12 md:col-10">
                    <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="Descricao"
                        label='Descrição'
                        maxLength={40}
                        required={!deleteFormaIngresso}
                        tarefa={tarefa}
                    />
                </div>

                <Divider />
                {deleteFormaIngresso ? (
                    <div className="flex flex-1 justify-content-between pt-3 px-2">
                        <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
                        <Button label="Confirmar Exclusão" icon="pi pi-check"
                            className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                            onClick={deletar} type='button' />
                    </div>
                ) : (
                    <div className="flex flex-1 justify-content-between pt-3 px-2">
                        <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                        <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
                    </div>
                )}

            </div>

        </form >

    );
};

export default FormaIngressoForm;
