import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCDropdown, SCFieldset, SCInputNumber, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import { useAuth } from '../providers/auth';
import api from "../services/api";

const TransferenciaFonte = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const [fontes, setFontes] = useState<any>(null);

  const methods = useForm();
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

  // Watchs
  const watchData = watch('Data')
  const watchFonte = watch('FonteId')
  const watchFontePara = watch('FonteDestino')
  const watchValor = watch('Valor')
  const watchDataCredito = watch('DataCredito')
  const watchReferente = watch('Referente')

  const loadFontes = async () => {
    try {
      const { data } = await api.get('/fontes/fonteAtivas', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });
      setFontes(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Fontes:', error);
      setFontes([]);
    }
  };

  const noSaldoFonte = () => {
    showToastError('Fonte não tem saldo!');
    setValue('FonteId', null);
    setValue('Agencia', '');
    setValue('Conta', '');
    setValue('BancoSaldo', '');
  }

  const resetForm = () => {
    setValue('Data', null)
    setValue('FonteId', null);
    setValue('Agencia', '');
    setValue('Conta', '');
    setValue('BancoSaldo', '');
    setValue('FonteDestino', null);
    setValue('ParaAgencia', '');
    setValue('ParaConta', '');
    setValue('ParaBancoSaldo', '')
    setValue('Referente', '')
    setValue('Valor', null)
    setValue('DataCredito', null)
  }

  const buscaSaldo = async (fonte: any, fontePara: any) => {
    const consultaFonte = fontes.find((item: any) => item.FonteId === watchFonte);
    const { BancoAgencia, BancoConta } = consultaFonte || {};

    try {
      if (fonte) {
        try {
          const { data: saldoFonte } = await api.get('/movimentacaoFonte/consultaSaldo', {
            params: {
              EmpresaId: periodoSelecionado?.EmpresaId,
              FonteId: fonte,
              Data: format(new Date(watchData), 'yyyy-MM-dd')
            }
          });

          if (saldoFonte?.length > 0) {
            const saldoAtualDinheiro = saldoFonte[0]?.SaldoAtualDinheiro ?? 0;

            if (saldoAtualDinheiro === 0) {
              noSaldoFonte()
            } else {
              setValue('Agencia', BancoAgencia);
              setValue('Conta', BancoConta);
              setValue('BancoSaldo', saldoAtualDinheiro.toFixed(2));
            }
          } else {
            noSaldoFonte()
          }
        } catch (error) {
          if (!watchData)
            showToastError('Erro ao consultar saldo da fonte. Preencha a data');
          else
            showToastError('Erro ao consultar saldo da fonte.');
        }
      }

      if (fontePara) {
        try {
          const { data: saldoFontePara } = await api.get('/movimentacaoFonte/consultaSaldo', {
            params: {
              EmpresaId: periodoSelecionado?.EmpresaId,
              FonteId: fontePara,
              Data: format(new Date(watchData), 'yyyy-MM-dd')
            }
          });

          const saldoAtualDinheiro = saldoFontePara[0]?.SaldoAtualDinheiro ?? 0;
          setValue('ParaAgencia', BancoAgencia);
          setValue('ParaConta', BancoConta);
          setValue('ParaBancoSaldo', saldoAtualDinheiro.toFixed(2));

          if (watchFonte && watchFontePara && watchFonte === watchFontePara) {
            showToastError('Fontes iguais!');
            setValue('FonteDestino', null)
            setValue('ParaAgencia', '');
            setValue('ParaConta', '');
            setValue('ParaBancoSaldo', '');
          }

        } catch (error) {
          if (!watchData)
            showToastError('Erro ao consultar saldo da fonte. Preencha a data');
          else
            showToastError('Erro ao consultar saldo da fonte.');
        }
      }

    } catch (error) {
      console.error('Erro ao carregar as Fontes:', error);
      setFontes([]);
    }
  };

  const consultaEncerramentoCaixa = async (dataTransferencia: any, dataCredito: any) => {
    try {
      // Consulta para a data de transferência
      const { data: dataTransferenciaResponse } = await api.get('consultaEncerramento', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Operador: usuario?.Apelido,
          Data: format(new Date(dataTransferencia), 'yyyy-MM-dd'),
          Acao: 'F',
        },
      });

      console.log(dataTransferenciaResponse); // Exibe o resultado da consulta da data de transferência

      if (dataTransferenciaResponse.length > 0) {
        toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Caixa Encerrado para a Data de Transferência!' });
        return false;
      }

      // Consulta para a data de crédito
      const { data: dataCreditoResponse } = await api.get('consultaEncerramento', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Operador: usuario?.Apelido,
          Data: format(new Date(dataCredito), 'yyyy-MM-dd'),
          Acao: 'F',
        },
      });

      console.log(dataCreditoResponse); // Exibe o resultado da consulta da data de crédito

      if (dataCreditoResponse.length > 0) {
        toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Caixa Encerrado para a Data de Crédito!' });
        return false;
      }

      return true; // Retorna true se não houver problemas

    } catch (error) {
      console.error('Erro na consulta:', error);
      return false; // Retorna false em caso de erro
    }
  };

  const validations = [
    { value: watchData, message: 'Selecione a Data' },
    { value: watchFonte, message: 'Selecione a Fonte' },
    { value: watchFontePara, message: 'Selecione a Fonte de Destino' },
    { value: watchReferente, message: 'Preencha o Referente' },
    { value: watchValor, message: 'Preencha o Valor de Transferência' },
    { value: watchDataCredito, message: 'Selecione a Data do Crédito' },
  ];

  const gravaDiarioFinanceiro = async (dados: any) => {
    let hora = format(new Date, 'HH:mm')
    let fonteOrigem = fontes.find((fonte: any) => fonte.FonteId == watchFonte)?.Codigo
    let fonteDestino = fontes.find((fonte: any) => fonte.FonteId == watchFontePara)?.Codigo

    let consultaFormaPagamento = await api.get('/formasPagamento/localizaDinheiro')

    if (consultaFormaPagamento.data.length == 0) {
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao consultar Forma de Pagamento!',
      });

    } else {

      let formaPagamentoId = consultaFormaPagamento.data.FormasPagamentoId;

      let dadosDiarioDebito = {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        LancamentoData: format(new Date, 'yyyy-MM-dd'),
        LancamentoHora: hora,
        LancamentoOperador: usuario?.Apelido,
        Tarefa: 'TRFFON',
        Opcao: 'TRD',
        DataPagamento: dados.Data,
        DataDisponivel: dados.Data,
        DataBomPara: dados.Data,
        Nome: `TRANSFERÊNCIA DO '${fonteOrigem}' (DÉBITO) PARA O '${fonteDestino}'`,
        Valor: dados.Valor,
        Referencia: dados.Referente,
        FonteId: dados.FonteId,
        FonteDestinoId: dados.FonteDestino,
        FormasPagamentoId: formaPagamentoId,
      }

      let dadosDiarioCredito = {
        ...dadosDiarioDebito,
        Opcao: 'TRC',
        Nome: `TRANSFERÊNCIA DO '${fonteOrigem}' PARA O '${fonteDestino}' (CRÉDITO)`,
        FonteId: dados.FonteDestino,
        FonteDestinoId: dados.FonteId,
      }

      try {
        let resultDeb = await api.post('/diariofinanceiro', dadosDiarioDebito)
        let resultCre = await api.post('/diariofinanceiro', dadosDiarioCredito)

        if (resultDeb && resultCre) {
          toast.showToast({
            severity: 'success',
            summary: 'Atenção',
            detail: 'Registro salvo com sucesso!',
          });

          resetForm()
        }
      } catch (error: any) {
        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Erro ao salvar o registro!',
        });

        return false
      }
    }

    await gravaMovimentacaoFonte()
  }

  const gravaMovimentacaoFonte = async () => {

    let totalSaidaDinheiro = 0;
    let totalSaidaCheque = 0;
    let totalSaidaTransferencia = 0;
    let totalSaida = 0;

    let totalEntradaDinheiro = 0;
    let totalEntradaCheque = 0;
    let totalEntradaCartao = 0;
    let totalEntradaTransferencia = 0;
    let totalEntradaOutro = 0;
    let totalEntrada = 0;

    let saldoAtualDinheiro = 0;
    let saldoAtualCheque = 0;
    let saldoAtualTotal = 0;
    let saldoAnteriorDin = 0;
    let saldoAnteriorChq = 0;
    let saldoAnteriorTot = 0;

    try {
      // Inicia os valores de movimentação da fonte
      await api.put('/movimentacaoFonte/iniciaValores', {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Data: format(new Date(watchData), 'yyyy-MM-dd')
      });

      const { data: diarioData } = await api.get('/diariofinanceiro/listaDiarioData', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Data: format(new Date(watchData), 'yyyy-MM-dd')
        }
      });

      if (diarioData) {
        const promessas = diarioData.map(async (item: any) => {

          let valor = item.Valor + item.ValorMulta + item.ValorJuros
            + item.ValorComplementar01 + item.ValorComplementar02 + item.ValorComplementar03
            + item.ValorComplementar04 + item.ValorComplementar05 + item.ValorComplementar06;

          valor = valor < 0 ? valor * -1 : valor;

          if (item.Tarefa === 'APAGAR') {

            if (item.Tipo === '1')
              totalSaidaDinheiro += valor
            else
              totalSaidaCheque += valor

            let dados = {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Data: format(new Date(watchData), 'yyyy-MM-dd'),
              FonteId: item.FonteId,
              Valor: item.Tipo === '2' ? totalSaidaCheque : totalSaidaDinheiro,
              Campo: item.Tipo === '2' ? 'SAIDACHEQUE' : 'SAIDADINHEIRO'
            }

            await api.put('/movimentacaoFonte/atualizaValores', dados)

          } else {

            if (item.Tarefa === 'TRFFON') {
              // TRANSFERECIA DEBITO
              if (item.Opcao === 'TRD') {

                totalSaidaTransferencia += valor

                let dados = {
                  EmpresaId: periodoSelecionado?.EmpresaId,
                  Data: format(new Date(watchData), 'yyyy-MM-dd'),
                  FonteId: item.FonteId,
                  Valor: totalSaidaTransferencia,
                  Campo: 'SAIDATRANSF'
                }

                await api.put('/movimentacaoFonte/atualizaValores', dados)

              }

              if (item.Opcao === 'TRC') {

                totalEntradaTransferencia += valor

                let dados = {
                  EmpresaId: periodoSelecionado?.EmpresaId,
                  Data: format(new Date(watchData), 'yyyy-MM-dd'),
                  FonteId: item.FonteId,
                  Valor: totalEntradaTransferencia,
                  Campo: 'ENTRADATRANSF'
                }

                await api.put('/movimentacaoFonte/atualizaValores', dados)

              }
            } else {

              if (item.Tipo === '1') {
                totalEntradaDinheiro += valor

                let dados = {
                  EmpresaId: periodoSelecionado?.EmpresaId,
                  Data: format(new Date(watchData), 'yyyy-MM-dd'),
                  FonteId: item.FonteId,
                  Valor: totalEntradaDinheiro,
                  Campo: 'ENTRADADINHEIRO'
                }

                await api.put('/movimentacaoFonte/atualizaValores', dados)

              } else {
                if (item.Tipo === '2') {

                  totalEntradaCheque += valor

                  console.log('VALOR', valor)
                  console.log('totalEntradaCheque', totalEntradaCheque)


                  let dados = {
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Data: format(new Date(watchData), 'yyyy-MM-dd'),
                    FonteId: item.FonteId,
                    Valor: totalEntradaCheque,
                    Campo: 'ENTRADACHEQUE'
                  }

                  await api.put('/movimentacaoFonte/atualizaValores', dados)
                } else {

                  if (item.Tipo === '3') {
                    totalEntradaCartao += valor

                    let dados = {
                      EmpresaId: periodoSelecionado?.EmpresaId,
                      Data: format(new Date(watchData), 'yyyy-MM-dd'),
                      FonteId: item.FonteId,
                      Valor: totalEntradaCartao,
                      Campo: 'ENTRADACARTAO'
                    }

                    await api.put('/movimentacaoFonte/atualizaValores', dados)
                  } else {

                    totalEntradaOutro += valor

                    let dados = {
                      EmpresaId: periodoSelecionado?.EmpresaId,
                      Data: format(new Date(watchData), 'yyyy-MM-dd'),
                      FonteId: item.FonteId,
                      Valor: totalEntradaOutro,
                      Campo: 'ENTRADAOUTRO'
                    }

                    await api.put('/movimentacaoFonte/atualizaValores', dados)
                  }
                }
              }
            }
          }

        });

        await Promise.all(promessas);
      }

      let consultaMovimentacao = await api.get('/movimentacaoFonte/listaMovimentacao', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Data: format(new Date(watchData), 'yyyy-MM-dd')
        }
      })

      let movimentacao = consultaMovimentacao.data

      let somandoValores = movimentacao.map(async (item: any) => {
        totalSaida = item.SaidaDinheiro + item.SaidaCheque + item.SaidaTransferencia + item.SaidaOutro
        totalEntrada = item.EntradaDinheiro + item.EntradaCheque + item.EntradaTransferencia + item.EntradaOutro + item.EntradaCartao
        saldoAtualDinheiro = (item.SaldoAnteriorDinheiro + item.EntradaDinheiro + item.EntradaCartao + item.EntradaTransferencia) -
          (item.SaidaDinheiro + item.SaidaTransferencia)

        saldoAtualCheque = (item.SaldoAnteriorCheque + item.EntradaCheque) - (item.SaidaCheque)
        saldoAtualTotal = saldoAtualDinheiro + saldoAtualCheque
        saldoAnteriorDin = item.SaldoAjustadoDinheiro > 0 ? item.SaldoAjustadoDinheiro : saldoAtualDinheiro
        saldoAnteriorChq = item.SaldoAjustadoCheque > 0 ? item.SaldoAjustadoCheque : saldoAtualCheque
        saldoAnteriorTot = saldoAnteriorDin + saldoAnteriorChq

        let dados = { EmpresaId: periodoSelecionado?.EmpresaId, Data: format(new Date(watchData), 'yyyy-MM-dd'), FonteId: item.FonteId }
        let totalS = { ...dados, Valor: totalSaida, Campo: 'TOTALSAIDA' }
        let totalE = { ...dados, Valor: totalEntrada, Campo: 'TOTALENTRADA' }
        let saldoAtualDin = { ...dados, Valor: saldoAtualDinheiro, Campo: 'SALDOATUALDINHEIRO' }
        let saldoAtualChq = { ...dados, Valor: saldoAtualCheque, Campo: 'SALDOATUALCHEQUE' }
        let saldoAtualTot = { ...dados, Valor: saldoAtualTotal, Campo: 'SALDOATUALTOTAL' }
        let saldoAnteriorDinheiro = { ...dados, Valor: saldoAnteriorDin, Campo: 'SALDOANTERIORDINHEIRO' }
        let saldoAnteriorCheque = { ...dados, Valor: saldoAnteriorChq, Campo: 'SALDOANTERIORCHEQUE' }
        let saldoAnteriorTotal = { ...dados, Valor: saldoAnteriorTot, Campo: 'SALDOANTERIORTOTAL' }
        let enceramentoFlg = { ...dados, Valor: 1, Campo: 'ENCERRAMENTOFLG' }
        let encerramentoOpe = { ...dados, Valor: usuario?.Apelido, Campo: 'ENCERRAMENTOOPERADOR' }
        let encerramentoRef = { ...dados, Valor: 'TRANSFERÊNCIA DE FONTE', Campo: 'ENCERRAMENTOREFERENTE' }

        await api.put('/movimentacaoFonte/atualizaValores', totalS)
        await api.put('/movimentacaoFonte/atualizaValores', totalE)
        await api.put('/movimentacaoFonte/atualizaValores', saldoAtualDin)
        await api.put('/movimentacaoFonte/atualizaValores', saldoAtualChq)
        await api.put('/movimentacaoFonte/atualizaValores', saldoAtualTot)
        await api.put('/movimentacaoFonte/atualizaValores', saldoAnteriorDinheiro)
        await api.put('/movimentacaoFonte/atualizaValores', saldoAnteriorCheque)
        await api.put('/movimentacaoFonte/atualizaValores', saldoAnteriorTotal)

        await api.put('/movimentacaoFonte/atualizaValores', enceramentoFlg)
        await api.put('/movimentacaoFonte/atualizaValores', encerramentoOpe)
        await api.put('/movimentacaoFonte/atualizaValores', encerramentoRef)
      })

      await Promise.all(somandoValores)

    } catch (error: any) {
      console.error('Erro ao gravar movimentação da fonte:', error.message);
    }
  }

  const onSubmit = async () => {
    for (const { value, message } of validations) {
      if (!value) {
        showToastError(message);
        return false;
      }
    }

    if (watchValor > getValues()?.BancoSaldo) {
      showToastError('Valor transferido não pode ser maior que o valor do saldo de origem!')
      return false;
    }

    if (watchDataCredito > new Date()) {
      showToastError('Data do Crédito não pode ser maior que hoje!')
      return false;
    }

    if (watchDataCredito < watchData) {
      showToastError('Data do Crédito não pode ser menor que a Data do Saldo')
      return false;
    }

    const isCaixaEncerrado = await consultaEncerramentoCaixa(watchData, watchDataCredito);

    if (isCaixaEncerrado) {
      console.log(getValues());
      let dataForm = getValues();

      await gravaDiarioFinanceiro(dataForm)
    }
  };

  // Função auxiliar para exibir toast de erro
  const showToastError = (message: string) => {
    toast.showToast({
      severity: 'error',
      summary: 'Atenção',
      detail: message,
      life: 5000
    });
    return false;
  };

  useEffect(() => {
    loadFontes()
  }, []);

  useEffect(() => {
    if (watchFonte) {
      buscaSaldo(watchFonte, watchFontePara)
    }
  }, [watchFonte, watchFontePara]);

  useEffect(() => {    // Resetar os outros campos
    setValue('FonteId', null);
    setValue('Agencia', '');
    setValue('Conta', '');
    setValue('BancoSaldo', '');

    setValue('FonteDestino', null);
    setValue('ParaAgencia', '');
    setValue('ParaConta', '');
    setValue('ParaBancoSaldo', '');

  }, [watchData, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772b3' }}>
              <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Transferência de Fonte</h5>
            </div>

            <SCFieldset legend='Filtros' className='col-12 mt-2 pt-0'>
              <div className='grid'>
                <div className="col-12 md:col-2">
                  <SCCalendar
                    control={control}
                    errors={errors}
                    label='Data do Saldo'
                    name='Data'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    placeholder='Data'
                  />
                </div>
              </div>

              <div className='grid align-items-center mt-2'>
                <div className="col-12 md:col-3">
                  <SCDropdown
                    name="FonteId"
                    errors={errors}
                    label='Fonte de Origem'
                    optionLabel='Codigo'
                    optionValue='FonteId'
                    options={fontes}
                    onChange={(e) => setValue('FonteId', e.target.value)}
                    style={{ width: '100%' }}
                    value={watchFonte}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    control={control}
                    name="Agencia"
                    errors={errors}
                    label='Agência'
                    disabled
                    style={{ width: '100%', fontWeight: "bold", fontSize: 12 }}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    name="Conta"
                    errors={errors}
                    control={control}
                    label='Conta'
                    disabled
                    style={{ width: '100%', fontWeight: "bold", fontSize: 12 }}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    control={control}
                    name="BancoSaldo"
                    errors={errors}
                    label='Saldo R$'
                    disabled
                    style={{ width: '100%', fontWeight: "bold", fontSize: 12 }}
                  />
                </div>
              </div>

              <div className='grid align-items-center mt-2'>
                <div className="col-12 md:col-3">
                  <SCDropdown
                    name="FonteDestino"
                    label='Fonte de Destino'
                    optionLabel='Codigo'
                    optionValue='FonteId'
                    options={fontes}
                    onChange={(e) => setValue('FonteDestino', e.target.value)}
                    style={{ width: '100%' }}
                    value={watchFontePara}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    name="ParaAgencia"
                    control={control}
                    errors={errors}
                    label='Agência'
                    disabled
                    style={{ width: '100%', fontWeight: "bold", fontSize: 12, color: '#2061e4' }}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    name="ParaConta"
                    control={control}
                    errors={errors}
                    label='Conta'
                    disabled
                    style={{ width: '100%', fontWeight: "bold", fontSize: 12, color: '#2061e4' }}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCInputText
                    control={control}
                    name="ParaBancoSaldo"
                    errors={errors}
                    label='Saldo R$'
                    disabled
                    style={{ width: '100%', fontWeight: "bold", fontSize: 12, color: '#2061e4' }}
                  />
                </div>
              </div>

              <Divider />

              <div className='grid'>
                <div className="col-12 md:col-12">
                  <SCInputText
                    control={control}
                    name="Referente"
                    errors={errors}
                    label='Referente'
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div className='grid align-items-center'>
                <div className="col-12 md:col-2">
                  <SCInputNumber
                    control={control}
                    name='Valor'
                    errors={errors}
                    label='Valor R$'
                    min={0}
                    max={10000}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    currency='BRL'
                    mode='currency'
                    locale='pt-BR'
                    inputStyle={{ width: "100%" }}
                  />
                </div>

                <div className="col-12 md:col-2">
                  <SCCalendar
                    control={control}
                    errors={errors}
                    label='Data do Crédito'
                    name='DataCredito'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    placeholder='Data Crédito'
                  />
                </div>
              </div>

            </SCFieldset>

            <div className='grid'>
              <div className="col-12 flex justify-content-end">
                <div className='col-12 md:col-2 mt-2'>
                  <Button label='Executar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={onSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TransferenciaFonte;