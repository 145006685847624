import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCheckbox, SCInputText, SCCalendar } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IFeriadoFinanceiro from '../interfaces/IFeriadoFinanceiro';
import { useAuth } from '../providers/auth';
import { parseISO, format } from 'date-fns';
import { confirmDialog } from 'primereact/confirmdialog';

interface Props {
  feriadoFinanceiro?: IFeriadoFinanceiro;
  setFeriadoFinanceiroDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteFeriadoFinanceiro: boolean;
  setDeleteFeriadoFinanceiro(param: boolean): void;
}

const FeriadoFinanceiroForm: React.FC<Props> = ({ setFeriadoFinanceiroDialog, feriadoFinanceiro = null, reload, deletar, deleteFeriadoFinanceiro, setDeleteFeriadoFinanceiro }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'FeriadoFinanceiroForm';

  const toast = useToast();
  const { empresaSelecionada, periodoSelecionado } = useAuth();

  const defaultValues: IFeriadoFinanceiro = feriadoFinanceiro || {
    EmpresaId: empresaSelecionada?.EmpresaId,
    FeriadosFinanceiroId: undefined,
  };

  function formataData(dateString: string | undefined): Date | undefined {
    if (typeof dateString === 'string') {
      const [day, month, year] = dateString.split('/').map(Number);
  
      const fullYear = year;
  
      return new Date(fullYear, month - 1, day);
    }
    return undefined;
  }
  
  useEffect(() => {
    const dataFeriado = formataData(feriadoFinanceiro?.DataFeriadoString);
    setValue('DataFeriado', dataFeriado);
  }, [feriadoFinanceiro]);

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const onSubmit = async (data: IFeriadoFinanceiro) => {
    if (data.DataFeriado && data.DataFeriado < new Date()) {
      confirmDialog({
        message: 'Você informou uma data retroativa,\ndeseja continuar?',
        header: 'Atenção',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: gravar,
      });
    }else{
      gravar();
    }
  };

  const gravar = async () => {
    const data = getValues();
    let resp: any = undefined;
    data.DataFeriadoString = data.DataFeriado && format(new Date(data.DataFeriado), 'yyyy-MM-dd');

    try {
      if (data.FeriadosFinanceiroId === undefined) {
        resp = await api.post('/feriadoFinanceiro', data);
      } else {
        resp = await api.put('/feriadoFinanceiro', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setFeriadoFinanceiroDialog(false);
    } catch (e: any) {
      console.error('Erro:', e);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error || 'Erro desconhecido' });
    }
  }

  const cancelar = (e: any) => {
    setDeleteFeriadoFinanceiro(false);
    setFeriadoFinanceiroDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCCalendar
            control={control}
            errors={errors}
            name="DataFeriado"
            label='Data'
            dateFormat='dd/mm/yy'
            mask='99/99/9999'            
            required
            disabled={deleteFeriadoFinanceiro || feriadoFinanceiro?.FeriadosFinanceiroId !== undefined}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-12 md:col-4">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Descricao"
            label='Descrição'
            maxLength={40}
            required={!deleteFeriadoFinanceiro}
            tarefa={tarefa}
            tooltip='Máximo de 40 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>

        <div className="col-12">
          <div className='xs:flex-column md:flex md:justify-content-between sm:flex-wrap'>
            <div className="flex align-items-center">
              <SCCheckbox
                control={control}
                name="Fixo"
                label='Feriado Fixo (sempre na mesma data)'
                disabled={deleteFeriadoFinanceiro}
                tarefa={tarefa}
              />
            </div>
          </div>
        </div>

        <Divider />
        {deleteFeriadoFinanceiro ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default FeriadoFinanceiroForm;
