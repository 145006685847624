import axios from "axios";
import config from "../utilities/config";

const api = axios.create({
  baseURL: config.baseURL,
});

api.interceptors.request.use(
  function (config: any) {
    const token = '';
    const codigo_ = localStorage.getItem('harpia.codigo');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Codigo = codigo_ || '';

    return config;
  },

  function (error: Error) {
    return Promise.reject(error);
  }
);

export default api;