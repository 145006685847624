import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCButton, SCDialog, SCInputText } from '../components';
import Loading from '../components/Loading';
import FuncionarioForm from './FuncionarioForm';
import IFuncionario from '../interfaces/IFuncionario';
import FuncionarioEditaNomeForm from './FuncionarioEditaNomeForm';
import { FaUserCheck, FaUserSlash } from 'react-icons/fa';
import apiRelatorio from '../services/apiRelatorios';

import LoadingReport from '../components/LoadingReport';
import { useNavigate } from 'react-router-dom';
import ColaboradorFormImp from './Impressao/ColaboradorFormImp';

const Funcionario = () => {
  const toast = useToast();
  const { usuario, empresaSelecionada } = useAuth();

  let emptyFuncionario: IFuncionario = {
    FuncionarioId: undefined,
    Matricula: 0,
    Nome: '',
    Guerra: '',
    DataNascimento: new Date(''),
    Sexo: '',
    EstadoCivil: '',
    LecionaRegular: 0,
    LecionaGraduacao: 0,
    LecionaPos: 0,
    LecionaTec: 0,
    RG: '',
    CPF: '',
    EnderecoId: 0,
    Cep: '',
    Logradouro: '',
    Complemento: '',
    Numero: '',
    Bairro: '',
    Cidade: '',
    Uf: '',
    TelefoneOutros: '',
    TelefoneCelular: '',
    Email: '',
    GrauInstrucao: '',
    Formacao: '',
    Cargo: '',
    Contratado: 0,
    Observacao: '',
    Ativado: false,
    DataAdimissao: null,
    DataDemissao: null,
    RegimeTrabalho: '',
    CargaHoraria: 0,
    Regencia: 0,
    FuncaoAdministrativa: 0,
    SetorId: 0,
    Titulacao: '',
    AcessoProfessorTurma: 0,
    CadastraAgenda: 0,
    AtendimentoApp: 0,
    AdministradorOs: 0,
    CadastraOs: 0,
    FUNCIOEndereco: [],
    RGORGAO: '',
    TipoAdm: 0,
    TipoProfessor: 0,
    TipoCoordenador: 0,
    DDD: '',
  };

  const [funcionarios, setFuncionarios] = useState<any>(null);
  const [funcionarioDialog, setFuncionarioDialog] = useState<boolean>(false);
  const [editaNomeApelidoDialog, setEditaNomeApelidoDialog] = useState<boolean>(false);
  const [deleteFuncionario, setDeleteFuncioanrio] = useState<boolean>(false);
  const [funcionario, setFuncionario] = useState<IFuncionario>(emptyFuncionario);
  const [selectedFuncionario, setSelectedFuncionario] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [verRelatorioForm, setVerRelatorioForm] = useState<boolean>(false);
  const [verRelatorio, setVerRelatorio] = useState<any>(null);
  const [loadReport, setLoadReport] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>(null);
  const dt = useRef<DataTable>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyFuncionario });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;


  const load = async () => {

    setLoading(true)
    const result = await api.get('/funcionarios/list', { params: { Nome: getValues()?.Nome } });

    if (result.data)
      setFuncionarios(result.data);
    else
      setFuncionarios([]);

    setLoading(false)
  }

  useEffect(() => {
    load().finally(() => clearState());
  }, []);

  const clearState = () => {
    dt?.current?.reset();
  }

  const openNew = () => {
    setFuncionario(emptyFuncionario);
    setSubmitted(false);
    setFuncionarioDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setFuncionarioDialog(false);
  };

  const editar = (funcionario: any) => {
    funcionario.DataNascimento = funcionario.DataNascimento ? new Date(funcionario.DataNascimento) : null
    funcionario.DataAdimissao = funcionario.DataAdimissao ? new Date(funcionario.DataAdimissao) : null
    funcionario.DataDemissao = funcionario.DataDemissao ? new Date(funcionario.DataDemissao) : null

    setFuncionario(funcionario);
    setFuncionarioDialog(true);
  };

  const editaNomeApelido = (funcionario: any) => {
    setFuncionario(funcionario);
    setEditaNomeApelidoDialog(true);
  };

  const confirmDelete = (funcionario: any) => {
    setFuncionario(funcionario);
    setDeleteFuncioanrio(true);
    setFuncionarioDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/funcionarios', { params: { FuncionarioId: funcionario.FuncionarioId, Guerra: funcionario.Guerra } });
      console.log(resp.data);

      await api.delete('/enderecos/matricula', { params: { Matricula: funcionario.Matricula } }); // deletando funcionario da tabela endereços

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido, });
      load();
      setDeleteFuncioanrio(false);
      setFuncionarioDialog(false);
      setFuncionario(emptyFuncionario);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Exclusão cancelada!', detail: e?.response?.data?.error, life: 8000 });
    }

  };

  const onSubmit = async (data: any) => {
    await load();
  }

  const print = async () => {
    setLoadReport(true);
    try {
      const restReport = await apiRelatorio.post('Colaborador/resumido', {
        header: {
          idRelatorio: empresaSelecionada?.IdRelatorio,
          titulo: 'Relatório de Colaboradores',
          subtitulo: 'Organizado por Nome',
          Logo: empresaSelecionada?.Logo?.replace('data:image/png;base64,', '')
        },
        lista: funcionarios.map((item: any) =>
        ({
          Nome: item.Nome,
          Matricula: item.Matricula,
          Guerra: item.Guerra,
          DataNascimento: item.DataNascimento,
          Sexo: item.Sexo,
          Cargo: item.Cargo,
          Ativado: item.Ativado ? 1 : 0,
        }),
          {

          }
        )
      });
      setVerRelatorio(restReport.data);
    } catch (e) {
      console.log(e);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Erro ao gerar relatório' });
    }

    setLoadReport(false);
  }

  useEffect(() => {
    if (verRelatorio) {
      const iframe = document.querySelector("iframe");
      if (iframe)
        iframe.src = verRelatorio;
    }
  }, [verRelatorio])

  if (loading)
    return <Loading />

  return (
    <div className="grid crud-demo">
      {loadReport && (
        <LoadingReport full={true} />
      )}
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Colaboradores</h5>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Nome"
                  label='Nome'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-outlined" />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />
                <Button label='Impressão' icon="pi pi-print" className="p-button-secondary" type='button' onClick={() => setVerRelatorioForm(true)} />
              </div>
            </div>
          </form>

          <Divider />

          {loading ? <Loading /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={funcionarios}
              onSelectionChange={(e) => setSelectedFuncionario(e.value)}
              dataKey="id"
              paginator
              rows={10}
              stateKey='tabColaboradores'
              stateStorage='local'
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }}></Column>
              <Column field="Nome" header="Nome" sortable ></Column>
              <Column field="Guerra" header="Apelido" sortable ></Column>
              <Column field="Ativado" header="Ativado" align={'center'} alignHeader={'center'} sortable body={(rowData: any) => {
                if (rowData.Ativado === true) {
                  return (
                    <FaUserCheck size={20} color="#01902f" />
                  )
                } else {
                  return (
                    <FaUserSlash size={20} color="red" />
                  );
                }
              }} ></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button label='Altera Nome / Apelido' style={{ width: 165 }} className="p-button-info " onClick={() => editaNomeApelido(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 245 }}
              />
            </DataTable>
          )}
          <SCDialog maximized={!util.isDesktop()} visible={funcionarioDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${funcionario.FuncionarioId === undefined ? 'Cadastrando ' : (deleteFuncionario ? 'Excluíndo' : 'Alterando')} Colaborador`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <FuncionarioForm
              setFuncionarioDialog={setFuncionarioDialog}
              funcionario={funcionario}
              reload={load}
              deleteFuncionario={deleteFuncionario}
              setDeleteFuncioanrio={setDeleteFuncioanrio}
              deletar={deletar}
            />
          </SCDialog>

          <SCDialog maximized={!util.isDesktop()} visible={editaNomeApelidoDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`Alterando Nome/Apelido Colaborador`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <FuncionarioEditaNomeForm
              setEditaNomeApelidoDialog={setEditaNomeApelidoDialog}
              funcionario={funcionario}
              reload={load}
            />
          </SCDialog>

          <SCDialog
            header={`Colaboradores - Impressão`}
            visible={verRelatorioForm} style={{ width: '70vw', maxHeight: '60vh' }}
            onHide={() => setVerRelatorioForm(false)}>

            <ColaboradorFormImp setLoadReport={setLoadReport} onPrint={print} setVerRelatorioForm={setVerRelatorioForm} />

          </SCDialog>

          <SCDialog visible={verRelatorio != null} style={{ width: '80vw', height: '90vh' }} onHide={() => setVerRelatorio(null)}>
            <iframe src={verRelatorio} width="100%" height="100%"></iframe>
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default Funcionario;
