import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputText, SCInputTextarea, SCRadioButton } from '../components';
import { TabPanel, TabView } from 'primereact/tabview';
import IBancos from '../interfaces/IBancos';
import { InputSwitch } from 'primereact/inputswitch';
import { ALERTAS } from '../utilities/constantes';

interface Props {
  registro?: IBancos;
  setBancoDialog(param: boolean): void;
  reload(): void;
}

const BancosForm: React.FC<Props> = ({ setBancoDialog, registro, reload }) => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'FuncionarioForm';
  const dt = useRef<any>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [fontes, setFontes] = useState<any>(null);

  const defaultValues: IBancos = registro || {
    BancoId: undefined,
    Ativo: false,
    Sequencial: '',
    CodigoBanco: '',
    NomeBanco: '',
    Logo: '',
    Carteira: '',
    Variacao: '',
    TipoCobranca: 1,
    FonteId: 0,
    Agencia: '',
    CedenteCodigo: '',
    CedenteUnidade: '',
    CedenteNome: '',
    CNPJ: '',
    CedenteTituloAgencia: '',
    CedenteAgencia: '',
    DiasDevolucao: '',
    RemessaSequencia: '',
    Observacao: '',
    RemessaArquivo: false,
    RemessaArquivoAutomatico: false,
    RemessaLinhaR: false,
    RemessaArquivoDebitoAutomatico: false,
    RemessaInformarTarifa: false,
    RemessaPercentualMulta: '',
    RemessaLayout: 1,
    RetornoLayout: 1,
    BoletoCodigoBarras: false,
    BoletoEmiteCarne: false,
    BoletoImprimeDesconto: false,
    BoletoImprimeLinhaDigitavel: false,
    BoletoCalculoValor: 1,
    MensagemSemDesconto01: '',
    MensagemSemDesconto02: '',
    MensagemSemDesconto03: '',
    MensagemSemDesconto04: '',
    MensagemSemDeducao01: '',
    MensagemSemDeducao02: '',
    MensagemSemDeducao03: '',
    MensagemSemDeducao04: '',
    MensagemComDesconto01: '',
    MensagemComDesconto02: '',
    MensagemComDesconto03: '',
    MensagemComDesconto04: '',
    BoletoLocalPagamento: '',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

  useEffect(() => {
    if (registro) {
      setChecked(registro.Ativo)
    }
  }, [registro]);

  const loadFontes = async () => {
    try {
      const { data } = await api.get('/fontes/bancarias', { params: { Tipo: 2, EmpresaId: periodoSelecionado?.EmpresaId } });
      setFontes(data || []);
    } catch (error) {
      console.error('Erro ao carregar fontes bancárias:', error);
      setFontes([]);
    }
  };

  const onSubmit = async (data: IBancos) => {
    setLoading(true)

    let dadosBanco = {
      BancoId: data.BancoId,
      Ativo: data.Ativo,
      Sequencial: data.Sequencial,
      CodigoBanco: data.CodigoBanco,
      NomeBanco: data.NomeBanco,
      Logo: data.Logo,
      Carteira: data.Carteira,
      Variacao: data.Variacao,
      TipoCobranca: data.TipoCobranca,
      FonteId: data.FonteId,
      Agencia: data.Agencia,
      CedenteCodigo: data.CedenteCodigo,
      CedenteUnidade: data.CedenteUnidade,
      CedenteNome: data.CedenteNome,
      CNPJ: data.CNPJ,
      CedenteTituloAgencia: data.CedenteTituloAgencia,
      CedenteAgencia: data.CedenteAgencia,
      DiasDevolucao: data.DiasDevolucao,
      RemessaSequencia: data.RemessaSequencia,
      Observacao: data.Observacao,
      RemessaArquivo: data.RemessaArquivo,
      RemessaArquivoAutomatico: data.RemessaArquivoAutomatico,
      RemessaLinhaR: data.RemessaLinhaR,
      RemessaArquivoDebitoAutomatico: data.RemessaArquivoDebitoAutomatico,
      RemessaInformarTarifa: data.RemessaInformarTarifa,
      RemessaPercentualMulta: data.RemessaPercentualMulta,
      RemessaLayout: data.RemessaLayout,
      RetornoLayout: data.RetornoLayout,
      BoletoCodigoBarras: data.BoletoCodigoBarras,
      BoletoEmiteCarne: data.BoletoEmiteCarne,
      BoletoImprimeDesconto: data.BoletoImprimeDesconto,
      BoletoImprimeLinhaDigitavel: data.BoletoImprimeLinhaDigitavel,
      BoletoCalculoValor: data.BoletoCalculoValor,
      MensagemSemDesconto01: data.MensagemSemDesconto01,
      MensagemSemDesconto02: data.MensagemSemDesconto02,
      MensagemSemDesconto03: data.MensagemSemDesconto03,
      MensagemSemDesconto04: data.MensagemSemDesconto04,
      MensagemSemDeducao01: data.MensagemSemDeducao01,
      MensagemSemDeducao02: data.MensagemSemDeducao02,
      MensagemSemDeducao03: data.MensagemSemDeducao03,
      MensagemSemDeducao04: data.MensagemSemDeducao04,
      MensagemComDesconto01: data.MensagemComDesconto01,
      MensagemComDesconto02: data.MensagemComDesconto02,
      MensagemComDesconto03: data.MensagemComDesconto03,
      MensagemComDesconto04: data.MensagemComDesconto04,
      BoletoLocalPagamento: data.BoletoLocalPagamento,
    };

    if (dadosBanco) {
      try {
        await api.put('/bancos/update', dadosBanco)
        toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
        setLoading(false)
        reload();
        setBancoDialog(false)

      } catch (e: any) {
        setLoading(false)
        toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data });
      }
    }
  }

  const cancelar = () => {
    setBancoDialog(false);
  }

  useEffect(() => {
    loadFontes()
  }, [])


  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} >
        <TabView >
          <TabPanel header="Principal">
            <SCFieldset legend='Identificação do Banco' className='col-12 mt-1 mb-3'>

              <div className='grid'>
                <div className="col-12 md:col-1">
                  <div className='pb-2'>Ativado</div>
                  <InputSwitch
                    name='Ativo' checked={checked} onChange={(e) => setChecked(e.value)} />
                </div>

                <div className="col-12 md:col-1">
                  <SCInputText
                    control={control}
                    name="Sequencial"
                    label='Sequência'
                    style={{ width: '100%' }}
                    disabled={true}
                    className='font-bold'
                  />
                </div>

                <div className="col-12 md:col-1">
                  <SCInputMask
                    control={control}
                    name="CodigoBanco"
                    label='Código'
                    style={{ width: '100%' }}
                    disabled={true}
                    mask='999-9'
                    className='font-bold'
                  />
                </div>

                <div className="col-12 md:col-3">
                  <SCInputText
                    errors={errors}
                    control={control}
                    name="NomeBanco"
                    label='Nome'
                    style={{ width: '100%' }}
                    maxLength={30}
                    tooltip='Máximo de 30 caracteres'
                    required={true}
                    tarefa={tarefa}
                    tooltipOptions={{ event: 'focus' }}
                  />
                </div>

                <div className="col-12 md:col-2 mt-3">
                  <SCInputText
                    errors={errors}
                    control={control}
                    name="Logo"
                    style={{ width: '100%' }}
                    maxLength={30}
                    tooltip='Máximo de 30 caracteres'
                    disabled
                    tarefa={tarefa}
                    tooltipOptions={{ event: 'focus' }}
                    placeholder='LOGO'
                  />
                </div>

              </div>

              <div className='grid'>
                <div className="col-12 md:col-1">
                  <SCInputText
                    control={control}
                    name="Carteira"
                    label='Carteira'
                    style={{ width: '100%' }}
                    disabled={true}
                    className='font-bold'
                  />
                </div>

                <div className="col-12 md:col-1">
                  <SCInputText
                    control={control}
                    name="Variacao"
                    label='Variação'
                    style={{ width: '100%' }}
                    required
                  />
                </div>

                <div className="col-4">
                  <div className='pb-2'>Tipo de Cobrança</div>
                  <div className="flex align-items-center">
                    <SCRadioButton
                      control={control}
                      name="TipoCobranca"
                      label='Registrada'
                      value={1}
                    />
                    <SCRadioButton
                      control={control}
                      name="TipoCobranca"
                      label='Não Registrada'
                      value={2}
                      className='ml-3'
                    />
                  </div>
                </div>

                <div className="col-12 md:col-2">
                  <SCDropdown
                    control={control}
                    errors={errors}
                    name="FonteId"
                    label='Fonte'
                    options={fontes}
                    optionLabel='Codigo'
                    optionValue='FonteId'
                  />
                </div>
              </div>
            </SCFieldset>

            <div className='grid col-12'>
              <SCFieldset legend='Dados da Conta do Cliente' className='col-6 mt-1 mb-3'>
                <div className='grid'>
                  <div className="col-12 md:col-3">
                    <SCInputText
                      control={control}
                      name="Agencia"
                      label='Agência'
                      style={{ width: '100%' }}
                      required
                    />
                  </div>

                  <div className="col-12 md:col-7">
                    <SCInputText
                      control={control}
                      name="CedenteCodigo"
                      label='Nº do Cedente / Conta Corrente / Convênio'
                      style={{ width: '100%' }}
                      required
                      maxLength={7}
                      tooltip='Máximo de 7 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                </div>
                <div className='grid'>
                  <div className="col-12 md:col-6">
                    <SCInputText
                      errors={errors}
                      control={control}

                      name="NomeBanco"
                      label='Nome do Banco'
                      style={{ width: '100%' }}
                      maxLength={30}
                      tooltip='Máximo de 30 caracteres'
                      required={true}
                      tarefa={tarefa}
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <SCInputText
                      errors={errors}
                      control={control}

                      name="CedenteUnidade"
                      label='Unidade Cedente'
                      style={{ width: '100%' }}
                      maxLength={30}
                      tooltip='Máximo de 30 caracteres'
                      required={true}
                      tarefa={tarefa}
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                </div>

                <div className='grid'>
                  <div className="col-12 md:col-6">
                    <SCInputText
                      errors={errors}
                      control={control}
                      name="CedenteNome"
                      label='Nome do Cedente'
                      style={{ width: '100%' }}
                      maxLength={30}
                      tooltip='Máximo de 30 caracteres'
                      required={true}
                      tarefa={tarefa}
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <SCInputMask
                      errors={errors}
                      control={control}
                      name="CNPJ"
                      label='CNPJ / CPF'
                      style={{ width: '100%' }}
                      maxLength={30}
                      tooltip='Máximo de 30 caracteres'
                      required={true}
                      tooltipOptions={{ event: 'focus' }}
                      mask='99.999.999/9999-99'
                    />
                  </div>
                </div>

                <div className='grid'>

                  <div className="col-12 md:col-6">
                    <SCInputText
                      errors={errors}
                      control={control}
                      name="CedenteTituloAgencia"
                      label='Título - Agência/CC/Ced'
                      style={{ width: '100%' }}
                      maxLength={30}
                      tooltip='Máximo de 30 caracteres'
                      required={true}
                      tarefa={tarefa}
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <SCInputText
                      errors={errors}
                      control={control}
                      name="CedenteAgencia"
                      label='Agência/CC/Ced'
                      style={{ width: '100%' }}
                      maxLength={20}
                      tooltip='Máximo de 20 caracteres'
                      required={true}
                      tarefa={tarefa}
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                </div>

                <div className='grid'>
                  <div className="col-12 md:col-6">
                    <SCInputText
                      errors={errors}
                      control={control}
                      name="DiasDevolucao"
                      label='Qtd. de Dias para Baixa/Devolução'
                      style={{ width: '100%' }}
                      maxLength={3}
                      tooltip='Máximo de 3 caracteres'
                      required={true}
                      tarefa={tarefa}
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <SCInputText
                      errors={errors}
                      control={control}
                      name="RemessaSequencia"
                      label='Sequência da Remessa'
                      style={{ width: '100%' }}
                      maxLength={3}
                      tooltip='Máximo de 3 caracteres'
                      required={true}
                      tarefa={tarefa}
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                </div>
              </SCFieldset>

              <SCFieldset legend='Regras de Preenchimento' className='col-5 mt-1 mb-3'>
                <div className="col-12">
                  <SCInputTextarea
                    style={{ minHeight: 150 }}
                    control={control}
                    errors={errors}
                    name="Observacao"
                    label=''
                    rows={10}
                    disabled
                    className='font-bold'
                  />
                </div>
              </SCFieldset>
            </div>
          </TabPanel>

          <TabPanel header="Configurações">
            <div className='grid flex align-items-center col-12'>
              Local para Pagamento
              <div className="col-12 md:col-10">
                <SCInputText
                  errors={errors}
                  control={control}
                  name="BoletoLocalPagamento"
                  style={{ width: '100%' }}
                  maxLength={100}
                  tooltip='Máximo de 100 caracteres'
                  tooltipOptions={{ event: 'focus' }}
                />
              </div>
            </div>

            <div className='grid col-12'>
              <SCFieldset legend='Arquivo de Remessa' className='col-12 mt-1 mb-3'>
                <div className='grid'>
                  <div className="col-6">
                    <SCCheckbox
                      control={control}
                      name="RemessaArquivo"
                      label='Gerar Arquivo de Remessa'
                    />
                  </div>

                  <div className="col-6">
                    <SCCheckbox
                      control={control}
                      name="RemessaArquivoAutomatico"
                      label='Gerar Arquivo de Remessa Automático'
                    />
                  </div>
                </div>

                <div className='grid'>
                  <div className="col-6">
                    <SCCheckbox
                      control={control}
                      name="RemessaLinhaR"
                      label='Gerar o seqmento "R" com os dados de MULTA (para os bancos BB, CAIXA e SANTANDER)'
                    />
                  </div>

                  <div className="col-6">
                    <SCCheckbox
                      control={control}
                      name="RemessaArquivoDebitoAutomatico"
                      label='Gerar Arquivo de Débito Automático'
                    />
                  </div>
                </div>

                <div className='grid flex justify-content-between'>
                  <div className="col-2">
                    <SCCheckbox
                      control={control}
                      name="RemessaInformarTarifa"
                      label='Informar Tarifa'
                    />
                  </div>

                  <div className='flex align-items-center'>
                    <div className=''>Percentual de Multa</div>
                    <div className="col-12 md:col-3">
                      <SCInputText
                        errors={errors}
                        control={control}

                        name="RemessaPercentualMulta"
                        style={{ width: '100%' }}
                        maxLength={3}
                        tooltip='Máximo de 3 caracteres'
                        tarefa={tarefa}
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>
                  </div>

                  <div className="flex col-6">
                    <div className='pb-2'>Layout do Arquivo</div>
                    <div className="flex align-items-center ml-3">
                      <SCRadioButton
                        control={control}
                        name="RemessaLayout"
                        label='CNAB 400'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="RemessaLayout"
                        label='CNAB 240'
                        value={2}
                        className='ml-3'
                      />
                    </div>
                  </div>
                </div>

                <div className='grid'>

                </div>
              </SCFieldset>

              <SCFieldset legend='Arquivo de Retorno' className='col-12 mt-1 mb-3'>
                <div className="flex col-12">
                  <div className='pb-2'>Layout do Arquivo</div>
                  <div className="flex align-items-center ml-3">
                    <SCRadioButton
                      control={control}
                      name="RetornoLayout"
                      label='CNAB 400'
                      value={1}
                    />
                    <SCRadioButton
                      control={control}
                      name="RetornoLayout"
                      label='CNAB 240'
                      value={2}
                      className='ml-3'
                    />
                    <SCRadioButton
                      control={control}
                      name="RetornoLayout"
                      label='CNAB 150 (Somente para o BASA, NR, codbco 40)'
                      value={3}
                      className='ml-3'
                    />
                  </div>
                </div>
              </SCFieldset>

              <SCFieldset legend='Boleto' className='col-12 mt-1 mb-3'>
                <div className='grid'>
                  <div className="col-3">
                    <SCCheckbox
                      control={control}
                      name="BoletoCodigoBarras"
                      label='Imprimir Código de Barra'
                    />
                  </div>
                  <div className="col-3">
                    <SCCheckbox
                      control={control}
                      name="BoletoEmiteCarne"
                      label='Emite Carnê de Pagamento'
                    />
                  </div>
                  <div className="col-3">
                    <SCCheckbox
                      control={control}
                      name="BoletoImprimeDesconto"
                      label='Imprimir o Valor do Desconto'
                    />
                  </div>

                  <div className="col-3">
                    <SCCheckbox
                      control={control}
                      name="BoletoImprimeLinhaDigitavel"
                      label='Imprimir Linha Digitável'
                    />
                  </div>

                  <Divider />

                  <div className="flex col-12">
                    <div className='pb-2'>Valor do boleto</div>
                    <div className="flex align-items-center ml-3">
                      <SCRadioButton
                        control={control}
                        name="BoletoCalculoValor"
                        label='Calcular valor liquido da boleta'
                        value={1}
                      />
                      <SCRadioButton
                        control={control}
                        name="BoletoCalculoValor"
                        label='Calcular valor bruto da boleta'
                        value={2}
                        className='ml-3'
                      />
                    </div>
                  </div>
                </div>
              </SCFieldset>
            </div>
          </TabPanel>

          <TabPanel header="Mensagens">
            <SCFieldset legend='Aluno sem Desconto' className='col-12 mt-3 mb-3'>
              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemSemDesconto01"
                    label='Mensagem 01'
                    style={{ width: '100%' }}
                    errors={errors}
                    maxLength={60}
                    tooltip='Máximo de 60 caracteres'
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemSemDesconto02"
                    label='Mensagem 02'
                    style={{ width: '100%' }}
                    errors={errors}
                    maxLength={60}
                    tooltip='Máximo de 60 caracteres'
                  />
                </div>
              </div>

              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemSemDesconto03"
                    label='Mensagem 03'
                    style={{ width: '100%' }}
                    errors={errors}
                    maxLength={60}
                    tooltip='Máximo de 60 caracteres'
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemSemDesconto04"
                    label='Mensagem 04'
                    style={{ width: '100%' }}
                    errors={errors}
                    maxLength={60}
                    tooltip='Máximo de 60 caracteres'
                  />
                </div>
              </div>
            </SCFieldset>

            <SCFieldset legend='Aluno sem Desconto e sem Dedução' className='col-12 mt-3 mb-3'>
              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemSemDeducao01"
                    label='Mensagem 01'
                    style={{ width: '100%' }}
                    errors={errors}
                    maxLength={60}
                    tooltip='Máximo de 60 caracteres'
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemSemDeducao02"
                    label='Mensagem 02'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemSemDeducao03"
                    label='Mensagem 03'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemSemDeducao04"
                    label='Mensagem 04'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>
              </div>
            </SCFieldset>

            <SCFieldset legend='Aluno com Desconto' className='col-12 mt-3 mb-3'>
              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemComDesconto01"
                    label='Mensagem 01'
                    style={{ width: '100%' }}
                    errors={errors}
                    maxLength={60}
                    tooltip='Máximo de 60 caracteres'
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemComDesconto02"
                    label='Mensagem 02'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>
              </div>

              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemComDesconto03"
                    label='Mensagem 03'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCInputText
                    control={control}
                    name="MensagemComDesconto04"
                    label='Mensagem 04'
                    style={{ width: '100%' }}
                    errors={errors}
                  />
                </div>
              </div>
            </SCFieldset>
          </TabPanel>

        </TabView>

        <div className='grid flex justify-content-between mt-1'>
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Atualizar Dados" icon="pi pi-check" className="p-button w-auto p-button-primary" style={{ width: '100%' }} type='submit' onClick={handleSubmit(onSubmit)} />
          </div>
        </div>

      </form>
    </FormProvider>
  );
};

export default BancosForm
