import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';

import { TabPanel, TabView } from 'primereact/tabview';
import AlunosFormPrincipal from './AlunosFormPrincipal';
import Loading from '../components/Loading';
import AlunosFormMaisInformacoes from './AlunosFormMaisInformacoes';
import AlunoResumo from '../components/AlunoResumo';
import AlunosFormNomeSocial from './AlunosFormNomeSocial';
import AlunosFormDocumentos from './AlunosFormDocumentos';
import AlunosFormPaisResponsaveis from './AlunosFormPaisResponsaveis';
import AlunosFormMatricula from './AlunosFormMatricula';

interface Props {
  alunoId?: any;
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
  loading: boolean;
  setLoading(param: boolean): void;
}

const AlunosForm: React.FC<Props> = ({ alunoId = undefined, reload, deletar, showDelete, setShowDelete, setLoading, loading }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'AlunoForm';

  const { periodoSelecionado } = useAuth();

  const { control, formState: { errors }, getValues, setValue, reset, watch } = useFormContext();

  const watchMatricula = watch('Matricula')

  const loadAluno = async () => {
    const resAca = await api.get('/alunosResponsavel', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: watchMatricula,
        Tipo: 'ACA'
      }
    });

    const resFin = await api.get('/alunosResponsavel', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: watchMatricula,
        Tipo: 'FIN'
      }
    });

    const endMae = await api.get('/endereco', { params: { Matricula: watchMatricula, Tipo: 'MAE' } });
    const endPai = await api.get('/endereco', { params: { Matricula: watchMatricula, Tipo: 'PAI' } });
    const endAluno = await api.get('/endereco', { params: { Matricula: watchMatricula, Tipo: 'ALUNO' } });
    const endResFin = await api.get('/endereco', { params: { Matricula: watchMatricula, Tipo: 'FIN' } });
    const endResAca = await api.get('/endereco', { params: { Matricula: watchMatricula, Tipo: 'ACA' } });

    resFin.data.DataNascimento = resFin.data?.DataNascimento ? new Date(resFin.data?.DataNascimento) : null;
    resAca.data.DataNascimento = resAca.data?.DataNascimento ? new Date(resAca.data?.DataNascimento) : null;

    setValue('ResponsavelACA', resAca.data);
    setValue('ResponsavelFIN', resFin.data);    

    setValue('ALUNOEndereco', endAluno.data);
    setValue('MAEEndereco', endMae.data);
    setValue('PAIEndereco', endPai.data);
    setValue('FINEndereco', endResFin.data);
    setValue('ACAEndereco', endResAca.data);
    setLoading(false);
  }


  async function load(id: any) {
    setLoading(true)
    const { data } = await api.get('/aluno', {
      params: { AlunoId: id }
    })

    const alunoperiodo = await api.get('/alunosPeriodo/matricula', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: data.Matricula
      }
    });

    data.Turma = {
      Codigo: alunoperiodo.data.TurmaCodigo,
    };

    data.AlunosPeriodo = alunoperiodo.data;
    data.DataNascimento = data.DataNascimento ? new Date(data.DataNascimento) : new Date();
    data.DataDeferimento = data.DataDeferimento ? new Date(data.DataDeferimento) : new Date();

    reset(data);
        
  }

  useEffect(() => {
    if (alunoId !== undefined)
      load(alunoId)

  }, [alunoId])

  const watchTurma = watch('Turma');
  useEffect(() => {
    if (watchTurma) {
      if (watchTurma?.NaoAplicaEF)
        setValue('AlunosPeriodo.TurmaEF', '');
      else
        setValue('AlunosPeriodo.TurmaEF', watchTurma?.CodigoTurmaEF);

    }
  }, [watchTurma])

  useEffect(() => {
    if (watchMatricula)
      loadAluno();
  }, [watchMatricula])

  return (
    <>
      {loading && <Loading full={true} />}

      <div className='grid'>
        <AlunoResumo aluno={getValues() as IAlunoForm} mostraPlanoPagamento={true} mostraFoto mostraTurma alteraFoto/>
      </div>

      <TabView renderActiveOnly={false}>
        <TabPanel header="Principal">
          <AlunosFormPrincipal matricula={watchMatricula} />
        </TabPanel>

        <TabPanel header="Pais/Responsáveis">
          <AlunosFormPaisResponsaveis matricula={watchMatricula} showDelete={showDelete} />
        </TabPanel>

        <TabPanel header="Matrícula">
          <AlunosFormMatricula matricula={watchMatricula} showDelete={showDelete} />
        </TabPanel>

        <TabPanel header="Documentos">
          <AlunosFormDocumentos matricula={watchMatricula} showDelete={showDelete} />
        </TabPanel>

        <TabPanel header="Mais Informações">
          <AlunosFormMaisInformacoes matricula={watchMatricula} showDelete={showDelete} />
        </TabPanel>

        <TabPanel header="Nome Social">
          <AlunosFormNomeSocial matricula={watchMatricula} showDelete={showDelete} />
        </TabPanel>
      </TabView>
    </>
  );
};

export default AlunosForm;
