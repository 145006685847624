import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { useForm } from 'react-hook-form';
import { SCCheckbox, SCFieldset, SCInputText, SCRadioButton } from '../components';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { ALERTAS } from '../utilities/constantes';
import { TabPanel, TabView } from 'primereact/tabview';
import IConfiguracoesSecretaria from '../interfaces/IConfiguracoesSecretaria';

const ConfiguracoesSecretaria = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'FuncionarioForm';
  const dt = useRef<any>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues: IConfiguracoesSecretaria = {
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    PER_IMPBOLDB: 0,
    PER_IMPBOLSE: 0,
    PER_IMPNCUR: 0,
    PER_BLNOVMAT: 0,
    PER_VALSEMLA: 0,
    PER_QTDAVA: 0,
    PER_QTDFAL: 0,
    PER_INIAPV: 0,
    PER_PERFAL: 0,
    PER_NOTMAX: 0,
    PER_NOTVER: 0,
    PER_NUMMAT: 0,
    GLO_LIMPALU: 0,
    GLO_EXBAPR: 0,
    GLO_VALCPF: 0,
    GLO_VALEMAIL: 0,
    GLO_MSGPDOC: 0,
    GLO_MSGPFIN: 0,
    GLO_MSGPANT: 0,
    GLO_PINSTAVA: 0,
    GLO_DDDPADRAO: ''
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

  const loadGlobal = async () => {
    setLoading(true);

    try {
      const resp = await api.get('/parametros/buscaParametro', {
        params: {
          Tipo: 'GLO',
          Grupo: 'S',
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial
        }
      });

      if (resp.data) {

        resp.data.forEach((item: any) => {
          let valor;

          if (item.TipoRetorno === 'N') {
            if (item.Codigo === 'DDDPADRAO') {
              valor = item.Valor
            } else {
              valor = Number(item.Valor)
            }

          }

          if (item.TipoRetorno === 'S') {
            valor = item.Valor
          }

          if (item.TipoRetorno === 'B') {
            valor = Number(item.Valor)

            if (valor === 1) {
              valor = true
            } else {
              valor = false
            }
          }

          setValue(item.CodigoPesquisa.trim(), valor);
        });
      }
    } catch (error) {
      console.error('Erro ao carregar os parâmetros:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadPeriodo = async () => {
    setLoading(true);

    try {
      const resp = await api.get('/parametros/buscaParametro', {
        params: {
          Tipo: 'PER',
          Grupo: 'S',
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial
        }
      });

      if (resp.data) {

        resp.data.forEach((item: any) => {
          let valor;

          if (item.TipoRetorno === 'N') {
            valor = Number(item.Valor)
          }

          if (item.TipoRetorno === 'S') {
            valor = item.Valor
          }

          if (item.TipoRetorno === 'B') {
            valor = Number(item.Valor)

            if (valor === 1) {
              valor = true
            } else {
              valor = false
            }
          }

          setValue(item.CodigoPesquisa.trim(), valor);
        });
      }
    } catch (error) {
      console.error('Erro ao carregar os parâmetros:', error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    let resp;

    if (data.GLO_DDDPADRAO.length === 2 || data.GLO_DDDPADRAO == 0 || data.GLO_DDDPADRAO === undefined) {
      data.GLO_DDDPADRAO = "091";
    }


    // Suponho que você deseja enviar um array de dados no corpo da requisição
    let arrayDados = [data];

    console.log(arrayDados)

    try {
      resp = await api.put('/parametros', arrayDados, {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
        }
      });

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setLoading(false);
      loadGlobal()
      loadPeriodo()

    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
      setLoading(false);
    }
  };

  useEffect(() => {
    loadGlobal()
    loadPeriodo()
  }, [])

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Configurações de Secretaria</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          {loading ? (
            <Loading full={false} />
          ) : (
            <TabView>
              <TabPanel header="Período">
                <div className='grid'>
                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_IMPBOLDB"
                      label='Imprimir Boletim para Alunos em Débito'
                    />
                  </div>
                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_IMPBOLSE"
                      label='Imprimir Boletim para Alunos com Pendência de Documentos'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_IMPNCUR"
                      label='Imprimir Disciplina "Não Cursando" no Boletim?'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_BLNOVMAT"
                      label='Bloquear NOVAS MATRÍCULAS neste período'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_VALSEMLA"
                      label='Validar "Sem Lançamento" no processamento de boletim'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="PER_VALCPF"
                      label='Validar CPF do Aluno e do Responsável no cadastro'
                    />
                  </div>

                  <div className="col-6">
                    <SCCheckbox
                      control={control}
                      name="PER_VALEMAIL"
                      label='Obrigar o preenchimento do E-mail'
                    />
                  </div>
                </div>

                <SCFieldset legend='Avaliação' className='col-12 mt-3 mb-3'>
                  <div className='grid'>
                    <div className="col-12 md:col-3 mt-2">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_QTDAVA"
                        label='Quantidade de Avaliações'
                        maxLength={1}
                        tarefa={tarefa}
                        tooltip='Máximo de 1 caracter'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-3 mt-2">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_INIAPV"
                        label='Aprovar a partir da Avaliação'
                        maxLength={1}
                        tarefa={tarefa}
                        tooltip='Máximo de 1 caracter'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-3 mt-2">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_NOTMAX"
                        label='Nota Máxima na Avaliação'
                        maxLength={2}
                        tarefa={tarefa}
                        tooltip='Máximo de 2 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-3 mt-2">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_NOTVER"
                        label='Nota vermelha abaixo de'
                        maxLength={2}
                        tarefa={tarefa}
                        tooltip='Máximo de 2 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-3 mt-2">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_QTDFAL"
                        label='Tolerância máxima de Faltas no mês'
                        maxLength={2}
                        tarefa={tarefa}
                        tooltip='Máximo de 2 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>

                    <div className="col-12 md:col-3 mt-2">
                      <SCInputText
                        control={control}
                        errors={errors}
                        style={{ textTransform: 'uppercase' }}
                        name="PER_PERFAL"
                        label='% Máximo de Faltas para não reprovar'
                        maxLength={2}
                        tarefa={tarefa}
                        tooltip='Máximo de 2 caracteres'
                        tooltipOptions={{ event: 'focus' }}
                      />
                    </div>
                  </div>
                </SCFieldset>

                <div className='grid'>
                  <div className="col-12 md:col-4 mt-2">
                    <SCInputText
                      control={control}
                      errors={errors}
                      style={{ textTransform: 'uppercase' }}
                      name="PER_NUMMAT"
                      label='Último Nº de Matrícula de Alunos'
                      maxLength={5}
                      tarefa={tarefa}
                      tooltip='Máximo de 5 caracteres'
                      tooltipOptions={{ event: 'focus' }}
                    />
                  </div>
                </div>
              </TabPanel>

              <TabPanel header="Global">
                <div className='grid'>
                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="GLO_LIMPALU"
                      label='Limpar as informações no Cadastro para Alunos novos'
                    />
                  </div>

                  <div className="col-5">
                    <SCCheckbox
                      control={control}
                      name="GLO_EXBAPR"
                      label='Exibir Percentual de Aprovação no Boletim'
                    />
                  </div>
                </div>

                <div className='grid'>
                  <SCFieldset legend='Sinalizar no cadastro do aluno pendências de' className='col-5 mt-3 mb-3'>
                    <div className='flex-column'>
                      <div className="col-5">
                        <SCCheckbox
                          control={control}
                          name="GLO_MSGPDOC"
                          label='Documentação'
                        />
                      </div>

                      <div className="col-5">
                        <SCCheckbox
                          control={control}
                          name="GLO_MSGPFIN"
                          label='Financeira'
                        />
                      </div>

                      <div className="col-6">
                        <SCCheckbox
                          control={control}
                          name="GLO_MSGPANT"
                          label='Financeira no Ano Anterior'
                        />
                      </div>
                    </div>
                  </SCFieldset>
                </div>

                <div className="col-12 md:col-6 mt-3">
                  <div className='pb-2'>Padrão para a fórmula dos Instrumentos de Avaliação</div>
                  <div className="flex align-items-center">
                    <SCRadioButton
                      control={control}
                      name="GLO_PINSTAVA"
                      label='Soma'
                      value={1}
                    />
                    <SCRadioButton
                      control={control}
                      name="GLO_PINSTAVA"
                      label='Média'
                      value={2}
                      className='ml-5'
                    />

                    <SCRadioButton
                      control={control}
                      name="GLO_PINSTAVA"
                      label='Em branco'
                      value={3}
                      className='ml-5'
                    />
                  </div>
                </div>

                <div className='col-12'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    style={{ textTransform: 'uppercase' }}
                    name="GLO_NORDEXC"
                    label='Números para não considerar na ordem de classe (Ex.: 7, 15, 24, 99, etc)'
                    tarefa={tarefa}
                  />
                </div>

                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    style={{ textTransform: 'uppercase' }}
                    name="GLO_DDDPADRAO"
                    label='DDD Padrão'
                    tarefa={tarefa}
                    maxLength={3}
                    numberOnly
                  />
                </div>

              </TabPanel>
            </TabView>
          )}

          <div className='grid flex justify-content-end'>
            <div className='col-12 md:col-2'>
              <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='submit' style={{ width: '100%' }} onClick={handleSubmit(onSubmit)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfiguracoesSecretaria;
