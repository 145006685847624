import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IOcorrenciaEstorno from '../interfaces/IOcorrenciaEstorno';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import OcorrenciaEstornoForm from './OcorrenciaEstornoForm';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { IoMdCheckboxOutline } from "react-icons/io";


const OcorrenciaEstorno = () => {
  const toast = useToast();
  const { usuario } = useAuth();
  const tarefa = 'OcorrenciaEstornoForm';

  let emptyOcorrenciaEstorno: IOcorrenciaEstorno = {
    CodigosEstornoId: undefined,
    Codigo: '',
    Descricao: '',
  };

  const [ocorrenciasEstorno, setOcorrenciasEstorno] = useState<any>(null);
  const [ocorrenciaEstornmDialog, setOcorrenciaEstornDialog] = useState<boolean>(false);
  const [deleteOcorrenciaEstorno, setDeleteOcorrenciaEstorno] = useState<boolean>(false);
  const [ocorrenciaEstorno, setOcorrenciaEstorno] = useState<IOcorrenciaEstorno>(emptyOcorrenciaEstorno);
  const [selectedOcorrenciaEstorno, setSelectedOcorrenciaEstorno] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyOcorrenciaEstorno });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);

    const result = await api.get('/codigosestorno/list', { params: { Descricao: getValues()?.Descricao } });

    if (result.data) {
      setOcorrenciasEstorno(result.data);
    } else {
      setOcorrenciasEstorno([]);
    }
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setOcorrenciaEstorno(emptyOcorrenciaEstorno);
    setSubmitted(false);
    setOcorrenciaEstornDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setOcorrenciaEstornDialog(false);
  };
  const editar = (ocorrencia: any) => {
    setOcorrenciaEstorno(ocorrencia);
    setOcorrenciaEstornDialog(true);
  };

  const confirmDelete = (ocorrencia: any) => {
    setOcorrenciaEstorno(ocorrencia);
    setDeleteOcorrenciaEstorno(true);
    setOcorrenciaEstornDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/codigosestorno', { params: { Codigo: ocorrenciaEstorno.Codigo } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteOcorrenciaEstorno(false);
      setOcorrenciaEstornDialog(false);
      setOcorrenciaEstorno(emptyOcorrenciaEstorno);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Ocorrência de Estorno</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                {usuario?.SuperMaster == 1 && (
                  <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />
                )}

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={ocorrenciasEstorno}
              onSelectionChange={(e) => setSelectedOcorrenciaEstorno(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Codigo" header="Código" sortable headerStyle={{ width: 92 }}></Column>
              <Column field="Descricao" header="Descrição" sortable ></Column>
              <Column bodyStyle={{ width: 60 }} field="Desativado" header="Ativado" align={'center'} alignHeader={'center'} sortable body={(rowData: any) => {
                return (
                  <IoMdCheckboxOutline size={20} color={rowData.Desativado ? 'red' : "#01902f"} />
                )
              }} ></Column>
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    {usuario?.SuperMaster == 1 && (
                      <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                    )}
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}
          <SCDialog maximized={!util.isDesktop()} visible={ocorrenciaEstornmDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${ocorrenciaEstorno.CodigosEstornoId === undefined ? 'Cadastrando ' : (deleteOcorrenciaEstorno ? 'Excluíndo' : 'Alterando')} Ocorrência de Estorno`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <OcorrenciaEstornoForm
              setOcorrenciaEstornoDialog={setOcorrenciaEstornDialog}
              ocorrenciaEstorno={ocorrenciaEstorno}
              reload={load}
              deleteOcorrenciaEstorno={deleteOcorrenciaEstorno}
              setDeleteOcorrenciaEstorno={setDeleteOcorrenciaEstorno}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default OcorrenciaEstorno;
