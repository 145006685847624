import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { IUsuario } from '../interfaces/IUsuario';
import GerenciadorUsuariosForm from './GerenciadorUsuariosForm';
import { FaUserTie, FaUserSlash, FaUserCheck } from "react-icons/fa";
import { Image } from 'primereact/image';
import semFoto from '../assets/sem-foto.png';

const GerenciadorUsuarios = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'FuncionarioForm';

  let emptyUsuario: IUsuario = {
    EmpresaId: periodoSelecionado?.EmpresaId
  };

  const [usuarios, setUsuarios] = useState<any>(null);
  const [usuarioDialog, setUsuarioDialog] = useState<boolean>(false);
  const [deleteUsuario, setDeleteUsuario] = useState<boolean>(false);
  const [usuario, setUsuario] = useState<IUsuario>(emptyUsuario);
  const [selectedUsuario, setSelectedUsuario] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [perfilUsuarioDialog, setPerfilUsuarioDialog] = useState<boolean>(false);
  const [perfilLista, setPerfilLista] = useState<any>([]);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyUsuario });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {

    setLoading(true)
    const result = await api.get('/usuario/listaUsuario', {
      params: {
        Nome: getValues?.('Nome')
      }
    });

    if (result.data)
      setUsuarios(result.data);
    else
      setUsuarios([]);
    setLoading(false)
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setUsuario(emptyUsuario);
    setSubmitted(false);
    setUsuarioDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setUsuarioDialog(false);
  };

  const hidePerfilUsuarioDialog = () => {
    setUsuario(emptyUsuario);
    setPerfilUsuarioDialog(false);
  }

  const editar = (usuario: any) => {
    setUsuario(usuario);
    setUsuarioDialog(true);
  };

  const confirmDelete = (usuario: any) => {
    setUsuario(usuario);
    setDeleteUsuario(true);
    setUsuarioDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/usuario', { params: { UsuarioId: usuario.UsuarioId } });
      console.log(resp.data);

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteUsuario(false);
      setUsuarioDialog(false);
      setUsuario(emptyUsuario);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    console.log(data);
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const exibirPerfisUsuario = (usuario: any) => {
    setUsuario(usuario);
    carregaPerfisUsuario(usuario.UsuarioId);
    setPerfilUsuarioDialog(true);
  }

  const carregaPerfisUsuario = (UsuarioId: any) => {
    api.get('/usuario/perfis', {params: { UsuarioId }}).then(({data}) => {
      setPerfilLista(data);
    }).catch(error => {
        console.log('Erro ao carregar perfis', error);
    })

  };

  if (loading)
    return <Loading />

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Gerenciador de Usuários (Global)</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Nome"
                  label='Nome'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />

          {loading ? <Loading /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={usuarios}
              onSelectionChange={(e) => setSelectedUsuario(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column field="Foto" header="" body={(rowData: any) => {
                return <Image imageClassName='border-round-md border-1' src={rowData.Foto || semFoto} alt="sem-foto" width='36' height='40' />
              }} ></Column>
              <Column field="Nome" header="Nome" sortable ></Column>
              <Column field="Email" header="E-mail (Login)*" sortable ></Column>
              <Column field="Ativo" header="Ativado" align={'center'} alignHeader={'center'} sortable body={(rowData: any) => {
                if (rowData.Ativo === true) {
                  return (
                    <FaUserCheck size={20} color="#01902f" />
                  )
                } else {
                  return (
                    <FaUserSlash size={20} color="red" />
                  );
                }
              }} ></Column>

              <Column field="Master" body={(rowData: any) => {
                if (rowData.Master === true) {
                  return (
                    <div className="flex align-items-center justify-content-between">
                      <FaUserTie size={20} color="#0772b3" />
                    </div>
                  );
                }
              }}>
              </Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-info" onClick={() => exibirPerfisUsuario(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 120 }}
              />
            </DataTable>
          )}
          <SCDialog maximized={!util.isDesktop()} visible={usuarioDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${usuario.UsuarioId === undefined ? 'Cadastrando ' : (deleteUsuario ? 'Excluíndo' : 'Alterando')} Usuário`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <GerenciadorUsuariosForm
              setUsuarioDialog={setUsuarioDialog}
              usuario={usuario}
              reload={load}
              deleteUsuario={deleteUsuario}
              setDeleteUsuario={setDeleteUsuario}
              deletar={deletar}
            />
          </SCDialog>


          <SCDialog maximized={!util.isDesktop()} visible={perfilUsuarioDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable
            header={`Perfis de ${usuario?.Nome} `}
            modal className="p-fluid" onHide={hidePerfilUsuarioDialog}
          >
            <DataTable
              size='small'
              stripedRows
              value={perfilLista}
              dataKey="id"
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="IDUnidade" header="Unidade" sortable ></Column>
              <Column field="Modulo" header="Módulo" sortable headerStyle={{ width: 120 }}></Column>
              <Column field="Codigo" header="Código" sortable headerStyle={{ width: 150 }}></Column>
              <Column field="Descricao" header="Descrição" sortable ></Column>
            </DataTable>
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default GerenciadorUsuarios;
