import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCCheckbox, SCDropdown, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import ICodigoOcorrencia from '../interfaces/ICodigoOcorrencia';
import { useAuth } from '../providers/auth';
import { IPeriodo } from '../interfaces/IUsuario';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { FaBackspace } from 'react-icons/fa';


interface Props {
  codigoOcorrencia?: ICodigoOcorrencia;
  setCodigoOcorrenciaDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteCodigoOcorrencia: boolean;
  setDeleteCodigoOcorrencia(param: boolean): void;
}

interface Documento {
  DocumentoId: number;
  // Outras propriedades...
}

const CodigoOcorrenciaForm: React.FC<Props> = ({ setCodigoOcorrenciaDialog, codigoOcorrencia, reload, deletar, deleteCodigoOcorrencia, setDeleteCodigoOcorrencia }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'CodigoOcorrenciaForm';

  const toast = useToast();
  const [areaSelecionada, setAreaSelecionada] = useState<any>(null);
  const [desativaAprovaBaseMedia, setDesativaAprovaBaseMedia] = useState<boolean>(false);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [geraHistoricoCheck, setGeraHistoricoCheck] = useState<boolean>(false)
  const [cursoLivreCheck, setCursoLivreCheck] = useState<boolean>(false)
  const [documentos, setDocumentos] = useState<any>([]);
  const [contas, setContas] = useState<any>([]);
  const [checked, setChecked] = useState(false);

  const { periodoSelecionado } = useAuth()
  const periodo = periodoSelecionado?.Id

  const defaultValues: ICodigoOcorrencia = codigoOcorrencia || {
    CodigosOcorrenciaId: undefined,
    Codigo: '',
    Descricao: '',
    Ativado: true,
    PrazoAtendimento: '',
    RequerDisciplina: false,
    Requerimento: false,
    RequerAnexo: false,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  useEffect(() => {
    const load = async () => {
      const resultContas = await api.get('/planoContas/combo', { params: { Tipo: 'ENTRADA' } });
      setContas(resultContas.data);
    }
    load()
  }, []);

  const onSubmit = async (data: ICodigoOcorrencia) => {
    let resp: any = undefined;
    console.log('data', data)

    // Transformando em UPPER antes de salvar
    data.Codigo = data.Codigo?.toUpperCase()
    data.Descricao = data.Descricao?.toUpperCase()

    try {
      if (data.CodigosOcorrenciaId === undefined) {
        resp = await api.post('/codigosOcorrencia', data);
      } else {
        resp = await api.put('/codigosOcorrencia', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setCodigoOcorrenciaDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setDeleteCodigoOcorrencia(false);
    setCodigoOcorrenciaDialog(false);
    reset();
  }

  useEffect(() => {
    if (codigoOcorrencia) {
      setChecked(codigoOcorrencia.Ativado)
    }
  }, [codigoOcorrencia]);

  useEffect(() => {
    setValue('Ativado', checked)
  }, [checked]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código'
            maxLength={6}
            disabled={codigoOcorrencia?.CodigosOcorrenciaId !== undefined}
            required={!deleteCodigoOcorrencia}
            tarefa={tarefa}
            tooltip='Máximo de 6 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
        <div className="col-12 md:col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Descricao"
            label='Descrição'
            maxLength={60}
            tarefa={tarefa}
            tooltip='Máximo de 60 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>

        <div className="col-12">
          <div className="col-12 md:col-2">
            <div className='pb-2'>Ativado</div>
            <InputSwitch
              name='Ativado' checked={checked} onChange={(e) => setChecked(e.target.value)} />
          </div>
          <div className='col-2 pl-0'>
            <SCInputText
              control={control}
              errors={errors}
              maxLength={30}
              name="PrazoAtendimento"
              label='Prazo de Atendimento'
              tarefa={tarefa}
              tooltip='Máximo de 30 caracteres'
              tooltipOptions={{ event: 'focus' }}
            />
          </div>
        </div>

        <div className="col-12">
          <div className='pb-2' style={{ color: "#0772B3" }}>Outras Configurações</div>
          <div className='md:justify-content-between sm:flex-wrap'>
            <div className="flex align-items-center">
              <SCCheckbox
                checked={true}
                onChange={(e) => setCursoLivreCheck(e.target.checked)}
                control={control}
                name="RequerDisciplina"
                label='Precisa de disciplina'
                tarefa={tarefa}
              />
            </div>

            <div className="flex align-items-center">
              <SCCheckbox
                checked={true}
                onChange={(e) => setCursoLivreCheck(e.target.checked)}
                control={control}
                name="Requerimento"
                label='Requerimento'
                tarefa={tarefa}
              />
            </div>

            <div className="flex align-items-center">
              <SCCheckbox
                checked={true}
                onChange={(e) => setCursoLivreCheck(e.target.checked)}
                control={control}
                name="RequerAnexo"
                label='Precisa de anexo'
                tarefa={tarefa}
              />
            </div>
          </div>
        </div>

        <div className="col-12 md:col-8">
          <SCDropdown
            control={control}
            errors={errors}
            name="PlanoContaId"
            label='Conta'
            options={contas}
            optionLabel='ContaDescricao'
            optionValue='PlanoContaId'
            tarefa={tarefa}
          />
        </div>

        <Divider />
        {deleteCodigoOcorrencia ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default CodigoOcorrenciaForm;
