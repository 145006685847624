import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCCheckbox, SCDropdown, SCInputNumber, SCInputText } from '../components';
import { ALERTAS } from '../utilities/constantes';
import SelectAlunoAutoComplete from '../components/SelectAlunoAutoComplete';
import IPlanoContas from '../interfaces/IPlanoConta';
import { IAlunoForm } from '../interfaces/IAlunos';
import { IAlunosPeriodo } from '../interfaces/IAlunosPeriodo';


const AlunosFormValoresComplementares = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();

  let defaultValues: any = {
    Aluno: undefined,
    AlunoPeriodo: undefined,
    Parcelas: [],
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [parcelas, setParcelas] = useState<any[]>([]);
  const [editingRows, setEditingRows] = useState<any>({});
  const [contasEntrada, setContasEntrada] = useState<any[]>([]);
  const [contasSaida, setContasSaida] = useState<any[]>([]);
  const [contasUsadas, setContasUsadas] = useState<any[]>([]);
  const [deducao, setDeducao] = useState<any>('');
  const [contaDeducao, setContaDeducao] = useState<any>('');
  const [contaAcrescimo01, setContaAcrescimo01] = useState<any>('');
  const [acrescimo01, setAcrescimo01] = useState<any>('');
  const [contaAcrescimo02, setContaAcrescimo02] = useState<any>('');
  const [acrescimo02, setAcrescimo02] = useState<any>('');
  const [contaAcrescimo03, setContaAcrescimo03] = useState<any>('');
  const [acrescimo03, setAcrescimo03] = useState<any>('');
  const [contaAcrescimo04, setContaAcrescimo04] = useState<any>('');
  const [acrescimo04, setAcrescimo04] = useState<any>('');
  const [contaAcrescimo05, setContaAcrescimo05] = useState<any>('');
  const [acrescimo05, setAcrescimo05] = useState<any>('');
  const [contaAcrescimo06, setContaAcrescimo06] = useState<any>('');
  const [acrescimo06, setAcrescimo06] = useState<any>('');

  const dt = useRef<any>(null);

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors },  setValue, setError, clearErrors, watch, getValues } = methods;

  const watchAluno: IAlunoForm = watch('Aluno');
  const watchParcelas: any = watch('Parcelas');
  const watchAlunoPeriodo:IAlunosPeriodo = watch('AlunoPeriodo');

  useEffect(() => {
    carregaContas();
  }, [])

  const load = async () => {
    setLoading(true);
    const alunoPeriodo = getValues().AlunoPeriodo;
    try {
      const result = await api.get('/mensalidades', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Matricula: alunoPeriodo?.Matricula
        }
      });
      setValue('Parcelas', result.data.map((x: any) => ({ ...x, check: false })));
      setContaDeducao(alunoPeriodo?.ContaValorDeducao);
      setContaAcrescimo01(alunoPeriodo?.ContaValorComplementar01);
      setContaAcrescimo02(alunoPeriodo?.ContaValorComplementar02);
      setContaAcrescimo03(alunoPeriodo?.ContaValorComplementar03);
      setContaAcrescimo04(alunoPeriodo?.ContaValorComplementar04);
      setContaAcrescimo05(alunoPeriodo?.ContaValorComplementar05);
      setContaAcrescimo06(alunoPeriodo?.ContaValorComplementar06);
      setDeducao(contasSaida.find((c: IPlanoContas) => c.Conta === alunoPeriodo?.ContaValorDeducao)?.DescricaoSimplificada || '');
      setAcrescimo01(contasEntrada.find((c: IPlanoContas) => c.Conta === alunoPeriodo?.ContaValorComplementar01)?.DescricaoSimplificada || '');
      setAcrescimo02(contasEntrada.find((c: IPlanoContas) => c.Conta === alunoPeriodo?.ContaValorComplementar02)?.DescricaoSimplificada || '');
      setAcrescimo03(contasEntrada.find((c: IPlanoContas) => c.Conta === alunoPeriodo?.ContaValorComplementar03)?.DescricaoSimplificada || '');
      setAcrescimo04(contasEntrada.find((c: IPlanoContas) => c.Conta === alunoPeriodo?.ContaValorComplementar04)?.DescricaoSimplificada || '');
      setAcrescimo05(contasEntrada.find((c: IPlanoContas) => c.Conta === alunoPeriodo?.ContaValorComplementar05)?.DescricaoSimplificada || '');
      setAcrescimo06(contasEntrada.find((c: IPlanoContas) => c.Conta === alunoPeriodo?.ContaValorComplementar06)?.DescricaoSimplificada || '');

    } catch (e: any) {
      console.error(e.response?.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    const alunoPeriodo = getValues().AlunoPeriodo;
    const aluno = getValues().Aluno;
    setLoading(true);
    try {

      // atualiza aluno
      await api.put('/alunosPeriodo', {        
        AlunosPeriodoId: alunoPeriodo?.AlunosPeriodoId,
        ContaValorComplementar01: contaAcrescimo01,
        ContaValorComplementar02: contaAcrescimo02,
        ContaValorComplementar03: contaAcrescimo03,
        ContaValorComplementar04: contaAcrescimo04,
        ContaValorComplementar05: contaAcrescimo05,
        ContaValorComplementar06: contaAcrescimo06,
        ContaValorDeducao: contaDeducao,
      });

      const mensalidadesMarcadas = data.Parcelas?.filter((x: any)=> x.check).map(({ check, ParcelaDescricao, ...rest }: any) => rest)

      await api.put('/mensalidades', {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: aluno.Matricula,
        Parcelas: mensalidadesMarcadas,
      });
      getAlunoPeriodo(aluno.Matricula);
      setLoading(false);
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
    } catch (error) {
      console.error('Erro ao enviar dados para o backend:', error);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Erro ao gravar os dados.' });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (watchAluno?.Matricula) {
      getAlunoPeriodo(watchAluno.Matricula);
    }
  }, [watchAluno]);

  const carregaContas = async () => {
    const reqContasEntrada = await api.get('/planoContas/comboEntrada');
    setContasEntrada(reqContasEntrada.data);

    const reqContasSaida = await api.get('/planoContas/comboSaida');
    setContasSaida(reqContasSaida.data);
  }

  useEffect(() => {
    setContasUsadas([contaDeducao, acrescimo01, acrescimo02, acrescimo03, acrescimo04, acrescimo05, acrescimo06]);
  }, [contaDeducao, acrescimo01, acrescimo02, acrescimo03, acrescimo04, acrescimo05, acrescimo06])

  const verificaAlunoContasAcrescimo = (coluna: any) =>{
     switch (coluna) {
      case 1:
        return watchAlunoPeriodo?.ContaValorComplementar01 !== '';
      case 2:
        return watchAlunoPeriodo?.ContaValorComplementar02 !== '';
      case 3:
        return watchAlunoPeriodo?.ContaValorComplementar03 !== '';
      case 4:
        return watchAlunoPeriodo?.ContaValorComplementar04 !== '';
      case 5:
        return watchAlunoPeriodo?.ContaValorComplementar05 !== '';
      case 6:
        return watchAlunoPeriodo?.ContaValorComplementar06 !== '';
      default:
        return false;
     }
  }

  const verificaParcelaComVencimento = (parcela: any) =>{
    return getValues().Parcelas?.some((p: any) => p.Parcela == parcela.Parcela && p.DataVencimento !== null);
  }
  
  const handleCheck = (index: any) => {
    const newParcelas = getValues().Parcelas;
    newParcelas[index].check = !newParcelas[index].check;
    setValue('Parcelas', newParcelas);
  }

  const getAlunoPeriodo = async (matricula: any) => {
    setLoading(true);
    const { data } = await api.get('/alunosPeriodo/matricula', {
        params: { 
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,                
            Matricula: matricula 
        }
    });  
    setValue('AlunoPeriodo', data);
    setLoading(false);
  }

  useEffect(() => {
    if (watchAlunoPeriodo?.Matricula) {
      load();
    }
  }, [watchAlunoPeriodo]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
              <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Valores Complementares / Aluno [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
            </div>

            <div className='grid mt-2'>
              <div className='col-12'>
                <div className='grid'>
                  <div className='col-12 md:col-6'>
                    <SelectAlunoAutoComplete 
                      name='AlunoId'
                      control={control}
                      errors={errors}
                      label='Aluno'
                      loading={loading}
                      minLength={3}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  </div>
                  
                  <div className='col-12 md:col-2'>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Aluno.TurmaCodigo"
                      label="Código da Turma"
                      disabled
                      className='w-full'
                    />
                  </div>
                  <div className='col-12 md:col-4'>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Aluno.TurmaDescricao"
                      label="Nome da Turma"
                      disabled
                      className='w-full'
                    />
                  </div>
                </div>                
              </div>              
            </div>

            <Divider />

            {watchAlunoPeriodo?.Matricula && (            
              <>
              <div className='grid'>
                  <div className='col-12 md:col-3'>                
                    <SCDropdown 
                      label='Dedução'
                      resetFilterOnHide
                      dropdownIcon='pi pi-chevron-down'
                      options={contasSaida}
                      optionLabel="DescricaoSimplificada"
                      optionValue="DescricaoSimplificada"
                      value={deducao}
                      onChange={(e) => {
                        setDeducao(e.value);
                        setContaDeducao(contasSaida.find((c: IPlanoContas) => c.DescricaoSimplificada === e.value)?.Conta);
                      }}
                      className="w-full"
                    />
                  </div>  
                </div>  
                
                <Divider />
                
                <div className='grid'>
                  <div className='col-12 md:col-3'>                
                    <SCDropdown 
                      label='Acréscimo 1'
                      resetFilterOnHide
                      dropdownIcon='pi pi-chevron-down'
                      options={contasEntrada.filter((c: IPlanoContas) => !contasUsadas.includes(c.DescricaoSimplificada) || acrescimo01 === c.DescricaoSimplificada)}
                      optionLabel="DescricaoSimplificada"
                      optionValue="DescricaoSimplificada"
                      value={acrescimo01}
                      onChange={(e) => {
                        setAcrescimo01(e.value);
                        setContaAcrescimo01(contasEntrada.find((c: IPlanoContas) => c.DescricaoSimplificada === e.value)?.Conta);
                      }}
                      disabled={verificaAlunoContasAcrescimo(1)}                      
                      className="w-full"
                    />
                  </div>       
                  <div className='col-12 md:col-3'>                
                    <SCDropdown 
                      label='Acréscimo 2'
                      resetFilterOnHide
                      dropdownIcon='pi pi-chevron-down'
                      options={contasEntrada.filter((c: IPlanoContas) => !contasUsadas.includes(c.DescricaoSimplificada) || acrescimo02 === c.DescricaoSimplificada)}
                      optionLabel="DescricaoSimplificada"
                      optionValue="DescricaoSimplificada"
                      value={acrescimo02}
                      onChange={(e) => {
                        setAcrescimo02(e.value);
                        setContaAcrescimo02(contasEntrada.find((c: IPlanoContas) => c.DescricaoSimplificada === e.value)?.Conta);
                      }}
                      disabled={verificaAlunoContasAcrescimo(2)}                      
                      className="w-full"
                    />
                  </div>                     
                  <div className='col-12 md:col-3'>                
                    <SCDropdown 
                      label='Acréscimo 3'
                      resetFilterOnHide
                      dropdownIcon='pi pi-chevron-down'
                      options={contasEntrada.filter((c: IPlanoContas) => !contasUsadas.includes(c.DescricaoSimplificada) || acrescimo03 === c.DescricaoSimplificada)}
                      optionLabel="DescricaoSimplificada"
                      optionValue="DescricaoSimplificada"
                      value={acrescimo03}
                      onChange={(e) => {
                        setAcrescimo03(e.value);
                        setContaAcrescimo03(contasEntrada.find((c: IPlanoContas) => c.DescricaoSimplificada === e.value)?.Conta);
                      }}
                      disabled={verificaAlunoContasAcrescimo(3)}                      
                      className="w-full"
                    />
                  </div> 
                </div>  
                <div className='grid'>
                  <div className='col-12 md:col-3'>                
                    <SCDropdown 
                      label='Acréscimo 4'
                      resetFilterOnHide
                      dropdownIcon='pi pi-chevron-down'
                      options={contasEntrada.filter((c: IPlanoContas) => !contasUsadas.includes(c.DescricaoSimplificada) || acrescimo04 === c.DescricaoSimplificada)}
                      optionLabel="DescricaoSimplificada"
                      optionValue="DescricaoSimplificada"
                      value={acrescimo04}
                      onChange={(e) => {
                        setAcrescimo04(e.value);
                        setContaAcrescimo04(contasEntrada.find((c: IPlanoContas) => c.DescricaoSimplificada === e.value)?.Conta);
                      }}
                      disabled={verificaAlunoContasAcrescimo(4)}                      
                      className="w-full"
                    />
                  </div>    
                
                <div className='col-12 md:col-3'>                
                  <SCDropdown 
                    label='Acréscimo 5'
                    resetFilterOnHide
                    dropdownIcon='pi pi-chevron-down'
                    options={contasEntrada.filter((c: IPlanoContas) => !contasUsadas.includes(c.DescricaoSimplificada) || acrescimo05 === c.DescricaoSimplificada)}
                    optionLabel="DescricaoSimplificada"
                    optionValue="DescricaoSimplificada"
                    value={acrescimo05}
                    onChange={(e) => {
                      setAcrescimo05(e.value);
                      setContaAcrescimo05(contasEntrada.find((c: IPlanoContas) => c.DescricaoSimplificada === e.value)?.Conta);
                    }}
                      disabled={verificaAlunoContasAcrescimo(5)}                      
                      className="w-full"
                  />
                </div>       
                <div className='col-12 md:col-3'>                
                  <SCDropdown 
                    label='Acréscimo 6'
                    resetFilterOnHide
                    dropdownIcon='pi pi-chevron-down'
                    options={contasEntrada.filter((c: IPlanoContas) => !contasUsadas.includes(c.DescricaoSimplificada) || acrescimo06 === c.DescricaoSimplificada)} 
                    optionLabel="DescricaoSimplificada"
                    optionValue="DescricaoSimplificada"
                    value={acrescimo06}
                    onChange={(e) => {
                      setAcrescimo06(e.value);
                      setContaAcrescimo06(contasEntrada.find((c: IPlanoContas) => c.DescricaoSimplificada === e.value)?.Conta);
                    }}
                      disabled={verificaAlunoContasAcrescimo(6)}                      
                      className="w-full"
                  />
                </div>                                       
              </div>  
              <Divider />
            </>
            )}

            <div className='grid pb-8'>
              <div className='col-12'>
                <table cellSpacing={0} cellPadding={2} id="Mensalidades" className='w-full'>
                  <thead>
                    <tr>
                      <th style={{minWidth: 100}}>Parcela</th>
                      <th style={{maxWidth: 22}}></th>
                      <th style={{maxWidth: 80, textAlign: 'center'}}>Valor</th>
                      <th style={{maxWidth: 80}}>Desconto</th>
                      <th style={{maxWidth: 80, color: 'red', textAlign: 'center'}}>{deducao ? deducao : 'Valor Dedução'}</th>
                      <th style={{maxWidth: 80, color: 'green'}}>{acrescimo01 ? acrescimo01 : 'Acréscimo 1'}</th>
                      <th style={{maxWidth: 80, color: 'green'}}>{acrescimo02 ? acrescimo02 : 'Acréscimo 2'}</th>
                      <th style={{maxWidth: 80, color: 'green'}}>{acrescimo03 ? acrescimo03 : 'Acréscimo 3'}</th>                      
                      <th style={{maxWidth: 80, color: 'green'}}>{acrescimo04 ? acrescimo04 : 'Acréscimo 4'}</th>                      
                      <th style={{maxWidth: 80, color: 'green'}}>{acrescimo05 ? acrescimo05 : 'Acréscimo 5'}</th>                      
                      <th style={{maxWidth: 80, color: 'green'}}>{acrescimo06 ? acrescimo06 : 'Acréscimo 6'}</th>                      
                    </tr>
                  </thead>
                  <tbody>
                    {watchParcelas?.length > 0 ? (
                      watchParcelas?.map((parcela: any, index: any) => (
                        <tr key={parcela.MensalidadeId}>
                          <td >{parcela.Parcela} {parcela.ParcelaDescricao}</td>
                          <td><SCCheckbox onChange={(e) =>  handleCheck(index)} checked={parcela.check} name="Sel" disabled={parcela.LinhaDigitavel?.length > 0} /></td>
                          <td style={{maxWidth: 80, textAlign: 'center'}}>
                            <SCInputNumber inputStyle={{maxWidth: 80, padding: 2, textAlign: 'right'}} 
                              value={parcela.ValorMensalidade} 
                              control={control}
                              errors={errors}
                              disabled={!parcela.check}
                              name={`Parcelas.[${index}].ValorMensalidade`}
                              allowEmpty={false}                              
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              currency='BRL'
                              locale='pt-BR'/>
                          </td>
                          <td style={{maxWidth: 80, textAlign: 'center'}}>
                            <SCInputNumber inputStyle={{maxWidth: 80, padding: 2, textAlign: 'right'}} 
                              value={parcela.ValorDesconto} 
                              control={control}
                              errors={errors}
                              disabled={!parcela.check}
                              name={`Parcelas.[${index}].ValorDesconto`}
                              allowEmpty={false}
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              currency='BRL'
                              locale='pt-BR'/>
                            </td>
                          <td style={{maxWidth: 80, textAlign: 'center'}}>
                            <SCInputNumber inputStyle={{ maxWidth: 80, padding: 2, textAlign: 'right'}} 
                              value={parcela.ValorDeducao} allowEmpty={false}
                              disabled={deducao === '' || !parcela.check}
                              control={control}
                              errors={errors}
                              name={`Parcelas.[${index}].ValorDeducao`}
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              currency='BRL'
                              locale='pt-BR'/>
                          </td>
                          <td style={{maxWidth: 80, textAlign: 'center'}}>
                            <SCInputNumber inputStyle={{ maxWidth: 80, padding: 2, textAlign: 'right'}} 
                              value={parcela.ValorComplementar01} 
                              disabled={acrescimo01 === '' || verificaParcelaComVencimento(parcela) || !parcela.check}
                              control={control}
                              errors={errors}
                              name={`Parcelas.[${index}].ValorComplementar01`}
                              allowEmpty={false}
                              minFractionDigits={2}
                              maxFractionDigits={2}                              
                              currency='BRL'
                              locale='pt-BR'/>
                            </td>
                          <td style={{maxWidth: 80, textAlign: 'center'}}>
                            <SCInputNumber inputStyle={{maxWidth: 80, padding: 2, textAlign: 'right'}} 
                              value={parcela.ValorComplementar02} 
                              disabled={acrescimo02 === '' || verificaParcelaComVencimento(parcela) || !parcela.check}
                              control={control}
                              errors={errors}
                              name={`Parcelas.[${index}].ValorComplementar02`}
                              allowEmpty={false}
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              currency='BRL'
                              locale='pt-BR'/>
                            </td>
                          <td style={{maxWidth: 80, textAlign: 'center'}}>
                            <SCInputNumber inputStyle={{ maxWidth: 80, padding: 2, textAlign: 'right'}} 
                            value={parcela.ValorComplementar03} 
                            disabled={acrescimo03 === '' || verificaParcelaComVencimento(parcela) || !parcela.check}
                            control={control}
                              errors={errors}
                              name={`Parcelas.[${index}].ValorComplementar03`}
                            allowEmpty={false}
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              currency='BRL'
                              locale='pt-BR'/>
                            </td>
                          <td style={{maxWidth: 80, textAlign: 'center'}}>
                            <SCInputNumber inputStyle={{ maxWidth: 80, padding: 2, textAlign: 'right'}} 
                            value={parcela.ValorComplementar04} 
                            disabled={acrescimo04 === '' || verificaParcelaComVencimento(parcela) || !parcela.check}
                            control={control}
                              errors={errors}
                              name={`Parcelas.[${index}].ValorComplementar04`}
                            allowEmpty={false}
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              currency='BRL'
                              locale='pt-BR'/>
                            </td>
                          <td style={{maxWidth: 80, textAlign: 'center'}}>
                            <SCInputNumber inputStyle={{maxWidth: 80, padding: 2, textAlign: 'right'}} 
                            value={parcela.ValorComplementar05} allowEmpty={false}
                            disabled={acrescimo05 === '' || verificaParcelaComVencimento(parcela) || !parcela.check}
                            control={control}
                              errors={errors}
                              name={`Parcelas.[${index}].ValorComplementar05`}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            currency='BRL'
                            locale='pt-BR'/>
                            </td>
                          <td style={{maxWidth: 80, textAlign: 'center'}}>
                            <SCInputNumber inputStyle={{maxWidth: 80, padding: 2, textAlign: 'right'}} 
                            value={parcela.ValorComplementar06} allowEmpty={false}
                            disabled={acrescimo06 === '' || verificaParcelaComVencimento(parcela) || !parcela.check}
                            control={control}
                              errors={errors}
                              name={`Parcelas.[${index}].ValorComplementar06`}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            currency='BRL'
                            locale='pt-BR'/>
                            </td>
                        </tr>
                      ))
                    ):(
                      <tr>
                        <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <Button
              label='Gravar'
              icon="pi pi-check"
              className="p-button p-button-success"
              disabled={getValues()?.Parcelas.filter((x: any) => x.check).length === 0}
              style={{
                float: 'right',
                position: 'absolute',                        
                width: 120,
                right: 10,
                bottom: 10
              }}
              type='submit'
            />
          </div>
        </div>
      </div>
    </form>



  );
};

export default AlunosFormValoresComplementares;
