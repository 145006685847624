import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IOrigem from '../interfaces/IOrigem';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import OrigemForm from './OrigemForm';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';


const Boletos = () => {
  const toast = useToast();
  const tarefa = 'OrigemForm';

  let emptyOrigem: IOrigem = {
    FormaOrigemId: undefined,
    Codigo: '',
    Descricao: '',
    Tipo: 0
  };

  const [origens, setOrigens] = useState<any>(null);
  const [origemDialog, setOrigemDialog] = useState<boolean>(false);
  const [deleteOrigem, setDeleteOrigem] = useState<boolean>(false);
  const [origem, setOrigem] = useState<IOrigem>(emptyOrigem);
  const [selectedOrigem, setSelectedOrigem] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [verRelatorio, setVerRelatorio] = useState<any>(null);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyOrigem });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    // const result = await api.get('/origens', { params: { Descricao: getValues()?.Descricao } });
    // if (result.data)
    //   setOrigens(result.data);
    // else
    //   setOrigens([]);
    setLoading(false);
  }
  const bol = async () => {
    setLoading(true);
    const result = await api.post('/boletos/bradesco');
    console.log(result)
    if (result.data)
      setVerRelatorio('data:application/pdf;base64,'+result.data);
  }
  const bolbb = async () => {
    setLoading(true);
    const result = await api.post('/boletos/bancoBrasil');
    console.log(result)
    if (result.data)
      setVerRelatorio('data:application/pdf;base64,'+result.data);
  }
  // const bolsantander = async () => {
  //   setLoading(true);
  //   const result = await api.post('/boleto/santander');
  //   console.log(result)
  //   if (result.data)
  //     setVerRelatorio('data:application/pdf;base64,'+result.data);
  // }
  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setOrigem(emptyOrigem);
    setSubmitted(false);
    setOrigemDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setOrigemDialog(false);
  };
  const editar = (item: any) => {
    setOrigem(item);
    setOrigemDialog(true);
  };

  const confirmDelete = (item: any) => {
    setOrigem(item);
    setDeleteOrigem(true);
    setOrigemDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/origem', { params: { FormaOrigemId: origem.FormaOrigemId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteOrigem(false);
      setOrigemDialog(false);
      setOrigem(emptyOrigem);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Boletos</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <p> PAGINA TESTE BOLETOS</p>
              <button onClick={bol}>Boleto Bradesco</button>
              <SCDialog visible={verRelatorio != null} style={{ width: '80vw', height: '90vh' }} onHide={() => setVerRelatorio(null)}>
                <iframe src={verRelatorio} width="100%" height="100%"></iframe>
              </SCDialog>
              <button onClick={bolbb}>Boleto Banco do Brasil</button>
              <SCDialog visible={verRelatorio != null} style={{ width: '80vw', height: '90vh' }} onHide={() => setVerRelatorio(null)}>
                <iframe src={verRelatorio} width="100%" height="100%"></iframe>
              </SCDialog>  
              {/* <button onClick={bolsantander}>Boleto Santander</button>
              <SCDialog visible={verRelatorio != null} style={{ width: '80vw', height: '90vh' }} onHide={() => setVerRelatorio(null)}>
                <iframe src={verRelatorio} width="100%" height="100%"></iframe>
              </SCDialog>                   */}
              </div>              
            </div>
          </form>          
        </div>
      </div>
    </div>
  );
};

export default Boletos;
