import React, { createContext, useContext, useRef } from "react";
import { Toast } from "primereact/toast";

interface Props {
    children: React.ReactNode;
}

interface IToastContext {
    showToast(params: IShow): void;
}

interface IShow {
    severity: string;
    summary: string;
    detail?: string;
    life?: number
}

const ToastContext = createContext<IToastContext>({} as IToastContext);

const ToastContextProvider: React.FC<Props> = ({ children }) => {

    const toastRef = useRef<any>(); 

    const showToast = ({severity, summary, detail, life}: IShow) => {
        toastRef.current?.show({
            severity: severity || 'error',
            summary: summary || 'Erro',
            detail: detail || '',
            life: life || 3000
        })
    }

    return (
        <ToastContext.Provider value={{showToast}}>
            {children}
            <Toast position="center" ref={toastRef} className="zIndex"/>
        </ToastContext.Provider>
    )
}

function useToast() {
    const context = useContext(ToastContext);
    return context;
}

export { ToastContextProvider, useToast };