import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, SITUACAO_ALUNO, TURNOS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCDialog, SCDropdown, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import IAluno, { IAlunoForm, IAlunoPost } from '../interfaces/IAlunos';
import { format } from 'date-fns';
import AlunosForm from './AlunosForm';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { loadAlunosPeriodo, loadDadosAlunos } from '../utilities/alunosUtil';
import SelectTurma from '../components/SelectTurma';
import SelectTurmaResumido from '../components/SelectTurmaResumido';
import SelectSituacaoFinal from '../components/SelectSituacaoFinal';


interface IFormAlunoConsulta {
  Nome?: string;
  CodigoTurma?: string;
  SituacaoAcademica?: string;
}

const Alunos = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'AlunoForm';
  // Access the client
  const queryClient = useQueryClient()

  let emptyRegistro: IAlunoForm = {
    Loading: false,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    AlunosPeriodo: {
      AlunosPeriodoId: null,
      EmpresaId: periodoSelecionado?.EmpresaId,
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      DataMatricula: new Date(),
      SituacaoAcademica: 'M',
    },
    FormaOrigem: 'ESC.PARTIC',
    Religiao: '0',
    Cor: '6',
    EstadoCivil: 'N',
    CursoId: '',
    Turno: '',
    NivelSequenciaId: '',
    Sexo: 'O',
    ItinerarioFormativoId: '',
    DataNascimento: null,
    ExigeItinerario: false,
    NaoAplicaEF: false,    
    Nacionalidade: 'BRASILEIRO',
    Natural: '',
    CPF:'',
    RG: '',  
    PaiDDD: '',
    MaeDDD: '',
    RGOrgaoExpeditorUF: '',
    PaiRGOrgaoExpeditorUF: '',
    MaeRGOrgaoExpeditorUF: '',
    ALUNOEndereco: {
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Tipo: 'ALUNO',
      Cep: '',
      Logradouro: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      Cidade: '',
      UF: '',
    },
    PAIEndereco: {
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Tipo: 'PAI',
      Cep: '',
      Logradouro: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      Cidade: '',
      UF: '',
    },
    MAEEndereco: {
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Tipo: 'MAE',
      Cep: '',
      Logradouro: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      Cidade: '',
      UF: '',
    },
    ACAEndereco: {
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Tipo: 'ACA',
      Cep: '',
      Logradouro: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      Cidade: '',
      UF: '',
    },
    FINEndereco: {
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Tipo: 'FIN',
      Cep: '',
      Logradouro: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      Cidade: '',
      UF: '',
    },
    ResponsavelACA: {
      EmpresaId: periodoSelecionado?.EmpresaId,
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Tipo: 'ACA',
      DDD: ''
    },
    ResponsavelFIN: {
      EmpresaId: periodoSelecionado?.EmpresaId,
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      Tipo: 'FIN',
      DDD: ''
    },
  };

  const [lista, setLista] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [registro, setRegistro] = useState<IAlunoForm>({} as IAlunoForm);
  const [registroSelecionado, setRegistroSelecionado] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [filter, setFilter] = useState<IFormAlunoConsulta>({
    Nome: '',
    CodigoTurma: '',
    SituacaoAcademica: '',

  });
  const [loading, setLoading] = useState<boolean>(false);

  // Consultas
  const { isLoading } = useQuery('litar-alunos', () => {
    return api.get('/aluno/list', {
      params: {
        Nome: filter.Nome,
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        CodigoTurma: filter.CodigoTurma,
        SituacaoAcademica: filter.SituacaoAcademica,
      }
    });
  }, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess(data) {
      setLista(data.data);
    },
  })

  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyRegistro });
  const { handleSubmit, control, formState: { errors }, getValues, reset, trigger } = methods;

  const openNew = async () => {
    const result = await api.get('/parametros', {
      params: {
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Parametros: 'DIAPAGTO, LOCPAGTO'
      }
    });
    const parametrosGeral = await api.get('/parametros', {
      params: {
        Ano: '0000',
        Sequencial: '0',
        Parametros: 'DDDPADRAO'
      }
    })
    emptyRegistro.PaiDDD = emptyRegistro.PaiDDD === '' && (parametrosGeral?.data?.DDDPADRAO || '');
    emptyRegistro.MaeDDD = emptyRegistro.MaeDDD === '' && (parametrosGeral?.data?.DDDPADRAO || '');
    emptyRegistro.DDD = emptyRegistro.DDD === '' && (parametrosGeral?.data?.DDDPADRAO || '');
    if (emptyRegistro.ResponsavelFIN )
      emptyRegistro.ResponsavelFIN.DDD = emptyRegistro.ResponsavelFIN?.DDD === '' && (parametrosGeral?.data?.DDDPADRAO || '');
    
    if (emptyRegistro.ResponsavelACA)
      emptyRegistro.ResponsavelACA.DDD = emptyRegistro.ResponsavelACA?.DDD === '' && (parametrosGeral?.data?.DDDPADRAO || '');

    emptyRegistro.DiaPadraoVencimento = result?.data?.DIAPAGTO || '5';
    emptyRegistro.AlunosPeriodo = { 
      ...emptyRegistro.AlunosPeriodo, 
      LocalPagamento: result?.data?.LOCPAGTO || '' 
    };

    setRegistro(emptyRegistro);
    reset(emptyRegistro);
    setSubmitted(false);
    setShowDialog(true);
  };

  const hideDialog = () => {
    reset(emptyRegistro);
    setRegistro(emptyRegistro);
    setSubmitted(false);
    setShowDialog(false);
  };

  const editar = (item: any) => {
    setRegistro(item);
    setShowDialog(true);
  };

  const confirmDelete = (item: any) => {
    setRegistro(item);
    setShowDelete(true);
    setShowDialog(true);
  };

  // Mutations
  const mutationDeletar = useMutation(() => {
    return api.delete('/aluno', { params: { AlunoId: registro.AlunoId } });
  }, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.refetchQueries('litar-alunos');
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      setRegistro(emptyRegistro);

    },
    onError: (e: any) => {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    },
    onSettled: () => {
      setShowDelete(false);
      setShowDialog(false);
    }
  })

  const deletar = async () => {
    mutationDeletar.mutate();
  };

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const cancelar = (e: any) => {
    reset(emptyRegistro);    
    setRegistro(emptyRegistro);
    setShowDelete(false);
    setShowDialog(false);
  }

  const onSubmitCad = async () => {
    setLoading(true);
    const data: IAlunoForm = getValues();
    console.log('data', data);
    const formValido = await trigger();
    if (!formValido) {
      setLoading(false);
      return;
    }

    const aluno: IAlunoPost = loadDadosAlunos(data);
    const alunosPeriodo = loadAlunosPeriodo(data.AlunosPeriodo);

    const docs = data.Documentos?.filter((doc: any) => doc.Checked).map((doc: any) => {
      return {         
        Matricula: data.Matricula, 
        EmpresaId: periodoSelecionado?.EmpresaId, 
        Ano: periodoSelecionado?.Ano, 
        Sequencial: periodoSelecionado?.Sequencial,
        DocumentoId: doc.DocumentoId,
       }
    });
    const postData = {
      aluno,
      alunosPeriodo: { ...alunosPeriodo, Matricula: alunosPeriodo.Matricula },
      enderecos: [
        { ...data.ALUNOEndereco, Matricula: data.Matricula, Tipo: 'ALUNO' },
        { ...data.PAIEndereco, Matricula: data.Matricula, Tipo: 'PAI' },
        { ...data.MAEEndereco, Matricula: data.Matricula, Tipo: 'MAE' },
        { ...data.ACAEndereco, Matricula: data.Matricula, Tipo: 'ACA' },
        { ...data.FINEndereco, Matricula: data.Matricula, Tipo: 'FIN' },
      ],
      responsaveis: [
        { ...data.ResponsavelFIN, Matricula: data.Matricula, Tipo: 'FIN' },
        { ...data.ResponsavelACA, Matricula: data.Matricula, Tipo: 'ACA' },
      ],
      documentos: docs,
      foto: data.Foto,
    }
    let resp = null;
    console.log('postData', postData)
    // return;

    try {
      if (postData.aluno.AlunoId == null || postData.aluno.AlunoId == undefined)
      {
        postData.alunosPeriodo.SituacaoAcademica = 'R';
        postData.aluno.DataCadastro = format(new Date(), 'yyyy-MM-dd');
        postData.aluno.HoraCadastro = format(new Date(), 'HH:mm:ss');
        postData.alunosPeriodo.DataAtualizacaoCadastro = new Date();
        postData.alunosPeriodo.DataMatricula = new Date();

        resp = await api.post('/aluno', util.transformStringsToUppercase(postData, ['foto']));
      }
      else
      {
        postData.alunosPeriodo.DataAtualizacaoCadastro = new Date();
        resp = await api.put('/aluno', util.transformStringsToUppercase(postData, ['foto']));
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      
      reset(emptyRegistro);    
      setRegistro(emptyRegistro);
      
      queryClient.refetchQueries('litar-alunos');

      setShowDialog(false);
    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
    setLoading(false);
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Alunos [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <div className='grid'>
            <div className="col">
              <div className='grid'>
                <div className="col">
                  <SCInputText
                    value={filter.Nome}
                    onChange={(e) => setFilter({ ...filter, Nome: e.target.value })}
                    name="Nome"
                    label='Nome do Aluno'
                    className='w-full'
                  />
                </div>
              </div>
              <div className='grid'>
                <div className="col">
                  <SelectTurmaResumido
                    value={filter.CodigoTurma}
                    onChange={(e: any) => setFilter({ ...filter, CodigoTurma: e.value })}                    
                    label='Turma do Aluno'
                    className='w-full'
                  />
                </div>
                <div className="col">
                  <SCDropdown
                    value={filter.SituacaoAcademica}
                    options={SITUACAO_ALUNO}
                    optionLabel='descricao'
                    optionValue='value'
                    onChange={(e: any) => setFilter({ ...filter, SituacaoAcademica: e.value })}                    
                    label='Situação do Aluno'
                    className='w-full'
                  />
                </div>
              </div>
            </div>
            <div className='col-4 flex align-items-end justify-content-around p-3'>
              <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={() => queryClient.fetchQuery('litar-alunos')} />
              <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

              <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
              <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
            </div>
          </div>
          <Divider />
          {isLoading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={lista}
              onSelectionChange={(e) => setRegistroSelecionado(e.value)}
              dataKey="AlunoId"
              paginator
              rows={20}
              rowsPerPageOptions={[10, 20, 40]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="TurmaCodigo" header="Turma" sortable headerStyle={{ width: 92 }}></Column>
              <Column field="Nome" header="Nome" sortable ></Column>
              <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 92 }}></Column>
              <Column field="SituacaoAcademica" header="Situação" sortable body={(rowData) => {
                return SITUACAO_ALUNO.find(x => x.value === rowData.SituacaoAcademica)?.descricao;
              }}></Column>
              <Column field="AlunoNovo" header='Aluno Novo' align='center' sortable body={(rowData) => {
                return rowData.AlunoNovo ? (<i className='pi pi-check-circle text-green-500' />) : '';
              }}></Column>
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitCad)} className='pt-3'>

              <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%', height: '94vh' }} closable={!util.isDesktop()}
                header={`${registro.AlunoId === undefined ? 'Cadastrando ' : (showDelete ? 'Excluíndo' : 'Alterando')} Aluno`}
                modal className="p-fluid" onHide={hideDialog}
                footer={<>
                  <Divider />
                  {showDelete ? (
                    <div className="flex flex-1 justify-content-between">
                      <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" type='button' onClick={cancelar} />
                      <Button label="Confirmar Exclusão" icon="pi pi-check"
                        className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                        onClick={deletar} type='button' />
                    </div>
                  ) : (
                    <div className="flex flex-1 justify-content-between">
                      <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                      <SCButton onClick={onSubmitCad} label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" />
                    </div>
                  )}
                </>}
              >
                <AlunosForm
                  setShowDialog={setShowDialog}
                  alunoId={registro.AlunoId}
                  reload={() => queryClient.fetchQuery('litar-alunos')}
                  showDelete={showDelete}
                  setShowDelete={setShowDelete}
                  deletar={deletar}
                  loading={loading}
                  setLoading={setLoading}
                />

              </SCDialog>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Alunos;
