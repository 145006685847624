import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { SpeedDial } from 'primereact/speeddial';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { getAreaConhecimento, ALERTAS } from '../utilities/constantes';
import IDisciplina from '../interfaces/IDisciplina';
import DisciplinaForm from './DisciplinaForm';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';


const Disciplina = () => {
  const toast = useToast();
  const { usuario, periodoSelecionado } = useAuth();
  const tarefa = 'DisciplinaForm';

  let emptyDisciplina: IDisciplina = {
    EmpresaId: periodoSelecionado?.EmpresaId,
    DisciplinaId: undefined,
    Codigo: '',
    Descricao: '',
    DescricaoHistorico: '',
    AreaConhecimento: '',
    ControlaFaltas: 0,
    PermiteLancamentoNotas: 0,
    MontagemHorario: 0,
    Itinerario: 0
  };

  const [disciplinas, setDisciplinas] = useState<any>(null);
  const [disciplinaDialog, setDisciplinaDialog] = useState<boolean>(false);
  const [deleteDisciplina, setDeleteDisciplina] = useState<boolean>(false);
  const [disciplina, setDisciplina] = useState<IDisciplina>(emptyDisciplina);
  const [selectedDisciplina, setSelectedDisciplina] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyDisciplina });
  const { handleSubmit, control, formState: { errors }, getValues, setValue } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/disciplina', { params: { Descricao: getValues()?.Descricao } });
    console.log(result.data)
    if (result.data)
      setDisciplinas(result.data);
    else
      setDisciplinas([]);
    setLoading(false);
  }

  useEffect(() => {
    setValue('PermiteLancamentoNotas', 1)
    setValue('ControlaFaltas', 1)
    load();
  }, []);


  const openNew = () => {
    setDisciplina(emptyDisciplina);
    setSubmitted(false);
    setDisciplinaDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setDisciplinaDialog(false);
  };
  const editar = (disciplina: any) => {
    setDisciplina(disciplina);
    setDisciplinaDialog(true);
  };

  const confirmDelete = (disciplina: any) => {
    setDisciplina(disciplina);
    setDeleteDisciplina(true);
    setDisciplinaDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/disciplina', { params: { EmpresaId: disciplina.EmpresaId, Codigo: disciplina.Codigo } });
      console.log(resp.data);
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteDisciplina(false);
      setDisciplinaDialog(false);
      setDisciplina(emptyDisciplina);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Disciplinas</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />

          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={disciplinas}
              onSelectionChange={(e) => setSelectedDisciplina(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Codigo" header="Código" sortable headerStyle={{ width: 92 }}></Column>
              <Column field="Descricao" header="Descrição" sortable ></Column>

              <Column hidden={!util.isDesktop()} field="PermiteLancamentoNotas" header="Permite Lançamento de Notas"
                body={(rowData: IDisciplina) => (
                  rowData.PermiteLancamentoNotas == 1 ? 'Sim' : 'Não'
                )}
                alignHeader='center'
                align='center'
              />
              <Column hidden={!util.isDesktop()} field="ControlaFaltas" header="Controla Faltas"
                body={(rowData: IDisciplina) => (
                  rowData.ControlaFaltas == 1 ? 'Sim' : 'Não'
                )}
                alignHeader='center'
                align='center'
              />

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}
          <SCDialog maximized={!util.isDesktop()} visible={disciplinaDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${disciplina.DisciplinaId === undefined ? 'Cadastrando ' : (deleteDisciplina ? 'Excluíndo' : 'Alterando')} Disciplina`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <DisciplinaForm
              setDisciplinaDialog={setDisciplinaDialog}
              disciplina={disciplina}
              reload={load}
              deleteDisciplina={deleteDisciplina}
              setDeleteDisciplina={setDeleteDisciplina}
              deletar={deletar}
            />
          </SCDialog>


        </div>
      </div>
    </div>
  );
};

export default Disciplina;
