import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';

import { SCButton, SCCheckbox, SCDropdown, SCInputMask, SCInputNumber, SCInputText, SCFieldset } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, MESES } from '../utilities/constantes';
import util from '../utilities/util';
import IMateria from '../interfaces/IMateria';
import IDisciplina from '../interfaces/IDisciplina';
import IPlanoPagamento from '../interfaces/IPlanoPagamento';
import IConvenio from '../interfaces/IConvenio';
import IPlanoPagamentoParcelas from '../interfaces/IPlanoPagamentoParcelas';
import Loading from '../components/Loading';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { useAuth } from '../providers/auth';

interface Props {
  registro?: IPlanoPagamento;
  NovoComBase?: any;
  setCadastroDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteRegistro: boolean;
  setDeleteRegistro(param: boolean): void;
  novoComBase?: any;
  loading?: boolean;
}

const PlanoPagamentoForm: React.FC<Props> = ({ loading = false, setCadastroDialog, registro, reload, deletar, deleteRegistro, novoComBase = undefined, setDeleteRegistro }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'PlanoPagamentoForm';

  const toast = useToast();
  const [convenios, setConvenios] = useState<IConvenio[]>([])
  const [planoPagamentoParcelas, setPlanoPagamentoParcelas] = useState<IPlanoPagamentoParcelas[]>([]);
  const [parcelas, setParcelas] = useState<any>(0);
  const [valor, setValor] = useState<any>(0);
  const [desconto, setDesconto] = useState<any>(0);
  const [contasEntrada, setContasEntrada] = useState<any>(null);
  const [contasSaida, setContasSaida] = useState<any>(null);
  const [tipo, setTipo] = useState<any>(1);

  const { control, formState: { errors }, getValues, setValue, reset, watch } = useFormContext();

  const watchValorDesconto = watch('ValorDesconto');

  useEffect(() => {
    async function load() {
      const { data } = await api.get('/convenio/list', { params: { Descricao: '' } });

      if (registro?.PlanosPagamentoId === undefined)
        setConvenios(data.filter((convenio: any) => convenio.Desativado != 1))
      else
        setConvenios(data)

      const resultContasEntrada = await api.get('/planoContas/list', { params: { Tipo: 'ENTRADA' } });
      setContasEntrada(resultContasEntrada.data);

      const resultContasSaida = await api.get('/planoContas/list', { params: { Tipo: 'SAÍDA' } });
      setContasSaida(resultContasSaida.data);

      if (novoComBase) {
        const result = await api.get('/planosPagamento/parcelas', { params: { PlanosPagamentoId: novoComBase } });
        setPlanoPagamentoParcelas(result.data);
        setValue('PlanoPagamentoParcelas', result.data);
      } else if (registro?.PlanosPagamentoId !== undefined) {
        const result = await api.get('/planosPagamento/parcelas', { params: { PlanosPagamentoId: registro?.PlanosPagamentoId } });
        setPlanoPagamentoParcelas(result.data);
        setValue('PlanoPagamentoParcelas', result.data);
      }
    }

    load()
  }, [])

  useEffect(() => {
    if (planoPagamentoParcelas) {
      setValue('PlanoPagamentoParcelas', planoPagamentoParcelas);
    }
  }, [planoPagamentoParcelas])

  let mesAtual = 1;

  const projetar = () => {
    const novasParcelas: IPlanoPagamentoParcelas[] = [];
    if (parcelas > 0)
      for (let index = 1; index <= parcelas; index++) {

        mesAtual = index > 12 ? index - 12 : index;
        const descricao = tipo === 2 ? "PARCELA " + index.toString() : MESES.find(mes => mes.mes === mesAtual)?.des.toUpperCase();

        novasParcelas.push({
          Parcela: index,
          Descricao: descricao,
          Valor: valor, Desconto: desconto
        });
      }

    setPlanoPagamentoParcelas(novasParcelas);
  }

  const isPositiveInteger = (val: any) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      str = '0';
    }

    str = str.replace(/^0+/, '') || '0';
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const onCellEditComplete = (e: any) => {
    console.log('onCellEditComplete', e);
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case 'Desconto':
        if (isPositiveInteger(newValue))
          if (newValue > rowData.Valor) {
            toast.showToast({
              severity: 'error',
              summary: 'Desconto não pode ser maior que o valor da parcela.',
              life: 4000
            });

          } else {
            rowData[field] = newValue;
          }
        else
          rowData[field] = 0;

        break;
      case 'Valor':
        if (isPositiveInteger(newValue))
          rowData[field] = newValue;
        else
          rowData[field] = 0;
        break;

      case 'Descricao':
        rowData[field] = newValue;
        break;
      default:
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
    planoPagamentoParcelas[e.rowIndex] = rowData;
    setValue('PlanoPagamentoParcelas', planoPagamentoParcelas);
  };

  const valorTemplate = (rowData: IPlanoPagamentoParcelas): string => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.Valor || 0);
  };

  const valorEditor = (options: any) => {
    return <SCInputNumber value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      min={0}
      max={10000}
      minFractionDigits={2}
      maxFractionDigits={2}
      currency='BRL'
      mode='currency'
      locale='pt-BR'
    />;
  };

  const textEditor = (options: any) => {
    return <SCInputText type="text" value={options.value} onChange={(e: any) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
  };

  return (
    <div className='grid'>
      {loading && (
        <Loading full={true} />
      )}
      <div className="col-5">
        <div className='grid'>
          <div className="col-3">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="Codigo"
              label='Código'
              maxLength={10}
              required={!deleteRegistro}
              disabled={registro?.PlanosPagamentoId !== undefined && registro?.PlanosPagamentoId !== null}
              tarefa={tarefa}
            />
          </div>
          <div className="col-9">
            <SCInputText
              control={control}
              errors={errors}
              name="Descricao"
              label='Descrição'
              minLength={3}
              required={!deleteRegistro}
              disabled={deleteRegistro}
              tarefa={tarefa}
            />
          </div>
          <div className="col-3">
            <SCInputNumber
              errors={errors}
              control={control}
              name="ValorDesconto"
              label='% Desconto'
              min={0}
              max={100}
              minFractionDigits={0}
              maxFractionDigits={2}
              onBlur={(e: any) => {
                if (e.target.value > 100) {
                  toast.showToast({
                    severity: 'error',
                    summary: 'Desconto não pode ser maior que 100%.',
                    life: 4000
                  });
                  setValue('ValorDesconto', 0);
                }

                if (e.target.value < 0) {
                  toast.showToast({
                    severity: 'error',
                    summary: 'Desconto não pode ser menor que 0%.',
                    life: 4000
                  });
                  setValue('ValorDesconto', 0);
                }

                if (e.target.value === '') {
                  setValue('ValorDesconto', 0);
                }
              }}
              disabled={registro?.PlanosPagamentoId !== undefined && registro?.PlanosPagamentoId !== null}
            />
          </div>
          <div className="col-9">
            <SCDropdown
              autoFocus={true}
              control={control}
              errors={errors}
              name="ConvenioId"
              label='Convênio Sugerido'
              options={convenios}
              optionLabel='Descricao'
              optionValue='ConvenioId'
            />
          </div>
        </div>
        <div className='grid'>
          <div className="col-12">
            <SCCheckbox
              control={control}
              name="Desativado"
              label='Desabilitar esse código.'
              disabled={deleteRegistro}
            />
          </div>

        </div>
        <br />

        <Divider align="left" >
          <div className="inline-flex align-items-center">
            <b>Projeção</b>
          </div>
        </Divider>
        <div className='grid'>
          <div className="col-4">
            <SCInputNumber
              errors={errors}
              onChange={(e) => setParcelas(e.value)}
              label='Qtd. Parcelas'
              disabled={deleteRegistro || (registro?.PlanosPagamentoId ? true : false)}
              value={parcelas}
              min={0}
              max={60}
              minFractionDigits={0}
              maxFractionDigits={0}
            />
          </div>
          <div className="col-4">
            <SCInputNumber
              errors={errors}
              value={valor}
              label='Valor'
              onChange={(e) => setValor(e.value)}
              disabled={deleteRegistro || (registro?.PlanosPagamentoId ? true : false)}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
              onBlur={(e: any) => {
                // Removendo todos os caracteres não numéricos, exceto ponto e vírgula
                let valorDigitadoStr = e.target.value.replace(/[^\d,-]/g, "");
                // Substituindo vírgula por ponto
                valorDigitadoStr = valorDigitadoStr.replace(",", ".");

                const valorDigitado = parseFloat(valorDigitadoStr);

                if (!isNaN(valorDigitado) && desconto !== undefined && !isNaN(desconto)) {
                  const calculo = (valorDigitado * getValues()?.ValorDesconto) / 100;
                  console.log('Cálculo:', calculo);
                  setDesconto(calculo);
                }
              }}
            />
          </div>

          <div className="col-4">
            <SCInputNumber
              errors={errors}
              value={desconto}
              label='Desconto'
              name='Desconto'
              onChange={(e) => setDesconto(e.value)}
              disabled={deleteRegistro || (registro?.PlanosPagamentoId ? true : false) || watchValorDesconto > 0}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
              onBlur={(e) => {
                const desc = e.target.value.replace('R$', '').replace(',', '.');
                if (desc == '') {
                  setDesconto(0);
                }

                if (desc > valor) {
                  toast.showToast({
                    severity: 'error',
                    summary: 'Desconto não pode ser maior que o valor da parcela.',
                    life: 4000
                  });
                  setDesconto(0);
                }
              }}
            />
          </div>
          <div className="col-3">
            <SCDropdown
              name="MesParcela"
              label='Tipo'
              showClear={false}
              onChange={(e) => setTipo(e.value)}
              value={tipo}
              options={[
                { mes: 1, des: 'Mês' },
                { mes: 2, des: 'Parcela' }
              ]}
              optionLabel='des'
              optionValue='mes'
              required={!deleteRegistro}
              disabled={deleteRegistro || (registro?.PlanosPagamentoId ? true : false)}
            />
          </div>
          <div className="col-4 flex ">
            <Button type='button' icon="pi pi-plus" disabled={registro?.PlanosPagamentoId ? true : false} className="p-button-success mt-auto" onClick={() => projetar()} label='Projetar' />
          </div>
        </div>
        <br />

        <Divider align="left" >
          <div className="inline-flex align-items-center">
            <b>Contas</b>
          </div>
        </Divider>
        <div className='grid' >
          <div className='col-12 pl-0 pr-1'>
            <div className="col-12 md:col-12">
              <SCDropdown
                control={control}
                errors={errors}
                name="ContaValorId"
                label='Valor'
                options={contasEntrada}
                optionLabel='ContaDescricao'
                optionValue='PlanoContaId'
                placeholder='Selecione a conta...'
                required={!deleteRegistro}
              />
            </div>

            <div className="col-12 md:col-12">
              <SCDropdown
                control={control}
                errors={errors}
                name="ContaDescontoId"
                label='Desconto'
                options={contasSaida}
                optionLabel='ContaDescricao'
                optionValue='PlanoContaId'
                placeholder='Selecione a conta...'
                required={!deleteRegistro}
              />
            </div>
          </div>
          <div className='col-12 pl-0 pr-1'>
            <div className="col-12 md:col-12">
              <SCDropdown
                control={control}
                errors={errors}
                name="ContaMultaId"
                label='Multa'
                options={contasEntrada}
                optionLabel='ContaDescricao'
                optionValue='PlanoContaId'
                placeholder='Selecione a conta...'
                required={!deleteRegistro}
              />
            </div>

            <div className="col-12 md:col-12">
              <SCDropdown
                control={control}
                errors={errors}
                name="ContaJurosId"
                label='Juros'
                options={contasEntrada}
                optionLabel='ContaDescricao'
                optionValue='PlanoContaId'
                placeholder='Selecione a conta...'
                required={!deleteRegistro}
              />
            </div>
          </div>
        </div>

      </div>
      <div className="col-1">
        <Divider layout='vertical' />
      </div>
      <div className="col-6">
        <DataTable
          loading={loading}
          stripedRows
          value={planoPagamentoParcelas}
          dataKey="id"
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          header={null}
          editMode="cell"

          editingRows={planoPagamentoParcelas}
          emptyMessage="Nenhum registro encontrado."
          responsiveLayout="scroll">
          <Column header="Parcela" field='Parcela' bodyStyle={{ width: 90 }} />
          <Column header="Descrição" field='Descricao'
            editor={textEditor}
            onCellEditComplete={onCellEditComplete}
          />
          <Column header="Valor" field='Valor'
            bodyStyle={{ width: 120 }}
            body={valorTemplate}
            editor={valorEditor}
            onCellEditComplete={onCellEditComplete} />
          <Column header="Desconto" field='Desconto'
            bodyStyle={{ width: 120 }}
            editor={valorEditor}
            onCellEditComplete={onCellEditComplete}
            body={(rowData: IPlanoPagamentoParcelas) => (
              <span>R$ {rowData.Desconto?.toFixed(2)}</span>
            )} />
          {/* <Column body={(rowData: any) => {
                  return (
                  <div className="actions flex align-items-center justify-content-between">
                      <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => console.log(rowData)} />
                  </div>
                  );
              }} style={{ width: 90 }}
              /> */}
        </DataTable>
      </div>
    </div>
  );
};

export default PlanoPagamentoForm;
