import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, TURNOS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCCheckbox, SCDialog, SCDropdown, SCFieldset, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import IVisitantes from '../interfaces/IVisitantes';
import { format } from 'date-fns';
import VisitantesForm from './VisitantesForm';
import { useAuth } from '../providers/auth';
import NivelSequencia from './NivelSequencia';
import { getValue } from '@testing-library/user-event/dist/utils';


const Visitantes = () => {
  const toast = useToast();
  const tarefa = 'Visitantes';

  let emptyVisitantes: IVisitantes = {
    VisitantesId: undefined,
    Nome: '',
    AlunoNome: '',
    Parentesco: 0,
    CPF: '',
    Agendamento: null,
    DataAtendimento: null,
    FuncionarioId: undefined,
    CursoId: undefined,
    NivelSequenciaId: undefined,
    Turno: '',
    EscolaAnterior: '',
    ConhecimentoIndicacao: false,
    ConhecimentoTV: false,
    ConhecimentoJornal: false,
    ConhecimentoInternet: false,
    ConhecimentoOutdoor: false,
    ConhecimentoOutros: false,
    ContatoEmail: '',
    ContatoTelefone: '',
    ContatoCelular: '',
    Observacao: '',
    SomenteAtendidos: false,
    SomenteNaoAtendidos: false,
  };

  const [visitantes, setVisitantes] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [visitante, setVisitante] = useState<IVisitantes>(emptyVisitantes);
  const [visitanteSelecionado, setVisitanteSelecionado] = useState<any>(null);
  const [nivelSequencia, setNivelSequencia] = useState<any>();
  const [filter, setFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [turno, setTurno] = useState<any>(null);
  const [allVisitantes, setAllVisitantes] = useState<any>(null);

  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyVisitantes });
  const { handleSubmit, control, formState: { errors }, getValues, watch, setValue } = methods;

  const watchNome = watch('Nome')
  const watchNivelSequencia = watch('NivelSequenciaId');
  const watchTurno = watch('Turno');
  const watchAtendidos = watch('SomenteAtendidos')
  const watchNaoAtendidos = watch('SomenteNaoAtendidos')
  const watchAgendados = watch('Agendamento')

  const { periodoSelecionado } = useAuth();

  const load = async () => {
    setLoading(true)

    let resp;
    let dataFormatada;

    try {
      const resp = await api.get('/nivelSequencia/list', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
        }
      });

      setNivelSequencia(resp.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }


    if (watchAgendados) {
      dataFormatada = format(watchAgendados, 'yyyy-MM-dd')
    }

    resp = await api.get('/visitantes/filtroLista', {
      params: {
        Turno: watchTurno,
        NivelSequenciaId: watchNivelSequencia,
        DataAgendamento: dataFormatada,
        SomenteAtendidos: watchAtendidos,
        SomenteNaoAtendidos: watchNaoAtendidos,
        Nome: getValues()?.Nome
      }
    });

    if (resp) {
      setVisitantes(resp.data);
      setLoading(false)
    } else {
      setVisitantes([]);
      setLoading(false)
    }

  };

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setVisitante(emptyVisitantes);
    setShowDialog(true);
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const editar = (visitantes: any) => {
    visitantes.Agendamento = visitantes.Agendamento ? new Date(visitantes.Agendamento) : null;
    visitantes.DataAtendimento = visitantes.DataAtendimento ? new Date(visitantes.DataAtendimento) : null;

    setVisitante(visitantes);
    setShowDialog(true);
  };

  const confirmDelete = (item: any) => {
    setVisitante(item);
    setShowDelete(true);
    setShowDialog(true);
  };

  const deleteVisitante = async () => {
    try {
      const resp = await api.delete('/visitantes', { params: { VisitantesId: visitante.VisitantesId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setShowDelete(false);
      setShowDialog(false);
      setVisitante(emptyVisitantes);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const cancelar = (e: any) => {
    setShowDelete(false);
    setShowDialog(false);
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Visitantes</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>

            <SCFieldset legend='Filtros' className='col-12 mt-1'>

              <div className="grid">
                <div className="col-12 md:col-10">
                  <div className="grid">
                    <div className="col-12 md:col-10">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="Nome"
                        placeholder='Digite o nome'
                        className='w-full'
                      />
                    </div>
                  </div>

                  <div className='grid'>
                    <div className="col-12 md:col-3">
                      <SCDropdown
                        name='Turno'
                        options={TURNOS}
                        optionLabel="descricao"
                        optionValue='value'
                        placeholder='Turno'
                        style={{ width: '100%' }}
                        value={watchTurno}
                        onChange={(e) => setValue('Turno', e.target.value)}
                      />
                    </div>

                    <div className="col-12 md:col-7">
                      <SCDropdown
                        name="NivelSequenciaId"
                        options={nivelSequencia}
                        optionLabel="Descricao"
                        optionValue='NivelSequenciaId'
                        placeholder='Selecione o Nivel/Escolaridade'
                        style={{ width: '100%' }}
                        value={watchNivelSequencia}
                        onChange={(e) => setValue('NivelSequenciaId', e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="grid align-items-center">
                    <div className="col-12 md:col-3">
                      <SCCalendar
                        control={control}
                        name='Agendamento'
                        dateFormat='dd/mm/yy'
                        mask='99/99/9999'
                        showIcon
                        disabled={watchAtendidos}
                        placeholder='Agendados Para'
                      />
                    </div>

                    <div className="col-12 md:col-3">
                      <SCCheckbox
                        name='SomenteAtendidos'
                        control={control}
                        label='Somente Atendidos'
                        value={false}
                        disabled={watchNaoAtendidos}
                      />
                    </div>

                    <div className="col-12 md:col-4">
                      <SCCheckbox
                        name='SomenteNaoAtendidos'
                        control={control}
                        label='Somente Não Atendidos'
                        value={false}
                        disabled={watchAtendidos}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 md:col-2 flex flex-column align-items-end">
                  <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined mb-2 w-full" type='button' onClick={load} />
                  <Button label='Novo' icon="pi pi-file-o" className="p-button-success mb-2 w-full" type='button' onClick={openNew} />
                  <Button label='Opções' className='p-button-secondary w-full' type="button" icon="pi pi-plus" onClick={openMenu} />
                  <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                </div>
              </div>

            </SCFieldset>
          </form>




          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={visitantes}
              onSelectionChange={(e) => setVisitanteSelecionado(e.value)}
              dataKey="VisitantesId"
              paginator
              rows={20}
              rowsPerPageOptions={[10, 20, 40]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={filter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column
                field="Turno"
                header="Turno"
                sortable
                body={(rowData: any) => (
                  <span className='text-sm'>{TURNOS.find((turno: any) => turno.value === rowData.Turno)?.descricao}</span>
                )}
              />

              <Column field="NivelSequenciaId" header="Nível/Escolaridade" sortable
                body={(rowData: any) => (
                  <span className='text-sm'>{nivelSequencia.find((nivel: any) => nivel.NivelSequenciaId === rowData.NivelSequenciaId)?.Descricao}</span>
                )}
              />
              <Column field="Nome" header="Nome" bodyStyle={{ fontSize: 11 }} sortable />
              <Column field="AlunoNome" header="Aluno" sortable bodyStyle={{ fontSize: 11 }} />
              <Column
                sortable
                header="Agendado"
                field='Agendamento'
                align='center'
                body={(rowData: any) => {
                  return (
                    <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 11 }}>
                      {rowData.Agendamento ? (
                        <>
                          <i className='pi pi-calendar text-green-500' />
                          <span>{rowData.DataAgendamentoString}</span>
                        </>
                      ) : ''}
                    </span>
                  );
                }}
                style={{ width: 90 }}
              />

              <Column sortable header="Atendido" field='DataAtendimento' align='center' body={(rowData: any) => {
                return (
                  <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 11 }}>
                    {rowData.DataAtendimento ? (
                      <>
                        <i className='pi pi-check-circle text-green-500' />
                        <span>{rowData.DataAtendimentoString}</span>
                      </>
                    ) : ''}
                  </span>
                );
              }} style={{ width: 90 }}
              />
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 80 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${visitante.VisitantesId === undefined ? 'Cadastrando ' : (showDelete ? 'Excluíndo' : 'Alterando')} Visitante`}
            modal className="p-fluid" onHide={hideDialog}>
            <VisitantesForm
              visitante={visitante}
              setShowDialog={setShowDialog}
              deletar={deleteVisitante}
              showDelete={showDelete}
              setShowDelete={setShowDelete}
              reload={load}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default Visitantes;
