import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SCButton, SCDropdown, SCInputText } from '../components';
import AlunoResumo from '../components/AlunoResumo';
import Loading from '../components/Loading';
import { useToast } from '../context/ToastContext';
import { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { ALERTAS, STATUS_DISCIPLINA, TIPO_DISCIPLINA } from '../utilities/constantes';
import { IBoletim } from '../interfaces/IBoletim';

interface Props {
  aluno: IAlunoForm;
  setAlunoDialog(param: boolean): void;
  reload(): void;
}

const StatusPorDisciplinaForm: React.FC<Props> = ({ setAlunoDialog, aluno, reload }) => {
  const tarefa = 'NotasAlunosForm';
  const [loading, setLoading] = useState<boolean>(false);
  const [listaBoletim, setListaBoletim] = useState<any[]>([]);
  const [avaliacao, setAvaliacao] = useState<any>();
  const [desabilitaNota, setDesabilitaNota] = useState(false)
  const [ignorar, setIgnorar] = useState(false)

  const toast = useToast();
  const { periodoSelecionado } = useAuth();

  const defaultValues: IBoletim = {
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Matricula: 0,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const load = async () => {
    const { data } = await api.get('/aluno', {
      params: { AlunoId: aluno?.AlunoId }
    });

    const alunoperiodo = await api.get('/alunosPeriodo/matricula', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: data?.Matricula
      }
    });

    data.Turma = {
      Codigo: alunoperiodo.data.TurmaCodigo,
    };

    data.AlunosPeriodo = alunoperiodo.data;
    reset(data);
  };

  const onEditorValueChange = (props: any, value: any) => {
    let updatedBoletim = [...listaBoletim];
    updatedBoletim[props.rowIndex][props.field] = value;
    setListaBoletim(updatedBoletim);
  };

  const inputNumberEditor = (props: any) => {
    const field = props.field;
    const rowIndex = props.rowIndex;

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      if (value === '') {
        onEditorValueChange({ field: field, rowIndex: rowIndex }, null);
      } else {
        let numberValue = parseFloat(value);
        if (!isNaN(numberValue)) {
          numberValue = parseFloat(numberValue.toFixed(2)); // Formatar para até 2 casas decimais
          onEditorValueChange({ field: field, rowIndex: rowIndex }, numberValue); // Atualizar o estado
        }
      }
    };

    return (
      <SCInputText
        type="number"
        defaultValue={props.rowData[props.field]}
        onBlur={handleBlur}
        style={{ borderWidth: 1, borderColor: "#0772b3" }}
      />
    );
  };

  const buscaStatus = async () => {

    const listaDisciplinas = await api.get('/boletim/aluno', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: aluno?.Matricula,
      }
    });

    if (listaDisciplinas) {
      console.log(listaDisciplinas.data)
      setListaBoletim(listaDisciplinas.data);
    }
    else {
      setListaBoletim([]);
    }
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    buscaStatus();
  }, []);

  const tipoDisciplinaBodyTemplate = (rowData: any) => {
    const tipo = TIPO_DISCIPLINA.find(item => item.value === rowData.Tipo);
    return tipo ? tipo.descricao : rowData.Tipo;
  };


  const handleDropdownChange = (selectedValue: any, id: any) => {
    setListaBoletim((prevStatus: any) => {
      const updatedStatus = prevStatus.map((status: any) => {
        if (status.BoletimId === id) {
          return { ...status, Status: selectedValue };
        }
        return status;
      });

      return updatedStatus;
    });
  };

  const onSubmit = async () => {

    let validaGravacao = true;

    listaBoletim.map((item: any) => {
      if (item.Status === '3' || item.Status === '8') {

        if (item.CargaHoraria == 0) {
          toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Carga Horária Obrigatória para ' + item.Descricao })
          validaGravacao = false
        }

        if (item.CargaHoraria === null) {
          toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Carga Horária Obrigatória para ' + item.Descricao })
          validaGravacao = false
        }

        if (item.Media == 0) {
          toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Média Obrigatória para ' + item.Descricao })
          validaGravacao = false
        }

        if (item.Media === null) {
          toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Média Obrigatória para ' + item.Descricao })
          validaGravacao = false
        }

        if (item.Media > 10) {
          toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Na disciplina ' + item.Descricao + ', a Média não pode ser maior que 10' })
          validaGravacao = false
        }

      } else {
        if (item.Media > 10) {
          toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Na disciplina ' + item.Descricao + ', a Média não pode ser maior que 10' })
          validaGravacao = false
        }
      }

      if (item.Media > 0 || item.CargaHoraria > 0) {
        if (item.Status === undefined) {
          item.Status = '0'
        }
      }

      if (item.Status === 'Y') {
        item.Media = null
        item.CargaHoraria = null
      }
    })

    if (!validaGravacao) {
      return false
    }

    let resp: any = undefined;
    const updatedListaBoletim = listaBoletim.map(item => {
      const { Descricao, desabilitaNota, ...rest } = item;
      return rest;
    });

    try {
      setLoading(true)
      resp = await api.put('/boletim/multiUpdate', updatedListaBoletim)

      setLoading(false)
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setAlunoDialog(false);

    } catch (e: any) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const notaBodyTemplate = (rowData: any) => {
    const value = rowData[avaliacao];
    return value !== undefined && value !== null && !isNaN(value) ? value.toFixed(2) : '';
  };

  const cancelar = (e: any) => {
    setAlunoDialog(false);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
        <div className='grid'>
          <AlunoResumo mostraFoto mostraTurma aluno={getValues() as IAlunoForm} />
        </div>

        <Divider />

        {loading ? <Loading /> : (
          <DataTable
            size='small'
            stripedRows
            dataKey="id"
            value={listaBoletim}
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            emptyMessage="Selecione uma avaliação."
            editMode='cell'
            responsiveLayout="scroll"
          >
            <Column field="Tipo" header="Tipo" headerStyle={{ width: 180 }} body={tipoDisciplinaBodyTemplate}></Column>
            <Column field="Descricao" header="Disciplina"  ></Column>

            <Column field="FezAvaliacao" headerStyle={{ width: 100 }} header="Status" body={(rowData: any) => (
              <div className="col-12 flex justify-content-center">
                <SCDropdown
                  value={rowData.Status}
                  options={STATUS_DISCIPLINA}
                  name='Status'
                  optionLabel='descricao'
                  optionValue='value'
                  style={{ width: 220 }}
                  onChange={(e) => handleDropdownChange(e.value, rowData.BoletimId)}
                />
              </div>
            )}
              alignHeader='center'
              align='center'></Column>
            <Column field="CargaHoraria" header="Carga Horária" editor={inputNumberEditor}
              headerStyle={{ width: 75 }}></Column>
            <Column field="Media" header="Média" editor={inputNumberEditor}
              headerStyle={{ width: 50 }}></Column>
          </DataTable>
        )}

        <Divider />

        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
          <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
        </div>

      </form >
    </FormProvider>
  );
};

export default StatusPorDisciplinaForm;
