import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCheckbox, SCDropdown, SCFieldset, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import util from '../utilities/util';
import { IUsuario } from '../interfaces/IUsuario';
import { useAuth } from '../providers/auth';
import { InputSwitch } from 'primereact/inputswitch';
import api from '../services/api';
import { ALERTAS } from '../utilities/constantes';
import { Password } from 'primereact/password';
import Foto from '../components/Foto';
import SelectPerfil from '../components/SelectPerfil';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { he } from 'date-fns/locale';



interface Props {
  usuario?: IUsuario;
  setUsuarioDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteUsuario: boolean;
  setDeleteUsuario(param: boolean): void;
}

const GerenciadorUsuariosForm: React.FC<Props> = ({ setUsuarioDialog, usuario, reload, deletar, deleteUsuario, setDeleteUsuario }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'OrigemForm';
  const toast = useToast();
  const { periodoSelecionado, alteraFoto, usuario: usuarioLogado } = useAuth();

  const defaultValues: IUsuario = usuario || {
    EmpresaId: periodoSelecionado?.EmpresaId,
    UsuarioId: undefined,
    Email: '',
    Ativo: false,
    Master: 0,
    Senha: '',
    Nome: '',
    Apelido: '',
    Foto: ''
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [perfilLista, setPerfilLista] = useState<any>([]);	
  const [perfis, setPerfis] = useState<any>([]);
  const [perfil, setPerfil] = useState<any>(null);

  useEffect(() => {
    let isMounted = true
    api.get('/perfil/list', {params: {
      EmpresaId: periodoSelecionado?.EmpresaId,
    }}).then(({data}) => {
        if (isMounted)
        {
          console.log('Perfis', data);
          setPerfis(data);
        }
    }).catch(error => {
        console.log('Erro ao carregar perfis', error);
    })

    return () => { isMounted = false };
  }, [])

  const carregaPerfisUsuario = () => {
    api.get('/usuario/perfis', {params: { 
      UsuarioId: usuario?.UsuarioId,
    }}).then(({data}) => {
      console.log('Perfis', data);
      setPerfilLista(data);
    }).catch(error => {
        console.log('Erro ao carregar perfis', error);
    })

  };

  const watchFoto = watch('Foto');

  const onSubmit = async (data: IUsuario) => {
    setLoading(true)
    let resp = undefined;

    // Transformando Nome e Apelido para UPPERCASE
    data.Nome = data.Nome?.toLocaleUpperCase()
    data.Apelido = data.Apelido?.toLocaleUpperCase()
    data.Perfis = perfilLista.map((x: any) => {
      return { 
        PerfilId: x.PerfilId, 
        UsuarioId: data.UsuarioId,        
      }
    })

    if (data) {
      try {
        if (data.UsuarioId === undefined)
          resp = await api.post('/usuario', data)
        else
          resp = await api.put('/usuario', data)

        toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });

        if (usuarioLogado?.UsuarioId == usuario?.UsuarioId && watchFoto) {
          alteraFoto(watchFoto);
        }

        setLoading(false);
        reload();
        setUsuarioDialog(false);
      } catch (e: any) {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        setLoading(false);
      }

    }
  }

  useEffect(() => {
    if (usuario) {
      setChecked(usuario.Ativo ?? false);
      if (usuario?.UsuarioId !== undefined)
        carregaPerfisUsuario();
    }

    if (usuario?.UsuarioId === undefined)
      setChecked(true);
  }, [usuario]);

  useEffect(() => {
    setValue('Ativo', checked)
  }, [checked]);

  const cancelar = (e: any) => {
    setDeleteUsuario(false);
    setUsuarioDialog(false);
    reset();
  }

  const removeItem = (item: any) => {
    const lista = perfilLista.filter((x: any) => x.PerfilId !== item.PerfilId);
    setPerfilLista(lista);
  }

  const addPerfil = () => {
    if (perfil) {
      const item = perfilLista.find((x: any) => x.PerfilId === perfil.PerfilId);
      if (!item) {
        const lista = [...perfilLista, perfil];
        setPerfilLista(lista);
      }

      setPerfil(null);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-9">
          <div className='grid'>
            <div className='col-12'>
              <SCInputText
                autoFocus={true}
                control={control}
                errors={errors}
                name="Nome"
                label='Nome'
                maxLength={60}
                required={!deleteUsuario}
                disabled={deleteUsuario}
              />
            </div>
          </div>
          <div className='grid'>
            <div className="col-12 md:col-4">
              <SCInputText
                control={control}
                errors={errors}
                name="Apelido"
                label='Apelido'
                maxLength={20}
                disabled={deleteUsuario}
                tarefa={tarefa}
                tooltip='Máximo de 20 caracteres'
                tooltipOptions={{ event: 'focus' }}
              />
            </div>
            <div className="col-12 md:col-2">
              <div className='pb-1'>Ativado</div>
              <InputSwitch
                name='Ativo' checked={checked} onChange={(e) => setChecked(e.value)} disabled={deleteUsuario} />
            </div>

            <div className="col-4 mt-4">
              <SCCheckbox
                control={control}
                name="Master"
                label='Usuário Master'
                disabled={deleteUsuario}
              />
            </div>
          </div>
        </div>

        <div className="col-12 md:col-3 ">
          <Foto name='Foto' foto={watchFoto} alteraFoto={true} setValue={setValue} />
        </div>
      </div>

      <SCFieldset legend='Dados de Acesso' className='col-12 mt-3 mb-3'>
        <div className='grid'>
          <div className="col-12 md:col-8">
            <SCInputText
              autoFocus={true}
              control={control}
              errors={errors}
              name="Email"
              label='E-mail (Login)'
              maxLength={100}
              required={!deleteUsuario}
              disabled={deleteUsuario || usuario?.UsuarioId !== undefined}
              tarefa={tarefa}
              tooltip='Máximo de 100 caracteres'
              tooltipOptions={{ event: 'focus' }}
            />
          </div>
        </div>

        <div className='grid'>
          <div className="col-12 md:col-4">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="Senha"
              label='Senha'
              maxLength={30}
              required={!deleteUsuario}
              disabled={deleteUsuario}
              tarefa={tarefa}
              tooltip='Máximo de 30 caracteres'
              tooltipOptions={{ event: 'focus' }}
            />
          </div>
        </div>
        <div className='grid'>
          <div className="col-11">
            <label
              htmlFor="PerfilId"
              className={classNames({
                'p-error': false,
                block: true,
              })}>
              Perfil               
            </label>
            <div className="p-inputgroup">             
              <SCDropdown              
                name="PerfilId"
                options={perfis.filter((x: any) => !perfilLista.find((y: any) => y.PerfilId === x.PerfilId))}
                optionLabel='DescricaoDropdown'
                optionValue='PerfilId'
                value={perfil?.PerfilId}
                onChange={(e: any) => setPerfil(perfis.find((x: any) => x.PerfilId === e.value))}
                disabled={deleteUsuario}
                className='w-full'
              />
              <SCButton type='button' style={{width: 36, maxHeight: 32}} className='p-button-info p-button-outlined' onClick={() => addPerfil()} icon='pi pi-user-plus' />
            </div>
          </div>
        </div>
        <div className='grid'>
          <div className="col-12">
            <DataTable
              size='small'
              stripedRows
              value={perfilLista.filter((x: any) => x.EmpresaId === periodoSelecionado?.EmpresaId)}
              dataKey="id"
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Modulo" header="Módulo" sortable headerStyle={{ width: 120 }}></Column>
              <Column field="Codigo" header="Código" sortable headerStyle={{ width: 150 }}></Column>
              <Column field="Descricao" header="Descrição" sortable ></Column>
              <Column body={(rowData: any) => {
                return (
                    <Button type='button' icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => removeItem(rowData)} />
                );
              }} style={{ width: 60 }}
              />
            </DataTable>
          </div>
        </div>
      </SCFieldset>

      <div className='grid'>
        <Divider />
        {deleteUsuario ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default GerenciadorUsuariosForm;
