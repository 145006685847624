import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCCheckbox, SCDialog, SCDropdown, SCFieldset } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { format, isAfter, isEqual } from 'date-fns';
import IApagar from '../interfaces/IApagar';
import ContasAPagarForm from './ContasAPagarForm';
import { CiCreditCard1 } from "react-icons/ci";


const ContasAPagar = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const tarefa = 'SetorForm';

  let emptyContaAPagar: IApagar = {
    ApagarId: undefined,
    AtualizacaoData: new Date(''),
    AtualizacaoHora: new Date(''),
    AtualizacaoOperador: '',
    CartoesId: 0,
    CentroCustoId: 0,
    ContaDescontoId: 0,
    ContaJurosPagamento: 0,
    ContaMultaPagamento: 0,
    ContaValorId: 0,
    CredorId: '',
    DataBomPara: null,
    DataPagamento: null,
    DataProgramada: null,
    DataVencimento: null,
    EmpresaId: periodoSelecionado?.EmpresaId,
    FonteId: 0,
    FormasPagamentoId: '',
    LancamentoData: new Date(''),
    LancamentoHora: new Date(''),
    LancamentoOperador: '',
    NumeroDocumento: '',
    NumeroDocumentoPagamento: '',
    Observacao: '',
    Referente: '',
    Valor: 0,
    ValorDesconto: 0,
    ValorDescontoPagamento: 0,
    ValorDescontoProgramado: 0,
    ValorJurosPagamento: 0,
    ValorJurosProgramado: 0,
    ValorMultaPagamento: 0,
    ValorMultaProgramado: 0,
    ValorPagamento: 0,
    ValorProgramado: 0,
    DataInicialVencimento: null,
    DataFinalVencimento: null,
    DataInicialPagamento: null,
    DataFinalPagamento: null,
    Nome: '',
    FlgMeuCaixa: 0,
    CodigosEstornoId: undefined,
    Status: 'TODOS',
    CartaoInstitucionalId: 0,
    CartaoInstitucional: false,
    FaturaCartao: false,
  };

  const [aPagar, setAPagar] = useState<any>(null);
  const [todosAPagar, setTodosAPagar] = useState<any>(null);
  const [aPagarDialog, setAPagarDialog] = useState<boolean>(false);
  const [deleteAPagar, setDeleteAPagar] = useState<boolean>(false);
  const [contaApagar, setContaApagar] = useState<IApagar>(emptyContaAPagar);
  const [selectedAPagar, setSelectedApagar] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);
  const [credor, setCredor] = useState<any>(null);
  const [invalidPeriod, setInvalidPeriod] = useState(false);

  const methods = useForm({ defaultValues: emptyContaAPagar });
  const { handleSubmit, control, formState: { errors }, getValues, setValue, watch } = methods;

  const loadCredor = async () => {
    let resp;
    resp = await api.get('/credor/list')

    if (resp.data) {
      setCredor(resp.data)
    } else {
      setCredor([])
    }
  }

  const watchDataInicialVencimento = watch('DataInicialVencimento')
  const watchDataFinalVencimento = watch('DataFinalVencimento')
  const watchDataInicialPagamento = watch('DataInicialPagamento')
  const watchDataFinalPagamento = watch('DataFinalPagamento')

  useEffect(() => {
    // Valida a consistência das datas e exibe uma mensagem de erro
    if (watchDataInicialVencimento && watchDataFinalVencimento) {
      if (watchDataInicialVencimento > watchDataFinalVencimento) {
        setValue('DataInicialVencimento', watchDataFinalVencimento);

        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Período Inválido!',
        });
      }
    }
  }, [watchDataInicialVencimento, watchDataFinalVencimento]);

  useEffect(() => {
    // Valida a consistência das datas e exibe uma mensagem de erro
    if (watchDataInicialPagamento && watchDataFinalPagamento) {
      if (watchDataInicialPagamento > watchDataFinalPagamento) {
        setValue('DataInicialPagamento', watchDataFinalPagamento);

        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Período Inválido!',
        });
      }
    }
  }, [watchDataInicialPagamento, watchDataFinalPagamento]);

  useEffect(() => {
    if (watchDataInicialVencimento) {
      setValue('DataFinalVencimento', watchDataInicialVencimento);
    }

    if (watchDataInicialPagamento) {
      setValue('DataFinalPagamento', watchDataInicialPagamento);
    }
  }, [watchDataInicialVencimento, watchDataInicialPagamento]);

  useEffect(() => {
    if (watchDataFinalVencimento && !watchDataInicialVencimento) {
      setValue('DataInicialVencimento', watchDataFinalVencimento);
    }

    if (watchDataFinalPagamento && !watchDataInicialPagamento) {
      setValue('DataInicialPagamento', watchDataFinalPagamento);
    }
  }, [watchDataFinalVencimento, watchDataFinalPagamento]);

  const watchOutrosRecebimentos = watch('OutrosRecebimentosId')
  const watchCartaoInstitucional = watch('CartaoInstitucional')
  const watchCredorId = watch('CredorId')

  const load = async () => {

    const dataVencInicial = getValues().DataInicialVencimento;
    const dataIV = dataVencInicial ? format(dataVencInicial, 'yyyy-MM-dd') : '';

    const dataVencFinal = getValues().DataFinalVencimento;
    const dataFV = dataVencFinal ? format(dataVencFinal, 'yyyy-MM-dd') : '';

    const dataPagInicial = getValues().DataInicialPagamento;
    const dataIP = dataPagInicial ? format(dataPagInicial, 'yyyy-MM-dd') : '';

    const dataPagFinal = getValues().DataFinalPagamento;
    const dataFP = dataPagFinal ? format(dataPagFinal, 'yyyy-MM-dd') : '';

    let resp;

    resp = await api.get('/apagar/filtroLista', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        DataIV: dataIV,
        DataFV: dataFV,
        DataIP: dataIP,
        DataFP: dataFP,
        CredorId: watchCredorId,
        Status: getValues().Status,
        CartaoInstitucional: watchCartaoInstitucional,
        OutrosRecebimentos: watchOutrosRecebimentos
      }
    })

    setAPagar(resp.data)
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    loadCredor()
  }, []);

  const openNew = () => {
    setContaApagar(emptyContaAPagar);
    setSubmitted(false);
    setAPagarDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setAPagarDialog(false);
  };

  const editar = (conta: any) => {
    setAPagarDialog(true);

    // Converte as datas no documento
    conta.DataVencimento = conta.DataVencimento ? new Date(conta.DataVencimento) : null;
    conta.DataProgramada = conta.DataProgramada ? new Date(conta.DataProgramada) : null;
    conta.DataPagamento = conta.DataPagamento ? new Date(conta.DataPagamento) : null;
    conta.DataBomPara = conta.DataBomPara ? new Date(conta.DataBomPara) : null;

    // Define o estado 'contaApagar'
    setContaApagar(conta);
  };

  const confirmDelete = (conta: any) => {
    setContaApagar(conta);
    setDeleteAPagar(true);
    setAPagarDialog(true);

    // Converte as datas
    conta.DataVencimento = conta.DataVencimento ? new Date(conta.DataVencimento) : null;
    conta.DataProgramada = conta.DataProgramada ? new Date(conta.DataProgramada) : null;
    conta.DataPagamento = conta.DataPagamento ? new Date(conta.DataPagamento) : null;
    conta.DataBomPara = conta.DataBomPara ? new Date(conta.DataBomPara) : null;
  };

  const deletar = async () => {

    if (contaApagar?.DataPagamento) {
      let dataFormat = format(new Date(), 'yyyy-MM-dd')

      try {
        const result = await api.get('/consultaEncerramento', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Data: dataFormat,
            Operador: usuario?.Apelido,
            Acao: 'F'
          }
        })

        if (result.data.length > 0) {
          toast.showToast({
            severity: 'error',
            summary: 'Estorno Cancelado',
            detail: `O Caixa está encerrado para o dia de hoje!`,
          });

          return false;
        }
      } catch (error) {
        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Erro na consulta',
        });
      }
    }

    let hora = format(new Date(), 'HH:mm')
    let consultaApagar;
    let codigoEstornoId;

    consultaApagar = await api.get('/apagar/buscaCodigoEstorno', { params: { ApagarId: contaApagar.ApagarId } });

    if (consultaApagar.data) {
      codigoEstornoId = consultaApagar.data[0].CodigosEstornoId
    }

    let dadosParaExcluir = {
      ApagarId: contaApagar.ApagarId,
      Excluido: 1,
      ExcluidoData: new Date(),
      ExcluidoHora: hora,
      ExcluidoOperador: usuario?.Apelido,
    }

    let dadosEstornoDiarioFinanceiro = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      LancamentoData: new Date(),
      LancamentoHora: hora,
      LancamentoOperador: usuario?.Apelido,
      Tarefa: 'APAGAR',
      Opcao: 'EST',
      DataPagamento: contaApagar.DataPagamento,
      DataDisponivel: contaApagar.DataBomPara,
      DataBomPara: contaApagar.DataBomPara,
      Nome: contaApagar.Nome,
      Valor: contaApagar.ValorPagamento * -1,
      Referencia: contaApagar.Referente,
      NumeroRecibo: contaApagar.ApagarId,
      FonteId: contaApagar.FonteId,
      FormasPagamentoId: contaApagar.FormasPagamentoId,
      ContaValorId: contaApagar.ContaValorId,
      ChequeNumero: contaApagar.NumeroDocumentoPagamento,
      CartaoNumero: contaApagar.NumeroDocumentoPagamento,
      FlgMeuCaixa: contaApagar.FlgMeuCaixa,
      CodigosEstornoId: codigoEstornoId
    }

    let dadosEncerramentoCaixa = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      CaixaData: new Date(),
      CaixaHora: hora,
      CaixaOperador: usuario?.Apelido,
      CaixaValor: contaApagar.ValorPagamento
    }

    try {
      await api.post('/diariofinanceiro', dadosEstornoDiarioFinanceiro)
      await api.put('/apagar/excluir', dadosParaExcluir)
      await api.post('/gravacaixa', dadosEncerramentoCaixa)

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteAPagar(false);
      setAPagarDialog(false);
      setContaApagar(emptyContaAPagar);

    } catch (error) {
      console.error('Erro ao salvar no Diário Financeiro:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Diário Financeiro',
      });
    }
  };

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Contas a Pagar</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form className='pt-2'>
            <SCFieldset legend='Filtros' className='col-12 mt-1 p-0'>
              <div className='grid align-items-center justify-content-center'>
                <div className="col-12 md:col-2" style={{ marginTop: -10 }}>
                  <SCCalendar
                    control={control}
                    name='DataInicialVencimento'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    placeholder='Vencimento Inicial'
                    showIcon />
                </div>

                <div className="col-12 md:col-2" style={{ marginTop: -10 }}>
                  <SCCalendar
                    control={control}
                    name='DataFinalVencimento'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    placeholder='Vencimento Final'
                    showIcon />
                </div>

                <div className="col-12 md:col-2" style={{ marginTop: -10 }}>
                  <SCCalendar
                    control={control}
                    name='DataInicialPagamento'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    placeholder='Pagamento Inicial'
                    inputStyle={{ background: '#c9e1f7' }}
                    showIcon />
                </div>

                <div className="col-12 md:col-2" style={{ marginTop: -10 }}>
                  <SCCalendar
                    control={control}
                    name='DataFinalPagamento'
                    dateFormat='dd/mm/yy'
                    mask='99/99/9999'
                    placeholder='Pagamento Final'
                    inputStyle={{ background: '#c9e1f7' }}
                    showIcon />
                </div>
              </div>

              <div className='grid mt-1 align-items-center justify-content-center'>
                <div className="col-12 md:col-3" style={{ marginTop: -10 }}>
                  <SCDropdown
                    name="CredorId"
                    options={credor}
                    optionLabel="Nome"
                    optionValue='CredorId'
                    placeholder='Selecione o Credor'
                    style={{ width: '100%' }}
                    value={watchCredorId}
                    onChange={(e) => setValue('CredorId', e.target.value)}
                  />
                </div>
                <div className="col-12 md:col-3" style={{ marginTop: -10 }}>
                  <SCDropdown
                    name="Status"
                    options={[
                      { label: 'TODOS', value: 'TODOS' },
                      { label: 'PAGOS', value: 'PAGOS' },
                      { label: 'APAGAR - TODOS', value: 'APAGAR-TODOS' },
                      { label: 'APAGAR - VENCIDOS', value: 'APAGAR-VENCIDOS' },
                      { label: 'APAGAR - A VENCER', value: 'APAGAR-A-VENCER' }
                    ]}
                    onChange={(e) => setValue('Status', e.target.value)}
                    value={watch('Status')}
                    style={{ width: '100%' }}
                    optionLabel="label"
                    optionValue='value'
                    placeholder='Status'
                  />
                </div>
                <div className="col-12 md:col-2" style={{ marginTop: -10 }}>
                  <SCCheckbox
                    control={control}
                    name='CartaoInstitucional'
                    label='Cartão Institucional'
                  />
                </div>
                <div className="col-12 md:col-2" style={{ marginTop: -10 }}>
                  <SCCheckbox
                    control={control}
                    name='OutrosRecebimentosId'
                    label='Mostrar Taxas'
                  />
                </div>
              </div>

              <div className='grid mt-1 justify-content-center'>
                <div className='col-12 md:col-4 flex align-items-end justify-content-around' style={{ marginTop: -10 }}>
                  <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                  <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                  <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                  <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
                </div>
              </div>
            </SCFieldset>
          </form>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={aPagar}
              onSelectionChange={(e) => setSelectedApagar(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="NumeroDocumento" header="Nº Documento" sortable headerStyle={{ width: '10%' }} ></Column>
              <Column field="DataVencimento" header="Vencimento" sortable body={(rowData: any) => {
                if (rowData.DataVencimento) {
                  return (
                    <span>{rowData.DataVencimentoString}</span>
                  );
                }
                return null;
              }} style={{ width: '12%' }} />

              <Column field="Nome" header="Credor" sortable headerStyle={{ width: '20%' }} ></Column>
              <Column field="Referente" header="Referente" sortable headerStyle={{ width: '20%' }} ></Column>

              <Column field="Valor" header="Valor" align='center' sortable body={(rowData: any) => {
                return (
                  <span>R$ {rowData.Valor?.toFixed(2).replace('.', ',')}</span>
                );
              }} style={{ width: '15%' }}
              />

              <Column field="DataPagamento" header="Data Pagto" sortable body={(rowData: any) => {
                if (rowData.DataPagamento) {
                  return (
                    <span>{rowData.DataPagamentoString}</span>
                  );
                }
                return null;
              }} style={{ width: '12%' }} />


              <Column field="ValorPagamento" header="Valor Pago" align='center' sortable body={(rowData: any) => {
                return (
                  <span>R$ {rowData.ValorPagamento?.toFixed(2).replace('.', ',')}</span>
                );
              }} style={{ width: '12%' }}
              />

              <Column field="FaturaCartao" header="Fatura" align='center' sortable body={(rowData: any) => {
                if (rowData.FaturaCartao) {
                  return (
                    <CiCreditCard1 size={20} color={rowData.LiquidaFaturaId != 0 ? "#01902f" : 'red'} />
                  );
                }
              }} style={{ width: '12%' }}
              />

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={aPagarDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${contaApagar.ApagarId === undefined ? 'Cadastrando ' : (deleteAPagar ? 'Excluíndo' : 'Alterando')} Contas A Pagar`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <ContasAPagarForm
              setAPagarDialog={setAPagarDialog}
              contaApagar={contaApagar}
              reload={load}
              deleteAPagar={deleteAPagar}
              setDeleteAPagar={setDeleteAPagar}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default ContasAPagar;
