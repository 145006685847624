import React, { createContext, useContext, useState } from 'react'
import api from "../services/api";
import IAuthContextData from '../interfaces/IAuthContextData';
import IAuthState from '../interfaces/IAuthState';
import ISignIn from '../interfaces/ISigIn';
import { IEmpresa, IPeriodo } from '../interfaces/IUsuario';

interface Props {
    children: React.ReactNode;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC<Props> = ({ children }) => {
    
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState<IAuthState>((): IAuthState => {
        const usuario_ = localStorage.getItem('harpia.usuario');
        const periodo_ = localStorage.getItem('harpia.periodo');
        const empresa_ = localStorage.getItem('harpia.empresa');

        if (usuario_)
            return { usuario: JSON.parse(usuario_), empresa: JSON.parse(empresa_!), periodo: JSON.parse(periodo_!) }

        return {} as IAuthState;
    })

    const signIn = async ({Codigo, Email, Senha}: ISignIn) => {
        setLoading(true);
        const usuario_ = await api.post('usuario/login', {Codigo, Email, Senha});
        if (!usuario_.data.Error){
            usuario_.data.Codigo = Codigo;
            setData({usuario: usuario_.data});
            localStorage.setItem('harpia.usuario', JSON.stringify({...usuario_.data}));            
            localStorage.setItem('harpia.codigo', Codigo || '');            
        }
        setLoading(false);
        
        return usuario_.data;
    }

    const signUp = async (data: any) => {
        const usuario_ = await api.post('signup', data);
        if (usuario_.data){
            setData({usuario: usuario_.data});
            localStorage.setItem('harpia.usuario', JSON.stringify({...usuario_.data}));
        }
        
        return usuario_.data;
    }

    const signOut = (UsuarioId: any) => {
        
        api.put('usuario/logout', { UsuarioId: UsuarioId });
        setData({usuario: undefined, periodo: undefined, empresa: undefined});
        localStorage.removeItem('harpia.usuario');        
        localStorage.removeItem('harpia.empresa');        
        localStorage.removeItem('harpia.periodo');        
    }

    const setEmpresaPeriodo = async (empresa: IEmpresa, periodo: IPeriodo): Promise<any> => {
        setLoading(true);
        setData({...data, periodo, empresa});
        localStorage.setItem('harpia.empresa', JSON.stringify(empresa));            
        localStorage.setItem('harpia.periodo', JSON.stringify(periodo));            
        setLoading(false);     
        return {...data, periodo, empresa};   
    }

    const setEmpresa = async (empresa: IEmpresa): Promise<any> => {
        setLoading(true);
        setData({...data, empresa});
        localStorage.setItem('harpia.empresa', JSON.stringify(empresa));            
        setLoading(false);     
        return {...data, empresa};   
    }

    const alteraFoto = async (foto: string): Promise<any> => {
        setLoading(true);
        setData({...data, usuario: {...data.usuario, Foto: foto}});
        localStorage.setItem('harpia.usuario', JSON.stringify({...data.usuario, Foto: foto}));
        setLoading(false);
    }

    return (
        <AuthContext.Provider value={{isLoged: !!data.usuario, loading, usuario: data.usuario, 
            periodoSelecionado: data.periodo, empresaSelecionada: data.empresa, 
            signIn, signUp, signOut, setEmpresaPeriodo, alteraFoto, setEmpresa}}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuth(): IAuthContextData {
    const context = useContext(AuthContext);
    if (!context){
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}

export { AuthProvider, useAuth };