import React from 'react';
import { useFormContext } from 'react-hook-form';

import { SCCalendar, SCDropdown, SCInputMask, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import FormEndereco from '../components/FormEndereco';
import SelectEstadoCivil from '../components/SelectEstadoCivil';
import SelectParentesco from '../components/SelectParentesco';
import SelectCidade from '../components/SelectCidade';
import { isValid } from 'date-fns';
import util from '../utilities/util';
import { IAlunoForm } from '../interfaces/IAlunos';
import { UF_LISTA } from '../utilities/constantes';
import InputCPFCNPJ from '../components/form/inputCPFCNPJ/inputCPFCNPJ';


interface Props {
  disabled: boolean;
  tipo: string;
  isLoading?: boolean;
};

const AlunosFormResponsavel: React.FC<Props> = ({ disabled, tipo='FIN' }) => {

  const toast = useToast();

  const { control, formState: { errors }, setValue, watch, getValues, clearErrors, setError } = useFormContext();

  const validaDataNascimento = (e: any) => {
    const data = e.target.value;
    console.log('data', isValid(new Date(data)));
    if (data !== null && isValid(new Date(data))) 
      if (util.calcularIdade(data) < 10) {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Responsável menor de 10 anos' });
        setValue(`Responsavel${tipo}.DataNascimento`, '');
      }
    return true;
  }

  const copiaDados = (e: any) => {    
    const from = e.target.value.toUpperCase();
    const dados = getValues() as IAlunoForm;
    switch (from) {
      case '=':
      {
        setValue(`Responsavel${tipo}.Nome`, dados.Nome);
        setValue(`Responsavel${tipo}.DataNascimento`, dados.DataNascimento);
        setValue(`Responsavel${tipo}.ParentescoId`, 0);
        setValue(`Responsavel${tipo}.EstadoCivil`, dados.EstadoCivil);
        setValue(`Responsavel${tipo}.Natural`, dados.Natural);
        setValue(`Responsavel${tipo}.Nacionalidade`, dados.Nacionalidade);
        setValue(`Responsavel${tipo}.RG`, dados.RG);
        setValue(`Responsavel${tipo}.RGOrgaoExpeditorUF`, dados.RGOrgaoExpeditorUF);
        setValue(`Responsavel${tipo}.CPF`, dados.CPF);
        setValue(`Responsavel${tipo}.CNPJ`, '');
        setValue(`Responsavel${tipo}.DDD`, dados.DDD);
        setValue(`Responsavel${tipo}.FoneCelular`, dados.FoneCelular);
        setValue(`Responsavel${tipo}.FoneOutro`, dados.FoneOutro);
        setValue(`Responsavel${tipo}.Email`, dados.Email);
        setValue(`${tipo}Endereco.Logradouro`, dados?.ALUNOEndereco?.Logradouro);
        setValue(`${tipo}Endereco.Cep`, dados?.ALUNOEndereco?.Cep);
        setValue(`${tipo}Endereco.Numero`, dados?.ALUNOEndereco?.Numero);
        setValue(`${tipo}Endereco.Complemento`, dados?.ALUNOEndereco?.Complemento);
        setValue(`${tipo}Endereco.Bairro`, dados?.ALUNOEndereco?.Bairro);
        setValue(`${tipo}Endereco.UF`, dados?.ALUNOEndereco?.UF);
        setValue(`${tipo}Endereco.Cidade`, dados?.ALUNOEndereco?.Cidade);
        break;
      }           
      case 'PAI':
      {
        setValue(`Responsavel${tipo}.Nome`, dados.Pai);
        setValue(`Responsavel${tipo}.DataNascimento`, dados.PaiDataNascimento);
        setValue(`Responsavel${tipo}.ParentescoId`, 5);
        setValue(`Responsavel${tipo}.EstadoCivil`, dados.PaiEstadoCivil);
        setValue(`Responsavel${tipo}.Natural`, '');
        setValue(`Responsavel${tipo}.Nacionalidade`, dados.PaiNacionalidade);
        setValue(`Responsavel${tipo}.RG`, dados.PaiRG);
        setValue(`Responsavel${tipo}.RGOrgaoExpeditorUF`, dados.PaiRGOrgaoExpeditorUF);
        setValue(`Responsavel${tipo}.CPF`, dados.PaiCPF);
        setValue(`Responsavel${tipo}.CNPJ`, '');
        setValue(`Responsavel${tipo}.DDD`, dados.PaiDDD);
        setValue(`Responsavel${tipo}.FoneOutro`, dados.PaiFoneOutro);
        setValue(`Responsavel${tipo}.FoneCelular`, dados.PaiFoneCelular);
        setValue(`Responsavel${tipo}.Email`, dados.PaiEmail);
        setValue(`Responsavel${tipo}.Profissao`, dados.PaiProfissao);
        setValue(`${tipo}Endereco.Logradouro`, dados?.PAIEndereco?.Logradouro);
        setValue(`${tipo}Endereco.Cep`, dados?.PAIEndereco?.Cep);
        setValue(`${tipo}Endereco.Numero`, dados?.PAIEndereco?.Numero);
        setValue(`${tipo}Endereco.Complemento`, dados?.PAIEndereco?.Complemento);
        setValue(`${tipo}Endereco.Bairro`, dados?.PAIEndereco?.Bairro);
        setValue(`${tipo}Endereco.UF`, dados?.PAIEndereco?.UF);
        setValue(`${tipo}Endereco.Cidade`, dados?.PAIEndereco?.Cidade);
        break;
      } 
      case 'MAE':
      {
        setValue(`Responsavel${tipo}.Nome`, dados.Mae);
        setValue(`Responsavel${tipo}.DataNascimento`, dados.MaeDataNascimento);
        setValue(`Responsavel${tipo}.ParentescoId`, 4);
        setValue(`Responsavel${tipo}.EstadoCivil`, dados.MaeEstadoCivil);
        setValue(`Responsavel${tipo}.Natural`, '');
        setValue(`Responsavel${tipo}.Nacionalidade`, dados.MaeNacionalidade);
        setValue(`Responsavel${tipo}.RG`, dados.MaeRG);
        setValue(`Responsavel${tipo}.RGOrgaoExpeditorUF`, dados.MaeRGOrgaoExpeditorUF);
        setValue(`Responsavel${tipo}.CPF`, dados.MaeCPF);
        setValue(`Responsavel${tipo}.CNPJ`, '');
        setValue(`Responsavel${tipo}.DDD`, dados.MaeDDD);
        setValue(`Responsavel${tipo}.FoneCelular`, dados.MaeFoneCelular);
        setValue(`Responsavel${tipo}.FoneOutro`, dados.MaeFoneOutro);
        setValue(`Responsavel${tipo}.Email`, dados.MaeEmail);
        setValue(`Responsavel${tipo}.Empresa`, dados.MaeEmpresa);
        setValue(`Responsavel${tipo}.Profissao`, dados.MaeProfissao);
        setValue(`${tipo}Endereco.Logradouro`, dados?.MAEEndereco?.Logradouro);
        setValue(`${tipo}Endereco.Cep`, dados?.MAEEndereco?.Cep);
        setValue(`${tipo}Endereco.Numero`, dados?.MAEEndereco?.Numero);
        setValue(`${tipo}Endereco.Complemento`, dados?.MAEEndereco?.Complemento);
        setValue(`${tipo}Endereco.Bairro`, dados?.MAEEndereco?.Bairro);
        setValue(`${tipo}Endereco.UF`, dados?.MAEEndereco?.UF);
        setValue(`${tipo}Endereco.Cidade`, dados?.MAEEndereco?.Cidade);
        break;
      }    
      case 'RFIN':
      {
        setValue(`Responsavel${tipo}.Nome`, dados?.ResponsavelFIN?.Nome);
        setValue(`Responsavel${tipo}.DataNascimento`, dados?.ResponsavelFIN?.DataNascimento);
        setValue(`Responsavel${tipo}.ParentescoId`, dados?.ResponsavelFIN?.ParentescoId);
        setValue(`Responsavel${tipo}.EstadoCivil`, dados?.ResponsavelFIN?.EstadoCivil);
        setValue(`Responsavel${tipo}.Natural`, dados?.ResponsavelFIN?.Natural);
        setValue(`Responsavel${tipo}.Nacionalidade`, dados?.ResponsavelFIN?.Nacionalidade);
        setValue(`Responsavel${tipo}.RG`, dados?.ResponsavelFIN?.RG);
        setValue(`Responsavel${tipo}.RGOrgaoExpeditorUF`, dados?.ResponsavelFIN?.RGOrgaoExpeditor);
        setValue(`Responsavel${tipo}.CPF`, dados?.ResponsavelFIN?.CPF);
        setValue(`Responsavel${tipo}.CNPJ`, dados?.ResponsavelFIN?.CNPJ);
        setValue(`Responsavel${tipo}.DDD`, dados?.ResponsavelFIN?.DDD);
        setValue(`Responsavel${tipo}.FoneCelular`, dados?.ResponsavelFIN?.FoneCelular);
        setValue(`Responsavel${tipo}.FoneOutro`, dados?.ResponsavelFIN?.FoneOutro);
        setValue(`Responsavel${tipo}.Email`, dados?.ResponsavelFIN?.Email);
        setValue(`Responsavel${tipo}.Empresa`, dados?.ResponsavelFIN?.Empresa);
        setValue(`Responsavel${tipo}.Profissao`, dados?.ResponsavelFIN?.Profissao);
        setValue(`${tipo}Endereco.Logradouro`, dados?.FINEndereco?.Logradouro);
        setValue(`${tipo}Endereco.Cep`, dados?.FINEndereco?.Cep);
        setValue(`${tipo}Endereco.Numero`, dados?.FINEndereco?.Numero);
        setValue(`${tipo}Endereco.Complemento`, dados?.FINEndereco?.Complemento);
        setValue(`${tipo}Endereco.Bairro`, dados?.FINEndereco?.Bairro);
        setValue(`${tipo}Endereco.UF`, dados?.FINEndereco?.UF);
        setValue(`${tipo}Endereco.Cidade`, dados?.FINEndereco?.Cidade);
        break;
      }   
      case 'RACAD':
      {
        setValue(`Responsavel${tipo}.Nome`, dados?.ResponsavelACA?.Nome);
        setValue(`Responsavel${tipo}.DataNascimento`, dados?.ResponsavelACA?.DataNascimento);
        setValue(`Responsavel${tipo}.ParentescoId`, dados?.ResponsavelACA?.ParentescoId);
        setValue(`Responsavel${tipo}.EstadoCivil`, dados?.ResponsavelACA?.EstadoCivil);
        setValue(`Responsavel${tipo}.Natural`, dados?.ResponsavelACA?.Natural);
        setValue(`Responsavel${tipo}.Nacionalidade`, dados?.ResponsavelACA?.Nacionalidade);
        setValue(`Responsavel${tipo}.RG`, dados?.ResponsavelACA?.RG);
        setValue(`Responsavel${tipo}.RGOrgaoExpeditorUF`, dados?.ResponsavelACA?.RGOrgaoExpeditor);
        setValue(`Responsavel${tipo}.CPF`, dados?.ResponsavelACA?.CPF);
        setValue(`Responsavel${tipo}.CNPJ`, dados?.ResponsavelACA?.CNPJ);
        setValue(`Responsavel${tipo}.DDD`, dados?.ResponsavelACA?.DDD);
        setValue(`Responsavel${tipo}.FoneCelular`, dados?.ResponsavelACA?.FoneCelular);
        setValue(`Responsavel${tipo}.FoneOutro`, dados?.ResponsavelACA?.FoneOutro);
        setValue(`Responsavel${tipo}.Email`, dados?.ResponsavelACA?.Email);
        setValue(`Responsavel${tipo}.Empresa`, dados?.ResponsavelACA?.Empresa);
        setValue(`Responsavel${tipo}.Profissao`, dados?.ResponsavelACA?.Profissao);
        setValue(`${tipo}Endereco.Logradouro`, dados?.ACAEndereco?.Logradouro);
        setValue(`${tipo}Endereco.Cep`, dados?.ACAEndereco?.Cep);
        setValue(`${tipo}Endereco.Numero`, dados?.ACAEndereco?.Numero);
        setValue(`${tipo}Endereco.Complemento`, dados?.ACAEndereco?.Complemento);
        setValue(`${tipo}Endereco.Bairro`, dados?.ACAEndereco?.Bairro);
        setValue(`${tipo}Endereco.UF`, dados?.ACAEndereco?.UF);
        setValue(`${tipo}Endereco.Cidade`, dados?.ACAEndereco?.Cidade);
        break;
      }   
    }
  }

  return (
    <div className='col-12'>

      <div className='grid'>
        <div className="col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name={`Responsavel${tipo}.Nome`}
            label='Nome (= / PAI / MAE)'
            disabled={disabled}
            required={tipo=='FIN' ? true : false}
            maxLength={60}
            onBlur={copiaDados}
          />
        </div>
        <div className="col-3">
          <SCCalendar
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.DataNascimento`}
            label="Data Nascimento"            
            mask='99/99/9999'
            dateFormat='dd/mm/yy'
            showIcon
            onBlur={validaDataNascimento}
            showOnFocus={false} 
            disabled={disabled}
          />
        </div>       
      </div>

      <div className='grid'>
        <div className="col-2">
          <SelectParentesco 
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.ParentescoId`}
            label='Grau de Parentesco'
            disabled={disabled}
          />
        </div>      
        <div className="col-2">
          <SelectEstadoCivil 
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.EstadoCivil`}
            label='Est. Cív.'
            disabled={disabled}
          />
        </div>
        <div className="col-2">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name={`Responsavel${tipo}.Nacionalidade`}
            label='Nacionalidade'
            disabled={disabled}
            maxLength={20}
          />
        </div>
        <div className="col-3">
          <SCDropdown
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.Natural`}
            label='Natural'
            options={UF_LISTA}
            optionLabel='nome'
            optionValue='sigla'
          />          
        </div>
        <div className="col-3">
            <SelectCidade
              uf={watch(`Responsavel${tipo}.Natural`)}
              control={control}
              errors={errors}
              name="LocalNascimento"
              label='Local de Nascimento'
            />
          </div>
      </div>      

      <div className='grid'>
        <div className="col-2">
          <SCInputText
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.RG`}
            label='RG'
            disabled={disabled}
            maxLength={30}
            numberOnly
          />
        </div>    
        <div className="col-2">
          <SCInputText
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.RGOrgaoExpeditorUF`}
            label='Orgão Exp./UF'
            disabled={disabled}
            maxLength={25}
            onBlur={(e) => {
              const rg = getValues(`Responsavel${tipo}.RG`);
              clearErrors(`Responsavel${tipo}.RG`);
              if ((rg == '' || rg == null)  && e.target?.value?.length > 0)
                setError(`Responsavel${tipo}.RG`, { type: 'manual', message: 'Preencha o campo' });
            }}
          />
        </div>  
        <div className="col-3">
          <InputCPFCNPJ
            label={`CPF ${tipo == 'FIN' ? '/ CNPJ' : ''}`}
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.CPF`}
            disabled={disabled}
            required={tipo=='FIN' ? true : false}
            className='p-inputtext p-component undefined block'
            onBlur={(e) => util.cpfValido(e.target.value) ? clearErrors(`Responsavel${tipo}.CPF`) : setError(`Responsavel${tipo}.CPF`, { type: 'manual', message: 'CPF inválido' })}
          />
        </div>          
      </div>      
      
      <FormEndereco tipo={tipo} matricula={watch('Matricula')} required={tipo=='FIN' ? true : false} />     

      <div className='grid'>
        <div className="col-1">
          <SCInputMask
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.DDD`}
            label='DDD'
            mask='(999)'
            disabled={disabled}
            maxLength={5}
          />
        </div>
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name={`Responsavel${tipo}.FoneCelular`}
            label='Celular'
            disabled={disabled}
            maxLength={20}
          />
        </div>
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.FoneOutro`}
            label='Outros Fones'
            disabled={disabled}
            maxLength={40}
          />
        </div>      
        <div className="col-4">
          <SCInputText
            control={control}
            errors={errors}
            name={`Responsavel${tipo}.Email`}
            label='E-mail'
            disabled={disabled}
            maxLength={60}
          />
        </div>  
      </div>

      {tipo=='FIN' && (
        <>
          <Divider align="center">
            <div className="inline-flex align-items-center">
              <i className="pi pi-building mr-2"></i>
              <b>Comercial</b>
            </div>
          </Divider>

          <div className='grid'>
            <div className="col-5">
              <SCInputText
                control={control}
                errors={errors}
                style={{ textTransform: 'uppercase' }}
                name={`Responsavel${tipo}.Empresa`}
                label='Empresa'
                disabled={disabled}
                maxLength={60}
              />
            </div>
            <div className="col-5">
              <SCInputText
                control={control}
                errors={errors}
                name={`Responsavel${tipo}.Profissao`}
                label='Profissão'
                disabled={disabled}
                maxLength={50}
              />
            </div>      
          </div>

          <FormEndereco tipo={`${tipo}COM`} matricula={watch('Matricula')} />   
        </>  
      )}
    </div>
  );
};

export default AlunosFormResponsavel;
