import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import IEndereco from '../interfaces/IEndereco';
import EnderecoForm from './EnderecoForm';


const Endereco = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'EnderecoForm';

  let emptyEndereco: IEndereco = {
    EnderecosId: undefined,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Cep: '',
    Logradouro: '',
    Numero: '',
    Bairro: '',
    Cidade: '',
    UF: '',
    Complemento: '',
  };

  const [enderecos, setEnderecos] = useState<any>(null);
  const [enderecoDialog, setEnderecoDialog] = useState<boolean>(false);
  const [deleteEndereco, setDeleteEndereco] = useState<boolean>(false);
  const [endereco, setEndereco] = useState<IEndereco>(emptyEndereco);
  const [selectedEndereco, setSelectedEndereco] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyEndereco });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true)
    const result = await api.get('/enderecos/list', { params: { Logradouro: getValues()?.Logradouro } });
    if (result.data)
      setEnderecos(result.data);
    else
      setEnderecos([]);
    setLoading(false)
  }

  const formatarCep = (cep: any) => {
    if (cep && cep.length === 8) {
      return `${cep.substr(0, 5)}-${cep.substr(5)}`;
    }
    return cep;
  };

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setEndereco(emptyEndereco);
    setSubmitted(false);
    setEnderecoDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setEnderecoDialog(false);
  };
  const editar = (endereco: any) => {
    setEndereco(endereco);
    setEnderecoDialog(true);
  };

  const confirmDelete = (endereco: any) => {
    setEndereco(endereco);
    setDeleteEndereco(true);
    setEnderecoDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/enderecos', { params: { EnderecosId: endereco.EnderecosId } });
      console.log(resp.data);
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteEndereco(false);
      setEnderecoDialog(false);
      setEndereco(emptyEndereco);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    console.log(data);
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  if (loading)
    return <Loading />

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Endereços</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Logradouro"
                  label='Logradouro'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />

          {loading ? <Loading /> : (
            <DataTable
              stripedRows
              ref={dt}
              value={enderecos}
              onSelectionChange={(e) => setSelectedEndereco(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column
                field="CEP"
                header="CEP"
                sortable
                headerStyle={{ width: 200 }}
                body={(rowData) => <span>{formatarCep(rowData.CEP)}</span>}
              ></Column>
              <Column field="Logradouro" header="Logradouro" sortable ></Column>
              <Column field="Bairro" header="Bairro" sortable ></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}
          <SCDialog maximized={!util.isDesktop()} visible={enderecoDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${endereco.EnderecosId === undefined ? 'Cadastrando ' : (deleteEndereco ? 'Excluíndo' : 'Alterando')} Endereço`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <EnderecoForm
              setEnderecoDialog={setEnderecoDialog}
              endereco={endereco}
              reload={load}
              deleteEndereco={deleteEndereco}
              setDeleteEndereco={setDeleteEndereco}
              deletar={deletar}
            />
          </SCDialog>

        </div>
      </div>
    </div>
  );
};

export default Endereco;
