import { isValid } from "cpf";
import api from "../services/api";

const util = {
  formatCurrency: (value: any) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  },
  isDesktop: () => {
    return window.innerWidth > 896;
  },
  calcularIdade(dataNascimento: Date) {
    const hoje = new Date();
    const nascimento = new Date(dataNascimento);
    let idade = hoje.getFullYear() - nascimento.getFullYear();
    const mes = hoje.getMonth() - nascimento.getMonth();

    // Ajusta a idade se o aniversário ainda não aconteceu no ano atual
    if (mes < 0 || (mes === 0 && hoje.getDate() < nascimento.getDate())) {
      idade--;
    }

    return idade;
  },
  formatarData: (data: Date) => {
    return data.toLocaleDateString('pt-BR');
  },
  buscaParametro: async (parametro: string) => {
    const restParametro = await api.get(`/parametros/${parametro}`);
  },

  removeMascaraCelular: (value: any) => {
    return value.replace(/\D/g, '');
  },

  transformStringsToUppercase(obj: any, fieldsToIgnore: string[] = []){
    for (let key in obj) {
      if (!fieldsToIgnore.includes(key) && typeof obj[key] === 'string') {
        obj[key] = obj[key].toUpperCase();
      } else if (Array.isArray(obj[key])) {
        obj[key] = obj[key].map((item: any) => util.transformStringsToUppercase(item));
      } else if (typeof obj[key] === 'object') {
        obj[key] = util.transformStringsToUppercase(obj[key]);
      }
    }
    return obj;
  },

  cnpjMask(value: any){
    return value
      .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
  },

  cpfValido(value: string){
    const cpf = value.replace(/\D/g, '');
    if (cpf === '') return true;

    if (cpf === '77777777777') {
      return true;
    }
    
    return isValid(cpf);
  },
  cnpjValido(value: string) {
    const cnpj = value.replace(/\D/g, '');
    if (cnpj === '77777777777777') {
      return true;
    }
    
    return this.validaCNPJ(cnpj);
  },
  validaCNPJ(cnpj: string) {
    cnpj = cnpj.replace(/\D/g, '');
    if (cnpj.length !== 14) return false;
  
    if (/^(\d)\1+$/.test(cnpj)) return false;
  
    const pesosPrimeiroDigito = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const pesosSegundoDigito = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  
    const calcularDigito = (digitos: string, pesos: number[]) => {
      let soma = 0;
      for (let i = 0; i < digitos.length; i++) {
        soma += parseInt(digitos[i]) * pesos[i];
      }
      const resto = soma % 11;
      return resto < 2 ? 0 : 11 - resto;
    };
  
    const digito1 = calcularDigito(cnpj.slice(0, 12), pesosPrimeiroDigito);
    const digito2 = calcularDigito(cnpj.slice(0, 12) + digito1, pesosSegundoDigito);
  
    return cnpj.slice(-2) === `${digito1}${digito2}`;
  }
}

export default util;