import React, { createRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { CSSTransition } from 'react-transition-group';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Divider } from 'primereact/divider';

import { PanelMenu } from 'primereact/panelmenu';
import api from '../services/api';
import { MenuItem } from 'primereact/menuitem';

const AppSubmenu = forwardRef((props: any, ref: any) => {
    const [activeIndex, setActiveIndex] = useState(null);
    

    const onMenuItemClick = (event: any, item: any, index: any) => {
        console.log('onMenuItemClick', item, index);
        if (item.disabled) {
            event.preventDefault();
            return;
        }
        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
            event.preventDefault();
        }
        if (item.items) {
            event.preventDefault();
        }
        if (props.root) {
            props.onRootMenuItemClick({
                originalEvent: event
            });
        }
        if (item.items) {
            setActiveIndex(index === activeIndex ? null : index);
        }

        props.onMenuItemClick({
            originalEvent: event,
            item: item
        });
    };

    const onMenuItemMouseEnter = (index: any) => {
        if (props.root && props.menuActive && isHorizontalOrSlim() && !isMobile()) {
            setActiveIndex(index);
        }
    };

    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    const isHorizontalOrSlim = useCallback(() => {
        return props.layoutMode === 'horizontal' || props.layoutMode === 'slim';
    }, [props.layoutMode]);

    const isSlim = useCallback(() => {
        return props.layoutMode === 'slim';
    }, [props.layoutMode]);

    const visible = (item: any) => {
        return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
    };

    const getLink = (item: any, index: any) => {
        const menuitemIconClassName = classNames('layout-menuitem-icon', item.icon);
        const content = (
            <>
                {item.icon && <i className={`ph-bold ${item.icon}`} style={{fontSize: 20, marginRight: 4}}></i>}
                {item.label == '#-' ? <Divider style={{height: 10}} className='p-0 m-0'/> : <span className="layout-menuitem-text" style={{marginBottom: 10}}>{item.label}</span>}
                {item.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
                {item.badge && <Badge value={item.badge} />}
                <Ripple />
            </>
        );

        const commonLinkProps = {
            style: item.style,
            className: classNames(item.class, 'p-ripple', {
                'p-disabled': item.disabled,
                'p-link': !item.to
            }),
            target: item.target,
            onClick: (e: any) => onMenuItemClick(e, item, index),
            onMouseEnter: () => onMenuItemMouseEnter(index)
        };

        if (item.url) {
            return (
                <a  href={item.url} rel="noopener noreferrer" {...commonLinkProps}>
                    {content}
                </a>
            );
        } else if (!item.to) {
            return (
                <button type="button" {...commonLinkProps} >
                    {content}
                </button>
            );
        }

        return (
            <NavLink to={item.to} {...commonLinkProps} className={({ isActive }) => classNames(commonLinkProps.className, isActive ? 'active-menuitem-routelink' : undefined)}>
                {content}
            </NavLink>
        );
    };

    const isMenuActive = (item: any, index: any) => {
        return item.items && (props.root && (!isSlim() || (props.mobileMenuActive || activeIndex !== null)) ? true : activeIndex === index);
    };

    const getItems = () => {
        const transitionTimeout = props.mobileMenuActive ? 0 : isSlim() && props.root ? { enter: 0, exit: 0 } : props.root ? 0 : { enter: 1000, exit: 450 };
        return props.items.map((item: any, i: any) => {
            if (visible(item)) {
                const submenuRef = createRef<any>();
                const menuitemClassName = classNames({
                    'layout-root-menuitem': props.root,
                    'active-menuitem': activeIndex === i && !item.disabled
                });
                const rootMenuItem = props.root && <div className="layout-menuitem-root-text">{item.label}</div>;
                const link = getLink(item, i);
                const tooltip = (
                    <div className="layout-menu-tooltip">
                        <div className="layout-menu-tooltip-arrow"></div>
                        <div className="layout-menu-tooltip-text">{item.label}</div>
                    </div>
                );

                return (
                    <li key={i} className={menuitemClassName} role="menuitem">
                        {rootMenuItem}
                        {link}
                        {tooltip}
                        <CSSTransition nodeRef={submenuRef} classNames="p-toggleable-content" timeout={transitionTimeout} in={isMenuActive(item, i)} unmountOnExit>
                            <AppSubmenu ref={submenuRef} items={visible(item) && item.items} menuActive={props.menuActive} layoutMode={props.layoutMode} parentMenuItemActive={activeIndex === i} onMenuItemClick={props.onMenuItemClick}></AppSubmenu>
                        </CSSTransition>
                    </li>
                );
            }

            return null;
        });
    };

    useEffect(() => {
        if (!props.menuActive) {
            setActiveIndex(null);
        }
    }, [props.menuActive]);

    if (!props.items) {
        return null;
    }

    const items = getItems();
    return (
        <ul ref={ref} className={props.className} role="menu">
            {items}
        </ul>
    );
});

const AppMenu = (props: any) => {

    const [menuData, setMenuData] = useState([]);
    const navigate = useNavigate();

    const getSistemMenu = async (SistemaId: any) => {
        const resulMenu = await api.get('/menu', { params: { SistemaId }});

        const menuData = resulMenu.data.filter((i: any) => i.Descricao !== '-').map((item: any) => ({
            ...item,
            label: item.Descricao,
            icon: `ph ph-bold ${item.Icone} menu-icon`,
            command: (event: any) => {                
                if (item.Caminho) navigate(item.Caminho);
            },
            separator: item.Descricao == '#-',
            // template: (item: any, options: any) => {
            //     if (item.label == '#-')
            //         return (
            //             <Divider style={{height: 10}} className='p-0 m-0'/>
            //         );                                                        
            // }
            disabled: item.Caminho == '##'
        }));

        const menu = createMenu(menuData);
        setMenuData(menu);
    }

    // Função para criar um menu recursivamente
    const createMenu = (menuItems: any, parentId = null): any => {
        const menu = [];
    
        for (const item of menuItems) {
            if (item.MenuIdReferencia === parentId) {
                const submenu = createMenu(menuItems, item.MenuId);
                if (submenu.length > 0) {
                    item.items = submenu;
                }
                menu.push(item);
            }
        }
    
        return menu;
    }

    useEffect(() => {
        getSistemMenu(2); 
    }, [])

    if (menuData.length > 0)
        return (
            <PanelMenu model={menuData} className='card' style={{width:'100%'}} />

            // <AppSubmenu
            //     items={props.model}
            //     className="layout-menu"
            //     menuActive={props.active}
            //     onRootMenuItemClick={props.onRootMenuItemClick}
            //     mobileMenuActive={props.mobileMenuActive}
            //     onMenuItemClick={props.onMenuItemClick}
            //     root
            //     layoutMode={props.layoutMode}
            //     parentMenuItemActive
            // />
        );
    else
        return null;
};

export default AppMenu;
