import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import ICentroCusto from '../interfaces/ICentroCusto';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import CentroCustoForm from './CentroCustoForm';
import AjudaFormTela from './AjudaFormTela';


const CentroCusto = () => {
    const toast = useToast();
    const tarefa = 'CentroCusto';

    let emptyCentroCusto: ICentroCusto = {
      CentroCustoId: undefined,
      Codigo: '',
      Descricao: ''
    };

    const [centrosCusto, setCentrosCusto] = useState<any>(null);
    const [centroCustoDialog, setCentroCustoDialog] = useState<boolean>(false);
    const [deleteCentroCusto, setDeleteCentroCusto] = useState<boolean>(false);
    const [centroCusto, setCentroCusto] = useState<ICentroCusto>(emptyCentroCusto);
    const [selectedCentroCusto, setSelectedCentroCusto] = useState<any>(null);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const dt = useRef<any>(null);
    const menu = useRef<any>(null);

    const methods = useForm({ defaultValues: emptyCentroCusto });
    const { handleSubmit, control, formState: { errors }, getValues } = methods;

    const load = async () => {
        const result = await api.get('/centroCusto/list', { params: { Descricao: getValues()?.Descricao } });
        if (result.data)
            setCentrosCusto(result.data);
        else
            setCentrosCusto([]);
    }

    useEffect(() => {        
        load();
    }, []);

    const openNew = () => {
        setCentroCusto(emptyCentroCusto);
        setSubmitted(false);
        setCentroCustoDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setCentroCustoDialog(false);
    };
    const editar = (item: any) => {
        setCentroCusto(item);
        setCentroCustoDialog(true);
    };

    const confirmDelete = (item: any) => {
        setCentroCusto(item);
        setDeleteCentroCusto(true);
        setCentroCustoDialog(true);
    };

    const deletar = async () => {
        try {
            const resp = await api.delete('/centroCusto', { params: { CentroCustoId: centroCusto.CentroCustoId } });
            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
            load();
            setDeleteCentroCusto(false);
            setCentroCustoDialog(false);
            setCentroCusto(emptyCentroCusto);

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }

    };

    const onSubmit = async (data: any) => {
        load();
    }

    const opcoes = [
        {
            label: 'Imprimir',
            icon: 'pi pi-print',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Exp. para Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Ajuda',
            icon: 'pi pi-question-circle',
            command: () => {
                dt.current.exportCSV();
            }
        },
    ];


    const openMenu = (event: any) => {
        menu?.current?.toggle(event)
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Centro de Custo</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
                        <div className='grid'>
                            <div className="col-12 md:col-8">
                                <SCInputText
                                    control={control}
                                    errors={errors}
                                    name="Descricao"
                                    label='Descrição'
                                    className='w-full'
                                />
                            </div>
                            <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
                            </div>
                        </div>
                    </form>
                    <Divider />

                    <DataTable
                        size='small'
                        stripedRows
                        ref={dt}
                        value={centrosCusto}
                        onSelectionChange={(e) => setSelectedCentroCusto(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhum registro encontrado."
                        header={null}
                        responsiveLayout="scroll">
                        <Column field="Codigo" header="Código" sortable headerStyle={{ width: 92 }}></Column>
                        <Column field="Descricao" header="Descrição" sortable ></Column>
                        <Column body={(rowData: any) => {
                            return (
                                <div className="actions flex align-items-center justify-content-between">
                                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                                </div>
                            );
                        }} style={{ width: 90 }}
                        />
                    </DataTable>

                    <SCDialog maximized={!util.isDesktop()} visible={centroCustoDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
                        header={`${centroCusto.CentroCustoId === undefined ? 'Cadastrando ' : (deleteCentroCusto ? 'Excluíndo' : 'Alterando')} Centro de Custo`}
                        modal className="p-fluid" onHide={hideDialog}
                    >
                        <CentroCustoForm
                            setCentroCustoDialog={setCentroCustoDialog}
                            centroCusto={centroCusto}
                            reload={load}
                            deleteCentroCusto={deleteCentroCusto}
                            setDeleteCentroCusto={setDeleteCentroCusto}
                            deletar={deletar}
                        />
                    </SCDialog>
                </div>
            </div>
        </div>
    );
};

export default CentroCusto;
