import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IPerfil from '../interfaces/IPerfil';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import PerfilForm from './PerfilForm';


const Perfil = () => {
  const toast = useToast();
  const tarefa = 'RegistroForm';


  const [lista, setLista] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [deleteRegistro, setDeleteRegistro] = useState<boolean>(false);
  const [registro, setRegistro] = useState<IPerfil>({} as IPerfil);
  const [selectedRegistro, setSelectedRegistro] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [novoComBase, setNovoComBase] = useState<any>(null);
  const { empresaSelecionada } = useAuth();
  
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);
  
  
  let emptyRegistro: IPerfil = {
    PerfilId: undefined,
    EmpresaId: empresaSelecionada?.EmpresaId,
    Modulo: 'Secretaria',
    Codigo: '',
    Descricao: '',
    Sigla: '',
    Ativo: true,
  };

  const methods = useForm({ defaultValues: emptyRegistro });
  const { handleSubmit, control, formState: { errors }, getValues, reset, setValue } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/perfil/list', { params: { Descricao: getValues()?.Descricao, EmpresaId: empresaSelecionada?.EmpresaId } });
    if (result.data)
      setLista(result.data);
    else
      setLista([]);

    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setNovoComBase(null)
    setRegistro(emptyRegistro);
    setSubmitted(false);
    setShowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setShowDialog(false);
  };
  const editar = (item: any) => {
    setNovoComBase(null)    
    setRegistro(item);
    setShowDialog(true);
  };

  const confirmDelete = (item: any) => {
    setRegistro(item);
    setDeleteRegistro(true);
    setShowDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/perfil', { params: { PerfilId: registro.PerfilId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteRegistro(false);
      setShowDialog(false);
      setRegistro(emptyRegistro);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const novoComBaseNesse = (reg: IPerfil) => {
    setNovoComBase(reg.PerfilId)
    setRegistro({...reg, PerfilId: undefined});
    setDeleteRegistro(false);
    setShowDialog(true);
  };

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Perfil de tarefas</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={lista}
              onSelectionChange={(e) => setSelectedRegistro(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Modulo" header="Módulo" sortable headerStyle={{ width: 120 }}></Column>
              <Column field="Codigo" header="Código" sortable headerStyle={{ width: 150 }}></Column>
              <Column field="Descricao" header="Descrição" sortable ></Column>
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button label="Novo Com Base Nesse" icon="pi pi-plus" className="p-button w-auto p-button-success" onClick={() => novoComBaseNesse(rowData)} type='button' />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 250 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%', minHeight: '88%' }} closable={!util.isDesktop()}
            header={`${registro.PerfilId === undefined ? 'Cadastrando ' : (deleteRegistro ? 'Excluíndo' : 'Alterando')} Perfil`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <PerfilForm
              setShowDialog={setShowDialog}
              registro={registro}
              reload={load}
              deleteRegistro={deleteRegistro}
              setDeleteRegistro={setDeleteRegistro}
              deletar={deletar}
              novoComBase={novoComBase}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default Perfil;
