import { Divider } from 'primereact/divider';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SCButton, SCDropdown, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, SITUACAO_ALUNO, TIPO_DISCIPLINA } from '../utilities/constantes';
import { useAuth } from '../providers/auth';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import AlunoResumo from '../components/AlunoResumo';
import { IAlunoForm } from '../interfaces/IAlunos';
import { InputText } from 'primereact/inputtext';
import OrganizaNumeroOrdem from './OrganizaNumeroOrdem';

interface Props {
    turma?: any;
    setOrganizaNumeroOrdemDialog(param: boolean): void;
    reload(): void;
}

const OrganizaNumeroOrdemForm: React.FC<Props> = ({ setOrganizaNumeroOrdemDialog, turma, reload }) => {
    // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
    // por padrão vamos utilizar o mesmo nome do componente
    const tarefa = 'FormaIngressoForm';

    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [alunosTuma, setAlunosTuma] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true);
    const [parametros, setParametros] = useState<any>(null);
    const dt = useRef<any>(null);


    const methods = useForm();
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

    async function load() {
        const alunosPorTurma = await api.get('/turma/listaAlunos', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Sequencial: periodoSelecionado?.Sequencial,
                Ano: periodoSelecionado?.Ano,
                Turma: turma?.Codigo,
                Ordem: true,
            }
        });

        if (alunosPorTurma.data.length > 0) {
            setAlunosTuma(alunosPorTurma.data)
        }

        const parametro = await api.get('/parametro', {
            params: {
                EmpresaId: '0',
                Sequencial: '0',
                Ano: '0000',
                Codigo: 'NORDEXC',
                Grupo: 'S',
            }
        });

        console.log(parametro.data.Valor)
        setParametros(parametro.data.Valor)
    }

    useEffect(() => {
        load()
    }, [])

    const cancelar = async (e: any) => {
        setLoading(true)
        const valoresInvalidos = parametros.split(',').map(Number);
        const updatedAlunos = alunosTuma.map((aluno: any) => {
            if (valoresInvalidos.includes(aluno.NumeroOrdem)) {
                return {
                    ...aluno,
                    NumeroOrdem: 0
                };
            }
            return aluno;
        });

        setAlunosTuma(updatedAlunos);

        for (const item of updatedAlunos) {
            const { Nome, ...itens } = item;
            await api.put('/alunosPeriodo', itens);
        }

        setOrganizaNumeroOrdemDialog(false);
        setLoading(false)
        reset();
    };

    const onRowReorder = async (e: any) => {
        setLoading(true)
        const updatedAlunos = e.value.map((aluno: any, index: any) => ({
            ...aluno,
            NumeroOrdem: index + 1
        }));
        updatedAlunos.forEach(async (item: any & { Nome?: string }) => {
            console.log(item)
            const { Nome, ...itens } = item
            await api.put('/alunosPeriodo', itens)
        })
        setAlunosTuma(updatedAlunos);
        setLoading(false)
    };

    return (
        <>
            <FormProvider {...methods}>
                <form className='pt-3' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >

                    <DataTable
                        size='small'
                        stripedRows
                        ref={dt}
                        value={alunosTuma}
                        dataKey="id"
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhum registro encontrado."
                        header={null}
                        responsiveLayout="scroll"
                        onRowReorder={onRowReorder}>

                        <Column rowReorder rowReorderIcon='pi pi-sort-alt' header=""></Column>
                        <Column field="NumeroOrdem" header="Ordem" headerStyle={{ width: 50 }}></Column>
                        <Column field="Matricula" header="Matrícula" headerStyle={{ width: 150 }} ></Column>
                        <Column field="Nome" header="Nome" ></Column>
                    </DataTable>

                    <Divider />

                    <div className="flex justify-content-center pt-3 px-2 mt-auto">
                        <SCButton label="Confirmar" icon="pi pi-check" className="p-button w-auto p-button-success" style={{ maxHeight: 32}} type='button' onClick={cancelar} />
                    </div>
                </form>
            </FormProvider>
        </>

    );
};

export default OrganizaNumeroOrdemForm;
