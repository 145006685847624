import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCButton, SCCheckbox, SCDropdown, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import { ALERTAS, APROVA_BASE } from '../utilities/constantes';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IDisciplinaAprovaBaseMedia from '../interfaces/IDisciplinaAprovaBaseMedia';
import Loading from '../components/Loading';
import IPlanoPagamentoTurma from '../interfaces/IPlanoPagamentoTurma';
import ITurmas from '../interfaces/ITurmas';
import { CheckboxChangeParams } from 'primereact/checkbox';
import ITurmaAuxiliar from '../interfaces/ITurmaAuxiliar';


const PlanoPagamentoTurma = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'DisciplinaAprovaBaseMedia';

  const periodo = periodoSelecionado?.Id

  let defaultValues: IPlanoPagamentoTurma = {
    PlanosPagamentoTurmasId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    TurmaId: 0,
    TurmaAuxiliarId: 0,
    PlanosPagamentoId: 0,
    Selecionado: 0
  };

  const [turmasCombo, setTurmasCombos] = useState<ITurmas[]>([]);
  const [turmasAuxCombo, setTurmasAuxCombos] = useState<ITurmaAuxiliar[]>([]);
  const [selectedTurmaAux, setSelectedTurmaAux] = useState(null);
  const [planoPagamentoTurma, setPlanoPagamentoTurma] = useState<IPlanoPagamentoTurma[]>([]);
  const [selectedPlanoPagamentoTurma, setSelectedPlanoPagamentoTurma] = useState<any>(null);
  const [todosSelecionados, setTodosSelecionados] = useState(false);
  const [onlySelected, setOnlySelected] = useState(false);

  const [desativarComboTurma, setDesativarComboTurma] = useState<boolean>(false);
  const [desativarComboTurmaAux, setDesativarComboTurmaAux] = useState<boolean>(false);

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [copia, setCopia] = useState<any>(null);

  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const load = async () => {
    setLoading(true);

    try {

      const result = await api.get('/turmas/combo', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
        }
      });

      const resultTurmas = result.data;

      if (resultTurmas) {
        setTurmasCombos(resultTurmas)

      } else {
        setTurmasCombos([]);
      }

      const turmaAux = await api.get('/turmaAuxiliar', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
        }
      });

      const resultTurmaAux = turmaAux.data;

      if (resultTurmaAux) {
        setTurmasAuxCombos(resultTurmaAux)

      } else {
        setTurmasAuxCombos([]);
      }
    } catch (e: any) {
      console.error(e.response?.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    } finally {
      setLoading(false);
    }
  };

  const watchTurmaId = watch('TurmaId')
  const watchTurmaAuxiliarId = watch('TurmaAuxiliarId')

  const pesquisaCodigoPagamento = async () => {
    setLoading(true);

    try {
      const TurmaId = watchTurmaId || watchTurmaAuxiliarId;
      if (!TurmaId) {
        setPlanoPagamentoTurma([]);
        return;
      }

      let rotaPesquisa;

      if (watchTurmaId)
        rotaPesquisa = '/planoPagamentoTurma/listar'
      else
        rotaPesquisa = '/planoPagamentoTurma/listarTurmaAuxiliar'

      const result = await api.get(rotaPesquisa, {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaId: TurmaId
        }
      });

      const codigoPagamento = result?.data;
      console.log(codigoPagamento)
      setPlanoPagamentoTurma(codigoPagamento || []);
    } catch (e: any) {
      console.error(e.response?.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    } finally {
      setLoading(false);
    }
  }


  const handleCheckboxChange = (rowData: IPlanoPagamentoTurma, checked: boolean) => {
    const updatedPlanoPagamentoTurma = planoPagamentoTurma.map(item => {
      if (item.PlanosPagamentoId === rowData.PlanosPagamentoId) {
        return { ...item, Selecionado: checked ? 1 : 0 };
      }
      return item;
    });

    setPlanoPagamentoTurma(updatedPlanoPagamentoTurma);
  };

  const onSubmit = async () => {
    const TurmaId = watchTurmaId || watchTurmaAuxiliarId;
    setLoading(true);
    try {
      if (!TurmaId) throw new Error("TurmaId não definido.");

      await api.delete('/planoPagamentoTurma', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaId: TurmaId
        }
      });

      const filtraPlano = planoPagamentoTurma.filter((item: any) => item.Selecionado === 1);

      await Promise.all(filtraPlano.map((item: any) => api.post('/planoPagamentoTurma', {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        TurmaId: TurmaId,
        PlanosPagamentoId: item.PlanosPagamentoId,
        Selecionado: item.Selecionado
      })));

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
    } catch (error) {
      console.error('Erro ao enviar dados para o backend:', error);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Erro ao gravar os dados.' });
    } finally {
      setLoading(false);
    }
  };


  const copiaCola = () => {
    if (planoPagamentoTurma.length > 0) {
      setCopia(planoPagamentoTurma)
      toast.showToast({ severity: 'info', summary: 'Atenção', detail: 'Seleção copiada!' });
    } else {
      toast.showToast({ severity: 'info', summary: 'Atenção', detail: 'Não há dados a serem copiados!' });
    }
  }

  const colar = () => {
    if (copia) {
      setPlanoPagamentoTurma(copia)
      toast.showToast({ severity: 'info', summary: 'Atenção', detail: 'Seleção colada!' });
    } else {
      toast.showToast({ severity: 'info', summary: 'Atenção', detail: 'Não há dados a serem colados!' });
    }
  }

  useEffect(() => {
    load();
  }, []);

  const handleTurmaAuxChange = (e: any) => {
    if (e.value !== undefined) {
      setDesativarComboTurma(true);
      setValue('TurmaAuxiliarId', e.value)
    } else {
      setValue('TurmaAuxiliarId', e.value)
      setDesativarComboTurma(false);
    }
  };

  useEffect(() => {
    pesquisaCodigoPagamento();
  }, [watchTurmaId, watchTurmaAuxiliarId]);

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },

    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const handleSelectAllChange = (e: any) => {
    const isChecked = e.target.checked;
    setTodosSelecionados(isChecked);

    //Isso aqui manda o estado atualizado com o Selecionado true
    const updatedPlanoPagamentoTurma = planoPagamentoTurma.map(item => {
      if (item.PlanosPagamentoId === e.PlanosPagamentoId) {
        return { ...item, Selecionado: isChecked ? 1 : 0 };
      }
      return item;
    });

    if (isChecked) {
      updatedPlanoPagamentoTurma.forEach(item => {
        item.Selecionado = 1
      })
      setPlanoPagamentoTurma(updatedPlanoPagamentoTurma)
    } else {
      updatedPlanoPagamentoTurma.forEach(item => {
        item.Selecionado = 0
      })
      setPlanoPagamentoTurma(updatedPlanoPagamentoTurma)
    }
  };


  return (

    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
              <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Plano de Pagamento x Turmas [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
              <AjudaFormTela tarefa={tarefa} campo='' />
            </div>

            <div className='grid mt-2'>
              <div className='col-12 md:col-8'>
                <div className='grid'>
                  <div className='col-12 md:col-7'>
                    <SCDropdown
                      name="TurmaId"
                      options={turmasCombo}
                      optionLabel='TurmaDescricao'
                      optionValue='TurmaId'
                      placeholder='Selecione a Turma Regular'
                      style={{ width: '100%' }}
                      value={watchTurmaId}
                      onChange={(e) => {
                        if (e.target.value === undefined) {
                          setValue('TurmaId', undefined);
                          setDesativarComboTurmaAux(false);
                        } else {
                          setValue('TurmaId', e.target.value);
                          setDesativarComboTurmaAux(true);
                        }
                      }}
                      disabled={desativarComboTurma}
                    />
                  </div>

                  <div className='col-12 md:col-5'>
                    <SCDropdown
                      name="TurmaAuxiliarId"
                      options={turmasAuxCombo}
                      optionLabel='Descricao'
                      optionValue='TurmaAuxiliarId'
                      placeholder='Selecione a Turma Auxiliar'
                      style={{ width: '100%' }}
                      value={watchTurmaAuxiliarId}
                      onChange={handleTurmaAuxChange}
                      disabled={desativarComboTurmaAux}
                    />
                  </div>
                </div>
                <div className='grid'>
                  <div className='col-12 md:col-4'>
                    <SCCheckbox
                      label='Exibir somente selecionados'
                      name='exibirSomenteSelecionados'
                      checked={onlySelected}
                      onChange={(e) => setOnlySelected(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 md:col-4'>
                <div className='grid'>
                  <div className='col-12 flex justify-content-between'>
                    <Button
                      label='Gravar'
                      icon="pi pi-check"
                      className="p-button col-3 md:col-4 p-button-success"
                      type='submit'
                    />

                    <Button label='' icon="pi pi-copy" className="p-button md:col-1 p-button-info" type="button" onClick={copiaCola} tooltip='Copiar Seleção' tooltipOptions={{ position: 'bottom' }}></Button>
                    <Button label='' icon="pi pi-file" className="p-button col-12 md:col-1 p-button-primary" type="button" onClick={colar} tooltip='Colar Seleção' tooltipOptions={{ position: 'bottom' }}></Button>

                    <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                    <Button label='Opções' className='p-button-secondary col-3 md:col-4' type="button" onClick={openMenu}></Button>
                  </div>
                </div>
              </div>
            </div>
            <Divider />


            {loading ? <Loading full={false} /> : (
              <DataTable
                size='small'
                stripedRows
                ref={dt}
                value={planoPagamentoTurma.filter((item: any) => !onlySelected || item.Selecionado === 1)}
                onSelectionChange={(e) => setSelectedPlanoPagamentoTurma(e.value)}
                dataKey="id"                
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
                globalFilter={globalFilter}
                emptyMessage="Nenhum registro encontrado."
                header={null}
                responsiveLayout="scroll">
                <Column field="Codigo" header="Código" sortable headerStyle={{ width: 90 }}></Column>
                <Column field="Descricao" header="Descrição" sortable ></Column>

                <Column header="% Desconto" bodyStyle={{ width: 90 }} body={(rowData: any) => (
                  <div className="flex justify-content-center align-items-center">
                    <span>{rowData.ValorDesconto == 0 ? '0.00' : rowData.ValorDesconto} % </span>
                  </div>

                )} />
                <Column header="Valor Líquido" bodyStyle={{ width: 80, background: '#d2f7c1' }} body={(rowData: any) => (
                  <div className="flex justify-content-center align-items-center">
                    <span>R$ {(rowData.Valor - rowData.Desconto).toFixed(2).replace('.', ',')}</span>
                  </div>
                )} />
                <Column header="Valor 1ª Parcela" bodyStyle={{ width: 100 }} body={(rowData: any) => (
                  <div className="flex justify-content-center align-items-center">
                    <span>R$ {rowData.Valor?.toFixed(2).replace('.', ',')}</span>
                  </div>

                )} />
                <Column header="Desc. 1ª Parcela" bodyStyle={{ width: 80 }} body={(rowData: any) => (
                  <div className="flex justify-content-center align-items-center">
                    <span>R$ {rowData.Desconto?.toFixed(2).replace('.', ',')}</span>
                  </div>

                )} />

                <Column
                  header={
                    <div>
                      <SCCheckbox
                        label='Selecionar Todos'
                        name='selecionarTodos'
                        checked={todosSelecionados}
                        onChange={handleSelectAllChange}
                      />
                    </div>
                  }
                  body={(rowData: any) => (
                    <div className="col-12 flex justify-content-center">
                      <SCCheckbox
                        key={rowData.TurmaId}
                        checked={rowData.Selecionado === 1}
                        onChange={(e: CheckboxChangeParams) => handleCheckboxChange(rowData, e.checked)}
                        id={rowData.PlanosPagamentoId}
                        name="Selecionado"
                        value={rowData.Selecionado === 1} // Ajuste o valor do checkbox aqui
                      />
                    </div>
                  )}
                  alignHeader='center'
                  align='center'
                />

              </DataTable>
            )}
          </div>
        </div>
      </div>
    </form>



  );
};

export default PlanoPagamentoTurma;
