import React, { useState, useEffect } from 'react';

import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import { Divider } from 'primereact/divider';

import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { Button } from 'primereact/button';
import util from '../utilities/util';
import { Dialog } from 'primereact/dialog';
import { SCDialog } from '../components';

interface IAjuda {
    AjudaId?: number;
    Tarefa?: string;
    Campo?: string;
    Ajuda?: string;
}

interface Props {
    tarefa: string;
    campo: string;
}

const AjudaForm: React.FC<Props> = ({tarefa, campo}) => {
    const toast = useToast();    
    const isKeyboardOpen = useDetectKeyboardOpen();
    const [showForm, setShowForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    
    const defaultValues: IAjuda = {
        AjudaId: undefined,
        Tarefa: '',
        Campo: '', 
        Ajuda: '',
    };

    const [ajuda, setAjuda] = useState<IAjuda>(defaultValues)
    
    async function load(){
        setLoading(true);
        const {data} = await api.get('/ajuda', {params: {Tarefa: tarefa, Campo: campo}});
        if (data)
        {
            setAjuda(data);
            setLoading(false);
        }
    }


    useEffect(() => {
        if (showForm)
            load();

    }, [showForm])

    const gravar = async () => {       
        setLoading(true); 
        let resp: any = undefined;
        try{
            if (ajuda.AjudaId === undefined)
                resp = await api.post('/ajuda', ajuda);
            else        
                resp = await api.put('/ajuda', ajuda);
            
            toast.showToast({severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado});
            setShowForm(false);   

        }catch(e: any){
            console.log(e)
            toast.showToast({severity: 'error', summary: 'Erro', detail: e?.message});
        }finally{
            setLoading(false);
        }
             
    }    

    const cancelar = (e: any) => {
        setShowForm(false);
        setAjuda(defaultValues);
    }
    
    const handleAjuda = (e: any) => {
        setAjuda({...ajuda, Ajuda: e.target.value})
    }
   
    return (
        <>
            <Button icon="pi pi-question-circle" className="p-button-ajuda p-button-rounded p-button-text" onClick={()=>setShowForm(true)} type='button'/>
            <SCDialog maximized={!util.isDesktop()} visible={showForm} style={{ width: util.isDesktop() ? '60%' : '90%' }} closable={!util.isDesktop()} 
                header={`Ajuda` } 
                modal className="p-fluid" onHide={() => setShowForm(false)}
            >
                {loading ? (
                    <div className='grid ' style={{minHeight: 300}}>
                        <div className="col-12">
                        <ul className="loader">
                            <li className="dot-a"></li>
                            <li className="dot-b"></li>
                            <li className="dot-c"></li>
                            <li className="dot-d"></li>
                            <li className="dot-e"></li>
                            <li className="dot-f"></li>
                            <li className="dot-g"></li>
                            <li className="dot-h"></li>
                            <li className="dot-i"></li>
                        </ul>
                        </div>
                    </div>
                ):(
                    <form className='pt-3' >
                        <div className='grid'>     
                            <div className="col-12">
                                <label>Tarefa</label>
                                <input
                                    className='p-inputtext p-component p-filled undefined block'
                                    name="Tarefa" 
                                    disabled
                                    value={ajuda?.Tarefa}
                                />
                            </div>               
                            <div className="col-12">
                                <label>Campo</label>
                                <input 
                                    className='p-inputtext p-component p-filled undefined block'
                                    name="Campo" 
                                    disabled
                                    value={ajuda?.Campo}
                                />
                            </div>
                            <div className="col-12">
                                <label>Detalhe</label>
                                <textarea
                                    className='p-inputtext p-component p-filled undefined block'
                                    autoFocus
                                    name="Ajuda" 
                                    required
                                    rows={6}
                                    value={ajuda?.Ajuda}
                                    onChange={handleAjuda}
                                />
                            </div>     
                        
                            <Divider />
                            <div className="flex flex-1 justify-content-between pt-3 px-2">
                                <Button label="Cancelar"  icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />                        
                                <Button label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='button' onClick={gravar}/>
                            </div>
                            
                        </div>                   
                    </form>
                )}
            </SCDialog>
        </>
    );
};

export default AjudaForm;
