import React, { } from 'react';
import { useFormContext } from 'react-hook-form';

import { SCCalendar, SCDropdown, SCInputMask, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import FormEndereco from '../components/FormEndereco';
import SelectEstadoCivil from '../components/SelectEstadoCivil';
import { isValid, set, subYears } from 'date-fns';
import util from '../utilities/util';


type Props = any;

const AlunosFormPai: React.FC<Props> = ({ showDelete, matricula, ...props }) => {

  const toast = useToast();

  const { control, formState: { errors }, setValue, clearErrors, setError, getValues } = useFormContext();  

  const validaDataNascimentoPai = (e: any) => {
    const data = e.target.value;
    console.log('data', isValid(new Date(data)));
    if (data !== null && isValid(new Date(data))) 
      if (util.calcularIdade(data) < 10) {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Pai menor de 10 anos' });
        setValue('PaiDataNascimento', '');
      }
    return true;
  }

  return (
    <div className='col-12' >
      <div className='grid'>
        <div className="col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Pai"
            label='Nome'
            disabled={showDelete}
            maxLength={60}
            onBlur={(e) => {
              if (e.target.value.length > 0 && getValues('PaiNacionalidade') === ''){
                setValue('PaiNacionalidade', 'BRASILEIRO');
              }
            }}
          />
        </div>
        <div className="col-3">
          <SCCalendar
            control={control}
            errors={errors}
            name="PaiDataNascimento"
            label="Data de Nascimento"
            mask='99/99/9999'
            dateFormat='dd/mm/yy'
            showIcon
            disabled={showDelete}
            onBlur={validaDataNascimentoPai}
            showOnFocus={false}            
          />
        </div>
      </div>
      <div className='grid'>
       <div className="col-3">
          <SelectEstadoCivil
            control={control}
            errors={errors}
            name="PaiEstadoCivil"
            label='Estado Civil'
            disabled={showDelete}
          />
        </div>
        <div className="col-2">
          <SCInputText
            control={control}
            errors={errors}
            name="PaiRG"
            label='RG'
            disabled={showDelete}
            maxLength={20}
          />
        </div>
        <div className="col-2">
          <SCInputText
            control={control}
            errors={errors}
            name={`PaiRGOrgaoExpeditorUF`}
            label='Orgão Exp./UF'
            disabled={showDelete}
            maxLength={25}
            onBlur={(e) => {
              const rg = getValues(`PaiRG`);
              clearErrors(`PaiRG`);
              if ((rg == '' || rg == null)  && e.target?.value?.length > 0)
                setError(`PaiRG`, { type: 'manual', message: 'Preencha o campo' });
            }}
          />
        </div>  
        <div className="col-2">
          <SCInputMask
            control={control}
            errors={errors}
            name="PaiCPF"
            label='CPF'
            disabled={showDelete}
            maxLength={15}
            mask='999.999.999-99'
            onBlur={(e) => util.cpfValido(e.target.value) ? clearErrors(`PaiCPF.CPF`) : setError(`PaiCPF.CPF`, { type: 'manual', message: 'CPF inválido' })}
          />
        </div>
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            name="PaiNacionalidade"
            label='Nacionalidade'
            disabled={showDelete}
            maxLength={10}
          />
        </div>   
      </div>  

      <FormEndereco tipo="PAI" matricula={matricula} required={false} />

      <div className='grid'>
        <div className="col-1">
          <SCInputMask
            control={control}
            errors={errors}
            name="PaiDDD"
            label='DDD'
            mask='(999)'
            disabled={showDelete}
            maxLength={5}
          />
        </div>
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="PaiFoneCelular"
            label='Celular'
            disabled={showDelete}
            maxLength={20}
          />
        </div>
        <div className="col-3">
          <SCInputText
            control={control}
            errors={errors}
            name="PaiFoneOutro"
            label='Outros Fones'
            disabled={showDelete}
            maxLength={40}
          />
        </div>
        <div className="col-4">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="PaiEmail"
            label='E-mail'
            disabled={showDelete}
            maxLength={60}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-5">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="PaiEmpresa"
            label='Empresa'
            disabled={showDelete}
            maxLength={60}
          />
        </div>
        <div className="col-5">
          <SCInputText
            control={control}
            errors={errors}
            name="PaiProfissao"
            label='Prof./Cargo/Ativ.'
            disabled={showDelete}
            maxLength={20}
          />
        </div>
      </div>
    </div>
  );
};

export default AlunosFormPai;
