import React from 'react';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../providers/auth';

import logo from '../assets/logo_pqn.png';
import config from '../utilities/config';

const AppTopbar = (props: any) => {
    const navigate = useNavigate();
    const { usuario, signOut } = useAuth();

    const onTopbarItemClick = (event: any, item: any) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    };

    return (
        <div className="layout-topbar">

            <button type="button" className="p-link layout-menu-button layout-topbar-icon" onClick={props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <button type="button" className="p-link layout-topbar-logo" onClick={() => navigate('/home')}>
                <img id="topbar-logo" src={usuario?.Empresa?.Logo || logo} alt="roma-react" />
            </button>
            
            <span style={{fontSize: 10, padding: 10, color: '#FFF'}}>{config.versao}</span>
            
            <ul className="topbar-menu">
                <li className={classNames('user-profile', { 'active-topmenuitem': props.activeTopbarItem === 'profile' })}>
                    {!props.inlineUser && (
                        <button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'profile')}>
                            <img src={ usuario?.Foto || "assets/layout/images/avatar.png"} alt={usuario?.Nome} className='border-circle'/>
                            <div className="layout-profile-userinfo">
                                <span className="layout-profile-name">{usuario?.Nome}</span>
                                <span className="layout-profile-role">{usuario?.Email}</span>
                            </div>
                            <i className="pi pi-ellipsis-v text-white text-4xl" style={{width: 45, height: 45, marginBottom: 8,}}></i>
                        </button>
                    )}

                    <ul className="fadeInDown">
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <i className="pi pi-fw pi-user"></i>
                                <span className='ml-2'>Meus Dados</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <i className="pi pi-fw pi-cog"></i>
                                <span className='ml-2'>Configurações</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <i className="pi pi-fw pi-envelope"></i>
                                <span className='ml-2'>Mensagens</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <i className="pi pi-fw pi-bell"></i>
                                <span className='ml-2'>Notificações</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link" onClick={() => {signOut(usuario?.UsuarioId); navigate('/');}} style={{backgroundColor: 'red', color: 'white'}}>
                                <i className="pi pi-fw pi-sign-out"></i>
                                <span className='ml-2 text-red-500'>Sair</span>
                            </button>
                        </li>
                    </ul>
                </li>

                <li className={classNames({ 'active-topmenuitem fadeInDown': props.activeTopbarItem === 'settings' })}>
                    <button type="button" className="p-link layout-topbar-icon" onClick={(e) => onTopbarItemClick(e, 'settings')}>
                        <i className="topbar-icon pi pi-fw pi-bell"></i>
                    </button>
                    <ul className="fadeInDown">                        
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <img src="assets/layout/images/avatar-2.png" alt="roma-react" />
                                <div className="topbar-menu-info">
                                    <span className="topbar-menu-name">Dai Jiang</span>
                                    <span className="topbar-menu-role">Uh, we have sort of a problem here.</span>
                                </div>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <img src="assets/layout/images/avatar-3.png" alt="roma-react" />
                                <div className="topbar-menu-info">
                                    <span className="topbar-menu-name">Karlien Nijhuis</span>
                                    <span className="topbar-menu-role">You apparently didn’t put the thing</span>
                                </div>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <img src="assets/layout/images/avatar-4.png" alt="roma-react" />
                                <div className="topbar-menu-info">
                                    <span className="topbar-menu-name">Tom Chun</span>
                                    <span className="topbar-menu-role">Please check the files</span>
                                </div>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link">
                                <img src="assets/layout/images/avatar-5.png" alt="roma-react" />
                                <div className="topbar-menu-info">
                                    <span className="topbar-menu-name">Maria Trofimova</span>
                                    <span className="topbar-menu-role">Meeting reports attached.</span>
                                </div>
                            </button>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default AppTopbar;
