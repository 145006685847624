import { useState } from "react";
import { SCButton, SCCalendar, SCDropdown, SCFieldset, SCInputText, SCRadioButton } from "../../components";
import { MESES } from "../../utilities/constantes";
import { SiMicrosoftexcel } from "react-icons/si";
import { SlPrinter } from "react-icons/sl";
import TooltipOptions from "primereact/tooltip/tooltipoptions";
import { Divider } from "primereact/divider";
import api from "../../services/api";
import { downloadExcel } from "react-export-table-to-excel";

interface Props {
    setVerRelatorioForm(param: boolean): void;
    onPrint(): Promise<void>;
    setLoadReport: (param: boolean) => void;
}

const ColaboradorFormImp:  React.FC<Props> = ({ setVerRelatorioForm, onPrint, setLoadReport }) => {

    const [tipo, setTipo] = useState('Resumido');
    const [mesAniversario, setAniversario] = useState(0);
    const [periodoInicio, setPeriodoInicio] = useState<any>(null);
    const [periodoFinal, setPeriodoFinal] = useState<any>(null);
    const [reportData, setReportData] = useState<any>(null);

    const handleChange = (e: any) => {
        setTipo(e.target.value);
    }

    const tooltipOptions: TooltipOptions = {
        position: 'top',        
    }

    const onExcel = async () => {
        setLoadReport(true)
        const result = await api.get('/funcionarios/list', { 
            params: {
                mesAniversario: mesAniversario,
            }
        });
        console.log(result.data);
        downloadExcel({
            fileName: "Colaboradores",
            sheet: "Listagem",
            tablePayload: {
                header: ['Nome', 'Matrícula', 'Situação', 'Apelido', 'Data de Nasc.', 'Sexo', 'Cargo'],
                body: result.data.map((item: any) => {
                    return [
                        item.Nome,
                        item.Matricula,
                        item.Ativo ? 'Ativo' : 'Inativo',
                        item.Guerra,
                        item.DataNascimento ? new Date(item.DataNascimento).toLocaleDateString() : '',
                        item.Sexo == 'M' ? 'Masculino' : 'Feminino',
                        item.cargo
                    ]
                })
            },
          });
        setLoadReport(false)
    }

    return (
    <div className="p-2 border rounded-md mx-auto">
        <form className="space-4">
            <div className="grid">
                <div className="col-12 flex flex-row justify-content-center ">
                    <SCRadioButton 
                        onChange={handleChange}
                        name="Detalhado" 
                        value='Detalhado'
                        checked={tipo == 'Detalhado'}
                        disabled
                        label="Detalhado"
                    />
                    <SCRadioButton 
                        onChange={handleChange}
                        name="Resumido" 
                        value='Resumido'
                        checked={tipo == 'Resumido'}
                        label="Resumido"
                        className="ml-3"
                    />
                    <SCRadioButton 
                        onChange={handleChange}
                        name="Senhas" 
                        value='Senhas'
                        checked={tipo == 'Senhas'}
                        label="Senhas"
                        className="ml-3 disabled"                    
                        disabled
                    />
                    <SCRadioButton 
                        onChange={handleChange}
                        name="Assinatura" 
                        value='Assinatura'
                        checked={tipo == 'Assinatura'}
                        label="Assinatura"
                        className="ml-3"
                        disabled
                    />
                    <SCRadioButton 
                        onChange={handleChange}
                        name="Censo" 
                        value='Censo'
                        checked={tipo == 'Censo'}
                        label="Censo"
                        className="ml-3"
                        disabled
                    />
                    
                </div>          
            </div>

            <div className="grid">
                <div className="col-3">
                    <SCDropdown 
                        label="Mês de Aniversário"
                        name="mesAniversario"
                        value={mesAniversario}
                        onChange={(e) => setAniversario(e.target.value)}
                        options={[{ mes: 0, _mes: '', des: 'Todos' }, ...MESES]}
                        showClear={false}
                        optionLabel="des"
                        optionValue="mes"
                        className='w-full'
                    />
                </div>           
            </div>   

            <div className="grid">        
                <div className="col-2">        
                    <SCCalendar
                        label='Período Início'
                        name='PeriodoInicio'
                        dateFormat='dd/mm/yy'
                        mask='99/99/9999'
                        showTime={false}
                        onChange={(e) => setPeriodoInicio(e.target.value)}
                        />
                </div>
                <div className="col-2">            
                    <SCCalendar
                        label='Período Final'
                        name='PeriodoFinal'
                        dateFormat='dd/mm/yy'
                        mask='99/99/9999'
                        onChange={(e) => setPeriodoFinal(e.target.value)}
                        showTime={false}
                    />
                </div>
            </div>

            <div className="grid">
                <div className="col-4">     
                    <SCDropdown 
                        label="Grau de Instrução"
                        name="grauInstrucao"
                        options={[
                            {label: 'Fundamental', value: 'fundamental'},
                            {label: 'Médio', value: 'medio'},
                            {label: 'Superior', value: 'superior'},
                            {label: 'Pós-Graduação', value: 'posGraduacao'},
                            {label: 'Mestrado', value: 'mestrado'},
                            {label: 'Doutorado', value: 'doutorado'}
                        ]}
                        className='w-full'
                    />                  
                </div>                          
            </div>          
            
            <br />
            <Divider align="center" />
            <div className="flex flex-1 justify-content-end pt-1">
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <SCButton tooltip="Exportar para Excel" tooltipOptions={tooltipOptions} 
                        className="p-button w-auto bg-green-400 border-green-400" style={{ width: '50' }} type='button' 
                        onClick={() => onExcel()}>
                        <SiMicrosoftexcel size={20} color="white"/>
                    </SCButton>
                    <SCButton tooltip="Imprimir" tooltipOptions={tooltipOptions} 
                        className="p-button w-auto ml-3" style={{ width: '50', backgroundColor: '#0772B3', borderColor: '#0772B3' }} type='button' 
                        onClick={() => onPrint()}>
                        <SlPrinter size={20} color="white"/>
                    </SCButton>
                </div>
            </div> 
                        
        </form>
    </div>
  )
}

export default ColaboradorFormImp;