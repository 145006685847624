import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCalendar, SCDropdown, SCFieldset, SCInputMask, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IAbastecimento from '../interfaces/IAbastecimento';
import { useAuth } from '../providers/auth';
import { format, parse } from 'date-fns';
import ISangria from '../interfaces/ISangria';
import { getValue } from '@testing-library/user-event/dist/utils';
import PaiDados from '../components/PaiDados';


interface Props {
    sangria?: ISangria;
    setSangriaDialog(param: boolean): void;
    reload(): void;
}

const SangriaForm: React.FC<Props> = ({ setSangriaDialog, sangria, reload }) => {
    // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
    // por padrão vamos utilizar o mesmo nome do componente
    const tarefa = 'SangriaForm';

    const toast = useToast();
    const { periodoSelecionado, usuario } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState('');
    const [saldoAtualDinheiro, setSaldoAtualDinheiro] = useState<any>(null);
    const [saldoAtualCheque, setSaldoAtualCheque] = useState<any>(null);
    const [saldoAtualTotal, setSaldoAtualTotal] = useState<any>(null);
    const [total, setTotal] = useState<any>(null);

    const defaultValues: ISangria = sangria || {
        SangriaId: undefined,
        LancamentoData: undefined,
        LancamentoHora: '',
        LancamentoOperador: '',
        ValorDinheiro: '',
        ValorCheque: '',
        Observacao: '',
        DataInicial: null,
        DataFinal: null,
        UsuarioId: 0,
    };

    const methods = useForm({ defaultValues });
    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

    const load = async () => {
        atualizaSaldo()
    }

    const onSubmit = async (data: any) => {

        let resp: any = undefined;

        if (parseFloat(data.ValorCheque) > parseFloat(saldoAtualCheque) || parseFloat(data.ValorDinheiro) > parseFloat(saldoAtualDinheiro)) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Valor da sangria não pode ser maior que o saldo!' });
            return;
        }
        if (data.ValorCheque == 0 && data.ValorDinheiro == 0 || data.ValorCheque === undefined && data.ValorDinheiro === undefined) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Sangria deve ter valor maior que zero.' });
            return;
        }

        try {
            await api.post('/sangria', {
                LancamentoData: new Date(),
                LancamentoHora: format(new Date(), 'HH:mm'),
                ValorDinheiro: data.ValorDinheiro,
                ValorCheque: data.ValorCheque,
                Observacao: data.Observacao,
                UsuarioId: usuario?.UsuarioId,
            })

            setLoading(false)
            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            reload();
            setSangriaDialog(false);

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    }

    useEffect(() => {
        load()
    }, [])

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString();
        setCurrentDate(formattedDate);
    }, []);

    const cancelar = (e: any) => {
        setSangriaDialog(false);
        reset();
    }

    const atualizaSaldo = async () => {
        setLoading(true)
        let saldoAtualCheque = 0;
        let saldoAtualDinheiro = 0;

        const abasteceSaldo = await api.get('/abastecimentocaixa/buscaSaldoData', { params: { UsuarioId: usuario?.UsuarioId } });
        saldoAtualDinheiro = abasteceSaldo.data.Total;

        const diarioSaldo = await api.get('/diariofinanceiro/buscaSaldoData', {
            params: {
                Usuario: usuario?.Apelido,
                Tipo: '1'
            }
        });
        if (diarioSaldo.data.TotalValor !== undefined) {
            saldoAtualDinheiro = saldoAtualDinheiro + diarioSaldo.data.TotalValor;
            saldoAtualDinheiro = saldoAtualDinheiro + diarioSaldo.data.TotalMulta;
            saldoAtualDinheiro = saldoAtualDinheiro + diarioSaldo.data.TotalJuros;
            saldoAtualDinheiro = saldoAtualDinheiro + diarioSaldo.data.TotalValorCom01;
            saldoAtualDinheiro = saldoAtualDinheiro + diarioSaldo.data.TotalValorCom02;
            saldoAtualDinheiro = saldoAtualDinheiro + diarioSaldo.data.TotalValorCom03;
            saldoAtualDinheiro = saldoAtualDinheiro + diarioSaldo.data.TotalValorCom04;
            saldoAtualDinheiro = saldoAtualDinheiro + diarioSaldo.data.TotalValorCom05;
            saldoAtualDinheiro = saldoAtualDinheiro + diarioSaldo.data.TotalValorCom06;
        }

        const diarioSaldoCheque = await api.get('/diariofinanceiro/buscaSaldoData', {
            params: {
                Usuario: usuario?.Apelido,
                Tipo: '2'
            }
        });
        if (diarioSaldoCheque.data.TotalValor !== undefined) {
            saldoAtualCheque = saldoAtualCheque + diarioSaldoCheque.data.TotalValor;
            saldoAtualCheque = saldoAtualCheque + diarioSaldoCheque.data.TotalMulta;
            saldoAtualCheque = saldoAtualCheque + diarioSaldoCheque.data.TotalJuros;
            saldoAtualCheque = saldoAtualCheque + diarioSaldoCheque.data.TotalValorCom01;
            saldoAtualCheque = saldoAtualCheque + diarioSaldoCheque.data.TotalValorCom02;
            saldoAtualCheque = saldoAtualCheque + diarioSaldoCheque.data.TotalValorCom03;
            saldoAtualCheque = saldoAtualCheque + diarioSaldoCheque.data.TotalValorCom04;
            saldoAtualCheque = saldoAtualCheque + diarioSaldoCheque.data.TotalValorCom05;
            saldoAtualCheque = saldoAtualCheque + diarioSaldoCheque.data.TotalValorCom06;
        }
        if (saldoAtualDinheiro > 0) {
            const sangriaSaldo = await api.get('/sangria/buscaSaldoData', { params: { UsuarioId: usuario?.UsuarioId } });
            if (sangriaSaldo.data.TotalDinheiro !== undefined) {
                saldoAtualDinheiro = saldoAtualDinheiro - sangriaSaldo.data.TotalDinheiro;
            }
            if (sangriaSaldo.data.TotalCheque !== undefined) {
                saldoAtualCheque = saldoAtualCheque - sangriaSaldo.data.TotalCheque;
            }
        }
        const saldoAtTotal = saldoAtualCheque + saldoAtualDinheiro;

        if (!Number.isNaN(saldoAtTotal)) {
            setSaldoAtualTotal(saldoAtTotal.toFixed(2));
        } else {
            setSaldoAtualTotal('0.00');
        }
        if (saldoAtualDinheiro !== undefined) {
            setSaldoAtualDinheiro(saldoAtualDinheiro.toFixed(2));
        } else {
            setSaldoAtualDinheiro('0.00');
        }
        if (saldoAtualCheque !== undefined) {
            setSaldoAtualCheque(saldoAtualCheque.toFixed(2));
        } else {
            setSaldoAtualCheque('0.00');
        }

        setLoading(false)
    }

    const valorDinheiro = watch('ValorDinheiro', '');
    const valorCheque = watch('ValorCheque', '');

    const handleBlur = (e: any) => {
        const { name, value } = e.target;
        const newValue = value.replace(',', '.');
        setValue(name, newValue, { shouldValidate: true });

        const valorDinheiro = parseFloat(name === 'ValorDinheiro' ? newValue : (watch('ValorDinheiro', '0') as string)) || 0;
        const valorCheque = parseFloat(name === 'ValorCheque' ? newValue : (watch('ValorCheque', '0') as string)) || 0;
        const total = valorDinheiro + valorCheque;
        setTotal(total.toFixed(2));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
            <SCFieldset legend='Saldo Atual' className='col-12'>
                <div>
                    <SCButton
                        label={`Atualiza Saldo do dia ${currentDate}`}
                        icon="ph ph-bold ph-arrows-clockwise"
                        className="p-button w-auto p-button-info p-button-outlined"
                        type='button'
                        style={{ width: '100%' }}
                        onClick={atualizaSaldo}
                    />
                </div>
                <div className='grid mt-2'>
                    <div className="col-12 md:col-4">
                        <SCInputText
                            label='Dinheiro'
                            control={control}
                            name='SaldoAtualDinheiro'
                            value={saldoAtualDinheiro}
                            style={{ textAlign: 'right' }}
                            disabled
                        />
                    </div>
                    <div className="col-12 md:col-4">
                        <SCInputText
                            label='Cheque'
                            control={control}
                            name='SaldoAtualCheque'
                            style={{ textAlign: 'right' }}
                            value={saldoAtualCheque}
                            disabled
                        />
                    </div>
                    <div className="col-12 md:col-4">
                        <SCInputText
                            label='Total'
                            control={control}
                            name='Total'
                            style={{ textAlign: 'right', fontWeight: "bolder", backgroundColor: "#0f97c7", color: "#fff" }}
                            value={saldoAtualTotal}
                            disabled
                        />
                    </div>
                </div>
            </SCFieldset>
            <SCFieldset legend='Sangria' className='col-12 mt-3'>
                <div className='grid'>
                    <div className="col-12 md:col-4">
                        <SCInputText
                            label='Dinheiro'
                            control={control}
                            name='ValorDinheiro'
                            style={{ textAlign: 'right' }}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="col-12 md:col-4">
                        <SCInputText
                            label='Cheque'
                            control={control}
                            name='ValorCheque'
                            style={{ textAlign: 'right' }}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="col-12 md:col-4">
                        <SCInputText
                            label='Total'
                            control={control}
                            name='ValorTotal'
                            style={{ textAlign: 'right', fontWeight: "bolder", backgroundColor: "#0f97c7", color: "#fff" }}
                            disabled
                            value={total}
                        />
                    </div>
                    <div className="col-12 md:col-12">
                        <SCInputText
                            label='Observação'
                            control={control}
                            name='Observacao'
                        />
                    </div>
                </div>
            </SCFieldset>

            <div className='grid mt-3'>
                <Divider />
                <div className="flex flex-1 justify-content-between pt-3 px-2">
                    <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                    <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
                </div>
            </div>

        </form >

    );
};

export default SangriaForm;
