import React from 'react';
import { Button } from 'primereact/button';
import './SCButton.css';
import IButton from '../../../interfaces/components/button/IButton';

// Documentation: https://www.primefaces.org/primereact/button/
const SCButton: React.FC<IButton> = ({ label, ...restProps }) => {
    return <Button label={label} {...restProps} />;
};
export default SCButton;
