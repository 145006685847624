import React, { } from 'react';
import { useFormContext } from 'react-hook-form';

import { SCCalendar, SCCheckbox, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';


type Props = any;

const AlunosFormMaisInformacoes: React.FC<Props> = ({ showDelete, ...props }) => {

  const toast = useToast();

  const { control, formState: { errors }, getValues, setValue, watch } = useFormContext();

  const watchAlunosPeriodo = watch('AlunosPeriodo');

  return (
    <div className='col-12' >
      <div className='grid'>       
        <div className="col-12">
          <SCCheckbox 
            control={control}
            errors={errors}
            name="AlunosPeriodo.Ouvinte" 
            label="Aluno OUVINTE (Não irá imprimir Boletim, Diário de Classe e Mapa de Resultado Final)"
            disabled={showDelete}
            />
        </div>   
      </div>   
      
      <div className='grid'>   
        <div className="col-12">
          <SCCheckbox 
            control={control}
            errors={errors}
            name="AlunosPeriodo.Concluiu" 
            label="Aluno CONCLUINTE (Não irá imprimir Boletim, Diário de Classe, Mapa de Resultado Final, Freq. de Prova e Boleto)"
            disabled={showDelete}
            />
        </div> 
      </div>

      <div className='grid'> 
        <div className="col-12">
          <SCCheckbox 
            control={control}
            errors={errors}
            name="AlunosPeriodo.MatriculaCondicionada" 
            label="Matrícula condicional (aluno que vem de escola do governo e que matriculam-se sem ter concluído)"
            disabled={showDelete}
            />
        </div> 
      </div>  

      <div className='grid'> 
        <div className="col-12">
          <SCCheckbox 
            control={control}
            errors={errors}
            name="AlunosPeriodo.MatriculaEspecial" 
            label="Matrícula ESPECIAL (Não processa Boletim)"
            disabled={showDelete}
            />
        </div> 
      </div>  

      <div className='grid'>   
        <div className="col-12">
          <SCCheckbox 
            control={control}
            errors={errors}
            name="SaidaLibre" 
            label="Saída livre (Catraca Eletrônica)"
            disabled={showDelete}
            />
        </div> 
      </div>
      
      <div className='grid'>   
        <div className="col-12 flex flex-row ">
          <label htmlFor="RetornoMatricula" >Retorno de Matrícula trancada</label>
          <SCRadioButton 
            id='RetornoMatricula'
            control={control}
            errors={errors}
            name="AlunosPeriodo.RetornoMatricula" 
            value={true}
            label="Sim"
            className="ml-2"
            disabled={showDelete}
            />
          <SCRadioButton 
            control={control}
            errors={errors}
            name="AlunosPeriodo.RetornoMatricula" 
            value={false}
            label="Não"
            className="ml-2"
            disabled={showDelete}
            />
        </div> 
      </div>

      <div className='grid'>       
        <div className="col-6">
          <SCCheckbox 
            control={control}
            errors={errors}
            name="AlunosPeriodo.Isento" 
            label="Isento de Educação Física"
            disabled={showDelete}
            />
        </div>  
      </div>  
      {watchAlunosPeriodo.Isento && (
        <div className='grid'>       
          <div className="col-3">
              <SCInputText
                control={control}
                errors={errors}
                style={{ textTransform: 'uppercase' }}
                name="AlunosPeriodo.AlineaLei"
                label='Lei / Alinea'
                disabled={showDelete}
                maxLength={15}
              />
          </div>        
        </div>
      )}
      <Divider />

      <div className='grid'>
          <div className="col-2">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="ValidadeCarteirinha"
              label='Validade da Carteirinha'
              disabled={showDelete}
              maxLength={10}
            />            
          </div>
          <div className="col-2">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="IdInep"
              label='ID INEP'
              disabled={showDelete}
              maxLength={12}
            />
          </div>
          <div className="col-2">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="Nis"
              label='NIS'
              disabled={showDelete}
              maxLength={15}
            />
          </div>
          <div className="col-2">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="CartaoRFID"
              label='Nº Cartão RFID'
              disabled={showDelete}
              maxLength={30}
            />
          </div>
      </div>
      
      
    </div>
  );
};

export default AlunosFormMaisInformacoes;
