import React from 'react';

import { useAuth } from '../providers/auth';
import DashboardCount from '../components/DashboardCount';
import DashboardDisciplinas from '../components/DashboardDisciplinas';
import DashboardCursos from '../components/DashboardCursos';
import DashboardFuncionarios from '../components/DashboardFuncionarios';


const Dashboard = () => {

  return (
    <div className="grid layout-dashboard">
      <DashboardCursos />
      <DashboardDisciplinas />
      <DashboardFuncionarios />
    </div>
  );
};

export default Dashboard;
