import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { useAuth } from '../providers/auth';
import Loading from '../components/Loading';
import IEndereco from '../interfaces/IEndereco';
import apiCep from '../services/apiCep';


interface Props {
  endereco?: IEndereco;
  setEnderecoDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteEndereco: boolean;
  setDeleteEndereco(param: boolean): void;
}

const EnderecoForm: React.FC<Props> = ({ setEnderecoDialog, endereco, reload, deletar, deleteEndereco, setDeleteEndereco }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'EnderecoForm';

  const toast = useToast();
  const {  periodoSelecionado } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [desabilita, setDesabilita] = useState(false)

  const defaultValues: IEndereco = endereco || {
    EnderecosId: undefined,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Cep: '',
    Logradouro: '',
    Numero: '',
    Bairro: '',
    Cidade: '',
    UF: '',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const onSubmit = async (data: IEndereco) => {

    setLoading(true)

    let resp: any = undefined;
    try {
      if (data.EnderecosId === undefined)
        resp = await api.post('/enderecos', data);
      else
        resp = await api.put('/enderecos', data);

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setLoading(false);
      reload();
      setEnderecoDialog(false);

    } catch (e: any) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
      setLoading(false);
    }
  }

  const consultaCep = async () => {

    try {

      const consulta = await apiCep.get(`${getValues().Cep}/json`)
      const resultado = consulta.data;

      if (resultado.logradouro) {
        setDesabilita(true)
        setValue('Logradouro', resultado.logradouro)
        setValue('Bairro', resultado.bairro)
        setValue('Cidade', resultado.localidade)
        setValue('UF', resultado.uf)
      } else {
        setDesabilita(false)
        setValue('Logradouro', '')
        setValue('Bairro', '')
        setValue('Cidade', '')
        setValue('UF', '')
      }

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  }

  const watchCEP = watch('Cep')

  useEffect(() => {
    if (endereco?.EnderecosId === undefined) {
      if (watchCEP?.length == 8) {
        consultaCep();
      }
    }
  }, [watchCEP])

  const cancelar = (e: any) => {
    setDeleteEndereco(false);
    setEnderecoDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' style={{ minHeight: 250, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

      {loading && (<Loading />)}

      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="CEP"
            label='CEP'
            maxLength={9}
            required={!deleteEndereco}
            disabled={endereco?.EnderecosId !== undefined}
            tarefa={tarefa}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-12 md:col-7">
          <SCInputText
            control={control}
            errors={errors}
            name="Logradouro"
            label='Logradouro'
            maxLength={100}
            required={!deleteEndereco}
            disabled={deleteEndereco}
            tarefa={tarefa}
          />
        </div>

      </div>

      <div className='grid'>
        <div className="col-9 md:col-3">
          <SCInputText
            control={control}
            errors={errors}
            maxLength={40}
            name="Bairro"
            label='Bairro'
            disabled={deleteEndereco}
            tarefa={tarefa}
            required={!deleteEndereco}
          />

        </div>
        <div className="col-9 md:col-3">
          <SCInputText
            control={control}
            errors={errors}
            maxLength={40}
            name="Cidade"
            label='Cidade'
            disabled={deleteEndereco}
            tarefa={tarefa}
            required={!deleteEndereco}

          />
        </div>

        <div className="col-2 md:col-1">
          <SCInputText
            control={control}
            errors={errors}
            name="UF"
            label='UF'
            disabled={deleteEndereco || desabilita || endereco?.EnderecosId !== undefined}
            tarefa={tarefa}
            required={!deleteEndereco}
          />
        </div>
      </div>

      <div className='grid'>
        <Divider />
        {deleteEndereco ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>
    </form>

  );
};

export default EnderecoForm;
