import React, { useEffect } from 'react';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
 
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from './config/Routes';
import { useAuth } from './providers/auth';
import api from "./services/api";

export default function AppMain(){ 
  addLocale('pt-BR', {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar',
    accept: 'Aceitar',
    reject: 'Rejeitar',
    choose: 'Escolher',
    cancel: 'Cancelar',
    dateFormat: 'dd/mm/yy',
    weak: 'Fraca',
    medium:'Mediana',
    strong:'Forte',
    passwordPrompt: 'Digite Sua Senha'
});



locale('pt-BR');   
  const { usuario, signOut  } = useAuth();

  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     signOut(usuario?.UsuarioId);
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

    return (
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
    )    
};
