import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCheckbox, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IItinerarios from '../interfaces/IItinerarios';


interface Props {
  itinerario?: IItinerarios;
  setItinerarioDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteItinerario: boolean;
  setDeleteItinerario(param: boolean): void;
}

const IntinerarioFormativoForm: React.FC<Props> = ({ setItinerarioDialog, itinerario, reload, deletar, deleteItinerario, setDeleteItinerario }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'ItinerarioFormativoForm';

  const toast = useToast();
  const [documentos, setDocumentos] = useState<any>([]);

  const defaultValues: IItinerarios = itinerario || {
    ItinerariosFormativosId: undefined,
    Codigo: '',
    Descricao: '',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const load = async () => {

  }

  const onSubmit = async (data: IItinerarios) => {

    let resp: any = undefined;
    let doc = documentos.filter((doc: any) => doc.checked);

    try {
      if (data.ItinerariosFormativosId === undefined) {
        resp = await api.post('/itinerarios', data);
      } else {
        resp = await api.put('/itinerarios', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setItinerarioDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  useEffect(() => {
    load()
  }, [])

  const cancelar = (e: any) => {
    setDeleteItinerario(false);
    setItinerarioDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código'
            maxLength={10}
            required={!deleteItinerario}
            disabled={deleteItinerario || itinerario?.ItinerariosFormativosId !== undefined}
            tarefa={tarefa}
            tooltip='Máximo de 10 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-12 md:col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Descricao"
            label='Descrição'
            maxLength={60}
            required={!deleteItinerario}
            disabled={deleteItinerario}
            tarefa={tarefa}
            tooltip='Máximo de 60 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
      </div>

      <div className='grid'>
        <Divider />
        {deleteItinerario ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default IntinerarioFormativoForm;
