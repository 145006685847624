import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCheckbox, SCDropdown, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, TIPO_FORMA_PAGAMENTO } from '../utilities/constantes';
import util from '../utilities/util';
import IFormaPagamento from '../interfaces/IFormaPagamento';
import { useAuth } from '../providers/auth';


interface Props {
  formaPagamento?: IFormaPagamento;
  setFormaPagamentoDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteFormaPagamento: boolean;
  setDeleteFormaPagamento(param: boolean): void;
}

const FormaRecebimentoForm: React.FC<Props> = ({ setFormaPagamentoDialog, formaPagamento, reload, deletar, deleteFormaPagamento, setDeleteFormaPagamento }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'FormaRecebimentoForm';
  const { periodoSelecionado } = useAuth();
  const toast = useToast();

  const defaultValues: IFormaPagamento = formaPagamento || {
    FormasPagamentoId: undefined,
    Codigo: '',
    Descricao: '',
    Desativado: false,
    Tipo: '',
    Fonte: 0,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;
  const [fontes, setFontes] = useState([])

  const watchTipo = watch('Tipo')

  const onSubmit = async (data: IFormaPagamento) => {
    let resp: any = undefined;

    let { FonteDescricao, ...newData } = data;

    try {
      if (data.FormasPagamentoId === undefined) {
        resp = await api.post('/formasPagamento', newData);
      } else {
        resp = await api.put('/formasPagamento', newData);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setFormaPagamentoDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setDeleteFormaPagamento(false);
    setFormaPagamentoDialog(false);
    reset();
  }

  const load = async () => {
    console.log('watchTipo', watchTipo)

    if (formaPagamento?.FormasPagamentoId !== undefined) {
      const resultFontes = await api.get('/fontes/list', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Tipo: watchTipo == '1' || watchTipo == '4' ? '1' : '2'
        }
      });

      setFontes(resultFontes?.data);
    } else {
      setFontes([]);
    }
  }

  useEffect(() => {
    load()
  }, []);

  const handleComboTipo = async (e: any) => {
    const resultFontes = await api.get('/fontes/list', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Tipo: e == '1' || e == '4' ? '1' : '2'
      }
    });

    setFontes(resultFontes.data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className='col-12 md:col-1'>
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código'
            maxLength={3}
            required={!deleteFormaPagamento}
            disabled={deleteFormaPagamento || formaPagamento?.FormasPagamentoId !== undefined}
            tarefa={tarefa}
            tooltip='Máximo de 3 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
        <div className='col-12 md:col-3'>
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Descricao"
            label='Descrição'
            maxLength={40}
            required={!deleteFormaPagamento}
            tarefa={tarefa}
            tooltip='Máximo de 40 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>

        <div className='col-12'>
          <SCCheckbox
            control={control}
            name="Desativado"
            label='Desabilitar esse código'
            disabled={deleteFormaPagamento}
            tarefa={tarefa}
          />
        </div>
      </div>

      <div className='grid'>
        <div className="col-12 md:col-4">
          <SCDropdown
            control={control}
            errors={errors}
            name="Tipo"
            label='Tipo de Recebimento'
            options={TIPO_FORMA_PAGAMENTO}
            optionLabel='descricao'
            optionValue='value'
            disabled={deleteFormaPagamento}
            tarefa={tarefa}
            style={{ marginBottom: 10 }}
            required={!deleteFormaPagamento}
            onChange={(e) => handleComboTipo(e.target.value)}
          />

          <SCDropdown
            control={control}
            errors={errors}
            name="Fonte"
            label='Fonte Sugerida'
            options={fontes}
            optionLabel='Codigo'
            optionValue='FonteId'
            disabled={deleteFormaPagamento}
            tarefa={tarefa}
          />
        </div>

        <Divider />
        {deleteFormaPagamento ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default FormaRecebimentoForm;
