import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IDocMatric from '../interfaces/IDocMatric';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import DocMatricForm from './DocumentosMatriculaForm';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';


const DocumentosMatricula = () => {
  const toast = useToast();
  const { usuario, periodoSelecionado } = useAuth();
  const tarefa = 'DocMatricForm';



  let emptyDocMatric: IDocMatric = {
    EmpresaId: periodoSelecionado?.EmpresaId,
    DocumentoId: undefined,
    Codigo: '',
    Descricao: '',
    Ordem: 0,
  };

  const [documentos, setDocumentos] = useState<any[]>([]);
  const [documentoDialog, setDocumentoDialog] = useState<boolean>(false);
  const [deleteDocumento, setDeleteDocumento] = useState<boolean>(false);
  const [documento, setDocumento] = useState<IDocMatric>(emptyDocMatric);
  const [selectedDocumento, setSelectedDocumento] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const methods = useForm({ defaultValues: emptyDocMatric });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/documentosMatricula', {
      params: {
        Descricao: getValues()?.Descricao,
        EmpresaId: periodoSelecionado?.EmpresaId
      }
    });

    if (result.data)
      setDocumentos(result.data);
    else
      setDocumentos([]);

    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setDocumento(emptyDocMatric);
    setSubmitted(false);
    setDocumentoDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setDocumentoDialog(false);
  };
  const editar = (documento: any) => {
    setDocumento(documento);
    setDocumentoDialog(true);
  };

  const confirmDelete = (documento: any) => {
    setDocumento(documento);
    setDeleteDocumento(true);
    setDocumentoDialog(true);
  };

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/documentosMatricula', { params: { DocumentoId: documento.DocumentoId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });

      documentos.splice(documentos.findIndex((item: any) => item.DocumentoId === documento.DocumentoId), 1);
      const items = documentos.map((item: any, index: any) => { item.Ordem = index + 1; return item; });
      const update = await api.put('/documentosMatricula/reordenar', items);

      load();
      setDeleteDocumento(false);
      setDocumentoDialog(false);
      setDocumento(emptyDocMatric);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const update = result.map((item: any, index: any) => { item.Ordem = index + 1; return item; });

    return update;
  };

  const onDragEnd = async (result: any) => {

    if (!result.dropIndex) {
      return;
    }

    const items = reorder(
      documentos,
      result.dragIndex,
      result.dropIndex
    );
    const update = await api.put('/documentosMatricula/reordenar', items);
    setDocumentos(items);
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Documentos para Matrícula</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              ref={dt}
              value={documentos}
              dataKey="id"
              paginator
              rows={25}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll"
              onRowReorder={(e) => onDragEnd(e)}
            >
              <Column rowReorder rowReorderIcon='pi pi-sort-alt' header="" headerStyle={{ width: 50 }}></Column>
              <Column field="Codigo" header="Código" headerStyle={{ width: 92 }}></Column>
              <Column field="Descricao" header="Descrição"  ></Column>
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={documentoDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${documento.DocumentoId === undefined ? 'Cadastrando ' : (deleteDocumento ? 'Excluíndo' : 'Alterando')} Documento`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <DocMatricForm
              setDocumentoDialog={setDocumentoDialog}
              documento={documento}
              reload={load}
              deleteDocumento={deleteDocumento}
              setDeleteDocumento={setDeleteDocumento}
              deletar={deletar}
            />
          </SCDialog>

        </div>
      </div>
    </div>
  );
};

export default DocumentosMatricula;
