import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCDropdown, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import IDocMatric from '../interfaces/IDocMatric';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import Loading from '../components/Loading';
import { useAuth } from '../providers/auth';

interface Props {
  documento?: IDocMatric;
  setDocumentoDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteDocumento: boolean;
  setDeleteDocumento(param: boolean): void;
}

const DocumentosMatriculaForm: React.FC<Props> = ({ setDocumentoDialog, documento, reload, deletar, deleteDocumento, setDeleteDocumento }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'DocumentosMatriculaForm';

  const toast = useToast();
  const ordem = new Array(20).fill(0).map((_, index) => ({ descricao: (index + 1).toString(), valor: index + 1 }));
  const [isLoading, setIsLoading] = useState(false);

  const { usuario, periodoSelecionado } = useAuth();

  const defaultValues: IDocMatric = documento || {
    EmpresaId: periodoSelecionado?.EmpresaId,
    DocumentoId: undefined,
    Codigo: '',
    Descricao: '',
    Ordem: 0,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  useEffect(() => {
    async function ultimaOrdem() {
      const resp = await api.get('/documentosMatricula/ultimaOrdem');
      setValue('Ordem', resp.data?.Ordem + 1);
    }

    if (documento?.DocumentoId === undefined) {
      ultimaOrdem();
    }

  }, [])

  const onSubmit = async (data: IDocMatric) => {

    setIsLoading(true);
    let resp: any = undefined;
    try {
      if (data.DocumentoId === undefined)
        resp = await api.post('/documentosMatricula', data);
      else
        resp = await api.put('/documentosMatricula', data);

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setTimeout(() => {
        setIsLoading(false);
        reload();
        setDocumentoDialog(false);
      }, 3000)

    } catch (e: any) {
      console.log(e.response.data)
      setIsLoading(false);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  }

  const cancelar = (e: any) => {
    setDeleteDocumento(false);
    setDocumentoDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      {isLoading && (
        <Loading full />
      )}
      <div className='grid'>
        <div className="col-3">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código'
            maxLength={6}
            required={!deleteDocumento}
            disabled={documento?.DocumentoId !== undefined}
            tarefa={tarefa}
            tooltip='Máximo de 6 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-12 md:col-9">
          <SCInputText
            control={control}
            errors={errors}
            name="Descricao"
            label='Descrição'
            minLength={2}
            maxLength={60}
            required={!deleteDocumento}
            disabled={deleteDocumento}
            tarefa={tarefa}
            tooltip='Máximo de 60 caracteres'
            tooltipOptions={{ event: 'focus' }}
            style={{ textTransform: 'uppercase' }}
          />
        </div>
      </div>
      <div className='grid'>
        <Divider />
        {deleteDocumento ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>
    </form>

  );
};

export default DocumentosMatriculaForm;
