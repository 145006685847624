import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCCheckbox, SCDropdown, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import IConvenio from '../interfaces/IConvenio';
import { useAuth } from '../providers/auth';
import { IPeriodo } from '../interfaces/IUsuario';
import { CheckboxChangeParams } from 'primereact/checkbox';


interface Props {
  convenio?: IConvenio;
  setConvenioDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteConvenio: boolean;
  setDeleteConvenio(param: boolean): void;
}

interface Documento {
  DocumentoId: number;
  // Outras propriedades...
}

const ConvenioForm: React.FC<Props> = ({ setConvenioDialog, convenio, reload, deletar, deleteConvenio, setDeleteConvenio }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'ConvenioForm';

  const toast = useToast();

  const defaultValues: IConvenio = convenio || {
    ConvenioId: undefined,
    Codigo: '',
    Descricao: '',
    Desativado: false,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const onSubmit = async (data: IConvenio) => {
    let resp: any = undefined;

    try {
      if (data.ConvenioId === undefined) {
        resp = await api.post('/convenio', data);

      } else {
        resp = await api.put('/convenio', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setConvenioDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setDeleteConvenio(false);
    setConvenioDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código'
            maxLength={10}
            required={!deleteConvenio}
            disabled={deleteConvenio || convenio?.ConvenioId !== undefined}
            tarefa={tarefa}
            tooltip='Máximo de 10 caracteres'
            tooltipOptions={{event: 'focus' }}
          />
        </div>
      </div>

      <div className='grid'>
        <div className="col-12 md:col-4">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Descricao"
            label='Descrição'
            maxLength={50}
            required={!deleteConvenio}
            disabled={deleteConvenio}
            tarefa={tarefa}
            tooltip='Máximo de 50 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
        <div className="col-12">

          <div className='xs:flex-column md:flex md:justify-content-between sm:flex-wrap'>
            <div className="flex align-items-center">
              <SCCheckbox
                control={control}
                name="Desativado"
                label='Desabilitar esse código?'
                disabled={deleteConvenio}
                tarefa={tarefa}
              />
            </div>

            {/* <div className="flex align-items-center">
              <SCCheckbox
                checked={true}
                control={control}
                name="PrecisaDisciplina"
                label='Não contabilizar para Imposto de Renda'
                disabled={deleteConvenio}
                tarefa={tarefa}
              />
            </div>

            <div className="flex align-items-center">
              <SCCheckbox
                checked={true}
                control={control}
                name="Requerimento"
                label='Esse convênio é o "Novo Fies"?'
                disabled={deleteConvenio}
                tarefa={tarefa}
              />
            </div> */}
          </div>
        </div>
      </div>


        <Divider />
        {deleteConvenio ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}


    </form >

  );
};

export default ConvenioForm;
