import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { IoMdCheckboxOutline } from "react-icons/io";
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import IBancos from '../interfaces/IBancos';
import BancosForm from './BancosForm';


const Bancos = () => {
  const toast = useToast();
  const { usuario, periodoSelecionado, empresaSelecionada } = useAuth();
  const tarefa = 'DisciplinaForm';

  let emptyData: IBancos = {
    BancoId: undefined,
    Ativo: false,
    Sequencial: '',
    CodigoBanco: '',
    NomeBanco: '',
    Logo: '',
    Carteira: '',
    Variacao: '',
    TipoCobranca: 1,
    FonteId: 0,
    Agencia: '',
    CedenteCodigo: '',
    CedenteUnidade: '',
    CedenteNome: '',
    CNPJ: '',
    CedenteTituloAgencia: '',
    CedenteAgencia: '',
    DiasDevolucao: '',
    RemessaSequencia: '',
    Observacao: '',
    RemessaArquivo: false,
    RemessaArquivoAutomatico: false,
    RemessaLinhaR: false,
    RemessaArquivoDebitoAutomatico: false,
    RemessaInformarTarifa: false,
    RemessaPercentualMulta: '',
    RemessaLayout: 1,
    RetornoLayout: 1,
    BoletoCodigoBarras: false,
    BoletoEmiteCarne: false,
    BoletoImprimeDesconto: false,
    BoletoImprimeLinhaDigitavel: false,
    BoletoCalculoValor: 1,
    MensagemSemDesconto01: '',
    MensagemSemDesconto02: '',
    MensagemSemDesconto03: '',
    MensagemSemDesconto04: '',
    MensagemSemDeducao01: '',
    MensagemSemDeducao02: '',
    MensagemSemDeducao03: '',
    MensagemSemDeducao04: '',
    MensagemComDesconto01: '',
    MensagemComDesconto02: '',
    MensagemComDesconto03: '',
    MensagemComDesconto04: '',
    BoletoLocalPagamento: '',
  };

  const [banco, setBanco] = useState<any>(null);
  const [bancoDialog, setBancoDialog] = useState<boolean>(false);
  const [registro, setRegistro] = useState<IBancos>(emptyData);
  const [selectedRegistro, setSelectedRegistro] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyData });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    console.log()
    const result = await api.get('/bancos/list', { params: { NomeBanco: getValues()?.NomeBanco } });
    if (result.data)
      setBanco(result.data);
    else
      setBanco([]);

    console.log(result.data)
    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setRegistro(emptyData);
    setSubmitted(false);
    setBancoDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setBancoDialog(false);
  };
  const editar = (reg: any) => {
    setRegistro(reg);
    setBancoDialog(true);
  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Bancos</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="NomeBanco"
                  label='Nome'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-between'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />

          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={banco}
              onSelectionChange={(e) => setSelectedRegistro(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Sequencial" header="Sequencial" headerStyle={{ width: 100 }} align='center' sortable />
              <Column field="Carteira" header="Carteira" align='center' headerStyle={{ width: 100 }} sortable />
              <Column field="CodigoBanco" header="Variação" headerStyle={{ width: 100 }} align='center' sortable />

              <Column field="NomeBanco" header="Nome do Banco" sortable />
              <Column field="Ativo" header="Ativado" align={'center'} alignHeader={'center'} sortable body={(rowData: any) => {
                return (<IoMdCheckboxOutline size={20} color={rowData.Ativo ? "#01902f" : "red"} />)
              }} ></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                  </div>
                );
              }} style={{ width: 50 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={bancoDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${registro.BancoId === undefined ? 'Cadastrando ' : ('Alterando')} Banco`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <BancosForm
              setBancoDialog={setBancoDialog}
              registro={registro}
              reload={load}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default Bancos;
