import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IFeriadoFinanceiro from '../interfaces/IFeriadoFinanceiro';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCCheckbox, SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import FeriadoFinanceiroForm from './FeriadoFinanceiroForm';
import AjudaFormTela from './AjudaFormTela';
import { format } from 'date-fns';
import Loading from '../components/Loading';
import { IoMdCheckboxOutline } from 'react-icons/io';


const FeriadoFinanceiro = () => {
  const toast = useToast();
  const tarefa = 'FeriadoFinanceiro';
  const { empresaSelecionada } = useAuth();

  let emptyFeriadoFinanceiro: IFeriadoFinanceiro = {
    EmpresaId: empresaSelecionada?.EmpresaId,
    FeriadosFinanceiroId: undefined,
  };

  const [feriadosFinanceiro, setFeriadosFinanceiro] = useState<any>([]);
  const [feriadoFinanceiroDialog, setFeriadoFinanceiroDialog] = useState<boolean>(false);
  const [deleteFeriadoFinanceiro, setDeleteFeriadoFinanceiro] = useState<boolean>(false);
  const [feriadoFinanceiro, setFeriadoFinanceiro] = useState<IFeriadoFinanceiro>(emptyFeriadoFinanceiro);
  const [selectedFeriadoFinanceiro, setSelectedFeriadoFinanceiro] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [exibirSomenteFeriadosFuturos, setExibirSomenteFeriadosFuturos] = useState<boolean>(false);
  const { periodoSelecionado } = useAuth();
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyFeriadoFinanceiro });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true);
    const result = await api.get('/feriadoFinanceiro/list', { params: { Descricao: getValues()?.Descricao, Ano: periodoSelecionado?.Ano } });
    const resultado = result.data

    if (resultado)
      setFeriadosFinanceiro(resultado);
    else
      setFeriadosFinanceiro([]);

    setLoading(false);
  }

  const clearState = () => {
    dt?.current?.reset();      
  }

  useEffect(() => {
    load().finally(() => clearState());
  }, []);

  const openNew = () => {
    setFeriadoFinanceiro(emptyFeriadoFinanceiro);
    setSubmitted(false);
    setFeriadoFinanceiroDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setFeriadoFinanceiroDialog(false);
  };
  const editar = (item: any) => {
    item.DataFeriado = new Date(item.DataFeriado);
    setFeriadoFinanceiro(item);
    setFeriadoFinanceiroDialog(true);
  };

  const confirmDelete = (item: any) => {
    item.DataFeriado = new Date(item.DataFeriado);
    setFeriadoFinanceiro(item);
    setDeleteFeriadoFinanceiro(true);
    setFeriadoFinanceiroDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/feriadoFinanceiro', { params: { FeriadosFinanceiroId: feriadoFinanceiro.FeriadosFinanceiroId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteFeriadoFinanceiro(false);
      setFeriadoFinanceiroDialog(false);
      setFeriadoFinanceiro(emptyFeriadoFinanceiro);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  function formataData(date: any) {
    const [year, month, day] = date.substr(0, 10).split('-')
    return format(new Date(
      year,
      (month - 1),
      day,
    ), 'dd/MM/yy')
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };


  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Feriado Financeiro</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <div className='grid'>
                  <div className='col-12'>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Descricao"
                      label='Descrição'
                      className='w-full'
                    />
                  </div>
                  <div className='col-12'>
                    <SCCheckbox
                      label='Exibir somente feriados futuros'
                      name='exibirSomenteFeriadosFuturos'
                      checked={exibirSomenteFeriadosFuturos}
                      onChange={(e) => setExibirSomenteFeriadosFuturos(e.target.checked)}
                    />
                  </div>
                </div>                
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />

          {loading ? <Loading /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={feriadosFinanceiro.filter((item: any) => exibirSomenteFeriadosFuturos ? new Date(item.DataFeriado) >= new Date() : item)}
              onSelectionChange={(e) => setSelectedFeriadoFinanceiro(e.value)}
              dataKey="id"
              stateKey='tabFeriadoFinanceiro'
              stateStorage='local'
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column header="DataFeriado" sortable headerStyle={{ width: 92 }} body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-center">
                    {rowData.DataFeriadoString}
                  </div>
                );
              }} />
              <Column field="Descricao" header="Descrição" sortable ></Column>
              <Column bodyStyle={{ width: 60 }} field="Fixo" header="Fixo" align={'center'} alignHeader={'center'} sortable 
                body={(rowData: any) => {
                  return <IoMdCheckboxOutline size={20} color={rowData.Fixo ? "#01902f" : 'red'} />
                }} >
              </Column>
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={feriadoFinanceiroDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${feriadoFinanceiro.FeriadosFinanceiroId === undefined ? 'Cadastrando ' : (deleteFeriadoFinanceiro ? 'Excluíndo' : 'Alterando')} Feriado Financeiro`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <FeriadoFinanceiroForm
              setFeriadoFinanceiroDialog={setFeriadoFinanceiroDialog}
              feriadoFinanceiro={feriadoFinanceiro}
              reload={load}
              deleteFeriadoFinanceiro={deleteFeriadoFinanceiro}
              setDeleteFeriadoFinanceiro={setDeleteFeriadoFinanceiro}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default FeriadoFinanceiro;
