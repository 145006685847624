import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCCheckbox, SCDropdown, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import ICentroCusto from '../interfaces/ICentroCusto';
import { useAuth } from '../providers/auth';
import { IPeriodo } from '../interfaces/IUsuario';
import { CheckboxChangeParams } from 'primereact/checkbox';
import Loading from '../components/Loading';


interface Props {
  centroCusto?: ICentroCusto;
  setCentroCustoDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteCentroCusto: boolean;
  setDeleteCentroCusto(param: boolean): void;
}

interface Documento {
  DocumentoId: number;
  // Outras propriedades...
}

const CentroCustoForm: React.FC<Props> = ({ setCentroCustoDialog, centroCusto, reload, deletar, deleteCentroCusto, setDeleteCentroCusto }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'CentroCustoForm';

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const { periodoSelecionado } = useAuth()
  const periodo = periodoSelecionado?.Id

  const defaultValues: ICentroCusto = centroCusto || {
    CentroCustoId: undefined,
      Codigo: '',
      Descricao: ''
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  // useEffect(() => {
  //   async function load() {
  //     const result = await api.get('/documentosMatricula', { params: { Descricao: '' } });
  //     setDocumentos(result.data)
  //   }

  //   load()
  // }, [])

  useEffect(() => {
    if (centroCusto) {
      //setGeraHistoricoCheck(centroCusto.GeraHistorico === 1)
      //setCursoLivreCheck(CodigoOcorrencia.CursoLivre === 1)
    }
  }, [centroCusto]);

  const onSubmit = async (data: ICentroCusto) => {
    setLoading(true);
    let resp: any = undefined;

    try {
      if (data.CentroCustoId === undefined) {
        resp = await api.post('/centroCusto', data);

      } else {
        resp = await api.put('/centroCusto', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setCentroCustoDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
    setLoading(false);
  }

  const cancelar = (e: any) => {
    setDeleteCentroCusto(false);
    setCentroCustoDialog(false);
    reset();
  }

  if (loading){
    return <Loading full={false}/>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-1">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código'
            maxLength={10}
            disabled={centroCusto?.CentroCustoId !== undefined}
            required={!deleteCentroCusto}
            tarefa={tarefa}
            tooltip='Máximo de 10 caracteres'
            tooltipOptions={{event: 'focus' }}
          />
        </div>

        <div className='col-12 pl-0'>
          <div className="col-12 md:col-6">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="Descricao"
              label='Descrição'
              maxLength={60}
              required={!deleteCentroCusto}
              tarefa={tarefa}
              tooltip='Máximo de 60 caracteres'
              tooltipOptions={{event: 'focus' }}
            />
          </div>
        </div>

        <Divider />
        {deleteCentroCusto ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default CentroCustoForm;
