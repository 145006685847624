import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCCalendar, SCDialog, SCDropdown, SCFieldset, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import SetorForm from './SetorForm';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { format, isAfter, isBefore, isEqual, parseISO } from 'date-fns';
import AbastecimentoCaixaForm from './AbastecimentoCaixaForm';
import ISangria from '../interfaces/ISangria';
import SangriaForm from './SangriaForm';
import { confirmDialog } from 'primereact/confirmdialog';
import { getValue } from '@testing-library/user-event/dist/utils';


const Sangria = () => {
    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const tarefa = 'SetorForm';

    let emptySangria: ISangria = {
        SangriaId: undefined,
        LancamentoData: undefined,
        LancamentoHora: '',
        LancamentoOperador: '',
        ValorDinheiro: '',
        ValorCheque: '',
        Observacao: '',
        DataInicial: null,
        DataFinal: null,
        UsuarioId: 0,
    };

    const [sangrias, setSangrias] = useState<any>(null);
    const [sangriaDialog, setSangriaDialog] = useState<boolean>(false);
    const [sangria, setSangria] = useState<ISangria>(emptySangria);
    const [selectedSangria, setSelectedSangria] = useState<any>(null);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [usuarios, setUsuarios] = useState<any>(null);
    const [todasSangrias, setTodasSangrias] = useState<any>(null);

    const dt = useRef<any>(null);
    const menu = useRef<any>(null);

    const methods = useForm({ defaultValues: emptySangria });
    const { handleSubmit, control, formState: { errors }, getValues, setValue, watch } = methods;

    const load = async () => {
        setLoading(true)

        const result = await api.get('/sangria');
        if (result.data) {
            setSangrias(result.data)
            setTodasSangrias(result.data)
        } else {
            setSangrias([])
            setTodasSangrias([])
        }

        setLoading(false);
    }

    const watchUsuario = watch('UsuarioId');
    const watchDataInicio = watch('DataInicial');
    const watchDataFinal = watch('DataFinal');

    const applyFilters = async () => {

        const dataInicial = getValues().DataInicial;
        const dataI = dataInicial ? format(dataInicial, 'yyyy-MM-dd') : '';

        const dataFinal = getValues().DataFinal;
        const dataF = dataFinal ? format(dataFinal, 'yyyy-MM-dd') : '';

        let resp;
        console.log(getValues().DataInicial, getValues().DataFinal)
        resp = await api.get('/sangria', { params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            DataI: dataI,
            DataF: dataF,
            UsuarioId: watchUsuario,
        }})

        setSangrias(resp.data)
    };

    const loadUsuarios = async () => {
        let resp;
        resp = await api.get('/usuario/listaUsuarioEmpresa', { params: { EmpresaId: periodoSelecionado?.EmpresaId } })

        if (resp.data) {
            setUsuarios(resp.data)
        } else {
            setUsuarios([])
        }
    }

    useEffect(() => {
        load();
        loadUsuarios()
    }, []);

    useEffect(() => {
        if (!watchDataFinal)
            setValue('DataFinal', watchDataInicio)
    }, [watchDataInicio]);

    useEffect(() => {
        if (!watchDataInicio)
            setValue('DataInicial', watchDataFinal)
    }, [watchDataFinal]);


    const openNew = () => {
        setSangria(emptySangria);
        setSubmitted(false);
        setSangriaDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setSangriaDialog(false);
    };

    const accept = async (rowData: any) => {
        try {
            await api.delete('/sangria', { params: { SangriaId: rowData.SangriaId } });
            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
            load();
            setSangria(emptySangria);

        } catch (e: any) {
            console.log(e.response.data)
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data });
        }
    }

    const deletarSangria = (rowData: any) => {
        confirmDialog({
            message: (<div style={{ fontSize: '16px' }} className='col-12'>
                <div className='col-12'>
                    DESEJA DELETAR O LANÇAMENTO DE SANGRIA?
                </div>
            </div>),
            header: 'Atenção!',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => accept(rowData),
            style: { maxWidth: '600px', width: '80%' }
        });
    };

    const onSubmit = async (data: any) => {
        load();
    }

    function formataData(date: any) {
        const [year, month, day] = date.substr(0, 10).split('-')
        return format(new Date(
            year,
            (month - 1),
            day,
        ), 'dd/MM/yy')
    }


    const opcoes = [
        {
            label: 'Imprimir',
            icon: 'pi pi-print',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Exp. para Excel',
            icon: 'pi pi-file-excel',
            command: () => {
                dt.current.exportCSV();
            }
        },
        {
            label: 'Ajuda',
            icon: 'pi pi-question-circle',
            command: () => {
                dt.current.exportCSV();
            }
        },
    ];

    const openMenu = (event: any) => {
        menu?.current?.toggle(event)
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Sangria</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
                        <SCFieldset legend='Filtros' className='col-12 mt-1 p-0'>
                            <div className='flex align-items-center'>
                                <div className="col-12 md:col-2" style={{ marginTop: -10 }}>
                                    <SCCalendar
                                        control={control}
                                        name='DataInicial'
                                        dateFormat='dd/mm/yy'
                                        mask='99/99/9999'
                                        placeholder='Data Inicial'
                                        showIcon />
                                </div>

                                <div className="col-12 md:col-2" style={{ marginTop: -10 }}>
                                    <SCCalendar
                                        control={control}
                                        name='DataFinal'
                                        dateFormat='dd/mm/yy'
                                        mask='99/99/9999'
                                        placeholder='Data Final'
                                        showIcon />
                                </div>

                                <div className="col-12 md:col-3" style={{ marginTop: -10 }}>
                                    <SCDropdown
                                        name="UsuarioId"
                                        options={usuarios}
                                        optionLabel="Apelido"
                                        optionValue='UsuarioId'
                                        placeholder='Selecione o Usuário'
                                        style={{ width: '100%' }}
                                        value={watchUsuario}
                                        onChange={(e) => setValue('UsuarioId', e.target.value)}
                                    />
                                </div>

                                <div className='col-12 md:col-5 flex align-items-end justify-content-around' style={{ marginTop: -10 }}>
                                    <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={applyFilters} />
                                    <Button label='Cadastrar Sangria' icon="pi pi-plus" className="p-button-success" type='button' onClick={openNew} />

                                    <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                                    <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
                                </div>
                            </div>
                        </SCFieldset>
                    </form>
                    <Divider />
                    {loading ? <Loading full={false} /> : (
                        <DataTable
                            size='small'
                            stripedRows
                            ref={dt}
                            value={sangrias}
                            onSelectionChange={(e) => setSelectedSangria(e.value)}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="{first} a {last} de {totalRecords}"
                            globalFilter={globalFilter}
                            emptyMessage="Nenhum registro encontrado."
                            header={null}
                            responsiveLayout="scroll">
                            <Column field="LancamentoData" header="Data" sortable body={(rowData: any) => {
                                const dataFormatada = new Date(rowData.LancamentoData).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                });
                                return (
                                    <span>{dataFormatada}</span>
                                );
                            }} style={{ width: '10%' }}
                            />
                            <Column field="LancamentoHora" header="Hora" sortable headerStyle={{ width: '5%' }} ></Column>
                            <Column field="Apelido" header="Usuário" sortable headerStyle={{ width: '40%' }}></Column>
                            <Column field="ValorDinheiro" header="Total em Dinheiro" body={(rowData: any) => {
                                return (
                                    <span>R$ {rowData.ValorDinheiro?.toFixed(2).replace('.', ',')}</span>
                                );
                            }} headerStyle={{ width: '15%' }}
                            />
                            <Column field="ValorCheque" header="Total em Cheque" body={(rowData: any) => {
                                return (
                                    <span>R$ {rowData.ValorCheque?.toFixed(2).replace('.', ',')}</span>
                                );
                            }} headerStyle={{ width: '15%' }}
                            />
                            <Column body={(rowData: any) => {
                                return (
                                    <div className="actions flex align-items-center justify-content-between">
                                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={(e) => deletarSangria(rowData)} />
                                    </div>
                                );
                            }} headerStyle={{ width: '10%' }}
                            />
                        </DataTable>
                    )}

                    <SCDialog maximized={!util.isDesktop()} visible={sangriaDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
                        header={'Cadastrando Sangria'}
                        modal className="p-fluid" onHide={hideDialog}
                    >
                        <SangriaForm
                            setSangriaDialog={setSangriaDialog}
                            sangria={sangria}
                            reload={load}
                        />
                    </SCDialog>
                </div>
            </div>
        </div>
    );
};

export default Sangria;
