import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, getSituacaoAcademica } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import IAlunosSituacao from '../interfaces/IAlunosSituacao';
import { getDate, getDay } from 'date-fns';
import AlunosFrequenciaForm from './FrequenciaAlunosForm';
import { IAlunoForm } from '../interfaces/IAlunos';


const AlunosFrequencia = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'FuncionarioForm';

  let defaultValues: IAlunoForm = {
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Nome: '',
    AlunoId: undefined,
  };

  const [alunos, setAlunos] = useState<any>(null);
  const [alunoFrequenciaDialog, setAlunoFrequenciaDialog] = useState<boolean>(false);
  const [aluno, setAluno] = useState<IAlunoForm>(defaultValues);
  const [selectedAluno, setSelectedAluno] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {

    setLoading(true)
    const result = await api.get('/aluno/list', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Nome: getValues()?.Nome
      }
    });

    if (result.data)
      setAlunos(result.data);
    else
      setAlunos([]);
    setLoading(false)
  }

  useEffect(() => {
    load();
  }, []);


  const hideDialog = () => {
    setSubmitted(false);
    setAlunoFrequenciaDialog(false);
  };

  const editar = (aluno: any) => {
    setAluno(aluno);
    setAlunoFrequenciaDialog(true);
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  if (loading)
    return <Loading />

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Alunos - Frequência</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Nome"
                  label='Nome'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />

          {loading ? <Loading /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={alunos}
              onSelectionChange={(e) => setSelectedAluno(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column field="NumeroOrdem" header="Ordem" sortable headerStyle={{ width: 50 }}></Column>
              <Column field="TurmaCodigo" header="Turma" sortable headerStyle={{ width: 100 }}></Column>
              <Column field="Nome" header="Nome" sortable ></Column>
              <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }}></Column>
              <Column field="SituacaoAcademica" header="Situação" sortable
                body={(rowData: any) => {
                  return (
                    <div className="flex align-items-center justify-content-between">
                      <span>{getSituacaoAcademica(rowData.SituacaoAcademica)}</span>
                    </div>
                  );
                }}
              ></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} disabled={rowData.SituacaoAcademica === 'R'} />
                  </div>
                );
              }} style={{ width: 50 }}
              />
            </DataTable>
          )}
          <SCDialog maximized={!util.isDesktop()} visible={alunoFrequenciaDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`Alterando Frequência do Aluno`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <AlunosFrequenciaForm
              setAlunoFrequenciaDialog={setAlunoFrequenciaDialog}
              aluno={aluno}
              reload={load}
            />
          </SCDialog>

        </div>
      </div>
    </div>
  );
};

export default AlunosFrequencia;
