import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCheckbox, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import IOrigem from '../interfaces/IOrigem';


interface Props {
  origem?: IOrigem;
  setOrigemDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteOrigem: boolean;
  setDeleteOrigem(param: boolean): void;
}

const OrigemForm: React.FC<Props> = ({ setOrigemDialog, origem, reload, deletar, deleteOrigem, setDeleteOrigem }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'OrigemForm';

  const toast = useToast();
  const [geraHistoricoCheck, setGeraHistoricoCheck] = useState<boolean>(false)
  const [cursoLivreCheck, setCursoLivreCheck] = useState<boolean>(false)
  const [documentos, setDocumentos] = useState<any>([]);

  const defaultValues: IOrigem = origem || {
    FormaOrigemId: undefined,
    Descricao: '',
    Tipo: 0,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const load = async () => {

    if (origem?.FormaOrigemId === undefined) {
      setValue('Tipo', 2)
    }
  }

  const onSubmit = async (data: IOrigem) => {

    let resp: any = undefined;
    let doc = documentos.filter((doc: any) => doc.checked);

    try {
      if (data.FormaOrigemId === undefined) {
        resp = await api.post('/origem', data);
      } else {
        resp = await api.put('/origem', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setOrigemDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  useEffect(() => {
    load()
  }, [])

  const cancelar = (e: any) => {
    setDeleteOrigem(false);
    setOrigemDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código'
            maxLength={30}
            required={!deleteOrigem}
            disabled={deleteOrigem || origem?.FormaOrigemId !== undefined}
            tarefa={tarefa}
            tooltip='Máximo de 30 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-12 md:col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Descricao"
            label='Descrição'
            maxLength={40}
            required={!deleteOrigem}
            disabled={deleteOrigem}
            tarefa={tarefa}
            tooltip='Máximo de 40 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
      </div>

      <SCRadioButton
        control={control}
        name="Tipo"
        label='Essa forma de origem é uma Instituição de Ensino "Privada"'
        value={2}
        disabled={deleteOrigem}
      />

      <SCRadioButton
        control={control}
        name="Tipo"
        label='Essa forma de origem é uma Instituição de Ensino "Pública"'
        value={1}
        disabled={deleteOrigem}
      />

      <SCRadioButton
        control={control}
        name="Tipo"
        label='Outro'
        value={3}
        disabled={deleteOrigem}
      />

      <div className='grid'>
        <Divider />
        {deleteOrigem ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default OrigemForm;
