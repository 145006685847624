import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { getAreaConhecimento, ALERTAS } from '../utilities/constantes';
import ICurso from '../interfaces/ICurso';
import CursoForm from './CursoForm';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import { parse, parseISO } from 'date-fns';
import Loading from '../components/Loading';


const Curso = () => {
  const toast = useToast();
  const { usuario } = useAuth();
  const tarefa = 'cursoForm';

  let emptycurso: ICurso = {
    EmpresaId: usuario?.EmpresaId,
  };

  const [cursos, setCursos] = useState<any>(null);
  const [cursoDialog, setCursoDialog] = useState<boolean>(false);
  const [deleteCurso, setDeleteCurso] = useState<boolean>(false);
  const [curso, setCurso] = useState<ICurso>(emptycurso);
  const [selectedcurso, setSelectedcurso] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptycurso });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    setLoading(true)
    const result = await api.get('/curso', { params: { Descricao: getValues()?.Descricao } });
    if (result.data)
      setCursos(result.data);
    else
      setCursos([]);
    setLoading(false)
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setCurso(emptycurso);
    setSubmitted(false);
    setCursoDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setCursoDialog(false);
  };
  const editar = (curso: any) => {
    curso.InicioFuncionamento = curso.InicioFuncionamento ? new Date(curso.InicioFuncionamento) : null;
    setCurso(curso);
    setCursoDialog(true);
  };

  const confirmDelete = (curso: any) => {
    setCurso(curso);
    setDeleteCurso(true);
    setCursoDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/curso', { params: { EmpresaId: curso.EmpresaId , CursoId: curso.CursoId } });
      console.log(resp.data);
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteCurso(false);
      setCursoDialog(false);
      setCurso(emptycurso);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data });
    }

  };

  const onSubmit = async (data: any) => {
    console.log(data);
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  if (loading)
    return <Loading />

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Cursos</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />

          {loading ? <Loading /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={cursos}
              onSelectionChange={(e) => setSelectedcurso(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Codigo" header="Código" sortable headerStyle={{ width: 200 }}></Column>
              <Column field="Descricao" header="Descrição" sortable ></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}
          <SCDialog maximized={!util.isDesktop()} visible={cursoDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${curso.CursoId === undefined ? 'Cadastrando ' : (deleteCurso ? 'Excluíndo' : 'Alterando')} Curso`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <CursoForm
              setCursoDialog={setCursoDialog}
              curso={curso}
              reload={load}
              deleteCurso={deleteCurso}
              setDeleteCurso={setDeleteCurso}
              deletar={deletar}
            />
          </SCDialog>


        </div>
      </div>
    </div>
  );
};

export default Curso;
