import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';

import Loading from '../components/Loading';
import AlunoResumo from '../components/AlunoResumo';
import { SCButton, SCDropdown, SCInputText } from '../components';
import { Divider } from 'primereact/divider';
import { ALERTAS } from '../utilities/constantes';
import { confirmDialog } from 'primereact/confirmdialog';

interface Props {
  aluno?: IAlunoForm;
  setTrocaTurmaDialog(param: boolean): void;
  reload(): void;
}

const AlunosForm: React.FC<Props> = ({ setTrocaTurmaDialog, aluno, reload }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'AlunoForm';

  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [turmasOrigem, setTurmasOrigem] = useState<any>();
  const [turmasDestino, setTurmasDestino] = useState<any>();
  const [turmaOrigemSelecionada, setTurmaOrigemSelecionada] = useState<any>();
  const [turmaDestinoSelecionada, setDestinoOrigemSelecionada] = useState<any>();

  const { handleSubmit, control, formState: { errors }, getValues, setValue, reset, watch } = useFormContext();

  const watchMatricula = watch('Matricula')

  async function load() {
    setLoading(true)
    const { data } = await api.get('/aluno', {
      params: { AlunoId: aluno?.AlunoId }
    })

    const alunoperiodo = await api.get('/alunosPeriodo/matricula', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: data.Matricula
      }
    });

    data.Turma = {
      Codigo: alunoperiodo.data.TurmaCodigo,
    };

    data.AlunosPeriodo = alunoperiodo.data;
    data.DataNascimento = data.DataNascimento ? new Date(data.DataNascimento) : new Date();
    reset(data);

    const { data: turmaRegular } = await api.get('/alunosPeriodo/aluno', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: data.Matricula
      }
    })

    const { data: turmaAuxiliar } = await api.get('/alunoTurmasAuxiliares/buscaTurmaAuxAluno', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: data.Matricula
      }
    })

    console.log(turmaRegular)
    console.log(turmaAuxiliar)

    setTurmasOrigem([...turmaRegular, ...turmaAuxiliar]);
    setLoading(false);
  }

  useEffect(() => {
    load()
  }, [])

  //VERIFICAR SE PODE TIRAR
  const watchTurma = watch('Turma');
  useEffect(() => {
    if (watchTurma) {
      if (watchTurma?.NaoAplicaEF)
        setValue('AlunosPeriodo.TurmaEF', '');
      else
        setValue('AlunosPeriodo.TurmaEF', watchTurma?.CodigoTurmaEF);

    }
  }, [watchTurma])

  const cancelar = (e: any) => {
    setTrocaTurmaDialog(false);
    reset();
  }

  const dropOrigemChange = async (e: any) => {
    const selectedValue = e.target.value;
    const selectedOrigem = turmasOrigem.find((option: any) => option.TurmaId === selectedValue);

    if (selectedOrigem) {
      const selectedLabel = selectedOrigem.Descricao;
      setTurmaOrigemSelecionada(selectedLabel)

      const turmas = await api.get('/turmas', {
        params: {
          Descricao: '',
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
        }
      })

      const { data: turmaAuxiliar } = await api.get('/turmaAuxiliar/turmaAuxList', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
        }
      })

      if (selectedValue) {
        let pesquisaTurmaRegular = turmas.data.find((t: any) => t.TurmaId === selectedValue)

        if (pesquisaTurmaRegular) {
          const turmasRegularesFiltradas = turmas.data.filter((turma: any) => turma.Codigo !== selectedOrigem.Codigo);
          setTurmasDestino(turmasRegularesFiltradas)
        } else {
          const turmasAuxiliaresFiltradas = turmaAuxiliar.filter((aux: any) => aux.Codigo !== selectedOrigem.Codigo)
          setTurmasDestino(turmasAuxiliaresFiltradas)
        }
      }
    }
  }

  const dropDestinoChange = (e: any) => {
    const selectedValue = e.target.value;
    const selectedDestino = turmasDestino.find((option: any) => option.TurmaId === selectedValue);

    if (selectedDestino) {
      const selectedLabel = selectedDestino.Descricao;
      setDestinoOrigemSelecionada(selectedLabel)
    }
  }

  const onSubmit = async (data: IAlunoForm) => {
    setLoading(true)
    try {

      let resp: any = undefined;

      const { data: turmaAuxiliar } = await api.get('/turmaAuxiliar/turmaAuxList', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
        }
      })

      const TurmaDestino = turmasDestino.find((option: any) => option.TurmaId === getValues().TurmaDestino)
      const TurmaOrigem = turmasOrigem.find((option: any) => option.TurmaId === getValues().TurmaOrigem)
      const TurmaAux = turmaAuxiliar.find((aux: any) => aux.TurmaAuxiliarId == getValues().TurmaDestino)

      if (TurmaAux) {
        const { data: alunosTurmaAux } = await api.get('/alunoTurmasAuxiliares/buscaTurmaAuxId', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            Matricula: data.Matricula,
            TurmaAuxiliarId: getValues().TurmaOrigem
          }
        })

        console.log(alunosTurmaAux)

        if (alunosTurmaAux) {
          resp = await api.put('/alunoTurmasAuxiliares/update', {
            AlunosTurmaAuxiliarId: alunosTurmaAux[0].AlunosTurmaAuxiliarId,
            TurmaAuxiliarId: getValues().TurmaDestino
          });
        }

      } else {
        //Área de atualização de AlunosPeriodo
        resp = await api.put('/alunosPeriodo/updateTurma', {
          Matricula: data.Matricula,
          Ano: periodoSelecionado?.Ano,
          EmpresaId: periodoSelecionado?.EmpresaId,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaOrigem: TurmaOrigem.Codigo,
          TurmaDestino: TurmaDestino.Codigo,
          AlunoPeriodoId: data.AlunosPeriodo?.AlunosPeriodoId,
        });

        //Listagem das matérias de boletim(se tiver) (TURMA ATUAL)
        const disciplinaBoletim = await api.get('/boletim/listBoletim', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            Matricula: data.Matricula
          }
        })

        //Listagem de oferta de disciplina (TURMA DESTINO)
        const ofertaDisciplina = await api.get('/ofertaDisciplina/listProfessorDisciplina', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            TurmaCodigo: TurmaDestino.Codigo
          }
        })

        //MUDANÇA DE TURMA PROPRIAMENTE DITA
        const boletimCodigos = disciplinaBoletim.data.map((item: { DisciplinaCodigo: any; MateriaCodigo: any; BoletimId: any }) => ({ DisciplinaCodigo: item.DisciplinaCodigo, MateriaCodigo: item.MateriaCodigo, BoletimId: item.BoletimId }));
        const ofertaCodigos = ofertaDisciplina.data.map((item: { DisciplinaCodigo: any; Tipo: any; Chr: any; Ordem: any }) => ({ DisciplinaCodigo: item.DisciplinaCodigo, Tipo: item.Tipo, Chr: item.Chr, Ordem: item.Ordem }));

        const boletimDisciplinaCodigos = boletimCodigos.map((codigo: any) => codigo.DisciplinaCodigo);
        const ofertaDisciplinaCodigos = ofertaCodigos.map((codigo: any) => codigo.DisciplinaCodigo);

        boletimCodigos.forEach(async (codigoDisciplina: any) => {
          if (ofertaDisciplinaCodigos.includes(codigoDisciplina.DisciplinaCodigo)) {
            // console.log(`Update em TurmaCodigo: Disciplina com DisciplinaCodigo ${codigoDisciplina.DisciplinaCodigo} existe em ambos os arrays.`);
            const updateBoletim = {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Ano: periodoSelecionado?.Ano,
              Sequencial: periodoSelecionado?.Sequencial,
              Matricula: data.Matricula,
              TurmaCodigo: TurmaOrigem.Codigo,
              DisciplinaCodigo: codigoDisciplina.DisciplinaCodigo,
              MateriaCodigo: codigoDisciplina.MateriaCodigo,
              TurmaCodigoDestino: TurmaDestino.Codigo
            }
            await api.put('/boletim/updateTrocaTurma', updateBoletim)
          } else {
            // console.log(`Delete: Disciplina com DisciplinaCodigo ${codigoDisciplina.DisciplinaCodigo} só existe em disciplinaBoletim.`);
            await api.delete('/boletim', { params: { BoletimId: codigoDisciplina.BoletimId } })
          }
        });

        ofertaCodigos.forEach(async (codigoDisciplina: any) => {
          if (!boletimDisciplinaCodigos.includes(codigoDisciplina.DisciplinaCodigo)) {
            // console.log(`Create: Disciplina com DisciplinaCodigo ${codigoDisciplina.DisciplinaCodigo} só existe em ofertaDisciplina.`);
            const novoBoletim = {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Ano: periodoSelecionado?.Ano,
              Sequencial: periodoSelecionado?.Sequencial,
              Matricula: data.Matricula,
              TurmaCodigo: TurmaDestino.Codigo,
              DisciplinaCodigo: codigoDisciplina.DisciplinaCodigo,
              Tipo: codigoDisciplina.Tipo,
              Ordem: codigoDisciplina.Ordem,
              CargaHoraria: codigoDisciplina.Chr,
            }
            await api.post('/boletim', novoBoletim)
          }
        });

        //Fazendo a verificação dos documentos
        const values = getValues();
        const turmaO = await api.get('/turma', { params: { TurmaId: values.TurmaOrigem } });
        const turmaD = await api.get('/turma', { params: { TurmaId: values.TurmaDestino } });

        if (turmaO.data.NivelSequenciaId !== turmaD.data.NivelSequenciaId) {
          // const nivelSequenciaDocumentoOrigem = await api.get('/nivelSequenciaDocumento', {
          //     params: { NivelSequenciaId: turmaO.data.NivelSequenciaId }
          // });
          const alunosDocumentosEntregues = await api.get('/alunosDocumento', {
            params: {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Ano: periodoSelecionado?.Ano,
              Sequencial: periodoSelecionado?.Sequencial,
              Matricula: data.Matricula,
            }
          })

          const nivelSequenciaDocumentoDestino = await api.get('/nivelSequenciaDocumento', {
            params: { NivelSequenciaId: turmaD.data.NivelSequenciaId }
          });

          const documentosEntregues = alunosDocumentosEntregues.data;
          const documentosDestino = nivelSequenciaDocumentoDestino.data;

          const documentosExcesso = documentosEntregues.filter((documentoEntregue: any) =>
            !documentosDestino.some((documentoDestino: any) =>
              documentoDestino.DocumentoId === documentoEntregue.DocumentoId
            )
          );

          documentosExcesso.forEach(async (item: any) => {
            await api.delete('/alunosDocumento', { params: { AlunosDocumentoId: item.AlunosDocumentoId } })
          })
        }
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setTrocaTurmaDialog(false);
      setLoading(false)
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }, 500);

    } catch (e: any) {
      if (e.response && e.response.data) {
        console.log(e.response.data);
        toast.showToast({ severity: 'error', summary: 'Erro', detail: e.response.data.error });
      } else {
        console.log(e);
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Erro desconhecido' });
      }
    }
  }

  const accept = () => {
    onSubmit(getValues() as IAlunoForm)
  }

  const confirm = () => {
    const values = getValues();
    if (!values.TurmaOrigem) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Campo Turma de Origem é obrigatório!' });
      return;
    }
    if (!values.TurmaDestino) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Campo Turma Destino é obrigatório!' });
      return;
    }
    confirmDialog({
      message: (<div style={{ fontSize: '16px' }} className='col-12'>
        <div className='col-12'>
          A EXECUÇÃO DA TROCA DE TURMA É UMA OPERAÇÃO IRREVERSÍVEL E PODE OCORRER PERDAS DE NOTAS E FALTAS LANÇADAS,
          CASO AS DISCIPLINAS DA 'OFERTAS' DAS TURMAS NÃO SEJAM AS MESMAS.
        </div>
        <div className='col-12'>
          VOCÊ TEM CERTEZA QUE QUER CONTINUAR?
        </div>
      </div>),
      header: 'Atenção!',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept,
      style: { maxWidth: '600px', width: '80%' }
    });
  };

  if (loading)
    return <Loading full={true} />

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid'>
          <AlunoResumo aluno={getValues() as IAlunoForm} mostraPlanoPagamento={true} mostraFoto mostraTurma />
        </div>
        <div className='grid'>
          <Divider align="left" type="solid">
            <b>Origem</b>
          </Divider>
          <div className='md:flex col-12 pl-0 pt-0'>
            <div className="col-12 md:col-2 pt-0">
              <SCDropdown
                control={control}
                errors={errors}
                options={turmasOrigem}
                name="TurmaOrigem"
                label='Turma de Origem'
                // optionLabel='TurmaDescricao'
                optionLabel='Codigo'
                optionValue='TurmaId'
                required
                onChange={(e) => { dropOrigemChange(e) }}
              />
            </div>
            <div className="col-12 md:col-4 pt-0">
              <SCInputText
                errors={errors}
                value={turmaOrigemSelecionada}
                name="TurmaOrigem"
                label='Descrição'
                disabled={true}
                readOnly
              />
            </div>
          </div>
          <Divider align="left" type="solid">
            <b>Destino</b>
          </Divider>
          <div className='md:flex col-12 pl-0 pt-0'>
            <div className="col-12 md:col-2 pt-0">
              <SCDropdown
                control={control}
                errors={errors}
                options={turmasDestino}
                name="TurmaDestino"
                label='Turma de Destino'
                optionLabel='Codigo'
                optionValue='TurmaId'
                required
                onChange={(e) => { dropDestinoChange(e) }}
              />
            </div>
            <div className="col-12 md:col-4 pt-0">
              <SCInputText
                errors={errors}
                name="TurmaDestino"
                label='Descrição'
                value={turmaDestinoSelecionada}
                disabled={true}
                readOnly
              />
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
          <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='button' onClick={confirm} />
        </div>
      </form>
    </>
  );
};

export default AlunosForm;
