import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import IEstagio from '../interfaces/IEstagio';
import EstagioForm from './EstagiosForm';
import { format } from 'date-fns';


const Estagios = () => {
  const toast = useToast();
  const { usuario } = useAuth();
  const tarefa = 'FuncionarioForm';

  let emptyEstagio: IEstagio = {
    EstagiosCursosId: undefined,
    EstagiosId: undefined,
    ConvenioId: 0,
    Descricao: '',
    ContatoEmail: '',
    ContatoTelefone: '',
    ContatoNome: '',
    PeriodoContratoInicial: new Date(''),
    PeriodoContratoFinal: new Date(''),
    Codigo: 0,
    VagasOfertadas: 0,
    CursoId: 0,
    EstagioCursoItem: [],
    ESTAGIOEndereco: []
  };

  const [estagios, setEstagios] = useState<any>(null);
  const [estagioDialog, setEstagioDialog] = useState<boolean>(false);
  const [deleteEstagio, setDeleteEstagio] = useState<boolean>(false);
  const [estagio, setEstagio] = useState<IEstagio>(emptyEstagio);
  const [selectedEstagio, setSelectedEstagio] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyEstagio });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {

    setLoading(true)
    const result = await api.get('/estagios/list', { params: { Descricao: getValues()?.Descricao } });

    console.log(result.data)

    if (result.data)
      setEstagios(result.data);
    else
      setEstagios([]);
    setLoading(false)
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setEstagio(emptyEstagio);
    setSubmitted(false);
    setEstagioDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setEstagioDialog(false);
  };

  const editar = (estagio: any) => {
    estagio.PeriodoContratoInicial = estagio.PeriodoContratoInicial ? new Date(estagio.PeriodoContratoInicial) : new Date('')
    estagio.PeriodoContratoFinal = estagio.PeriodoContratoFinal ? new Date(estagio.PeriodoContratoFinal) : new Date('')

    setEstagio(estagio);
    setEstagioDialog(true);
  };

  const confirmDelete = (estagio: any) => {
    setEstagio(estagio);
    setDeleteEstagio(true);
    setEstagioDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/estagios', { params: { EstagiosId: estagio.EstagiosId } });
      console.log(resp.data);

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteEstagio(false);
      setEstagioDialog(false);
      setEstagio(emptyEstagio);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    console.log(data);
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  if (loading)
    return <Loading />

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Estágios</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " onClick={load} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>

          <Divider />

          {loading ? <Loading /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={estagios}
              onSelectionChange={(e) => setSelectedEstagio(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="Descricao" header="Descrição" sortable ></Column>
              <Column field="Codigo" header="Convênio" sortable headerStyle={{ width: 100 }}></Column>
              <Column header="Periodo Inicial" sortable headerStyle={{ width: 92 }} body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-center">
                    {format(new Date(rowData.PeriodoContratoInicial), 'dd/MM/yyyy')}
                  </div>
                );
              }} />

              <Column header="Periodo Final" sortable headerStyle={{ width: 92 }} body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-center">
                    {format(new Date(rowData.PeriodoContratoFinal), 'dd/MM/yyyy')}
                  </div>
                );
              }} />

              <Column body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}
          <SCDialog maximized={!util.isDesktop()} visible={estagioDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${estagio.EstagiosId === undefined ? 'Cadastrando ' : (deleteEstagio ? 'Excluíndo' : 'Alterando')} Estágio`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <EstagioForm
              setEstagioDialog={setEstagioDialog}
              estagio={estagio}
              reload={load}
              deleteEstagio={deleteEstagio}
              setDeleteEstagio={setDeleteEstagio}
              deletar={deletar}
            />
          </SCDialog>

        </div>
      </div>
    </div>
  );
};

export default Estagios;
