import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCCheckbox, SCDropdown, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import IOcorrenciaEstorno from '../interfaces/IOcorrenciaEstorno';
import { useAuth } from '../providers/auth';
import { IPeriodo } from '../interfaces/IUsuario';
import { CheckboxChangeParams } from 'primereact/checkbox';


interface Props {
  ocorrenciaEstorno?: IOcorrenciaEstorno;
  setOcorrenciaEstornoDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteOcorrenciaEstorno: boolean;
  setDeleteOcorrenciaEstorno(param: boolean): void;
}

const OcorrenciaEstornoForm: React.FC<Props> = ({ setOcorrenciaEstornoDialog, ocorrenciaEstorno, reload, deletar, deleteOcorrenciaEstorno, setDeleteOcorrenciaEstorno }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'OcorrenciaEstornoForm';

  const toast = useToast();

  const { periodoSelecionado } = useAuth()
  const periodo = periodoSelecionado?.Id

  const defaultValues: IOcorrenciaEstorno = ocorrenciaEstorno || {
    CodigosEstornoId: undefined,
    Codigo: '',
    Descricao: '',
    Desativado: false
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const onSubmit = async (data: IOcorrenciaEstorno) => {
    let resp: any = undefined;

    console.log(data)

    try {
      if (data.CodigosEstornoId === undefined) {
        resp = await api.post('/codigosestorno', data);

      } else {
        console.log(data)
        resp = await api.put('/codigosestorno', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setOcorrenciaEstornoDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setDeleteOcorrenciaEstorno(false);
    setOcorrenciaEstornoDialog(false);
    reset();
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className="col-12 md:col-2">
          <SCInputText
            autoFocus={true}
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="Codigo"
            label='Código da Ocorrência'
            maxLength={10}
            disabled={deleteOcorrenciaEstorno || ocorrenciaEstorno?.CodigosEstornoId !== undefined}
            required={!deleteOcorrenciaEstorno}
            tarefa={tarefa}
            tooltip='Máximo de 10 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
        <div className='col-12'>
          <SCCheckbox
            control={control}
            name="Desativado"
            label='Desabilitar essa ocorrência'
            disabled={deleteOcorrenciaEstorno}
          />
        </div>
        <div className='col-12 pl-0'>
          <div className="col-12 md:col-4">
            <SCInputText
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="Descricao"
              label='Descrição'
              maxLength={40}
              disabled={deleteOcorrenciaEstorno}
              required={!deleteOcorrenciaEstorno}
              tarefa={tarefa}
              tooltip='Máximo de 40 caracteres'
              tooltipOptions={{ event: 'focus' }}
            />
          </div>
        </div>

        <Divider />
        {deleteOcorrenciaEstorno ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default OcorrenciaEstornoForm;
