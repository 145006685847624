import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCInputText, SCInputMask, SCFieldset } from '../components';
import { useToast } from '../context/ToastContext';
import IPlanoConta from '../interfaces/IPlanoConta';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';

interface Props {
  planoConta?: IPlanoConta;
  setAltPlanoContaDialog(param: boolean): void;
  reload(): void;
}

const PlanoContaAlterarForm: React.FC<Props> = ({ setAltPlanoContaDialog, planoConta, reload }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'PlanoContaForm';

  const toast = useToast();
  const [areaSelecionada, setAreaSelecionada] = useState<any>(null);
  const [verificaClassificacao, setVerificaClassicacao] = useState<boolean>(true);
  const [checked, setChecked] = useState(false);

  const defaultValues: IPlanoConta = planoConta || {
    PlanoContaId: undefined,
    Classificacao: '',
    Conta: '',
    Descricao: '',
    Desativado: false,
    Tipo: '',
    DescricaoSimplificada: '',
    ContabilizaIR: false,
    ContabilizaDQ: false,
    Valor: undefined,
    Percentual: undefined,
    ClassificacaoPara: '',
    ContaPara: ''
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const onSubmit = async (data: IPlanoConta) => {
    let resp: any = undefined;

    const classificacaoPara = data?.ClassificacaoPara;
    const novaClassificacao = classificacaoPara?.replace(/\./g, '');
    const contaPara = data?.ContaPara;

    console.log(novaClassificacao)
    console.log(contaPara)

    data.Classificacao = novaClassificacao;
    data.Conta = getValues()?.ContaPara;

    if (data.Conta === '00000') {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Conta não pode ser 00000' });
      return false
    }

    if (data.Conta) {
      if (data.Conta.length < 5) {
        toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Conta precisa ter 5 dígitos' });
        return false
      }
    }

    try {
      resp = await api.put('/planoContas/updateClassiConta', {
        PlanoContaId: data.PlanoContaId,
        Classificacao: data.Classificacao,
        Conta: data.Conta
      });

      if (resp) {
        toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
        reload();
        setAltPlanoContaDialog(false);
      }

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data });
    }
  }

  const watchClassi = watch('Classificacao')

  useEffect(() => {
    if (watchClassi?.length === 14)
      setVerificaClassicacao(false)
    else
      setVerificaClassicacao(true)
  }, [watchClassi])

  useEffect(() => {
    if (planoConta) {
      setValue('ClassificacaoPara', planoConta.Classificacao)
      setValue('ContaPara', planoConta.Conta)
    }
  }, []);

  const cancelar = (e: any) => {
    setAltPlanoContaDialog(false);
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-1'>
      <div className='grid justify-content-around'>
        <SCFieldset legend='Alterar de' className='col-12 md:col-5 mb-3 md:mb-0'>
          <div className='grid'>
            <div className="col-12 md:col-6 lg:col-5">
              <SCInputMask
                mask="9?.9.99.99.9999"
                control={control}
                errors={errors}
                style={{ textTransform: 'uppercase' }}
                name="Classificacao"
                label='Classificação'
                maxLength={14}
                required
                disabled={planoConta?.PlanoContaId !== undefined}
                tooltip='Máximo de 14 caracteres'
                tooltipOptions={{ event: 'focus' }}
              />
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <SCInputText
                control={control}
                errors={errors}
                name="Conta"
                label='Conta'
                maxLength={5}
                required
                disabled={planoConta?.PlanoContaId !== undefined}
                tarefa={tarefa}
                tooltip='Máximo de 5 caracteres'
                tooltipOptions={{ event: 'focus' }}
              />
            </div>
          </div>
        </SCFieldset>

        {/* Para */}

        <SCFieldset legend='Para' className='col-12 md:col-5'>
          <div className='grid'>
            <div className="col-12 md:col-6 lg:col-5">
              <SCInputMask
                mask="9?.9.99.99.9999"
                control={control}
                errors={errors}
                style={{ textTransform: 'uppercase', color: 'blue' }}
                name="ClassificacaoPara"
                label='Classificação'
                maxLength={14}
                required
              />
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <SCInputText
                control={control}
                errors={errors}
                name="ContaPara"
                label='Conta'
                maxLength={5}
                required
                tarefa={tarefa}
              />
            </div>
          </div>
        </SCFieldset>
      </div>

      <Divider />
      <div className="grid">
        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
          <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
        </div>
      </div>
    </form>



  );
};

export default PlanoContaAlterarForm;
